import React from 'react'
import CommonModal from '../../../../components/modal/CommonModal'
import useChatStore, { deleteMessage, getChatList } from '../../../../app/stores/others/chatStore'
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/button/CommonButton';

const ChatDeleteModal = () => {

    const { showDeleteChatModal, setShowDeleChatModal,selectChat,chat_search,message_array,setSelectChat} = useChatStore();

    const { t } = useTranslation();

    return (
        <div>
            <CommonModal
                showModal={showDeleteChatModal}
                setShowModal={setShowDeleChatModal}
                modalTitle={t("Confirmation")}
                modalSpace={true}
                mainContent={
                    <>
                        <div className='my-s20 font-fw500 text-fs16 text-cMainBlack'>{t("Are you sure you want to delete the conversation?")}</div>
                        <div className='flex justify-end'>
                            <CommonButton
                                onClick={async () => {
                                    const success = await deleteMessage(selectChat?.id);
                                    if (success) {
                                        await getChatList(chat_search, false, false,message_array?.total+1);
                                        setSelectChat({});
                                    }
                                }
                                }
                                btnLabel={t('Delete')} colorType='danger' />
                        </div>
                    </>
                }
            />
        </div>
    )
}

export default ChatDeleteModal