/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CommonTable from "../../../../../components/table/CommonTable";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import useGeneralStore from "../../../../../app/stores/others/generalStore";
import useRequestStore, {
  getTableViewRequestsData,
  handleRequestOrder,
  isTableFiltered,
} from "../../../../../app/stores/others/requestStore";
import {
  k_requests_status,
  k_orderBy,
  k_request_paths,
  common_take,
} from "../../../../../app/utility/const";
import { kuGetRequestsNew } from "../../../../../app/urls/commonUrl";
import { formatDateTime } from "../../../../../app/utility/utilityFunctions";
import InvitationFilter from "./components/InvitationFilter";
import Clamp from "react-multiline-clamp";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";

export default function Invitation({ tableTitleClassName }) {
  const {
    invitation,
    search_key,
    setSearchKey,
    request_take,
    setRequestTake,
    setRequestApiUrl,
    setRequestsOrder,
    setIsAsc,
    request_filter_form,
    resetRequestFilterForm,
    resetFilterRange,
    resetRequestFilterFormCopy,
    is_asc,
    requests_order,
    request_filter_form_copy,
    hasRequestFilter
  } = useRequestStore();
  const { path_record } = useGeneralStore();

  const [showModal, setShowModal] = useState(false);
  const [searchValue] = useDebounce(search_key, 500);
  const navigateTo = useNavigate();
  const { t } = useTranslation();

  const headers = [
    // { index: 0, name: "Req. ID", width: '2', onClickAction: () => { console.log('click event: #'); } },
    { isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.customer_from, sort: is_asc, name: t("Customer"), onClickAction: () => handleRequestOrder(k_orderBy.customer_from, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.title, sort: is_asc, name: t("Title"), onClickAction: () => handleRequestOrder(k_orderBy.title, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.pickup_starts_at, sort: is_asc, name: t("Bid Ends"), onClickAction: () => handleRequestOrder(k_orderBy.pickup_starts_at, getData), showSortImage: true },
  ];

  const getData = async () => {
    const success = await getTableViewRequestsData({
      data_set: k_requests_status.invitation,
      filter: {
        transport_type: request_filter_form?.transport_types?.map(item => item?.id),
        bids_end_from: request_filter_form?.bids_end_from,
        bids_end_to: request_filter_form?.bids_end_to,
      },
    });
    if (success) window.scrollTo(0, 0);
  };

  const resetTable = async () => {
    if (searchValue?.length > 0) await setSearchKey("");
    await setRequestApiUrl(kuGetRequestsNew);
    if (request_take !== common_take) await setRequestTake(common_take);
    await setRequestsOrder(k_orderBy.pickup_starts_at);
    await setIsAsc(1);
    await resetRequestFilterForm();
    await resetFilterRange();
    await resetRequestFilterFormCopy();
    const success = await getTableViewRequestsData({
      data_set: k_requests_status.invitation,
      filter: {},
      hasFilter: false
    });
    if (success) window.scrollTo(0, 0);
  };

  const handleInitialSetup = async () => {
    if (!hasRequestFilter) {
      resetTable();
    } else {
      getData();
    }
  };

  useEffect(() => {
    handleInitialSetup();
  }, []);

  useEffect(() => {
    getData();
  }, [searchValue]);
  const contents = invitation?.data?.map((item) => {
    return {
      contents: [
        {value: item?.request_id},
        {value: <Clamp lines={1}> {item?.customer_from ?? ""}</Clamp>},
        {value: <Clamp lines={1}> {item?.title ?? ""}</Clamp>},
        {value: item?.transport_type},
        {value: <Tooltip title={"Request | Stops | Packages"}>{
          "1 | " +
          (item?.stops_count ?? 0) +
          " | " +
          (item?.products_count ?? 0)}
          </Tooltip>
        },
        {value: formatDateTime(item?.pickup_starts_at, item?.pickup_starts_time)},
      ],
      clickAction: () => navigateTo(`/requests/invitation/details/${item?.id}`),
    };
  });

  return (
    <>
      <CommonTable
        tableTitle={t("Invitation") + ` (${invitation?.total ?? 0})`}
        showSearchBox={true}
        showTopRightFilter={true}
        showTakeOption={true}
        firstIndexColWidth="flex justify-start !w-max"
        showPagination={true}
        showPageCountText={true}
        tableHeaders={headers}
        tableContents={contents}
        widths="90px 23% auto 18% 120px 13%"
        hasClickFunction={true}
        outerPadding="p-s0"
        topRightFilterComponentOnClick={() => setShowModal(true)}
        tableTitleClassName={tableTitleClassName}
        paginationObject={invitation}
        withClearSearch={true}
        onSearchClear={() => {
          setSearchKey("");
        }}
        searchValue={search_key}
        searchOnChange={(e) => {
          setRequestApiUrl(kuGetRequestsNew);
          setSearchKey(e.target.value);
        }}
        currentTakeAmount={request_take}
        withReloader={true}
        onReload={resetTable}
        filtered={isTableFiltered(
          k_requests_status.invitation,
          request_filter_form_copy,
        )}
        takeOptionOnChange={async (e) => {
          await setRequestTake(e.target.value);
          await setRequestApiUrl(kuGetRequestsNew);
          getData();
        }}
        paginationOnClick={async (url) => {
          await setRequestApiUrl(url);
          getData();
        }}
      />

      <InvitationFilter showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
