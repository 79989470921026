import React from 'react'
import CommonViewComponent from './CommonViewComponent'

const CommonDetailsInfo = ({ title = "", description = "", address = false, className = "", }) => {
    return (
        <div className="">
            <CommonViewComponent
                labelText={title}
                value={description}
                address={address}
                className={className}
            />
        </div >
    )
}

export default CommonDetailsInfo