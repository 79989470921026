

export const adminRouteMapper = [

    // {
    //     path: "/favorite-address",
    //     element: <ProtectedRoute />,
    //     children: [
    //         { path: "", element: <Layout><FavoriteAddress /></Layout> },

    //     ],
    // },
    // {
    //     path: "/settings",
    //     element: <ProtectedRoute />,
    //     children: [
    //         { path: "", element: <Layout><Settings /></Layout> },

    //     ],
    // },
];