import React, { useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { iDropdownArrowIcon } from '../../app/utility/imageImports'

const MultipleSelectCombo = ({
  label="Select",
  onChange = () => {},
  disabled=false,
  dataArray=[{title: "hello", id: 1}, {title: "bye", id: 2}],
  isInvalid=false,
  selectedId=[],
  show_asterisk=false,
  noDataText="No data found!"
}) => {
    const [open, setOpen] = useState(false);
    const [isOnFocus, setIsOnFocus] = useState(false)
  const handleChange = (e, value) => {
    //return the array of objects that are selected.
    onChange(value)
  }

  const handleOpen = () => {
    setOpen(true)
    setIsOnFocus(true)
  }

  const handleClose = () => {
    setOpen(false)
    setIsOnFocus(false)
  }

  return (
    <div>
      <Autocomplete
        disabled={disabled}
        multiple
        id="tags-standard"
        options={dataArray}
        getOptionLabel={(option) => option?.title}
        onChange={handleChange}
        value={selectedId}
        noOptionsText={noDataText}
        popupIcon={<img src={iDropdownArrowIcon} />}
        disableCloseOnSelect={true}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => {
            setOpen(false)
            setIsOnFocus(false)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={label}
            className='w-full'
            focused={isOnFocus}
            autoFocus={false}
            style={{width: "100%"}}
            SelectProps={{
                onOpen: handleOpen, // Handle the open event
                onClose: handleClose,
            }}
            sx={{
                // normal label color
                "& label": {
                    color: '#48504C',
                    fontFamily: 'fEuclidLimadiRegular',
                    fontWeight: !selectedId ? '' : 'normal',
                },
                '& .MuiFormLabel-asterisk': {
                    color: show_asterisk && disabled ? '#BCC2BF' : '#48504C',
                    // color: show_asterisk && '#48504C',
                },

                "& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
                    borderBottomStyle: 'solid !important',
                    borderBottom: disabled ? '1px solid #D7DAD8' : '2px solid #949494',
                },

                "&:hover .MuiInput-root:before": {
                    borderBottom: disabled ? '1px solid #D7DAD8 !important' : '2px solid #949494 !important',
                },

                '& MuiSvgIcon-root:before': {
                    color: '#F0554B',
                },

                // label color when input is focused
                "& label.Mui-focused": {
                    color: '#48504C',
                    fontWeight: 'normal',
                    backgroundColor: '#FFFFFF',
                },

                "& .Mui-disabled": {
                    cursor: "not-allowed",
                    color: "#939D98",
                },

                // underline color for input with variant='standard'
                "& .MuiInput-underline:after": {
                    borderBottom: isInvalid ? "1px solid #F0554B" : disabled ? '1px solid #D7DAD8' : '1px solid #949494',
                },
                "& .MuiInput-underline:before": {
                    borderBottomStyle: 'solid',
                    borderBottom: isInvalid ? "1px solid #F0554B" : disabled ? '1px solid #D7DAD8' : '1px solid #949494',
                },

                // underline color for input with variant='filled'
                "& .MuiFilledInput-underline:after": {
                    borderBottom: isInvalid ? "1px solid #F0554B" : disabled ? '1px solid #D7DAD8' : '1px solid #949494',
                },
                "& .MuiFilledInput-underline:before": {
                    borderBottomStyle: 'solid',
                    borderBottom: isInvalid ? "1px solid #F0554B" : disabled ? '1px solid #D7DAD8' : '1px solid #949494',
                },

                // underline color for input with variant='outlined'
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: isInvalid ? "#F0554B" : '#D7DAD8',
                    },
                    "&:hover fieldset": {
                        borderColor: isInvalid ? "#000000" : '#D7DAD8',
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: '#D7DAD8',
                        backgroundColor: '#FFFFFF',
                    }
                },

                "&:hover .MuiInput-underline:before": {
                    borderBottomStyle: 'solid',
                    borderBottom: isInvalid ? "2px solid #F0554B" : disabled ? "1px solid #949494" : '2px solid #949494',
                },

                "& .MuiInputBase-input": {
                    fontSize: 14,
                    fontFamily: 'fEuclidLimadiRegular',
                    fontWeight: 'normal',
                    backgroundColor: 'transparent !important',
                },
                "& .Mui-focused .MuiInputBase-input": {
                    backgroundColor: 'transparent !important',
                },

                '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px white inset',
                    backgroundColor: '#FFFFFF !important',
                },
                "& .MuiSelect-select[value='null']": {
                    backgroundColor: 'transparent !important',
                },
            }}
          />
        )}
      />
    </div>
  )
}

export default MultipleSelectCombo