import React from 'react';
import useCreateRequestStore from '../../app/stores/others/createRequestStore';
import CommonModal from '../modal/CommonModal';
import CommonEmptyData from '../emptyData/CommonEmptyData';
import { useTranslation } from 'react-i18next';
import { FcCheckmark } from "react-icons/fc";
import { useState } from 'react';

const FavAddressModal = ({ showModal, setShowModal, onClick, address = "", lat = "", lng = "" }) => {

    const { favorite_addresses } = useCreateRequestStore();

    const { t } = useTranslation();

    const [selectId, setSelectId] = useState(null);

    return (
        <CommonModal
            showModal={showModal}
            setShowModal={setShowModal}
            modalTitle={t("Favorite Addresses")}
            mainContent={
                <>
                    <div className='h-auto max-h-[70vh] overflow-y-auto'>
                        {
                            favorite_addresses?.length > 0 ? favorite_addresses?.map((item, index) => (
                                <div onClick={() => {
                                    onClick(item);
                                    setSelectId(item?.id)
                                    setShowModal(false);
                                }} className="flex justify-between p-2 my-2 rounded text-cGray800 font-fw500 text-fs16 cp hover:bg-cPrimary100" key={index}>
                                    {item?.title}
                                </div>
                            ))
                                :
                                <CommonEmptyData title={t('No Address Available!')} />
                        }
                    </div>
                </>
            }
        />
    )
}

export default FavAddressModal