import React from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CommonViewComponent from '../../../../../../../components/viewer/CommonViewComponent';
import useRequestStore from '../../../../../../../app/stores/others/requestStore';

export default function AcknowledgeActionInfo() {

  const { t } = useTranslation();

  const { request_details } = useRequestStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='mb-3 sub-title text-cGray800'>{t("Acknowledgement")}</div>
      <CommonViewComponent labelText={t("Comment")} value={request_details?.acknowledge} />
    </>
  )
}
