import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './views/App';
import { BrowserRouter } from 'react-router-dom';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Register the service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((err) => {
      console.error('Service Worker registration failed:', err);
    });
}
// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
  console.warn = () => { }
}


root.render(
  <div>
    {/* <React.StrictMode> */}
    <BrowserRouter>
      <Suspense fallback="">
        <App />
      </Suspense>
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </div>
);

