import React from 'react'
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const CommonViewComponent = ({
  onClick = () => { },
  labelText = 'some label',
  address = false,
  value = ' ',
  underline = false,
  className = '',
  selectComponent = false,
  disabled = false,
  capitalizedData = false,
  disableColor = false,
  show_asterisk = false
}) => {
  const { t } = useTranslation();

  return (
    selectComponent ?
      <div onClick={onClick} className='pt-0 w-full h-[35px] relative'>
        <div className={`flex items-center justify-between border-b-[1.2px]  ${disableColor ? "border-solid border-b-[#939d98] cursor-not-allowed" : "border-solid border-b-[#BCC2BF] hover:border-b-[2px] hover:border-b-cGray800"}  `}>
          <div className={`capitalize flex ${disableColor ? "text-[#939d98] font-normal text-fs15" : "text-[#48504C]"} `}>
            {labelText}
            {show_asterisk && <div className='ml-1 max-h-[3px] min-h-[3px]'>*</div>}
          </div>
          <div style={{ position: 'absolute', bottom: '10px', right: '-7.5px', padding: '0', margin: '0' }}>
            <ArrowDropDownIcon style={{ color: '#6C7872' }} /> {/* Change the color here */}
          </div>
        </div>
        {/* <div className="mt-1.5 w-full h-s1 bg-cCommonViewText"></div> */}
      </div>
      :
      <div onClick={onClick} className={`flex flex-col justify-end  ${underline ? 'w-full' : 'w-fit'} ${className}`}>
        <div className='font-semibold text-fs10 text-cGray600'>{t(labelText)}</div>
        <div className={`font-normal break-all text-fs14 min-h-4 text-cGray800 ${capitalizedData ? "capitalize" : ""}`}>
          {
            address ?
              <a target={'_blank'} rel='noreferrer' href={`http://maps.google.com/?q=${value}`} className='break-all mt-s4 text-fs14 text-cMainBlack font-fw400 '>
                {value ? value : 'No data available.'}
              </a> :
              <div className="whitespace-pre-line">{value}</div> ?? " "

          }
        </div>
        {underline ? <div className="w-full h-s1 bg-cCommonViewText"></div> : ''}
      </div>
  )
}

export default CommonViewComponent