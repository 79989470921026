/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, InputAdornment, TextField, ThemeProvider } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { iHidePass, iShowPass } from '../../app/utility/imageImports';
import { specialCharacters, theme, theme2 } from '../../app/utility/utilityFunctions';
import { useTranslation } from 'react-i18next';

// author: @zamanshahed

// use for normal text input field and textarea input field
// form validations are handleable by default way

const CommonInput = ({
    valuePaddingRight = "0px",
    type = "text",
    preventDoubleSpacePeriod = true,                              //allowed types: text, password, email, number, 
    labelText = "__some input title__",         //enter a label string or enter empty string for no label
    value = "",
    onChange = () => { },
    name = null,
    onClick,
    max_input,
    min_input,
    is_readonly = false,
    className,
    textarea = false,                           //if true, input field will be a textarea
    rows = 3,
    max_rows = 5,
    togglePasswordBtn = false,                  //if true, toggle password hide/show button will be shown
    required = false,
    show_asterisk = true,
    disabled = false,
    min = 0,
    max,
    autoFocus = false,
    multiline = true,
    parentClassName,
    onBlurEvent = () => { },
    onFocusChange = () => { },
    isInvalid = false,
    onKeyUp
}) => {
    const [inputValue, setInputValue] = useState(value);
    const [inputOnFocus, setInputOnFocus] = useState(false);
    const [inputEmpty, setInputEmpty] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [inputType, setInputType] = useState(type);
    const [charCount, setCharCount] = useState(0);

    const handleClose = () => {
        setInputOnFocus(false);
    }

    const { t } = useTranslation();
    // to validate numeric input length and force to stop input if limit exceeds
    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const handlePaste = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData("text/plain");
        const numericValue = pastedData.replace(/\D/g, ""); // Use regex to remove non-numeric characters
        setInputValue(numericValue);
        setInputEmpty(false);

        event.preventDefault(); // Prevent the default paste behavior
    };

    const handleBeforeInput = (e) => {
        if (type === "number" && e.data === " ") e.preventDefault();
        // Check if the inserted text is a period and the last character is a space
        if (preventDoubleSpacePeriod && e.data === ". ") e.preventDefault();
    };

    useEffect(() => {
        if (!value) {
            setInputValue("");
            setInputEmpty(true);
            setInputOnFocus(false);
        }
        if ((typeof value === 'string' || typeof value === 'number' || typeof value === '' || value === null) && value !== " ") {
            setInputValue(value ?? "");
        }
        if (!value && inputValue) {
            setInputEmpty(true);
            setInputOnFocus(false);
        }
        if (inputValue) {
            setInputEmpty(false);
            setInputOnFocus(true);
        } else {
            setInputEmpty(true);
            setInputOnFocus(false);
        }
        setCharCount(value?.length);
    }, [value, inputValue]);

    const inputRef = useRef < HTMLElement > (null);

    useEffect(() => {
        if (!inputRef.current) return;
        inputRef.current.getElementsByTagName("input")[0].focus();
    }, [value, inputValue]);

    return (
        <div className={`relative bg-white w-full mt-s15 ${className} ${parentClassName}`}>
            <ThemeProvider theme={show_asterisk ? theme2 : theme}>
                <TextField
                    onKeyUp={onKeyUp}
                    onPaste={type === "number" ? handlePaste : null}
                    id="standard-basic"
                    variant="standard"
                    label={type === "date" ? " " : labelText}
                    onWheel={(e) => {e.target.blur()}}
                    onFocus={() => { setInputOnFocus(true); onFocusChange(true); }}
                    onBlur={(e) => {
                        onBlurEvent(e)
                        setInputOnFocus(false)
                        onFocusChange(false);
                    }
                    }
                    onEmptied={() => setInputEmpty(true)}
                    focused={inputOnFocus}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    required={required}
                    onClick={onClick}
                    name={name}

                    multiline={textarea ? multiline ?? true : false}
                    minRows={rows ?? 3}
                    maxRows={max_rows ?? 5}

                    // data-date-format={type === "date" ? "DD MMMM YYYY" : ""}

                    inputProps={{
                        ref: inputRef,
                        readOnly: is_readonly,
                        maxLength: max_input ? max_input : textarea ? 500 : 4096,
                        minLength: min_input ? min_input : 0,
                        autoFocus: autoFocus,
                        autoComplete: "off",
                        min: min,
                        max: max,
                        onClose: handleClose,
                        // step: 3600,
                    }}
                    onKeyDown={(e) => {
                        if (type === "date") e.preventDefault();
                        if (type === 'number' && specialCharacters.includes(e.key)) e.preventDefault();
                    }}
                    
                    type={inputType}
                    onInput={maxLengthCheck}
                    value={inputValue}
                    onBeforeInput={handleBeforeInput}
                    onChange={(e) => {
                        if (e.target.value.length) {
                            setInputEmpty(false);
                            setInputOnFocus(true);
                        }

                        setInputValue(e.target.value);
                        onChange(e);
                    }}

                    // InputProps={{
                    //     endAdornment: (
                    //       <InputAdornment position="end">
                    //         {value && (
                    //           <IconButton onClick={()=> onChange("")} edge="end">
                    //             X
                    //           </IconButton>
                    //         )}
                    //       </InputAdornment>
                    //     ),
                    //   }}

                    sx={{
                        // normal label color
                        "& label": {
                            color: '#48504C',
                            fontFamily: 'fEuclidLimadiRegular',
                            fontWeight: inputEmpty ? '' : 'normal',
                        },
                        '& .MuiFormLabel-asterisk': {
                            color: show_asterisk ? (disabled ? "#939d98" : '#48504C') : null,
                        },
                        "& .MuiInput-root:before": {
                            borderBottom: disabled ? "1px solid #D7DAD8 !important" : isInvalid ? "1px solid #F0554B" : '1px solid #949494',
                            cursor: disabled ? "default" : "pointer",
                            // borderBottom: isInvalid ? "1px solid #F0554B" : '1px solid rgba(0, 0, 0, 0.42)',
                        },
                        "& .MuiInput-underline.MuiInput-underline:after": {
                            borderBottom: disabled ? "1px solid #D7DAD8 !important" : isInvalid ? "1px solid #F0554B" : '1px solid #949494',
                        },
                        "& .MuiFilledInput-underline.MuiFilledInput-underline:after": {
                            borderBottom: disabled ? "1px solid #D7DAD8 !important" : isInvalid ? "1px solid #F0554B" : '1px solid #949494',
                        },
                        // "& .MuiOutlinedInput-root.MuiOutlinedInput-root": {
                        //     "&.Mui-focused.Mui-focused fieldset": {
                        //         borderColor: isInvalid ? "#F0554B" : '#949494',
                        //     }
                        // },

                        //label color when input is focused
                        "& label.Mui-focused": {
                            color: '#48504C',
                            fontWeight: 'normal',
                        },

                        // focused color for input with variant='standard'
                        // "& .MuiInput-underline:after": {
                        //     borderColor: isInvalid ? "#F0554B" : '#D7DAD8',
                        // },

                        // focused color for input with variant='filled'
                        // "& .MuiFilledInput-underline:after": {
                        //     borderColor: isInvalid ? "#F0554B" : '#D7DAD8',
                        // },
                        // focused color for input with variant='outlined'
                        // "& .MuiOutlinedInput-root": {
                        //     "&.Mui-focused fieldset": {
                        //         borderColor: isInvalid ? "#F0554B" : '#D7DAD8',
                        //     }
                        // },
                        "& .MuiInputBase-input": {
                            fontSize: 14,
                            fontFamily: 'fEuclidLimadiRegular',
                            fontWeight: 'normal',
                            paddingRight: valuePaddingRight,
                            cursor: disabled ? "default" : "pointer"
                        },
                        "& .Mui-focused .MuiInputBase-input": {
                            backgroundColor: '#FFFFFF',
                        },
                        '&:-webkit-autofill': {
                            WebkitBoxShadow: '0 0 0 1000px white inset',
                            backgroundColor: 'transparent !important', // Ensure the background is transparent
                        },

                        "&:hover .MuiInput-root:before": {
                            borderBottom: disabled ? "1px solid #D7DAD8 !important" : isInvalid ? "2px solid #F0554B !important" : '2px solid #949494 !important',
                        },
                        "&:hover .MuiInput-underline:before": {
                            borderBottom: disabled ? "1px solid #D7DAD8 !important" : isInvalid ? "2px solid #F0554B !important" : '2px solid #949494 !important',
                        },

                    }}

                    className={`bg-transparent w-full pb-0 mb-0 ${className}`}
                />
            </ThemeProvider>


            {
                textarea ? <div className={`absolute -bottom-[22px] right-0 ${disabled && "text-cGray500"}`}>
                    {charCount ?? 0} {max_input ? ("/ " + max_input) : "/500"}
                </div>
                    : ""
            }

            {/* password show/hide button */}
            {togglePasswordBtn || type === 'password' ? (
                <img
                    onClick={() => {
                        setShowPassword(!showPassword);
                        if (inputType === "password") {
                            setInputType("text");
                        } else {
                            setInputType("password");
                        }
                    }}
                    src={showPassword === true ? iShowPass : iHidePass}
                    alt="show-hide-icon"
                    className="absolute p-2 cursor-pointer top-4 right-3"
                />
            ) : (
                ""
            )}
        </div>
    )
}

export default CommonInput

