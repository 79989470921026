/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import useNotificationStore, { getNotification, handleNotificationOrder, notificationSeenFn } from '../../../app/stores/others/notificationStore';
import { formatDate, changePageTitle } from '../../../app/utility/utilityFunctions';
import CommonTable from '../../../components/table/CommonTable';
import { kuGetTableViewNotification } from '../../../app/urls/commonUrl';
import { common_take, k_Notification_order_by } from '../../../app/utility/const';
import Clamp from 'react-multiline-clamp';
import { useTranslation } from 'react-i18next';

const Notification = () => {

    const { t } = useTranslation();

    const { table_data, search_key, setSearchKey, take, setTake, setApiUrl, setOrder, setIsAsc, is_asc, order, setNotificationDetails, setShowNotificationDetailsModal } = useNotificationStore();

    const [searchValue] = useDebounce(search_key, 500);

    const headers = [
        { name: "#", placement: "justify-center items-center" },
        { isActive: order === k_Notification_order_by.title, sort: is_asc, name: t("Title"), onClickAction: () => { handleNotificationOrder(k_Notification_order_by.title, getNotification) }, showSortImage: true},
        { isActive: order === k_Notification_order_by.details, sort: is_asc, name: t("Details"), onClickAction: () => { handleNotificationOrder(k_Notification_order_by.details, getNotification) }, showSortImage: true},
        { isActive: order === k_Notification_order_by.created_date, sort: is_asc, name: t("Date & Time"), onClickAction: () => { handleNotificationOrder(k_Notification_order_by.created_date, getNotification) }, showSortImage: true },
        { isActive: order === k_Notification_order_by.status, sort: is_asc, name: t("Status"), onClickAction: () => { handleNotificationOrder(k_Notification_order_by.status, getNotification) }, showSortImage: true },
    ];

    const resetTable = async () => {
        if (searchValue?.length > 0) await setSearchKey('');
        await setApiUrl(kuGetTableViewNotification);
        if (take !== common_take) await setTake(common_take);
        await setOrder(null);
        await setIsAsc(0);
        getNotification();
    }

    const handleInitialSetup = async () => {
        resetTable();
    }

    useEffect(() => {
        changePageTitle(t('Limadi | Notification'))
        handleInitialSetup();
    }, []);

    useEffect(() => { getNotification() }, [searchValue]);

    const contents = table_data?.data?.map((item, index) => {
        return {
            contents: [
                {value: (table_data?.current_page * common_take - common_take) + index + 1},
                {value: <Clamp lines={2}> {item?.title ?? ''}</Clamp>},
                {value: <Clamp lines={2}> {item?.description ?? ''}</Clamp>},
                {value: item?.created_date && item?.created_time ? `${formatDate(item?.created_date)}, ${item?.created_time}` : ''},
                {value: item?.status, className: "capitalize"},
            ],
            clickAction: () => {
                notificationSeenFn(item?.id);
                setNotificationDetails(item);
                setShowNotificationDetailsModal(true)
            }
        }
    })

    return (
        <div>
            <CommonTable
                tableTitle={t('Notification')}
                tableTitleClassName={'title'}
                showSearchBox={true}
                showTopRightFilter={false}
                showTakeOption={true}
                showPagination={true}
                showPageCountText={true}
                outerPadding='p-s0'
                paginationObject={table_data}
                tableHeaders={headers}
                tableContents={contents}
                hasClickFunction={true}
                widths="50px auto 40% 20% 90px"

                withClearSearch={true}
                onSearchClear={() => { setSearchKey("") }}
                searchValue={search_key}
                searchOnChange={(e) => {
                    setApiUrl(kuGetTableViewNotification);
                    setSearchKey(e.target.value);
                }}

                currentTakeAmount={take}
                withReloader={true}
                onReload={resetTable}
                takeOptionOnChange={async (e) => {
                    await setTake(e.target.value);
                    await setApiUrl(kuGetTableViewNotification);
                    getNotification();
                }}
                paginationOnClick={async (url) => {
                    await setApiUrl(url);
                    getNotification()
                }}
            />
        </div>
    );
};

export default Notification;