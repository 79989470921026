import React, { useEffect } from 'react';
import { iNoData } from '../../app/utility/imageImports';

import { useTranslation } from 'react-i18next';

const CommonEmptyData = ({ title = "" }) => {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className='flex flex-col items-center justify-center h-[70vh]'>
            <img className='flex items-center justify-center' src={iNoData} alt='' />
            <div className='mt-5 normal-case font-bold text-center text-cGray500 font-fs24'>
                {title
                    ? title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()
                    : t("Nothing in history!")
                }
            </div>
        </div>
    );
};

export default CommonEmptyData;