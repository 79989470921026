import React from 'react'
import { iGrayCross } from '../../../../app/utility/imageImports';
import ImageUploadViewModal2 from '../../../../components/imageUpload/ImageUploadViewModal2';
import { useState } from 'react';

const SendImageView = ({ textFieldCount="305px", image = "", clearImage = () => { } }) => {

    const [show_modal, setShowModal] = useState(false);

    return (
        <div
            style={{ height: textFieldCount }}
            className="relative flex items-center justify-center bg-cGreen50">

            <div className='absolute mb-4 cursor-pointer left-4 top-4'>
                <img
                    src={iGrayCross}
                    onClick={() => {clearImage();}}
                    alt="clear-search"
                    className=" max-w-[16px] min-w-[16px] max-h-[16px] min-h-[16px] "
                />
            </div>
            <div className="flex items-center justify-center mx-4">
                <div
                    onClick={() => { setShowModal(true) }}
                    className='flex items-center justify-center cursor-pointer'
                >
                    <img src={image} alt="" className='rounded-br6 max-h-[calc(100vh-500px)] object-cover' />
                </div>
            </div>
            <ImageUploadViewModal2 fullView={true} show_modal={show_modal} setShowModal={setShowModal} src={image} url={image} isTransparent={true}/>
        </div>
    )
}

export default SendImageView