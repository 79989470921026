import React from 'react'
import useCarStore from '../../../../app/stores/company/carStore';
import { useTranslation } from 'react-i18next';
import DummyProfileImage from '../../../../components/image/DummyProfileImage';
import { iNoCar } from '../../../../app/utility/imageImports';
import CommonViewComponent from '../../../../components/viewer/CommonViewComponent';

const Details = () => {

    const { carDetails } = useCarStore();

    const { t } = useTranslation();

    return (
        <div>
            <DummyProfileImage isFullRoundedImage className='flex items-center justify-center object-cover !border-[1px] !border-cGray200' dummyImage={iNoCar} src={carDetails?.image} />
            <div className='mt-4 space-y-3'>
                <CommonViewComponent labelText={t('Name')} value={carDetails?.name} />
                <CommonViewComponent labelText={t('License Number')} value={carDetails?.car_license_plate_number} />
                <CommonViewComponent flexDirection='flex-col' labelText={t('Car Specification')} value={carDetails?.comment} />
            </div>
        </div>
    )
}

export default Details