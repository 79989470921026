export const date_width = "max-w-[95px] min-w-[95px]"
export const floor_width = "max-w-[70px] min-w-[70px]"
export const address_width = "2xl:max-w-[480px] 2xl:min-w-[480px] xl:max-w-[300px] xl:min-w-[300px] lg:max-w-[250px] lg:min-w-[250px] min-w-[250px]"
export const comment_width = "w-[100%]"
export const attachment_width = "max-w-[60px] min-w-[60px]"
export const time_width = "max-w-[70px] min-w-[70px]"
export const product_width = "table-view-product"
export const duration_width = "max-w-[80px] min-w-[80px]"

export const index_width = "max-w-[20px] min-w-[20px]"


