/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDebounce } from 'use-debounce';
import { common_take, k_orderBy } from '../../../app/utility/const';
import CommonTable from '../../../components/table/CommonTable';
import useDriverStore, { getDriversTableData, handleOrder } from '../../../app/stores/company/driverStore';
import { kuDriverTableData } from '../../../app/urls/companyUrl';
import CommonButton from '../../../components/button/CommonButton';
import DriverDetailsModal from './modal/DriverDetailsModal';
import Clamp from 'react-multiline-clamp';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useGeneralStore from '../../../app/stores/others/generalStore';
import { changePageTitle } from '../../../app/utility/utilityFunctions';

export default function DriverManager() {

  const { driver_table_data, search_key, setSearchKey, take, setTake, setApiUrl, setOrder, setIsAsc, is_asc, order, setAddDriver_form, showDetailsModal, setShowDetailsModal, setDriverDetails } = useDriverStore();

  const [searchValue] = useDebounce(search_key, 500);

  const { path_record } = useGeneralStore();

  const { t } = useTranslation();

  const navigateTo = useNavigate();

  useEffect(() => {
    changePageTitle(t('Limadi | Driver Manager'));
  }, [])

  const headers = [

    { name: "#", placement: "justify-center items-center"},

    { isActive: order === k_orderBy.name, sort: is_asc, name: t("Name"), onClickAction: () => handleOrder(k_orderBy.name, getDriversTableData), showSortImage: true},

    { isActive: order === k_orderBy.email, sort: is_asc, name: t("Email"), onClickAction: () => handleOrder(k_orderBy.email, getDriversTableData), showSortImage: true},

    { isActive: order === k_orderBy.phone_from_company, sort: is_asc, name: t("Phone"), onClickAction: () => handleOrder(k_orderBy.phone_from_company, getDriversTableData), showSortImage: true},

    { isActive: order === k_orderBy.joined_date, sort: is_asc, name: t("Joining Date"), onClickAction: () => handleOrder(k_orderBy.joined_date, getDriversTableData), showSortImage: true},
  ];


  const resetTable = async () => {
    if (searchValue?.length > 0) await setSearchKey('');
    await setApiUrl(kuDriverTableData);
    if (take !== common_take) await await setTake(common_take);
    await setOrder(k_orderBy.joined_date);
    await setIsAsc(1);
    getDriversTableData();
  }

  const handleInitialSetup = async () => {
    if (path_record?.old?.includes('/driver-manager/details/')) {
      await getDriversTableData();
    } else {
      await resetTable();
    }
  }


  useEffect(() => { handleInitialSetup() }, []);

  useEffect(() => {
    if (!path_record?.old?.includes('/driver-manager/details/')) {
      setApiUrl(kuDriverTableData);
    }
    getDriversTableData()
  }, [searchValue]);

  const contents = driver_table_data?.data?.map((item, index) => {
    return {
      contents: [
        {value: (driver_table_data?.current_page * common_take - common_take) + index + 1},
        {value: <Clamp lines={2}> {item?.name}</Clamp>},
        {value: <Clamp lines={2}> {item?.email}</Clamp>},
        {value: item?.phone_from_company},
        {value: item?.joined_date},
      ],
      clickAction: async () => {
        await setDriverDetails(item);
        navigateTo(`/driver-manager/details/${item?.id}`)
      }
    }
  })

  return (
    <>
      <CommonTable
        tableTitle={t('Driver') + ` (${driver_table_data?.total ?? 0})`}
        showSearchBox={true}
        showTakeOption={true}
        showPagination={true}
        showPageCountText={true}
        showTopRightFilter={false}
        headers={headers}
        outerPadding='p-s0'
        tableTitleClassName="title"
        paginationObject={driver_table_data}
        tableHeaders={headers}
        hasClickFunction={true}
        tableContents={contents}
        widths= "50px auto 25% 12% 12%"
        

        withClearSearch={true}
        onSearchClear={() => { setSearchKey("") }}
        searchValue={search_key}
        searchOnChange={(e) => {
          setApiUrl(kuDriverTableData);
          setSearchKey(e.target.value)
        }}

        currentTakeAmount={take}
        withReloader={true}
        onReload={resetTable}
        takeOptionOnChange={async (e) => {
          await setTake(e.target.value);
          await setApiUrl(kuDriverTableData);
          getDriversTableData();
        }}
        paginationOnClick={async (url) => {
          await setApiUrl(url);
          getDriversTableData();
        }}
        topRightMainComponent={
          <>
            <CommonButton
              btnLabel={t('Add Driver')}
              type='button'
              width={"w-[130px]"}
              onClick={() => {
                setAddDriver_form({ email: "", name: "", phone: "", comment: "" })
                navigateTo('/driver-manager/add')
              }} />
          </>
        }

      />

      <DriverDetailsModal showModal={showDetailsModal} setShowModal={setShowDetailsModal} />
    </>
  )
}
