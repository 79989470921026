/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useShiftStore from "../../../../app/stores/company/shiftStore";
import {
  formatDate,
  formatTimeHourMinutes,
} from "../../../../app/utility/utilityFunctions";
import { useTranslation } from "react-i18next";
import useDriverStore from "../../../../app/stores/company/driverStore";
import { getAllUserInfo } from "../../../../app/stores/others/generalStore";
import DriverDetailsModal from "../../driverManager/modal/DriverDetailsModal";
import useShiftStatus from "./useShiftStatus";
import RequestAccordion from "../../../../components/Accordion/RequestAccordion";
import { k_shift_section } from "../../../../app/utility/const";
import useCreateRequestStore from "../../../../app/stores/others/createRequestStore";
import ShiftInstruction from "./ShiftInstruction";
import ShiftLogBookTable from "./ShiftLogBookTable";

const ShiftOverView = ({ shiftDetailsData }) => {
  const { setShowDetailsModal, showDetailsModal } = useShiftStore();

  const { shiftExpandedAccordion, setShiftExpandedAccordion } = useCreateRequestStore();

  const { setDriverDetails } = useDriverStore();

  const [summaryData, setSummaryData] = useState([]);

  // In your component
  const [shiftStatusTitle] = useShiftStatus(shiftDetailsData);

  const { t } = useTranslation();

  useEffect(() => {
    setSummaryData();
    if (shiftDetailsData?.is_maintenance === 1) {
      setShiftExpandedAccordion(k_shift_section.shift_overview);
      setSummaryData([
        {
          title: t("Status"),
          description: shiftStatusTitle ?? " ",
          className: "text-fs14 leading-5 font-fw600"
        },

        {
          title: t("Schedule"),
          description:
            formatDate(shiftDetailsData?.start_date) +
            ", " +
            formatTimeHourMinutes(
              shiftDetailsData?.start_time
                ? shiftDetailsData?.start_time
                : "00:00:00"
            ) +
            " - " +
            formatTimeHourMinutes(
              shiftDetailsData?.end_time
                ? shiftDetailsData?.end_time
                : "00:00:00"
            ),
        },

        {
          title: t("Vehicle"),
          description: shiftDetailsData?.car?.name ?? " ",
        },
        {
          title: t("License plate"),
          description: shiftDetailsData?.car?.car_license_plate_number ?? " ",
        },
        // {
        //   title: t('Shift date'),
        //   description: formatDate(shiftDetailsData?.start_date),
        // },
        // {
        //   title: t('End date'), description: formatDate(shiftDetailsData?.end_date)
        // },
        // {
        //   title: t('Shift time'),
        //   description: formatTimeHourMinutes(shiftDetailsData?.start_time ? shiftDetailsData?.start_time : "00:00:00") + ' - ' + formatTimeHourMinutes(shiftDetailsData?.end_time ? shiftDetailsData?.end_time : "00:00:00"),
        // },
        // {
        //     title: t('Requests'),
        //     description: (shiftDetailsData?.reqs?.length ?? 0) + (shiftDetailsData?.reqs?.length > 1 ? ' requests' : ' request'),
        // },
        // {
        //     title: t('Stops'),
        //     description: (shiftDetailsData?.stops_count ?? 0) + (shiftDetailsData?.stops_count > 1 ? ' stops' : ' stop'),
        // },
        // {
        //     title: t('Packages'),
        //     description: (shiftDetailsData?.products_count ?? 0) + (shiftDetailsData?.products_count > 1 ? ' packages' : ' package'),
        // },
      ]);
    } else {
      setSummaryData([
        {
          title: t("Status"),
          description: shiftStatusTitle ?? " ",
          className: "leading-5"
        },

        {
          title: t("Schedule"),
          description:
            `${formatDate(shiftDetailsData?.start_date) ?? " "}` +
            ", " +
            formatTimeHourMinutes(
              shiftDetailsData?.start_time
                ? shiftDetailsData?.start_time
                : "00:00:00"
            ) +
            " - " +
            formatTimeHourMinutes(
              shiftDetailsData?.end_time
                ? shiftDetailsData?.end_time
                : "00:00:00"
            ),
        },
        {
          title: t("Driver name"),
          description: shiftDetailsData?.driver_user?.name ?? " ",
          onClick: async () => {
            if (shiftDetailsData?.driver_user_id) {
              const res = await getAllUserInfo(
                shiftDetailsData?.driver_user_id
              );
              if (res?.success) {
                setDriverDetails({
                  email: res?.data?.email,
                  user_id: res?.data?.id,
                  image: res?.data?.image,
                  name: res?.data?.name,
                  phone_from_driver: res?.data?.phone,
                });
                setShowDetailsModal(true);
              }
            }
          },
          className: "cursor-pointer text-cPrimary600",
        },
        {
          title: t("Co-Driver name"),
          description:
            shiftDetailsData?.shift_drivers?.filter((i) => !i?.is_main_driver)
              ?.length > 0
              ? shiftDetailsData?.shift_drivers?.map((driver, index) =>
                driver?.is_main_driver ? (
                  ""
                ) : (
                  <span
                    className="cursor-pointer text-cPrimary600"
                    onClick={async () => {
                      if (driver?.driver?.id) {
                        const res = await getAllUserInfo(driver?.driver?.id);
                        if (res?.success) {
                          setDriverDetails({
                            email: res?.data?.email,
                            user_id: res?.data?.id,
                            image: res?.data?.image,
                            name: res?.data?.name,
                            phone_from_driver: res?.data?.phone,
                          });
                          setShowDetailsModal(true);
                        }
                      }
                    }}
                  >
                    {`${driver?.driver?.name}${index < shiftDetailsData?.shift_drivers?.length - 1
                      ? ", "
                      : ""
                      }`}
                  </span>
                )
              )
              : "No co-driver",
          onClick: async () => { },
        },
        {
          title: t("Vehicle"),
          description: shiftDetailsData?.car?.name ?? " ",
        },
        {
          title: t("License plate"),
          description: shiftDetailsData?.car?.car_license_plate_number ?? " ",
        },
      ]);
    }
  }, [shiftDetailsData, shiftStatusTitle]);

  <div className="flex items-center justify-center mt-1 font-medium text-fs12 text-cGray500">
    ()
  </div>;

  return (
    shiftDetailsData?.is_maintenance === 1 ?
      <div>
        <div className="space-y-2">
          {summaryData.map((item, index) => (
            <React.Fragment key={index}>
              <SummaryItem
                title={item?.title}
                description={item?.description}
                className={item.className}
                onClick={item.onClick}
            />
          </React.Fragment>
          ))}
        </div>
        <div className="my-5">
          <ShiftInstruction shiftDetailsData={shiftDetailsData} />
        </div>
        {/* <ShiftLogBookTable /> */}
        <DriverDetailsModal
          showModal={showDetailsModal}
          setShowModal={setShowDetailsModal}
        />
      </div>
      :
      <RequestAccordion
        showClearButton={false}
        title={t("Shift Overview")}
        showShift={true}
        showBorder={false}
        isForm={false}
        isBorder={false}
        expandedPaddingWidth="p-0"
        subTitleOne={shiftDetailsData?.is_maintenance === 1 ? "Shift is in maintenance mode." : shiftDetailsData?.driver_user?.name}
        showBackgroundColor={false}
        isExpanded={shiftExpandedAccordion === k_shift_section.shift_overview}
        onExpand={() => {
          setShiftExpandedAccordion(
            shiftExpandedAccordion === k_shift_section.shift_overview
              ? null
              : k_shift_section.shift_overview
          );
        }}
      >
        <div>
          <div className="space-y-2">
            {summaryData.map((item, index) => (
              <React.Fragment key={index}>
                <SummaryItem
                  title={item?.title}
                  description={item?.description}
                  className={item.className}
                  onClick={item.onClick}
                />
              </React.Fragment>
            ))}
          </div>
          <div className="my-5">
            <ShiftInstruction shiftDetailsData={shiftDetailsData} />
          </div>
          <ShiftLogBookTable />
          <DriverDetailsModal
            showModal={showDetailsModal}
            setShowModal={setShowDetailsModal}
          />
        </div>
      </RequestAccordion>
  );
};

export default ShiftOverView;

const SummaryItem = ({ title = "", description = "", onClick, className }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="font-semibold text-fs12 leading-[10px] text-cSummeryTitle">{title}</div>
      <div
        onClick={onClick}
        className={`${className} mt-1 font-semibold text-fs14 text-cGray800`}
      >
        {description}
      </div>
    </div>
  );
};
