/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AutoPaginate from "./AutoPaginate";
import { iFilterGreen, iFilterWhite } from "../../app/utility/imageImports";
import CommonButtonOutlined from "../button/CommonButtonOutlined";
import CommonSearchBox from "../input/CommonSearchBox";

import { NavLink } from "react-router-dom";
import CommonTableLayout from "./CommonTableLayout";
import useGeneralStore from "../../app/stores/others/generalStore";

export default function CommonTable({
  currentTakeAmount,
  outerPadding = "p-s20",
  searchFilterTopSpacing = "space-x-5",
  tableTitle = "",
  tableTitleClassName,
  tableHeaderToPaddingClassName = "mb-[18px]",
  sort = true,
  tableHeaders = [],
  tableContents = [],
  widths,
  cellPadding = "",
  hasClickFunction = false,
  // to show and manage take/entries in the table data [default: hidden ]

  emptyDataMessage = "",
  noDataRowVisible = false,

  // to show and manage search in the table data [default: hidden ]
  showSearchBox = false,
  searchValue,
  searchOnChange,
  searchBoxHeight,
  onSearchClear,
  withClearSearch = true,
  search_loading = false,

  // for see all text button [ deprecated for now ]
  seeAllText,
  seeAllLink = "",
  seeAllOnClick,

  // to show and manage pagination(bottom-right) and counter text (bottom-left) in the table data [default: hidden ]
  showPagination = false,
  showPageCountText = false,
  paginationOnClick,
  paginationObject,

  // to show and manage filter(top-right) data [default: hidden ]
  showTopRightFilter = false,
  topRightMainComponent,
  topRightFilterComponentText = "filter",
  topRightFilterComponentOnClick = () => { },
  filtered = false,
  topRightFilterComponent = (
    <div className="relative">
      {filtered && (
        <div className="absolute z-50 w-2 h-2 rounded-full bg-cBrand right-1 top-1"></div>
      )}
      <CommonButtonOutlined
        btnLabel={topRightFilterComponentText}
        onClick={topRightFilterComponentOnClick}
        colorType="primary"
        iconLeft={iFilterWhite}
        iconLeftHover={iFilterGreen}
      />
    </div>
  ),
}) {
  const { t } = useTranslation();
  const { isLoading } = useGeneralStore();

  const [indexArray, setIndexArray] = useState([]);

  const tableEmptyDataMessage = emptyDataMessage
    ? emptyDataMessage
    : `No ${tableTitle?.replace(/\s\(\d+\)/, "")} found!`;

  useEffect(() => {
    let t_array = [];
    for (let i = paginationObject?.from; i <= paginationObject?.to; i++) {
      t_array.push(i);
      setIndexArray(t_array);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.scroll(0, 0);
  }, [paginationObject?.from, paginationObject?.to]);

  // useEffect(() => {
  //     if (window.location.pathname !== "/notification")
  //         window.scroll(0, 0)
  // }, [items, window.location.pathname])

  return (
    <>
      <div className={`w-full  ${outerPadding} text-cMainBlack rounded `}>
        <div
          className={`flex justify-between ${tableHeaderToPaddingClassName}`}
        >
          <div className={`${tableTitleClassName ?? "sub-title"} truncate`}>
            {tableTitle}
          </div>

          <div
            className={`flex justify-end items-end space-y-4 sm:space-y-0 ${searchFilterTopSpacing} `}
          >
            {/* p: search box */}
            {showSearchBox === true ? (
              <div className="">
                <CommonSearchBox
                  height={searchBoxHeight}
                  value={searchValue}
                  onChange={searchOnChange}
                  search_loading={search_loading}
                  onSearchClear={onSearchClear}
                  withClearSearch={withClearSearch}
                />
              </div>
            ) : (
              <div></div>
            )}

            {/* b: filter button or custom component */}
            {showTopRightFilter && topRightFilterComponent && (
              <div className="">{topRightFilterComponent}</div>
            )}

            {/*e            top right main component         */}
            {topRightMainComponent && (
              <div className="flex justify-end mb-5">
                {topRightMainComponent}
              </div>
            )}

            {/*g                see all text button              */}
            {seeAllText && (
              <NavLink
                className="text-fs16 font-fw500 text-cBrand"
                to={seeAllLink}
                onClick={seeAllOnClick}
              >
                {seeAllText}
              </NavLink>
            )}
          </div>
        </div>

        {/* blue: main table ui */}
        {/* green: Headers... */}


        <CommonTableLayout
          tableHeaders={tableHeaders}
          tableContents={isLoading ? [] : tableContents}
          widths={widths}
          hasClickFunction={hasClickFunction}
          tableEmptyDataMessage={isLoading ? "Please wait while data is loading..." : tableEmptyDataMessage}
          cellPadding={cellPadding}
          isNoDataRowVisible={isLoading ? true : noDataRowVisible || paginationObject?.total}
        />


        {/* blue: Pagination goes here ! */}
        {showPagination === true ? (
          <div
            className={`flex cursor-default justify-between items-center ${showPageCountText ? "h-s60 pt-5" : ""
              }`}
          >
            {showPageCountText ? (
              paginationObject?.total > 0 ? (
                <div className="text-sm">
                  {t("Showing")} {paginationObject?.from ?? 0} {t("to")}{" "}
                  {paginationObject?.to ?? 0}, {t("out of")}{" "}
                  {paginationObject?.total} {t("results")}
                </div>
              ) : (
                <div className="text-sm">
                  {/* {t('No results available!')} */}
                </div>
              )
            ) : (
              ""
            )}

            {paginationObject?.last_page !== 1 ? (
              <div className="">
                <AutoPaginate
                  currentTakeAmount={currentTakeAmount}
                  paginationObject={paginationObject}
                  paginationOnClick={paginationOnClick}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export function NoDataRow({
  message = "No Data Available!",
  columnNumber = 5,
}) {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div className="w-full info py-s20">
        {t(message)}
      </div>
    </div>
  );
}
