/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CommonTable from "../../../../../components/table/CommonTable";
import SavedFilter from "./components/SavedFilter";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import useGeneralStore from "../../../../../app/stores/others/generalStore";
import useRequestStore, {
  getTableViewRequestsData,
  handleRequestOrder,
  isTableFiltered,
} from "../../../../../app/stores/others/requestStore";
import {
  k_requests_status,
  k_orderBy,
  k_request_paths,
  common_take,
  user_role as role,
} from "../../../../../app/utility/const";
import { kuGetRequestsNew } from "../../../../../app/urls/commonUrl";
import {
  formatDate,
  formatDateTime,
} from "../../../../../app/utility/utilityFunctions";
import Clamp from "react-multiline-clamp";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";

export default function Saved({ tableTitleClassName }) {
  const {
    saved,
    search_key,
    setSearchKey,
    request_take,
    setRequestTake,
    setRequestApiUrl,
    setRequestsOrder,
    setIsAsc,
    request_filter_form,
    resetRequestFilterForm,
    resetFilterRange,
    resetRequestFilterFormCopy,
    is_asc,
    requests_order,
    request_filter_form_copy,
    hasRequestFilter
  } = useRequestStore();
  const { path_record, user_role } = useGeneralStore();

  const [showModal, setShowModal] = useState(false);
  const [searchValue] = useDebounce(search_key, 500);
  const navigateTo = useNavigate();
  const { t } = useTranslation();

  const { type } = useParams();

  let headers = user_role === role.customer ? [
    {isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true},

    {isActive: requests_order === k_orderBy.title, sort: is_asc, name: t("Title"), onClickAction: () => handleRequestOrder(k_orderBy.title, getData), showSortImage: true},

    {isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true},

    {isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true},

    {isActive: requests_order === k_orderBy.pickup_starts_at, sort: is_asc, name: user_role === role.company ? t("Schedule") : t("Date & Time"), onClickAction: () =>   handleRequestOrder(k_orderBy.pickup_starts_at, getData), showSortImage: true},

    {isActive: requests_order === k_orderBy.last_saved, sort: is_asc, name: t("Last Saved"), onClickAction: () => handleRequestOrder(k_orderBy.last_saved, getData), showSortImage: true},
  ] : [
    {isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true},

    {isActive: requests_order === k_orderBy.title, sort: is_asc, name: t("Title"), onClickAction: () => handleRequestOrder(k_orderBy.title, getData), showSortImage: true},

    {isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true},

    {isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true},

    { isActive: requests_order === k_orderBy.pickup_starts_at, sort: is_asc, name: user_role === role.company ? t("Schedule") : t("Date & Time"), onClickAction: () =>   handleRequestOrder(k_orderBy.pickup_starts_at, getData), showSortImage: true},

    {isActive: requests_order === k_orderBy.last_saved, sort: is_asc, name: t("Last Saved"), onClickAction: () => handleRequestOrder(k_orderBy.last_saved, getData), showSortImage: true}
  ]

  const getData = () =>
    getTableViewRequestsData({
      data_set: k_requests_status.saved,
      filter: {
        transport_type: request_filter_form?.transport_types?.map(item => {return item?.id}),
        pickup_date_from: request_filter_form?.pickup_date_from,
        pickup_date_to: request_filter_form?.pickup_date_to,
        saved_date_from: request_filter_form?.saved_date_from,
        saved_date_to: request_filter_form?.saved_date_to,
        status: request_filter_form?.status ? [request_filter_form?.status] : [],
      },
    });

  const resetTable = async () => {
    if (searchValue?.length > 0) await setSearchKey("");
    await setRequestApiUrl(kuGetRequestsNew);
    if (request_take !== common_take) await setRequestTake(common_take);
    await setRequestsOrder(k_orderBy.last_saved);
    await setIsAsc(1);
    await resetRequestFilterForm();
    await resetFilterRange();
    await resetRequestFilterFormCopy();
    getTableViewRequestsData({ data_set: k_requests_status.saved, filter: {}, hasFilter: false });
  };

  const handleInitialSetup = async () => {
    window.scrollTo(0, 0);
    if (
      !hasRequestFilter
    ) {
      resetTable();
    } else {
      getData();
    }
  };

  useEffect(() => {
    handleInitialSetup();
  }, []);

  useEffect(() => {
    getData();
  }, [searchValue]);

  const contents = saved?.data?.map((item) => {
    return user_role === role.company ? {
      contents: [
        {value: item?.request_id},
        {value: <Clamp lines={1}> {item?.title ?? ""}</Clamp>},
        {value: item?.transport_type},
        {value: <Tooltip title={"Request | Stops | Packages"}>
          { "1 | " + (item?.stops_count ?? 0) + " | " + (item?.products_count ?? 0)}
          </Tooltip>
        },
        {value: formatDateTime(
          item?.pickup_starts_date,
          item?.pickup_starts_time,
        )},
        {value: formatDate(item?.last_saved, true)},
      ],
      clickAction: () =>
        navigateTo(`/requests/saved/details/${item?.id}`, {
          state: { referrer: type, role: user_role, request_id: item?.id },
        }),
    } : {
      contents: [
        {value: item?.request_id},
        {value: <Clamp lines={1}> {item?.title ?? ""}</Clamp>},
        {value: item?.transport_type},
        {value: <Tooltip title={"Request | Stops | Packages"}>
          { "1 | " + (item?.stops_count ?? 0) + " | " + (item?.products_count ?? 0)}
          </Tooltip>
        },
        {value: formatDateTime(
          item?.pickup_starts_date,
          item?.pickup_starts_time,
        )},
        {value: formatDate(item?.last_saved, true)},
      ],
      clickAction: () =>
        navigateTo(`/requests/saved/details/${item?.id}`, {
          state: { referrer: type, role: user_role, request_id: item?.id },
        }),
    };
  });
  return (
    <>
      <CommonTable
        tableTitle={t("Saved") + ` (${saved?.total ?? 0})`}
        showSearchBox={true}
        showTopRightFilter={true}
        firstIndexColWidth="!min-w-[90px]"
        headersFirstIndexFixApply={false}
        showTakeOption={true}
        showPagination={true}
        showPageCountText={true}
        outerPadding="p-s0"
        topRightFilterComponentOnClick={() => setShowModal(true)}
        tableTitleClassName={tableTitleClassName}
        paginationObject={saved}
        withClearSearch={true}
        onSearchClear={() => {
          setSearchKey("");
        }}
        searchValue={search_key}
        searchOnChange={(e) => {
          setSearchKey(e.target.value);
          setRequestApiUrl(kuGetRequestsNew);
        }}
        currentTakeAmount={request_take}
        withReloader={true}
        onReload={resetTable}
        filtered={isTableFiltered(
          k_requests_status.saved,
          request_filter_form_copy,
        )}
        takeOptionOnChange={async (e) => {
          await setRequestTake(e.target.value);
          await setRequestApiUrl(kuGetRequestsNew);
          getData();
        }}
        paginationOnClick={async (url) => {
          await setRequestApiUrl(url);
          getData();
        }}
        tableHeaders={headers}
        tableContents={contents}
        widths={"90px auto 20% 120px 20% 13%"}
        hasClickFunction={true}
      />

      <SavedFilter showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
