import React from 'react'
import { FormControlLabel, Radio } from "@mui/material";
import { styled } from '@mui/material/styles';


const CommonRadioItemItem = ({ isActive = false, onChange, value, required, label = "" }) => {

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        border: "none",
        padding: 0,
        margin: 0,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: 'var(--base-50-light, rgba(26, 26, 26, 0.05))',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#307151',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&::before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
    });

    // Inspired by blueprintjs
    function BpRadio(props) {
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }



    return (
        <>
            <div className={`w-fit pl-s2 pr-s10 py-s2 flex  border-[2px] rounded-xl ${isActive ? 'border-cBrand' : ' border-[#D7DAD8]'} `}>
                <div style={{ width: '' }}>
                    <FormControlLabel
                        checked={isActive ? true : false}
                        value={value}
                        control={<BpRadio required={required} />}
                        label={<span style={{ color: '#1A1A1A', fontSize: '14px', fontWeight: 400, width: 'fit-content', marginLeft: "-2px" }}>{label}</span>}
                        onChange={(e) => onChange(e)}
                        style={{ margin: 0, padding: 0, width: 'fit-content' }}
                    />
                </div>
            </div>
        </>
    )
}

export default CommonRadioItemItem