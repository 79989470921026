import React from 'react'
import CarLicensePackage from './CarLicensePackage'
import useCarStore from '../../../../app/stores/company/carStore'

const LicenseEdit = () => {
    const { carDetails } = useCarStore();
    return (
        <div>
            <CarLicensePackage carDetails={carDetails} />
        </div>
    )
}

export default LicenseEdit