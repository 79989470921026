/* eslint-disable react-hooks/exhaustive-deps */
import { useDebounce } from "use-debounce";
import React, { useEffect } from "react";
import useFavoriteAddressStore, {
  getFavoriteAddress,
  handleFavAddressOrder,
} from "../../../app/stores/others/favoriteAddressStore";
import { changePageTitle } from "../../../app/utility/utilityFunctions";
import { useTranslation } from "react-i18next";
import { kuFavoriteAddressList } from "../../../app/urls/commonUrl";
import { useNavigate } from "react-router";
import {
  common_take,
  k_fav_address_order_by,
} from "../../../app/utility/const";
import Clamp from "react-multiline-clamp";
import CommonButton from "../../../components/button/CommonButton";
import CommonTable from "../../../components/table/CommonTable";
import useGeneralStore from "../../../app/stores/others/generalStore";
import { k_fav_address_paths } from "../../../app/utility/AllRoute";

const FavoriteAddress = () => {
  const { t } = useTranslation();

  const navigateTo = useNavigate();

  const {
    table_data,
    search_key,
    setSearchKey,
    take,
    setTake,
    setApiUrl,
    setOrder,
    setIsAsc,
    is_asc,
    order,
    setAddFavoriteAddress_form,
    setFavoriteAddressSearchValue,
  } = useFavoriteAddressStore();

  const { path_record } = useGeneralStore();

  const headers = [
    { name: "#", placement: "justify-center items-center"},
    { isActive: order === k_fav_address_order_by.title, sort: is_asc, name: t("Name"), onClickAction: () => { handleFavAddressOrder(k_fav_address_order_by.title, getFavoriteAddress)}, showSortImage: true,},
    { isActive: order === k_fav_address_order_by.address, sort: is_asc, name: t("Address"), onClickAction: () => {handleFavAddressOrder(k_fav_address_order_by.address, getFavoriteAddress)}, showSortImage: true,},
    { isActive: order === k_fav_address_order_by.floor_number, sort: is_asc, name: t("Floor Number"), onClickAction: () => {handleFavAddressOrder(k_fav_address_order_by.floor_number, getFavoriteAddress,) }, showSortImage: true},
  ];

  const [searchValue] = useDebounce(search_key, 500);

  const resetTable = async () => {
    if (searchValue?.length > 0) await setSearchKey("");
    await setApiUrl(kuFavoriteAddressList);
    setFavoriteAddressSearchValue("");
    if (take !== common_take) await setTake(common_take);
    await setOrder(null);
    await setIsAsc(1);
    const success = await getFavoriteAddress();
    if (success) window.scrollTo(0, 0);
  };

  useEffect(() => {
    changePageTitle(t("Limadi | Favorite Address"));
  }, []);

  const handleInitialSetup = async () => {
    if (path_record?.old?.toLowerCase().includes(k_fav_address_paths.details)) {
      await getFavoriteAddress();
    } else {
      await resetTable();
    }
  };

  useEffect(() => {
    handleInitialSetup();
  }, []);

  useEffect(() => {
    searchFavAddress();
  }, [searchValue]);

  const searchFavAddress = async () => {
    if (
      !path_record?.old?.toLowerCase().includes(k_fav_address_paths.details)
    ) {
      await setApiUrl(kuFavoriteAddressList);
    }
    const success = await getFavoriteAddress();
    if (success) window.scrollTo(0, 0);
  };

  const contents = table_data?.data?.map((item, index) => {
    return {
      contents: [
        { value: table_data?.current_page * common_take - common_take + index + 1 },
        { value: <Clamp lines={2}> {item?.title ?? ""}</Clamp> },
        { value: <Clamp lines={2}> {item?.address ?? ""}</Clamp> },
        { value: <Clamp lines={2}> {item?.floor_number ?? ""}</Clamp> },
      ],
      clickAction: () => {
        navigateTo(k_fav_address_paths.details + item?.id);
      },
    };
  });

  return (
    <div>
      <CommonTable
        tableTitle={t("Favorite Addresses") + ` (${table_data?.total ?? 0})`}
        tableTitleClassName={"title"}
        showSearchBox={true}
        showTopRightFilter={false}
        topRightMainComponent={
          <>
            <CommonButton
              onClick={async () => {
                await setAddFavoriteAddress_form({
                  title: "",
                  address: "",
                  note: "",
                  lat: "",
                  lng: "",
                });
                navigateTo("/favorite-address/add");
              }}
              btnLabel={t("Add Address")}
              width="w-[150px]"
            />
          </>
        }
        showTakeOption={true}
        showPagination={true}
        showPageCountText={true}
        tableHeaders={headers}
        tableContents={contents}
        hasClickFunction={true}
        widths="50px auto 40% 15%"
        outerPadding="p-s0"
        paginationObject={table_data}
        withClearSearch={true}
        onSearchClear={() => {
          setSearchKey("");
        }}
        searchValue={search_key}
        searchOnChange={(e) => {
          setApiUrl(kuFavoriteAddressList);
          setSearchKey(e.target.value);
        }}
        currentTakeAmount={take}
        withReloader={true}
        onReload={resetTable}
        takeOptionOnChange={async (e) => {
          await setTake(e.target.value);
          await setApiUrl(kuFavoriteAddressList);
          getFavoriteAddress();
        }}
        paginationOnClick={async (url) => {
          await setApiUrl(url);
          getFavoriteAddress();
        }}
      />
    </div>
  );
};

export default FavoriteAddress;
