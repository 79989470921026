import firebase from 'firebase'
import useGeneralStore from '../stores/others/generalStore';
import useChatStore from '../stores/others/chatStore';

export const firebase_config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})

const messaging = firebase.messaging();
let message = "you got a new message";

messaging.onMessage(async (payload) => {
    try {
        console.log('Message received. ', payload);
        if (message.toLocaleLowerCase() === payload.notification.title.toLocaleLowerCase()) {
            await useChatStore.getState().setFirebaseNewMessage(true);
            await useChatStore.getState().setMessageNotification(true);
        }
        let x = localStorage.getItem('numOfUnreadNotification').toString();
        let y = parseInt(x);
        localStorage.setItem("numOfUnreadNotification", ++y);
        useGeneralStore.getState().setHasUnseenNotification();
    } catch (error) {
        console.error('Error handling message:', error);
    }
});



export default firebase
