import React from "react";
import { defineTableSortIcon } from "../../app/utility/utilityFunctions";
import CommonEmptyData from "../emptyData/CommonEmptyData";
import { NoDataRow } from "./CommonTable";

const CommonTableLayout = ({
  tableHeaders = [],
  widths = "10% 25% 10% 25% 17% 13%",
  tableContents = [],
  hasClickFunction = false,
  tableEmptyDataMessage = "No data found!",
  cellPadding = "",
  isNoDataRowVisible = false,
}) => {

  return (
    <div className={`w-full border border-cGray300 rounded-[10px]`}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: widths,
        }}
      >
        {tableHeaders?.map((item, index) => (
          <div
            className={`h-s38 flex items-center ${item?.placement
                ? item?.placement
                : index === tableHeaders?.length - 1
                  ? "justify-end"
                  : "justify-start"
              } px-2 border-b ${index !== tableHeaders?.length - 1 ? "border-r" : ""
              } capitalize table-title cursor-pointer`}
            onClick={item?.onClickAction}
          >
            {item?.name}
            {item?.showSortImage && (
              <img
                className=""
                src={defineTableSortIcon(item?.sort, item?.isActive)}
                alt=""
              />
            )}
          </div>
        ))}
      </div>
      {tableContents?.length ? (
        tableContents?.map((item, index) => (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: widths,
              cursor: hasClickFunction ? "pointer" : "default",
            }}
            onClick={item?.clickAction}
            className={`${cellPadding} ${index === tableContents?.length - 1 ? "": "border-b"}`}
          >
            {
              item?.contents?.map((cellItem, cellIndex) => (
                <div
                  className={`h-s50 flex items-center table-info ${cellIndex === 0 ?
                    tableHeaders[0]?.placement || "justify-start" : cellIndex === item?.contents?.length - 1 ? "justify-end" : "justify-start"
                    } px-2 ${cellItem?.className} ${cellIndex === item?.contents?.length - 1 ? "" : "border-r"}
                    `}
                  onClick={() => {
                    if (cellItem?.hasclickAction) {
                      cellItem?.onClickAction()
                    }
                  }}
                >
                  {cellItem?.value}
                </div>
              ))
            }
          </div>
        ))
      ) : isNoDataRowVisible ? (
        <NoDataRow columnNumber={tableHeaders?.length} message={tableEmptyDataMessage} />
      ) : (
        <CommonEmptyData
          title={tableEmptyDataMessage}
          columnNumber={tableHeaders?.length}
        />
      )}
    </div>
  );
};

export default CommonTableLayout;
