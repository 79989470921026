/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import useLayoutStore from "../../app/stores/others/layoutStore";
import CommonModalArea from "./CommonModalArea";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useLocation } from "react-router-dom";

const Layout = (props) => {

  const { isSidebarOpen, setIsSidebarOpen, layoutWidth, setLayoutWidth } = useLayoutStore();

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("resize", () => { if (window.innerWidth !== layoutWidth) { setLayoutWidth(window.innerWidth) } });
    if (layoutWidth <= 1024) setIsSidebarOpen(false);
    else setIsSidebarOpen(true);
    return () => {
      window.removeEventListener("resize", () => setLayoutWidth(window.innerWidth));
    };
  }, [window.innerWidth]);


  return (
    <>
      <CommonModalArea />
      <div className="z-10 flex flex-row min-h-screen text-gray-800 largeScreenContainer main bg-cMainWhite main ">

        {/*e     new sidebar collapse button */}


        <Sidebar
          isOpenSidebar={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />

        <main className="relative flex flex-col items-start justify-start flex-grow transition-all duration-150 ease-in w-full">
          {/* <MobileTopBar /> */}
          <Topbar
            isOpenSidebar={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
          <div
            className={`w-full pt-[80px] ${location?.pathname !== "/chat" && "lg:pb-12 pb-5"}
            ${isSidebarOpen ? "pl-[254px] pr-[24px]" : "lg:pr-[40px] pr-[20px] pl-[135px]"}`}
          >
            <div>
              {props.children}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Layout;
