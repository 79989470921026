import React from "react";
import ShiftSummary from "./ShiftSummary";
import ShiftOverView from "./ShiftOverView";
import RequestList from "./RequestList";
import CustomStopList from "./CustomStopList";
import AdvancedCalculation from "./advancedCalculation/AdvancedCalculation";
import { useTranslation } from "react-i18next";
import useShiftStore from "../../../../app/stores/company/shiftStore";
import CommonEmptyView from "../../../common/createRequest/components/content/selectShift/components/CommonEmptyView";

const ShiftDetailsMainContents = ({ outlinedBox = false }) => {
  const { t } = useTranslation();
  const { shiftDetailsData } = useShiftStore();

  return (
    <div
      onClick={() => {
        console.log("shift details: ", shiftDetailsData);
      }}
      className="w-full space-y-5">

      {!shiftDetailsData &&
        <CommonEmptyView
          message={"No shift plan data!"}
        />
      }
      {shiftDetailsData && !shiftDetailsData?.is_maintenance && <ShiftSummary outlinedBox={outlinedBox} shiftDetailsData={shiftDetailsData} />}

      {shiftDetailsData && <ShiftOverView shiftDetailsData={shiftDetailsData} />}

      {shiftDetailsData && !shiftDetailsData?.is_maintenance && <RequestList />}

      {shiftDetailsData && !shiftDetailsData?.is_maintenance && <CustomStopList />}

      {/* <Stops /> */}

      {/* {shiftDetailsData?.is_maintenance === 1 && (
        <div className="w-full text-[24px] text-cTextGray limadi-semibold text-center ">
          {t("in maintenance")}
        </div>
      )} */}

      {shiftDetailsData && !shiftDetailsData?.is_maintenance && <AdvancedCalculation />}
    </div>
  );
};

export default ShiftDetailsMainContents;
