import React from 'react'

const CommonEmptyView = ({ message = "" }) => {
    return (
        <div className="bg-cButtonHoverColor h-[76px] w-full flex items-center justify-center rounded">
            <p className="leading-6 text-fs18 font-fw500 text-cGray500">
                {message}
            </p>
        </div>
    )
}

export default CommonEmptyView