const CommonButton = ({
    btnLabel = "Trek",
    colorType = "primary",
    isDisabled = false,
    customStyle,
    onClick,
    width = "min-w-[120px]", // min-w-[150px]
    type = "button",
    roundedFull = false,
    icon = "",
    iconRight = "",
    text = "fs-16",
    smallSize = false,
    className = "",
}) => {

    const colorDefinitions = {
        basic: { color: "bg-cGrey", border: "border-cGrey", text: "text-cMainWhite" },
        danger: { color: "bg-cRed", border: "border-cRed", text: "text-white" },
        primary: { color: "bg-cBrand hover:bg-cBrand", border: "border-cBrand", text: "text-cMainWhite" },
        success: { color: "bg-cSuccess", border: "border-cSuccess", text: "text-white" },
        warning: { color: "bg-cBrand", border: "border-cBrand", text: "text-white" },
        white: { color: "bg-cCard", border: "border-cCard", text: "text-cBrandColor" },
        BrandColor: { color: "bg-cBrandColor", border: "border-cBrandColor", text: "text-white" },
        disable: { color: "bg-cGrey", border: "border-cGrey", text: "text-white" },
        FilterClearButton: { color: "bg-cMainWhite", border: "border-cMainWhite", text: "text-cBrand" },
    };

    const { color: colorCode, border: textBorder, text: textColorCode } = colorDefinitions[colorType] || colorDefinitions.primary;

    const disabledClasses = "text-cGray600 bg-cGray300 cursor-not-allowed border-[2px] border-cGray300";
    const activeButtonClasses = `cursor-pointer border-[2px] ${textBorder ?? "border-cPrimary"} ${customStyle} ${colorCode} ${textColorCode}`
    const commonClasses = ` ${isDisabled ? disabledClasses : activeButtonClasses}  ${roundedFull ? "rounded-full" : 'rounded-br4'} relative select-none font-medium ${text} ${width} capitalize duration-300 max-w-full`;
    const sizeClass = smallSize ? 'h-[32px]' : `h-[40px] ${className}`;

    return (

        <button
            disabled={isDisabled}
            type={type}
            onClick={onClick}
            className={`${commonClasses} leading-5 flex outline-none items-center select-none justify-center  ${sizeClass} flex items-center justify-center `}
        >
            {icon && <img className="max-w-[14px] min-w-[14px] max-h-[14px] min-h-[14px] mr-[10px]" src={icon} alt="" />}
            {btnLabel}
            {iconRight}
        </button>

    );
};

export default CommonButton;
