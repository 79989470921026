/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useSettingsStore, { getProfileDetails } from '../../../../../app/stores/others/settingsStore';
import { changePageTitle } from '../../../../../app/utility/utilityFunctions';
import CommonButton from '../../../../../components/button/CommonButton';
import { useTranslation } from 'react-i18next';
import DummyProfileImage from '../../../../../components/image/DummyProfileImage';
import CommonDetailsInfo from '../../../../../components/viewer/CommonDetailsInfo';
import { useNavigate } from "react-router-dom";
import CommonTitle from '../../../../../components/title/CommonTitle';
import { BUTTON_WIDTH, k_settings_paths } from '../../../../../app/utility/const';
import CommonButtonOutlined from '../../../../../components/button/CommonButtonOutlined';

const CustomerProfile = () => {

    const { profileDetails } = useSettingsStore();

    const { t } = useTranslation();

    const navigate = useNavigate();

    useEffect(() => {
        fetchProfileDetails();
        changePageTitle(t('Settings | Profile'));
    }, [])

    const fetchProfileDetails = async () => {
        const success = await getProfileDetails();
        if (success) window.scrollTo(0, 0);
    }

    return (
        <>
            <div className='flex justify-between'>

                <CommonTitle title={t("Profile")} />

                <div className='flex gap-x-5'>
                    <CommonButtonOutlined
                        onClick={() => { navigate(k_settings_paths.delete_account); }}
                        btnLabel={t("Delete")}
                        colorType='danger'
                        width={BUTTON_WIDTH}
                    />
                    <CommonButton
                        onClick={() => { navigate(k_settings_paths.customer_edit) }}
                        // icon={iEditIcon}
                        btnLabel={t('Edit')}
                        width={BUTTON_WIDTH}
                    />
                </div>

            </div>
            <div className='mt-4'></div>
            <DummyProfileImage src={profileDetails?.image} />

            <div className='w-full space-y-3 mt-s20'>
                <CommonDetailsInfo title={t('Name')} description={profileDetails?.name} />
                <CommonDetailsInfo title={t('Email')} description={profileDetails?.email ?? "No email"} />
                <CommonDetailsInfo title={t('Phone Number')} description={profileDetails?.phone ?? "No phone number"} />
                <CommonDetailsInfo title={t('Address')} address={true} description={profileDetails?.address ?? "No address"} />
            </div>
        </>
    );
};

export default CustomerProfile;