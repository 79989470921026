/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useEffect } from 'react'
import useSettingsStore, { getTermsAndCondition } from '../../../app/stores/others/settingsStore'
import { changePageTitle, hasValue } from '../../../app/utility/utilityFunctions';
import { useTranslation } from 'react-i18next';
import CommonTitle from '../../../components/title/CommonTitle';

function TermsConditions() {

    const { termsAndConditionData } = useSettingsStore();

    const { t } = useTranslation();

    useEffect(() => {
        changePageTitle(t('Settings | Terms Conditions'));
        fetchTermsConditionsData();
    }, [])

    const fetchTermsConditionsData = async () => {
        const success = await getTermsAndCondition();
        if (success) window.scrollTo(0, 0);
    }

    function stripHTML(html) {
        const tempDiv = document.createElement('div'); // Create a temporary div
        tempDiv.innerHTML = html; // Set the HTML content
        return tempDiv.textContent || tempDiv.innerText || ''; // Extract plain text
    }

    let hasValue = stripHTML(termsAndConditionData?.terms_condition?.description)

    // const new_update_date = termsAndConditionData?.terms_condition?.updates ? termsAndConditionData?.terms_condition?.updates[0]?.date : null
    // const new_update = termsAndConditionData?.terms_condition?.updates?.filter((item) => item?.date === new_update_date);

    return (
        <div>
            <CommonTitle title={t("Terms & Conditions")} />

            <div className='flex mt-4 space-x-10 '>
                <div className='w-full text-fs16 font-fw500 text-cDarkGray'>
                    {
                        termsAndConditionData?.terms_condition?.description === "null" || termsAndConditionData?.terms_condition?.description === undefined ||
                            termsAndConditionData?.terms_condition?.description === null || !hasValue ? <span>{t('No Terms & Conditions Available')}</span>
                            : <div dangerouslySetInnerHTML={{ __html: termsAndConditionData?.terms_condition?.description }}>
                            </div>
                    }
                </div>

                <div className='min-w-[400px] max-w-[400px]'>
                    {/* <div className='p-4 rounded bg-cSecondary50 h-fit'>
                    <div className='flex space-x-3'>
                        <div className='text-cGray800 sub-title'>{t("New Updates")}</div>
                        <img src={iNewNotification} alt="" />
                    </div>
                    <div className='mb-5 font-normal text-cGray500 text-fs14'>{formatDate(new_update_date)}</div>

                    <div className='space-y-5'>
                        {
                            new_update?.length > 0?
                            new_update.map((item) => (
                                <div key={item?.note} className='space-y-1'>
                                    <div  className='font-normal cursor-pointer text-fs14 text-cGray800'>
                                        {item?.note}
                                    </div>
                                </div>
                            )):<div className='text-center text-cGray800 text-medium'>{t("No found !")}</div>
                        }
                    </div> 
                </div> */}
                </div>
            </div>
        </div>
    )
}

export default TermsConditions
