/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import useRequestStore from '../../../../../../../app/stores/others/requestStore';
import CommonModal from '../../../../../../../components/modal/CommonModal';
import { useParams } from 'react-router';
import Stops from '../../../../../../../components/modal/shiftDetailsAndPlanModal.jsx/components/Stops';
import { useTranslation } from 'react-i18next';
import useShiftStore, { getShiftDetails } from '../../../../../../../app/stores/company/shiftStore';
import ShiftOverView from '../../../../../../company/shiftManager/components/ShiftOverView';
import ShiftInstruction from '../../../../../../company/shiftManager/components/ShiftInstruction';

export default function ShiftDetailsModal({ showModal, setShowModal, selected_shift_index }) {

  const { available_shifts } = useRequestStore();
  const params = useParams();
  let { request_id } = params;
  const { t } = useTranslation();

  const { shiftDetailsData } = useShiftStore();

  useEffect(() => {
    fetchData()
  }, [request_id, selected_shift_index, showModal]);



  const fetchData = async () => {
    if (available_shifts[selected_shift_index]?.id && showModal) {
      // await getAdvanceCalculationData(request_id ? request_id : cr_form?.id, available_shifts[selected_shift_index]?.id);
      getShiftDetails(available_shifts[selected_shift_index]?.id);
    }
  }

  return (
    <>
      <CommonModal
        heightMax='max-h-[calc(100vh-30px)]'
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle={
          <div className='flex space-x-1'>
            <div className='font-medium text-fs20 text-cGray800'>{t('Shift Overview')}</div>
          </div>
        }

        mainContent={
          <div className='pr-1 max-h-[calc(100vh-160px)] overflow-y-auto'>
            {
              <div className='relative flex flex-col items-start w-full pl-1 space-y-5'>
                <ShiftOverView title={false} shiftDetailsData={shiftDetailsData} />
                <ShiftInstruction shiftDetailsData={shiftDetailsData} />
                {/* <ShiftSummary shiftDetailsData={shiftDetailsData} title={true} /> */}
              </div>
            }
            <Stops showModal={showModal} />
          </div>
        }
      />
    </>
  )
}
