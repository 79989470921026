import axios from "axios";
import { create } from "zustand";
import { produce } from "immer";
import {
  base_url_src,
  create_request_steps,
  create_request_type,
  k_cr_actions,
  user_role as role,
} from "../../utility/const";
import useGeneralStore from "./generalStore";
import {
  kuAddressValidation,
  kuEditInBidding,
  kuGetInitData,
  kuSearchCompany,
} from "../../urls/commonUrl";
import {
  formatTime,
  isFutureDateTime,
  isPastTime,
  printFirstError,
  Toastr,
} from "../../utility/utilityFunctions";
import { t } from "i18next";
import useRequestStore from "./requestStore";
import { kuCompanyRequest } from "../../urls/companyUrl";
import { kuCustomerRequest } from "../../urls/customerUrl";


const useCreateRequestStore = create((set, get) => ({
  request_type: create_request_type.mass_import,
  setCreateRequestType: (data) => set({ request_type: data }),

  isResolve: false,
  setIsResolve: (data) => set({ isResolve: data }),

  isFormClearDetected: true,
  setIsFormClearDetected: (data) => set({ isFormClearDetected: data }),

  massUpClearTriggered: false,
  setMassUpClearTriggered: (data) => set({ massUpClearTriggered: data }),

  massUpTableProductEmptyIndex: 0,
  setMassUpTableProductEmptyIndex: (data) => set({ massUpTableProductEmptyIndex: data }),

  oldPickupDateTime: null,
  setOldPickupDateTime: (data) => set({ oldPickupDateTime: data }),

  showRemoveAllStopConfirmationModal: false,
  setRemoveAllStopConfirmationModal: (data) =>
    set({ showRemoveAllStopConfirmationModal: data }),

  current_step: create_request_steps.pickup, //pickup, mass_import, select_company
  setCurrentSetup: (data) => set({ current_step: data }),

  expandedAccordion: null,
  setExpandedAccordion: (data) => set({ expandedAccordion: data }),

  deliveryExpandedAccordion: null,
  setDeliveryExpandedAccordion: (data) => set({ deliveryExpandedAccordion: data }),

  shiftExpandedAccordion: null,
  setShiftExpandedAccordion: (data) => set({ shiftExpandedAccordion: data }),

  isApplyGenerate: false,
  setApplyGenerate: (data) => set({ isApplyGenerate: data }),

  additionalStops: 0,
  setAdditionStops: (data) => set({ additionalStops: data }),

  pasteDataLength: 0,
  setPasteDataLength: (data) => set({ pasteDataLength: data }),

  newRequestShift: null,
  setNewRequestShift: (data) => set({ newRequestShift: data }),

  is_show_request_confirm_modal: false,
  setShowRequestConfirmModal: (data) =>
    set({ is_show_request_confirm_modal: data }),

  stops: [],
  setStops: async (data) => {
    await set({ stops: data });
    return;
  },

  crFormSelectedShift: null,
  setCrFormSelectedShift: (value) => set({ crFormSelectedShift: value }),

  updateSelectStopsForm: (index, field, value) =>
    set(
      produce((state) => {
        if (state.selectStop[index]) {
          state.selectStop[index][field] = value;
        }
      })
    ),

  changeSelectStopsProductQuantity: (newQuantity, deliveryIndex, productIndex) => {
    console.log("changeSelectStopsProductQuantity: ", newQuantity, deliveryIndex, productIndex);  //Product 0 1


    set(
      produce((state) => {
        if (
          state.selectStop[deliveryIndex] &&
          state.selectStop[deliveryIndex].products[productIndex]
        ) {
          state.selectStop[deliveryIndex].products[productIndex][
            "text"
          ] = newQuantity;
        }
      })
    )
  },

  removeSelectStopsDeliveryProduct: (index, productIndex) => {
    set(produce((state) => {
      if (state.selectStop[index]) {
        state.selectStop[index].products.splice(productIndex, 1);
      }
    }));
  },

  tableSelectStopsAddDeliveryProduct: (index, isItemEmpty = false) => {
    set(produce((state) => {
      if (state.selectStop[index]) {
        state.selectStop[index].products.push({ text: isItemEmpty ? "" : "Product" });
      }
    }));
  },

  showTableViewModal: false,
  setShowTableViewModal: (data) => set({ showTableViewModal: data }),

  table_cr_form: [
    {
      date: "",
      start_time: "",
      end_time: "",
      address: "",
      lat: "",
      lng: "",
      comment: "",
      attachment: "",
      floor_number: "",
      duration: null,
      products: [],
    },
  ],

  setTableCrFullForm: async (data) => {
    console.log("setTableCrFullForm", data);
    if (data === undefined) return console.log("SKIPPING UNDEFINED DATA");

    set({ table_cr_form: data });
  },


  updateTableStopsForm: (index, field, value) =>
    set(
      produce((state) => {
        if (state.table_cr_form[index]) {
          state.table_cr_form[index][field] = value;
        }
      })
    ),

  addDeliveryTable: () => {
    const currentForm = get().table_cr_form;
    const newStop = {
      date: "",
      formatted_date: "",
      start_time: "",
      end_time: "",
      delivery_time: "",
      address: "",
      lat: "",
      lng: "",
      comment: "",
      attachment: "",
      attachment_url: "",
      temp_attachment: null,
      products: [],
    };

    set({ table_cr_form: [...currentForm, newStop] });
  },

  stopData: {
    date: "",
    start_time: "",
    end_time: "",
    address: "",
    lat: "",
    lng: "",
    comment: "",
    attachment: "",
    floor_number: "",
    duration: null,
    products: [
      {
        text: "",
      },
    ],
    has_old_image: 0,
  },

  setStopData: (value) => set({ stopData: value }),

  updateStopField: (field, value) =>
    set(
      produce((state) => {
        state.stopData[field] = value;
      })
    ),

  updateStopProductField: (productIndex, field, value) =>
    set(
      produce((state) => {
        if (state.stopData.products[productIndex]) {
          state.stopData.products[productIndex][field] = value;
        }
      })
    ),

  resetStopData: () =>
    set({
      stopData: {
        date: "",
        formatted_date: "",
        start_time: "",
        end_time: "",
        delivery_time: "",
        floor_number: "",
        address: "",
        lat: null,
        lng: null,
        comment: "",
        attachment: "",
        attachment_url: "",
        temp_attachment: null,
        products: [{ text: "" }],
        has_old_image: 0,
      },
    }),

  cr_data: null,
  setCrData: (data) => set({ cr_data: data }),

  savedCrFormRes: null,
  setSavedCrFormRes: (data) => set({ savedCrFormRes: data }),

  tampDeliveriesIndex: null,
  setTampDeliveriesIndex: async (data) => {
    await set({ tampDeliveriesIndex: data });
    return;
  },

  tampDeliveries: null,
  setTampDeliveries: async (data) => {
    await set({ tampDeliveries: data });
    return;
  },

  updateTampDeliveries: (field, value) =>
    set(
      produce((state) => {
        state.tampDeliveries[field] = value;
      })
    ),

  updateTampDeliveriesProductField: (productIndex, field, value) =>
    set(
      produce((state) => {
        if (state.tampDeliveries.products[productIndex]) {
          state.tampDeliveries.products[productIndex][field] = value;
        }
      })
    ),

  removeTampDeliveriesProduct: (productIndex) => {
    set(produce((state) => {
      if (state.tampDeliveries[productIndex]) {
        state.tampDeliveries[productIndex].products.splice(productIndex, 1);
      }
    }));
  },

  cr_form: {
    action: "save",
    title: "",
    pickup: {
      address: "",
      lat: "",
      lng: "",
      date: "",
      start_time: "",
      end_time: "",
      comment: "",
      duration: null,
      floor_number: "",
    },
    deliveries: [
    ],
    transport_type: "",
    typeoftransportation_id: "",
    is_global: 0,
    // shift
    shift_id: null,
    // proposal
    proposal: {
      budget: null,
      details: "",
      customer_name: "",
    },
  },

  updateDeliveryField: (index, field, value) =>
    set(
      produce((state) => {
        if (state?.cr_form?.deliveries[index]) {
          state.cr_form.deliveries[index][field] = value;
        }
      })
    ),

  updateProductField: (deliveryIndex, productIndex, field, value) =>
    set(
      produce((state) => {
        if (
          state?.cr_form?.deliveries[deliveryIndex] &&
          state?.cr_form?.deliveries[deliveryIndex].products[productIndex]
        ) {
          state.cr_form.deliveries[deliveryIndex].products[productIndex][
            field
          ] = value;
        }
      })
    ),

  updateCrField: (path, value) =>
    set(
      produce((state) => {
        const keys = path.split(".");
        let obj = state?.cr_form;
        for (let i = 0; i < keys.length - 1; i++) {
          obj = obj[keys[i]];
        }
        obj[keys[keys.length - 1]] = value;
      })
    ),

  setCrFullForm: async (data) => {
    await set({ cr_form: data });
    return;
  },

  setCrForm: (e) =>
    set({ cr_form: { ...get().cr_form, [e.target.name]: e.target.value } }),
  changeCrForm: (name, value) =>
    set({ cr_form: { ...get().cr_form, [name]: value } }),

  updateStopsForm: (index, name, value) => {
    let stops = get().cr_form?.stops;
    let stopId = index || 1;
    let currentStopIndex = stops.findIndex((item) => item.serial_id === stopId);
    if (currentStopIndex !== -1) {
      let x = [...stops];
      x[currentStopIndex][name] = value;
      let y = get().cr_form;
      y.stops = x;

      set({ cr_form: y });
    }
  },

  clearCrFormData: (value) => set({ cr_form: value }),

  resetCreateRequest: () => {
    get().setCrData(null);
    set({
      cr_form: {
        // request_id: "",
        action: "save",
        title: "",
        pickup: {
          address: "",
          lat: "",
          lng: "",
          date: "",
          start_time: "",
          end_time: "",
          comment: "",
          duration: null,
          floor_number: "",
        },
        deliveries: [
        ],
        transport_type: "",
        typeoftransportation_id: "",
        is_global: 0,
        // shift
        shift_id: null,
        // proposal
        proposal: {
          // budget: null,
          details: "",
          customer_name: "",
        },
      },
    });
    get().setStops([]);
  },

  addDelivery: ({ deliveryData = {} }) => {
    let x = get().cr_form?.deliveries;
    const data = {
      date: deliveryData.date,
      start_time: deliveryData.start_time,
      end_time: deliveryData.end_time,
      address: deliveryData.address,
      lat: deliveryData.lat,
      lng: deliveryData.lng,
      comment: deliveryData.comment,
      duration: deliveryData.duration,
      attachment: deliveryData.attachment,
      attachment_url: deliveryData.attachment_url,
      temp_attachment: deliveryData.temp_attachment,
      products: deliveryData.products,
      floor_number: deliveryData.floor_number,
    };

    let y = get().cr_form;

    get().updateCrField("deliveries", [...x, data]);
  },

  removeDelivery: (index) => {
    let deliveries = get().cr_form?.deliveries;
    let shift_id = get().cr_form?.shift_id;
    let shift_plan = get().cr_form?.shift_plan;
    const { setCrFormSelectedShift } = useCreateRequestStore?.getState()
    let updatedDeliveries = deliveries?.filter((i, stopIndex) => stopIndex !== index);

    if (!updatedDeliveries?.length) {
      shift_id = null;
      shift_plan = null;
      setCrFormSelectedShift(null);
    }
    set({
      cr_form: {
        ...get().cr_form,
        deliveries: updatedDeliveries,
        shift_id: shift_id,
        shift_plan: shift_plan
      },
    });
  },


  addDeliveryProduct: (deliveryIndex, productData = { text: "" }) =>
    set(
      produce((state) => {
        if (state?.cr_form?.deliveries[deliveryIndex]) {
          state?.cr_form?.deliveries[deliveryIndex].products.push(productData);
        }
      })
    ),

  tableAddDeliveryProduct: (index) => {
    let x = [...get().table_cr_form?.stops];
    x[index].products.push({ text: "" });
    let y = get().cr_form;
    y.stops = x;

    set({ table_cr_form: y });
  },

  removeDeliveryProduct: (deliveryIndex, productIndex) => {
    set(
      produce((state) => {
        if (
          state?.cr_form?.deliveries[deliveryIndex] &&
          state?.cr_form?.deliveries[deliveryIndex].products[productIndex]
        ) {
          state?.cr_form?.deliveries[deliveryIndex].products.splice(
            productIndex,
            1
          );
        }
      })
    );
  },

  changeProductValue: (value, index, productIndex) => {
    let stops = get().cr_form?.stops;
    let stopId = index || 1;
    let currentStopIndex = stops.findIndex((item) => item.serial_id === stopId);
    if (currentStopIndex !== -1) {
      let x = get().cr_form?.stops[currentStopIndex]?.products[productIndex];
      x = value;
      let y = get().cr_form;
      y.stops[currentStopIndex].products[productIndex].text = x;

      set({ cr_form: y });
    }
  },

  isInvalidProduct: false,
  setIsInvalidProduct: (data) => set({ isInvalidProduct: data }),


  // isInvalidProduct: false,
  // setIsAddressFieldDisable: (data) => set({ isInvalidProduct: data }),



  isInvalidProductFrom: false,
  setIsInvalidProductFrom: (data) => set({ isInvalidProductFrom: data }),

  selectStop: null,
  setSelectStop: (data) => set({ selectStop: data }),

  favorite_companies: [],
  setFavoriteCompanies: (data) => set({ favorite_companies: data }),

  favorite_addresses: [],
  setFavoriteAddresses: (data) => set({ favorite_addresses: data }),

  type_of_transportation: [],
  setTypeofTransportation: (data) => {
    let x = [];
    data.forEach((element) => {
      x.push({
        id: element?.id,
        title: element?.title,
        value: element?.title,
        duration: element?.duration,
      });
    });
    set({ type_of_transportation: x });
  },

  is_fav_selected: false,
  setFavSelected: (value) => set({ is_fav_selected: value }),

  rate: 0,
  setRate: (value) => set({ rate: value }),

  search_company_key: "",
  setSearchCompanyKey: (value) => set({ search_company_key: value }),

  company_search_result: [],
  setCompanySearchResult: (value) => set({ company_search_result: value }),

  is_company_searching: false,
  setIsCompanySearching: (value) => set({ is_company_searching: value }),

  delivery_invalid_index: [],
  setDeliveryInvalidIndex: (data) => set({ delivery_invalid_index: data }),

  available_companies: [],
  setAvailableCompanies: (data) => set({ available_companies: data }),

  selected_companies: [],
  setSelectedCompanies: (data) => {
    set({ selected_companies: data });
    if (!!data?.length) {
      handleCompany();
      let x = [];
      data?.forEach((element) => {
        x.push(element?.id);
      });
      get().changeCrForm("company_user_ids", x);
    } else get().changeCrForm("company_user_ids", []);
  },

  isCompanyChosen: false, 
  setIsCompanyChosen: (value) => set({ isCompanyChosen : value}),

  // mass import
  pasteData: [],
  setPasteData: (data) => set({ pasteData: data }),

  mass_import_invalid_index: [],
  setMass_import_invalid_index: (data) =>
    set({ mass_import_invalid_index: data }),


  is_show_AdditionStopAlertModal: false,
  setShowAdditionStopAlertModal: (data) => set({ is_show_AdditionStopAlertModal: data }),

  showEditDeliveryModal: false,
  setShowEditDeliveryModal: (data) => set({ showEditDeliveryModal: data }),

  table_view_invalid_index: [],
  setTableViewInvalidIndex: (data) => set({ table_view_invalid_index: data }),

  deliverySelectIndex: null,
  setDeliverySelectIndex: (data) => set({ deliverySelectIndex: data }),

  addProduct: false,
  setAddProduct: (data) => set({ addProduct: data }),

  resetProduct: false,
  setResetProduct: (data) => set({ resetProduct: data }),

  tableCurrentPage: 1,
  setTableCurrentPage: (data) => set({ tableCurrentPage: data }),

  showTableViewInstructionsModal: false,
  setTableViewInstructionsModal: (data) =>
    set({ showTableViewInstructionsModal: data }),

  available_shift_search_key: "",
  setAvailableShiftSearchKey: (data) =>
    set({ available_shift_search_key: data }),
}));

export default useCreateRequestStore;

//! API calls
const { setLoading } = useGeneralStore.getState();

export const getInitData = async () => {

  const {
    setFavoriteCompanies,
    setFavoriteAddresses,
    setTypeofTransportation,
  } = useCreateRequestStore.getState();

  try {
    const res = await axios.get(kuGetInitData);
    if (res.data.success) {
      setFavoriteCompanies(res?.data?.data?.favorite_companies);
      setFavoriteAddresses(res?.data?.data?.favorite_addresses);
      setTypeofTransportation(res?.data?.data?.transportation_types);
    } else {
      Toastr({ message: res.data.message });
    }
  } catch (err) {
    console.log(err);
    Toastr({ message: t("An error occurred!") });
  }
};

export const saveRequest = async (action = k_cr_actions.save, showToaster = true, newParameters = {}) => {

  const { updateCrField, cr_form, setApplyGenerate, is_show_request_confirm_modal, setSavedCrFormRes, setCrFormSelectedShift } = useCreateRequestStore.getState();
  const { user_role } = useGeneralStore.getState();

  const isCompany = user_role === role.company;

  console.log("before cleanedDeliveries: ", cr_form);
  const cleanedDeliveries = cr_form?.deliveries?.map(delivery => {
    const filteredProducts = delivery?.products?.filter(product => product?.text?.trim() !== "");
    return {
      ...delivery,
      products: filteredProducts
    };
  });

  console.log("After cleanedDeliveries: ", cr_form);

  let data = {
    ...cr_form,
    action: action,
    deliveries: cleanedDeliveries,
    proposal: {
      customer_name: cr_form?.proposal?.customer_name,
      details: cr_form?.proposal?.details,
      ...((cr_form?.proposal?.budget) ? { budget: cr_form?.proposal?.budget } : { budget: "" }),
    },
    ...newParameters
  };

  console.log("saveRequest: ", action);
  console.log("saveRequest cr_form: ", cr_form);
  console.log("saveRequest data before organizing: ", data);
  data = loadCreateRequestDataBeforeApiCall(data);
  console.log("saveRequest data AFTER organizing: ", data);

  setLoading(true);

  console.log("saveRequest before: ", data);
  //return 

  try {
    const res = await axios.post(base_url_src + "api/v2" + (isCompany ? kuCompanyRequest : kuCustomerRequest), data);
    console.log("saveRequest after: ", res?.data);

    setSavedCrFormRes(res?.data?.data);


    if (res?.data?.success) {
      console.log("RES DATA SUCCESS");

      updateCrField("request_id", res?.data?.data?.request_id);
      loadCreateRequestData(res?.data?.data);
      setCrFormSelectedShift(res?.data?.data?.shift_id)
      setApplyGenerate(false)
      setLoading(false);
      if (k_cr_actions.save === action || k_cr_actions.submit === action || k_cr_actions.generate === action) {
        console.log("LAYER 1");
        if (showToaster) {
          console.log("RES DATA SUCCESS => MESSAGE: ", res?.data?.message);
          Toastr({ message: res?.data?.message, type: "success" });
          console.log("LAYER 2");
        }
      }
      return true;
    } else {
      Toastr({ message: printFirstError(res) ?? res?.data?.message === "An error occurred!" ? res?.data?.data : res?.data?.message });
      setLoading(false);
      return false;
    }
  } catch (err) {
    Toastr({ message: err?.response?.data?.message });
    setLoading(false);
    return false;
  }
};

export const editRequest = async (id) => {
  if (id === null) {
    Toastr({ message: t("Invalid request!"), type: "warning" });
    return;
  }

  setLoading(true);

  try {
    const res = await axios.post(kuEditInBidding, { id: id, is_web: 1 });
    console.log("editRequest: ", res?.data?.data);

    if (res.data.success) {
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message });
      setLoading(false);
      return false;
    }
  } catch (err) {
    console.log("editRequest: ", err);
    Toastr({ message: t("An error occurred!") });
    setLoading(false);
    return false;
  }
};

export const searchCompany = async (text, rate) => {
  const { setCompanySearchResult, setIsCompanySearching } = useCreateRequestStore.getState();
  try {
    if (rate === 0) rate = null;
    setIsCompanySearching(true);
    const res = await axios.get(kuSearchCompany, {
      params: { search: text, rate: rate },
    });

    console.log("searchCompany", res?.data);
    if (res?.data?.success) {
      setCompanySearchResult(res?.data?.data);
    } else {
      Toastr({ message: res?.data?.message });
    }
    setIsCompanySearching(false);
  } catch (err) {
    console.log(err);
    Toastr({ message: t("An error occurred!") });
    setIsCompanySearching(false);
  }
};

export const countAllStopsProducts = (stops) => {
  let x = 0;
  stops?.forEach((element) => {
    let y = 0;
    element?.products?.forEach((product) => {
      if (product?.text && product?.text?.length > 0) y++;
    });
    x += y;
  });

  return x;
};

export const clearCrForm = async () => {
  const { user_role } = useGeneralStore.getState();
  const { current_step, cr_form, clearCrFormData } =
    useCreateRequestStore.getState();

  if (user_role === role.customer) {
    if (current_step === create_request_steps.pickup) {
      let x = {
        ...cr_form,
        title: "",
        transport_type: "",
        typeoftransportation_id: "",
        pickup_duration: "",
        pickup_date: "",
        pickup_date_formatted: "",
        pickup_start_time: "",
        pickup_end_time: "",
        pickup_avg: "",
        pickup_address: "",
        pickup_floor_number: "",
        pickup_lat: "",
        pickup_lng: "",
        pickup_comment: "",
        pickup_attachment: "",
        temp_pickup_attachment: null,
        pickup_attachment_url: "",

        // deliveries
        stops: [
          {
            address: "",
            floor_number: "",
            lat: "",
            lng: "",
            comment: "",
            attachment: "",
            attachment_url: "",
            temp_attachment: null,
            products: [{ text: "" }],
            has_old_image: 0,
          },
        ],
      };
      clearCrFormData(x);
    }

    if (current_step === create_request_steps.select_company) {
      const {
        setSearchCompanyKey,
        setSelectedCompanies,
        setRate,
        setFavSelected,
      } = useCreateRequestStore.getState();
      let x = {
        ...cr_form,
        is_global: 0,
      };
      clearCrFormData(x);
      setSearchCompanyKey("");
      setSelectedCompanies([]);
      setRate(0);
      setFavSelected(false);
    }
  } else if (user_role === role.company) {
    if (current_step === create_request_steps.pickup) {
      let x = {
        ...cr_form,
        title: "",
        transport_type: "",
        typeoftransportation_id: "",
        pickup_date: "",
        pickup_date_formatted: "",
        pickup_duration: "",
        pickup_start_time: "",
        pickup_end_time: "",
        pickup_avg: "",
        pickup_address: "",
        pickup_lat: "",
        pickup_lng: "",
        pickup_comment: "",
        pickup_attachment: "",
        pickup_floor_number: "",
        temp_pickup_attachment: null,
        pickup_attachment_url: "",

        // deliveries
        stops: [
          {
            serial_id: 1,
            date: "",
            formatted_date: "",
            start_time: "",
            end_time: "",
            delivery_time: "",
            address: "",
            lat: "",
            lng: "",
            comment: "",
            attachment: "",
            attachment_url: "",
            temp_attachment: null,
            products: [{ text: "" }],
            has_old_image: 0,
          },
        ],
      };
      clearCrFormData(x);
    }

    if (current_step === create_request_steps.select_shift) {
      let x = {
        ...cr_form,
        shift_plan: null,
        shift_id: null,
        bid_details: "",
        budget: "",
      };
      clearCrFormData(x);
    }
  }

  if (current_step === create_request_steps.mass_import) {
    const { stops, setStops } = useCreateRequestStore.getState();
    await setStops([]);
    for (let i = 0; i < stops.length; i++) addStop();
  }
};

export const loadCreateRequestDataBeforeApiCall = (data) => {
  const { setCrFullForm } = useCreateRequestStore.getState();

  let deliveries = data?.deliveries;
  let y = [];
  deliveries?.forEach((stop, index) => {
    y.push({
      ...stop,
      products: checkProducts(stop?.products),
    });
  });

  let x = {
    ...data,
    stops: y,
  };
  setCrFullForm(x);
  console.log("form", x);
  return x;
};

export const loadCreateRequestData = (data, shift_id) => {
  console.log("loadCreateRequestData", data);

  const { setCrFullForm, setCrFormSelectedShift, setSelectedCompanies } = useCreateRequestStore.getState();
  const { user_role } = useGeneralStore.getState();

  const isCustomer = user_role === role.customer;

  let stops = data?.delivery_stops;
  let x = {

    deliveries: stops?.map((item) => {
      return {
        date: item?.date ?? "",
        id: item?.id ?? "",
        start_time: item?.start_time?.substring(0, 5) ?? "",
        end_time: item?.end_time?.substring(0, 5) ?? "",
        address: item?.address ?? "",
        lat: item?.lat ?? "",
        lng: item?.lng ?? "",
        comment: item?.comment ?? "",
        attachment_url: item?.attachment ?? null,
        floor_number: item?.floor_number ?? "",
        duration: item?.duration ?? "",
        products: item?.products,
      };
    }),

    ...(data?.id && { id: data?.id }),
    title: data?.title,
    transport_type: data?.transport_type,
    typeoftransportation_id: data?.typeoftransportation_id,
    ...(data?.request_id && { request_id: data?.request_id }),

    pickup: {
      address: data?.pickup_stop?.address,
      lat: data?.pickup_stop?.lat,
      lng: data?.pickup_stop?.lng?.toString(),
      date: data?.pickup_stop?.date ?? "",
      start_time: formatTime(data?.pickup_stop?.start_time),
      end_time: formatTime(data?.pickup_stop?.end_time),
      comment: data?.pickup_stop?.comment,
      duration: data?.pickup_stop?.duration,
      floor_number: data?.pickup_stop?.floor_number,
    },

    proposal: {
      ...((data?.awarded_bidding?.budget) && { budget: data?.awarded_bidding?.budget }),
      details: data?.awarded_bidding?.details,
      customer_name: data?.awarded_bidding?.customer_name ?? "",
    },

    pickup_attachment_url: data?.pickup_stop?.attachment,

    shift_id: data?.shift_id,

    is_web: true,

    shift_plan: {
      ...data?.shift_plan,
      driver_user: data?.driver,
      car: { car_license_plate_number: data?.car_license_number },
    },
  };

  setCrFormSelectedShift(data?.shift_id)

  if (isCustomer) {
    let company_user_ids = [];
    let selected_companies = [];
    data?.invitations?.forEach((item) => {
      company_user_ids.push(item?.user_id);
      selected_companies.push({id: item?.user_id})
    })
    x = {
      ...x,
      is_global: data?.is_global,
      company_user_ids: company_user_ids
    }

    setSelectedCompanies(selected_companies)
  }
  setCrFullForm(x);
  console.log("form", x);
};

export const filterCompany = (data, key, rate) => {
  let x = data?.filter((item) => {
    if (
      item?.name?.toLowerCase()?.includes(key?.toLowerCase()) &&
      item?.rate <= rate
    )
      return item;
    return null;
  });
  return x;
};

export const handleCompany = () => {
  const {
    search_company_key,
    rate,
    company_search_result,
    setAvailableCompanies,
    favorite_companies,
    is_fav_selected,
  } = useCreateRequestStore.getState();
  let x = [];

  if (is_fav_selected) {
    let a = filterCompany(
      favorite_companies,
      search_company_key ?? "",
      rate === 0 ? 5 : rate
    );
    let b = [...company_search_result, ...a];
    x = b?.filter((i) => i.is_favorite === true || i.is_favorite === 1);
  } else x = [...company_search_result];

  // x = x.filter(itemX => !selected_companies.some(itemY => itemX.id === itemY.id));

  setAvailableCompanies(x);
};

export const checkProducts = (products) => {
  let x = [];
  products?.forEach((product) => {
    if (!product?.text || product?.text === "") {
    } else x.push(product);
  });
  return x;
};

// mass import
export const addStop = () => {
  const { stops, setStops } = useCreateRequestStore.getState();
  const newStop = {
    stop_reference: "",
    is_stop_reference_valid: true,
    address: "",
    lat: null,
    lng: null,
    is_address_valid: true,
    zip: "",
    is_zip_valid: true,
    product: "",
    is_product_valid: true,
    start_time: "",
    end_time: "",
    date: "",
    is_date_time_valid: true,
    comment: "",
    attachment: null,
  };
  setStops([...stops, newStop]);
};

export const formatPasteData = (data = []) => {
  let index = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].length !== 0) index = i;
  }
  return data.slice(0, index + 1);
};

export const createRequestClear = async () => {
  const {
    setCrFullForm,
    setApplyGenerate,
    resetCreateRequest,
    setCurrentSetup,
    setExpandedAccordion,
    setDeliveryInvalidIndex,
    resetStopData,
    setIsFormClearDetected,
    setNewRequestShift,
    setCrFormSelectedShift
  } = useCreateRequestStore.getState();
  const { setRequestDetails, setSelectedNewShiftId } = useRequestStore.getState();
  setIsFormClearDetected(true);

  setRequestDetails({});
  setExpandedAccordion(null)
  await setCurrentSetup(null);
  setCrFullForm({});
  resetCreateRequest();
  resetStopData()
  clearCrForm();
  setDeliveryInvalidIndex(null)
  setApplyGenerate(false);
  setSelectedNewShiftId(null);
  setNewRequestShift(null);
  setCrFormSelectedShift(null)
  console.log("createRequestClear DONE!");

};

export const clearPickupFrom = async () => {
  const { setCrFullForm, cr_form } = useCreateRequestStore.getState();
  await setCrFullForm({
    ...cr_form,
    title: "",
    transport_type: "",
    typeoftransportation_id: "",
    pickup_attachment_url: "",
    pickup: {
      address: "",
      lat: "",
      lng: "",
      date: "",
      start_time: "",
      end_time: "",
      comment: "",
      duration: null,
      floor_number: "",
      attachment: null
    },
    shift_id: null,
  });
};

export const isFormComplete = (cr_form) => {
  const isPickupComplete =
    cr_form?.pickup?.start_time &&
    cr_form?.pickup?.end_time &&
    cr_form?.pickup?.date &&
    cr_form?.pickup?.address &&
    cr_form?.pickup?.lat &&
    cr_form?.pickup?.lng;

  const hasTransportType = cr_form?.transport_type;
  const hasDeliveries = cr_form?.deliveries?.length > 0;

  return isPickupComplete && hasTransportType && hasDeliveries;
};

export const updateNestedCrField = (field, subfield, value) => {
  const { cr_form, changeCrForm } = useCreateRequestStore.getState();
  changeCrForm(field, {
    ...cr_form[field],
    [subfield]: value,
  });
};

export const isCrFormValidation = () => {
  const { cr_form } = useCreateRequestStore.getState();
  const { user_role } = useGeneralStore.getState();

  const { transport_type, pickup, shift_id, proposal } = cr_form || {};
  const { address, start_time, end_time, date } = pickup || {};
  const { budget, details } = proposal || {};

  // Check if all delivery fields are valid
  const deliveriesValid =
    cr_form?.deliveries?.length > 0
      ? cr_form?.deliveries?.every(
        (delivery) => delivery.address
        // && delivery.lat && delivery.lng
      )
      : false;

  // Check if pickup date is not in the past
  const isPickupDateValid = (() => {
    if (!date) return false;
    const pickupDate = new Date(date);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Reset time to the start of the day
    return pickupDate >= currentDate;
  })();

  // Check if user role is company
  const isCompany = user_role === role.company;

  return {
    generate: isCompany ? [
      transport_type,
      address,
      start_time,
      end_time,
      date,
      deliveriesValid,
      isPickupDateValid,
      isCompany && shift_id,
      isCompany && budget,
      isCompany && details?.trim(),
    ].every(Boolean)
      :
      [
        transport_type,
        address,
        start_time,
        end_time,
        date,
        deliveriesValid,
        isPickupDateValid,
        cr_form?.is_global || cr_form?.company_user_ids?.length > 0
      ].every(Boolean),


    shift: !!shift_id,
    pickup: [transport_type, address, start_time, end_time, date, isPickupDateValid].every(
      Boolean
    ),
    proposal: [budget, details?.trim()].every(Boolean),
    deliveries: deliveriesValid,
  };
};

export const isCrFormValidationWithAddress = () => {
  const { cr_form } = useCreateRequestStore.getState();
  const { user_role } = useGeneralStore.getState();

  const { transport_type, pickup, deliveries, shift_id, proposal } =
    cr_form || {};
  const { address, lat, lng, start_time, end_time, date } = pickup || {};
  const { budget, details } = proposal || {};

  // Check if all delivery fields are valid
  const deliveriesValid =
    cr_form?.deliveries?.length > 0
      ? cr_form?.deliveries?.every(
        (delivery) => delivery.address
          && delivery.lat && delivery.lng
      )
      : false;

  // Check if pickup date is not in the past
  const isPickupDateValid = (() => {
    if (!date) return false;
    const pickupDate = new Date(date);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Reset time to the start of the day
    return pickupDate >= currentDate;
  })();

  // Check if user role is company
  const isCompany = user_role === role.company;

  return {
    generate: isCompany ? [
      transport_type,
      address,
      lat, lng,
      start_time,
      end_time,
      date,
      isPickupDateValid,
      deliveriesValid,
      isCompany && shift_id,
      isCompany && budget,
      isCompany && details,
    ].every(Boolean)
      :
      [
        transport_type,
        address,
        lat, lng,
        start_time,
        end_time,
        date,
        isPickupDateValid,
        deliveriesValid,
        cr_form?.is_global || cr_form?.company_user_ids?.length > 0
      ].every(Boolean),
    shift: !!shift_id,
    pickup: [transport_type, address, lat, lng, start_time, end_time, isPickupDateValid, date].every(
      Boolean
    ),
    proposal: [budget, details].every(Boolean),
    deliveries: deliveriesValid,
  };
};

export const getAddress = async (body, customStartIndex = 0) => {
  const { setTableCrFullForm, table_cr_form } = useCreateRequestStore.getState();
  try {
    setLoading(true);
    const res = await axios.post(kuAddressValidation, body);

    let updated_table_cr_form;
    if (res?.data?.length > 0) {
      // Ensure that each entry from the API response is correctly mapped
      updated_table_cr_form = table_cr_form.map((item, index) => {
        // Start processing from customStartIndex
        if (index >= customStartIndex && index < res.data.length + customStartIndex) {
          const data = res.data[index - customStartIndex]; // Adjust index for the response data
          return {
            ...item,
            address: data?.formatted_address ?? "",
            lat: data?.lat?.toString() || null,
            lng: data?.long?.toString() || null, // Corrected from "long" to "lng"
            // products: item?.products?.length === 0 ? [{ text: "GET ADDRESS" }] : item?.products,
          };
        } else {
          return item; // Return the original item if it doesn't match the criteria
        }
      });
      console.log("updated_table_cr_form: ", updated_table_cr_form);

      setTableCrFullForm([...updated_table_cr_form]);
    }

    setLoading(false);
    return updated_table_cr_form;
  } catch (err) {
    console.log("Error in getAddress:", err);
    Toastr({ message: t("An error occurred!") });
    setLoading(false);
    return false;
  }
};


export const handleUpdate = async () => {
  console.log("handleUpdate starting....");


  const { setCrFullForm, setCurrentSetup, setTableViewInvalidIndex, table_cr_form, cr_form } = useCreateRequestStore.getState();
  let isInvalidDateExists = false;
  const isFieldValid = (value) => value !== '' && value !== null && value != undefined && value !== 0 && !(value instanceof Array && value.length === 0);
  const isItemEmpty = (item) => Object.values(item).every(value => value === '' || value === null || value === undefined || value === 0 || (value instanceof Array && value?.length === 0));

  let product_resolved_table_cr_form = table_cr_form.map((item, index) => {
    return {
      ...item, products: (!!item?.address && (item?.products?.length === 0 || (item?.products?.length === 1 && item?.products[0].text?.trim() === ""))) ? [{ text: "Product" }] : item?.products,
    }
  });

  const able_cr_form = product_resolved_table_cr_form?.filter(item => {
    return isFieldValid(item.address) && isFieldValid(item.lat) && isFieldValid(item.lng);
  });


  // Filter out items that are completely empty
  const cleaned_table_cr_form = product_resolved_table_cr_form?.filter(item => !isItemEmpty(item));

  let hasEmptyAddress = false;
  let hasEmptyStartTime = false;
  let hasPastStartTime = false;
  let hasPastDate = false;
  let hasEmptyDate = false;
  let hasEmptyProducts = false;

  // Collect indices of invalid items based on address, lat, and lng
  const invalidItemIndices = cleaned_table_cr_form?.reduce((indices, item, index) => {
    console.log("cleaned_table_cr_form item: ", item);

    if (!item?.address) hasEmptyAddress = true;

    let comparableDate = cr_form?.pickup?.date ? new Date(cr_form?.pickup?.date) : new Date();
    // Subtract one day from the current date
    comparableDate.setDate(comparableDate.getDate() - 1);
    const isValidDate = item?.date
      ? new Date(item.date) !== "Invalid Date" && new Date(item.date) > comparableDate
      : true;


    if (!isValidDate && !isInvalidDateExists) {
      isInvalidDateExists = true;
    }

    if (item?.date && isValidDate && !item?.start_time) {
      hasEmptyStartTime = true;
    }

    if (item?.date && isValidDate && item?.start_time) {
      if (!isFutureDateTime(item?.date, true) && isPastTime(item?.start_time)) hasPastStartTime = true;
    }

    if (!item?.date && item?.start_time) {
      hasEmptyDate = true;
    }

    console.log("item?.products: ", item?.products);

    if (!item?.products || item?.products?.length === 0) {
      hasEmptyProducts = true;
    }

    if (item?.date && !isFutureDateTime(item?.date, true)) hasPastDate = true;

    if (
      !isFieldValid(item.address)
      || !isFieldValid(item.lat)
      || !isFieldValid(item.lng)
      || !isValidDate
      || hasEmptyStartTime
      || hasPastStartTime
      || hasPastDate
      || hasEmptyDate
      // || hasEmptyProducts
    ) {
      indices.push(index);
    }
    return indices;
  }, []);

  await setTableViewInvalidIndex(invalidItemIndices);

  let shift_id = cr_form?.shift_id;

  if (!able_cr_form?.length) {
    shift_id = null
  }

  console.log("invalidItemIndices: ", invalidItemIndices);

  if (parseInt(invalidItemIndices?.length) === 0) {

    // Check if able_cr_form is not empty before proceeding
    await setCrFullForm({ ...cr_form, deliveries: able_cr_form, shift_id: shift_id });
    setCurrentSetup(create_request_steps.mass_import);
  } else {
    if (hasEmptyAddress) return Toastr({ message: t("Please fill in the address field!"), type: "warning" });
    if (hasEmptyStartTime) return Toastr({ message: t("Time field can not be empty if the date is filled up."), type: "warning" });
    if (hasPastStartTime) return Toastr({ message: t("Time can not be past if the date is present day."), type: "warning" });
    if (hasPastDate) return Toastr({ message: t("Date can not be past."), type: "warning" });
    if (isInvalidDateExists) return Toastr({ message: t("Valid date is required."), type: "warning" });
    if (hasEmptyDate) return Toastr({ message: t("Date field can not be empty if the time is filled up."), type: "warning" });
    // if (hasEmptyProducts) return Toastr({ message: t("At least 1 product is required for a delivery."), type: "warning" });
    Toastr({ message: t("Address is invalid. Select a valid address from the suggestions."), type: "warning" });
  }
};