/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { RadioGroup } from "@mui/material";
import { useTranslation } from 'react-i18next';
import useSettingsStore, { setAppLangCodeFoo } from '../../../app/stores/others/settingsStore';
import { changePageTitle } from '../../../app/utility/utilityFunctions';
import CommonRadioItemItem from '../../../components/Radio/CommonRadioItemItem';
import CommonTitle from '../../../components/title/CommonTitle';

const Language = () => {

    const { t } = useTranslation();
    
    const { app_lang_code } = useSettingsStore();

    useEffect(() => {
        changePageTitle(t('Settings | Language'));
    }, [])


    return (
        <div>
            <CommonTitle title={t("Language")} />
            <div className='mt-4'></div>
            <RadioGroup
                className="w-full"
                required={true}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
            >
                <div className="flex w-full space-x-5">

                    <CommonRadioItemItem
                        isActive={app_lang_code === 'en'}
                        checked={app_lang_code === 'en'}
                        value="en"
                        required={true}
                        label={"English"}
                        onChange={(e) => setAppLangCodeFoo(e.target.value)}
                    />

                    <CommonRadioItemItem
                        isActive={app_lang_code === 'da'}
                        checked={app_lang_code === 'da'}
                        value="da"
                        required={true}
                        label={"Danish"}
                        onChange={(e) => setAppLangCodeFoo(e.target.value)}
                    />
                </div>
            </RadioGroup>
        </div>
    );
};

export default Language;