import React from 'react';
import useShiftStore from '../../../../app/stores/company/shiftStore';
import { formatDate, formatTimeHourMinutes, getOrdinalNumber } from '../../../../app/utility/utilityFunctions';
import { useTranslation } from 'react-i18next';
import { k_shift_status } from '../../../../app/utility/const';

const ShiftLogBookTable = () => {

    const { shiftDetailsData } = useShiftStore();

    const status = shiftDetailsData?.log_books_details?.shift_status;
    const { t } = useTranslation();

    return (
        <div>
            {
                shiftDetailsData?.is_maintenance !== 1 ?
                    <div className='w-full'>
                        <div className='content-title'>{t("Logbook")}</div>

                        <div className='flex justify-between mt-3 text-cBaseBlack'>
                            <div className='font-normal capitalize text-fs18 leading-6'>{t("Title")}</div>
                            <div className='font-normal capitalize text-fs18 leading-6'>{t("Time Range")}</div>
                        </div>

                        <table className='w-full font-medium'>
                            <LogTableRow breakIndex={t('Shift Starts')}
                                timeFrame={shiftDetailsData?.start_time ?
                                    `${formatTimeHourMinutes(shiftDetailsData?.start_time)}` ?? 'NA' : 'NA'} />

                            {(shiftDetailsData?.status === "init") &&
                                ((shiftDetailsData?.starts_in_raw <= -1 && shiftDetailsData?.starts_in_time_unit === "days") ||
                                    (shiftDetailsData?.starts_in_raw <= -24 && shiftDetailsData?.starts_in_time_unit === "hour")) ?
                                <LogTableRow breakIndex='Shift Time' timeFrame={"Expired"} />
                                : <>
                                    {(status === k_shift_status.ongoing || status === k_shift_status.break || status === k_shift_status.complete) &&
                                        <LogTableRow breakIndex={t('Driver Started')}
                                            timeFrame={shiftDetailsData?.log_books_details?.shift_start ? formatDate(shiftDetailsData?.log_books_details?.shift_start, true) ?? 'NA' : 'NA'} />}

                                    {
                                        shiftDetailsData?.log_books?.length ? shiftDetailsData?.log_books?.map((item, index) =>
                                            <>
                                                <LogTableRow
                                                    key={index}
                                                    breakIndex={getOrdinalNumber((index + 1)) + t(' Break Start')}
                                                    timeFrame={item?.break_start ? formatDate(item?.break_start, true) ?? 'NA' : 'NA'}
                                                />
                                                {item?.break_end && <LogTableRow
                                                    key={index + 500}
                                                    breakIndex={getOrdinalNumber((index + 1)) + t(' Break End')}
                                                    timeFrame={item?.break_end ? formatDate(item?.break_end, true) ?? 'NA' : 'NA'}
                                                />}
                                            </>
                                        )
                                            : ""
                                    }
                                    {status === 'complete' && shiftDetailsData?.ended_at ?
                                        <LogTableRow breakIndex={t('Shift Ended')}
                                            timeFrame={shiftDetailsData?.ended_at ?
                                                formatDate(shiftDetailsData?.ended_at, true) ?? 'NA' : 'NA'} /> : ""}
                                </>
                            }
                        </table>
                    </div> :
                    <div className='p-3 font-medium text-center border-2 rounded-lg border-cGray200 text-fs16 text-cGray500'>
                        {t("Maintenance Shift, No Logbook Available!")}
                        {/* This shift is not started! */}
                    </div>
            }
        </div>
    )
}

export default ShiftLogBookTable


const LogTableRow = ({
    breakIndex = '1st Break',
    startsAt = 'Start/End: ',
    timeFrame = 'Start/End: 9. Mar. 2023, 08:50',
}) => {
    return (
        <span className='flex items-center mt-2 space-x-2 font-medium text-fs14 text-cBaseBlack'>
            <div className='inline-block whitespace-nowrap text-fs14 text-cBaseBlack font-normal'>{breakIndex}</div>
            <div className='flex items-center justify-center w-full mr-2 border-t-2 border-dashed ml-s5 border-cBaseBlack'></div>
            <div className='flex justify-end whitespace-nowrap text-fs14 text-cBaseBlack font-normal'>{timeFrame}</div>
        </span>
    )
}