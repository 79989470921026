import { create } from "zustand";
import { t } from "i18next";
import axios from "axios";
import Pusher from 'pusher-js';
import useGeneralStore from "./generalStore";
import useUtilityStore from "./utilityStore";
import { chatCounterUrl, kuChatList, kuCompanyChatUserList, kuCustomerChatUserList, kuDeleteMessage, kuGetMessage, kuSendMessage } from "../../urls/commonUrl";
import { Toastr } from "../../utility/utilityFunctions";
import { chatCluster, chatKey, chat_role, user_role as role } from "../../utility/const";
const { setLoading } = useGeneralStore.getState();
const { setLoadingSearch } = useUtilityStore.getState();

const useChatStore = create((set) => ({

    showAddChatModal: false,
    setShowAddChatModal: (value) => set({ showAddChatModal: value }),

    selectChat: {},
    setSelectChat: (value) => set({ selectChat: value }),

    chat_list: [],
    setAddChatList: (value) => set({ chat_list: value }),

    current_role: "",
    setCurrentRole: (value) => set({ current_role: value }),

    textFieldCount: 40,
    setTextFieldCount: (value) => set({ textFieldCount: value }),

    message_array: [],
    setAddMessageArray: (value) => set({ message_array: value }),

    add_chat_user_list: [],
    setAddChatUserList: (value) => set({ add_chat_user_list: value }),

    chat_search: "",
    setChatSearch: (value) => set({ chat_search: value }),

    user_search: "",
    setUserSearch: (value) => set({ user_search: value }),

    message_notification: false,
    setMessageNotification: (value) => set({ message_notification: value }),

    notification_info: false,
    setNotificationInfo: (value) => set({ notification_info: value }),

    message_scroll_bottom: false,
    setMessage_scroll_bottom: (value) => set({ message_scroll_bottom: value }),

    showDeleteChatModal: false,
    setShowDeleChatModal: (value) => set({ showDeleteChatModal: value }),

    firebase_new_message: false,
    setFirebaseNewMessage: (value) => set({ firebase_new_message: value }),

    total_unread_message: 0,
    setTotalUnreadMessage: (value) => set({ total_unread_message: value }),

}));

// chat list
export const getChatList = async (search = "", is1stIndexSelect = false, isLoading = true, pagination = false) => {

    const { setAddChatList, setSelectChat, chat_list } = useChatStore.getState();

    const setLoadingState = (state) => {
        if (search === "") {
            if (isLoading) setLoading(state);
        } else {
            setLoadingSearch(state);
        }
    };

    try {
        setLoadingState(true);

        const body = {
            search: search,
            ...(pagination && { page: chat_list?.current_page + 1 }),
            take: 15
        };

        const res = await axios.get(kuChatList, { params: body });

        // console.log("chat list:::: ", res?.data)

        const { data: { success, data, message } = {} } = res;

        if (success) {
            if (!pagination) setAddChatList(data);
            if (is1stIndexSelect) setSelectChat(data?.data[0]);
        } else {
            Toastr({ message, type: "error" });
        }
        return res?.data;
    } catch (error) {
        console.log("getChatList: ", error);
        Toastr({ message: t("An error occurred!"), type: "error" });
        return { success: false };
    } finally {
        setLoadingState(false);
    }
};

// message list
export const getMessageList = async (body, isLoading = true) => {

    const { setAddMessageArray } = useChatStore.getState();

    try {
        if (isLoading) setLoading(true);
        const res = await axios.get(kuGetMessage, { params: body });
        // console.log("getMessageList res.data:::: ", res?.data);

        if (res?.data?.success) {
            setAddMessageArray({
                current_page: res?.data?.data?.current_page,
                last_page: res?.data?.data?.last_page,
                data: res?.data?.data?.data?.slice().reverse(),
                total: res?.data?.data?.total
            });
            setLoading(false);
            return res?.data;
        } else {
            Toastr({ message: res?.data?.message, type: "error" });
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log("getMessageList: ", error);
        Toastr({ message: t("An error occurred!"), type: "error" });
        setLoading(false);
        return false;
    }
};

//send message
export const sendMessage = async (body) => {
    const { selectChat } = useChatStore.getState();
    try {

        const res = await axios.post(kuSendMessage, body);
        console.log("sendMessage res.data:::: ", res?.data);

        if (res?.data?.success) {
            await newUserSubscribe(selectChat?.channel_name + "-" + res?.data[0]?.chat_id);
            return res?.data;
        } else {
            Toastr({ message: res?.data?.message, type: "error" });
            return res?.data;
        }
    } catch (error) {
        console.log("sendMessage: ", error);
        // Toastr({ message: t("An error occurred!"), type: "error" });
        return false;
    }
};

//user list
export const getChatUserList = async (body) => {

    const search = body.search
    const pagination = body.pagination
    const isLoading = body.isLoading
    const user_role = body.user_role

    const { add_chat_user_list, setAddChatUserList } = useChatStore.getState();

    try {
        if (!search) { if (isLoading) setLoading(true); } else { setLoadingSearch(true) }
        let body = {};
        if (pagination) { body = { search: search, page: add_chat_user_list?.current_page + 1, take: 10 } }
        else { body = { search: search, take: 10 } }

        const res = await axios.get(user_role === role.customer ? kuCustomerChatUserList : kuCompanyChatUserList, { params: body });
        console.log("getChatUserList res.data:::: ", res?.data);

        if (res?.data?.success) {
            if (!pagination) setAddChatUserList(res?.data?.data)
            setLoading(false);
            setLoadingSearch(false);
            return res?.data;
        } else {
            Toastr({ message: res?.data?.message, type: "error" });
            setLoading(false);
            setLoadingSearch(false);
            return res?.data;
        }
    } catch (error) {
        console.log("getChatUserList: ", error);
        Toastr({ message: t("An error occurred!"), type: "error" });
        setLoading(false);
        setLoadingSearch(false);
        return {
            success: false
        };
    }
};



const newUserSubscribe = (channel_name, id) => {
    const pusher = new Pusher(chatKey, { cluster: chatCluster });
    const channel = pusher.subscribe(channel_name?.channel_name + "-" + id);
    return () => {
        channel.unsubscribe();
    };
}


// export const chatSeen = async (chat_id) => {

//   try {
//     const res = await axios.get(chatSeenUrl,{ params: {chat_id:chat_id} } );
//     console.log("chatSeen res.data:::: ", res?.data);

//     if (res?.data?.success) {
//       return true;
//     } else {
//       Toastr({ message: res?.data?.message, type: "error" });
//       return false;
//     }
//   } catch (error) {
//     console.log("chatSeen: ", error);
//     Toastr({ message: t("An error occurred!"), type: "error" });
//     return false;
//   }
// };

export const getChatCounter = async () => {

    const { setTotalUnreadMessage } = useChatStore.getState();
    try {
        const res = await axios.get(chatCounterUrl);
        // console.log("getChatCounter res.data:::: ", res?.data);

        if (res?.data?.success) {
            setTotalUnreadMessage(res?.data?.data?.total_unread_message);
            return true;
        } else {
            Toastr({ message: res?.data?.message, type: "error" });
            return false;
        }
    } catch (error) {
        console.log("getChatCounter: ", error);
        Toastr({ message: t("An error occurred!"), type: "error" });
        return false;
    }
};


export const deleteMessage = async (id) => {
    const { setAddMessageArray, chat_search, setShowDeleChatModal } = useChatStore.getState();
    try {
        setLoading(true);
        const res = await axios.post(kuDeleteMessage, { "chat_id": id });
        console.log("deleteMessage res.data:::: ", res?.data);

        if (res?.data?.success) {
            Toastr({ message: res?.data?.message, type: "success" });
            setShowDeleChatModal(false);
            setAddMessageArray([]);
            getChatList(chat_search, false);
            setLoading(false);
            return true;
        } else {
            Toastr({ message: res?.data?.message, type: "error" });
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log("deleteMessage: ", error);
        Toastr({ message: t("An error occurred!"), type: "error" });
        setLoading(false);
        return false;
    }
};


export const currentRole = async (user_role, role) => {
    const { setCurrentRole } = useChatStore.getState();
    if (user_role === role.customer) {
        setCurrentRole(chat_role.customer);
    } else if (user_role === role.company) {
        setCurrentRole(chat_role.company)
    }
}

export const commonSelectedChat = async (data, current_role) => {
    const { setSelectChat } = useChatStore.getState();

    await setSelectChat({
        id: data?.chat_info?.chat_id,
        channel_name: current_role === chat_role.customer ? `${chat_role.customer}-${data?.user_role}` : data?.user_role + "-" + chat_role.company,
        image: data?.image,
        is_active: data?.is_active,
        name: data?.chat_info?.name,
        user_id: data?.id,
        user_type: data?.chat_info?.user_role,
    });
}

export default useChatStore;
