/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useRequestStore from "../../../../../../app/stores/others/requestStore";
import { useTranslation } from "react-i18next";
import CommonViewComponent from "../../../../../../components/viewer/CommonViewComponent";
import { useNavigate, useParams } from "react-router-dom";
import CommonCheckbox from "../../../../../../components/input/CommonCheckbox";
import {
    formatDate,
    formatTime,
} from "../../../../../../app/utility/utilityFunctions";
import CommonCompanyDetailsModal from "../../../../../../components/modal/CommonCompanyDetailsModal";

const Budget = () => {
    const { t } = useTranslation();

    const { request_details, selectBiddingId, inBiddingCheck, setBiddingCheck } =
        useRequestStore();

    const [showCompanyDetailsModal, setShowCompanyDetailsModal] = useState(false);

    const selectCompany = request_details?.biddings?.find(
        (item) => item?.id === selectBiddingId
    );

    const navigateTo = useNavigate();

    const params = useParams();

    const { request_id, type } = params;

    useEffect(() => {
        if (!selectCompany) {
            navigateTo(`/requests/${type}/details/${request_id}/bidding`);
        }
        setBiddingCheck(false);
    }, [selectCompany]);

    return (
        <div>
            <div className="mb-4 sub-title text-cGray800">
                {t("Agreement Overview")}
            </div>

            <div className="mb-5 space-y-2">
                <CommonViewComponent
                    labelText={t("Company")}
                    value={selectCompany?.company_info?.name}
                />
                <CommonViewComponent
                    labelText={t("Budget")}
                    value={"DKK " + selectCompany?.budget?.toLocaleString("da-DK")}
                />
                <CommonViewComponent
                    labelText={t("Bidding Expire on")}
                    value={`${request_details?.pickup_date
                        ? formatDate(request_details?.pickup_date)
                        : ""
                        }${request_details?.pickup_start_time ? "," : ""}  ${request_details?.pickup_start_time
                            ? (formatTime(request_details?.pickup_start_time) ?? "--") +
                            " - " +
                            (formatTime(request_details?.pickup_end_time) ?? "--")
                            : ""
                        }`}
                />
                <CommonViewComponent
                    labelText={t("Bidding Description")}
                    value={selectCompany?.details}
                />
            </div>

            <div className="flex space-x-2">
                <CommonCheckbox
                    checked={inBiddingCheck}
                    onChange={() => {
                        setBiddingCheck(!inBiddingCheck);
                    }}
                />

                <div className="flex items-center justify-center space-x-1">
                    <div className="checkBoxLabel">
                        {t("I agree with all")}{" "}
                    </div>
                    <a
                        className="font-normal text-cBrand text-fs14 underline"
                        href="https://limadi.dk/TermsAndCondition.html"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("Limadi Terms & Conditions")}
                    </a>
                    <div className="font-normal checkBoxLabel text-fs14">{t("also with")} </div>
                    <div
                        className="font-normal cursor-pointer text-cBrand text-fs14 underline"
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowCompanyDetailsModal(true);
                        }}
                    >
                        {t("Company Terms & Conditions.")}
                    </div>
                </div>
            </div>

            <CommonCompanyDetailsModal
                company_id={selectCompany?.company_info?.id}
                showModal={showCompanyDetailsModal}
                setShowModal={setShowCompanyDetailsModal}
            />
        </div>
    );
};

export default Budget;
