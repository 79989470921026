/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useShiftStore, {
  getAllShiftCarsAndDriversList,
  updateShift,
} from "../../../../app/stores/company/shiftStore";
import {
  checkForCurrentDate,
  checkPastTime,
  formatTimeHourMinutes,
  forwardDate,
  getStringFromDateObject,
  Toastr,
} from "../../../../app/utility/utilityFunctions";
import CommonCheckbox from "../../../../components/input/CommonCheckbox";
import CommonDatePicker from "../../../../components/input/CommonDatePicker";
import CommonTimePicker from "../../../../components/input/CommonTimePicker";
import CommonViewComponent from "../../../../components/viewer/CommonViewComponent";
import CommonSelect from "../../../../components/select/CommonSelect";
import CommonMultipleSelect from "../../../../components/input/CommonMultipleSelect";
import CommonInput from "../../../../components/input/CommonInput";
import CommonButton from "../../../../components/button/CommonButton";
import { useNavigate, useParams } from "react-router-dom";
import CommonTitle from "../../../../components/title/CommonTitle";
import CommonButtonOutlined from "../../../../components/button/CommonButtonOutlined";
import { useDebounce } from "use-debounce";

const EditShift = () => {
  const [reset_car_driver_data, setResetCarDriverData] = useState(false);

  const { t } = useTranslation();

  const navigateTo = useNavigate();

  const [canSubmit, setCanSubmit] = useState(false);
  const [isDisableOptions, setIsDisableOptions] = useState(true);
  const [time, setTime] = useState("");
  const [startingTime, setStartingTime] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  let [endTime] = useDebounce(time, 1000);
  let [startTime] = useDebounce(startingTime, 1000);

  const {
    shiftUpdateData,
    setShiftUpdateData,
    allShiftDriverList,
    allShiftCarList,
    shiftDetailsData,
    changeShiftUpdateData,
    resetShiftUpdateData,
  } = useShiftStore();

  const { shift_id } = useParams();

  useEffect(() => {
    if (!shiftDetailsData?.id) {
      navigateTo(`/shift-manager/details/${shift_id}`);
    }
  }, [shiftDetailsData]);

  // b    date/time validation
  const isTimeValid = (
    startTime,
    endTime,
    startDate = shiftUpdateData?.start_date,
    endDate = shiftUpdateData?.end_date
  ) => {
    // Combine the start date and time into a single Date object
    const startDateTime = new Date(startDate + "T" + startTime);

    // Combine the end date and time into a single Date object
    const endDateTime = new Date(endDate + "T" + endTime);

    // Check if the start date/time is before the end date/time
    return startDateTime < endDateTime;
  };

  const fetchCarAndDrivers = async () => {
    let validatedTime = await isTimeValid(
      shiftUpdateData?.start_time,
      shiftUpdateData?.end_time
    );
    let x;
    if (shiftUpdateData?.is_maintenance) {
      if (validatedTime) {
        //the loading can be removed from here by sending false as 5th parameter to load the data silently
        x = await getAllShiftCarsAndDriversList(
          shiftUpdateData?.start_date,
          shiftUpdateData?.end_date,
          shiftUpdateData?.start_time,
          shiftUpdateData?.end_time,
          true,
          1,
          shiftDetailsData?.id
        );
      } else {
        let start_date_next = shiftUpdateData?.start_date
          ? new Date(shiftUpdateData?.start_date)
          : null;
        start_date_next?.setDate(start_date_next?.getDate() + 1);
        x = await getAllShiftCarsAndDriversList(
          shiftUpdateData?.start_date,
          getStringFromDateObject(start_date_next ?? new Date()),
          shiftUpdateData?.start_time,
          shiftUpdateData?.end_time,
          true,
          1,
          shiftDetailsData?.id
        );
      }
      setResetCarDriverData(false);
      setIsDisableOptions(false);
      return;
    } else {
      if (validatedTime) {
        //the loading can be removed from here by sending false as 5th parameter to load the data silently
        x = await getAllShiftCarsAndDriversList(
          shiftUpdateData?.start_date,
          shiftUpdateData?.end_date,
          shiftUpdateData?.start_time,
          shiftUpdateData?.end_time,
          true,
          1,
          shiftDetailsData?.id
        );
      } else {
        let start_date_next = shiftUpdateData?.start_date
          ? new Date(shiftUpdateData?.start_date)
          : null;
        start_date_next?.setDate(start_date_next?.getDate() + 1);
        x = await getAllShiftCarsAndDriversList(
          shiftUpdateData?.start_date,
          getStringFromDateObject(start_date_next ?? new Date()),
          shiftUpdateData?.start_time,
          shiftUpdateData?.end_time,
          true,
          1,
          shiftDetailsData?.id
        );
      }
      if (x?.cars?.length === 0)
        Toastr({ message: "No car found", type: "warning" });
      if (x?.drivers?.length === 0)
        Toastr({ message: "No driver found", type: "warning" });
      setResetCarDriverData(false);
      setIsDisableOptions(false);
      setIsUpdated(true);
      return;
    }
  };

  const defineEndDate = async (start_date) => {
    if (start_date && startTime && endTime && isUpdated) {
      if (startTime < endTime) {
        const x = start_date;
        setShiftUpdateData({
          ...shiftUpdateData,
          end_date: x,
          start_date: start_date,
          start_time: startTime,
          end_time: endTime,
          car_id: null,
          car_license_plate_number: "",
          driver_user_id: "",
          co_drivers: [],
        });
        return;
      }
      if (startTime >= endTime) {
        const x = forwardDate(start_date);
        setShiftUpdateData({
          ...shiftUpdateData,
          end_date: x,
          start_date: start_date,
          start_time: startTime,
          end_time: endTime,
          car_id: null,
          car_license_plate_number: "",
          driver_user_id: "",
          co_drivers: [],
        });
        return;
      }
    }
  };

  useEffect(() => {
    if (shiftUpdateData?.id) {
      setShiftUpdateData({
        ...shiftUpdateData,
        start_time: startTime,
      });
      setResetCarDriverData(true);
      defineEndDate(
        shiftUpdateData?.start_date,
        startTime,
        shiftUpdateData?.end_time
      );
    }
  }, [startTime]);

  useEffect(() => {
    if (shiftUpdateData) {
      setShiftUpdateData({
        ...shiftUpdateData,
        end_time: endTime,
      });
      setResetCarDriverData(true);
      defineEndDate(
        shiftUpdateData?.start_date,
        shiftUpdateData?.start_time,
        endTime
      );
    }
  }, [endTime]);

  useEffect(() => {
    if (reset_car_driver_data) {
      if (shiftUpdateData?.start_date && startTime && endTime) {
        if (shiftUpdateData?.is_maintenance && shiftUpdateData?.end_date) {
          fetchCarAndDrivers();
        } else {
          fetchCarAndDrivers();
        }
      }
    }
  }, [shiftUpdateData, reset_car_driver_data]);

  const handleSubmit = async () => {
    if (canSubmit) {
      let updateSuccess;

      if (
        checkPastTime(shiftUpdateData?.start_time, shiftUpdateData?.start_date)
      ) {
        return Toastr({
          message: t("Start Time: Past Time not allowed."),
          type: "warning",
        });
      }

      let x = shiftUpdateData;
      if (
        shiftUpdateData?.start_time &&
        shiftUpdateData?.start_time > shiftUpdateData?.end_time
      ) {
        const date = new Date(shiftUpdateData?.start_date);
        date.setDate(date.getDate() + 1);
        await setShiftUpdateData({
          ...shiftUpdateData,
          end_date: date.toISOString().slice(0, 10),
        });
        x = { ...x, end_date: date.toISOString().slice(0, 10) };
      }

      if (
        shiftUpdateData?.start_date &&
        shiftUpdateData?.end_date &&
        shiftUpdateData?.start_time &&
        shiftUpdateData?.end_time &&
        shiftUpdateData?.car_license_plate_number
      ) {
        if (
          !shiftUpdateData?.is_maintenance &&
          shiftUpdateData?.driver_user_id &&
          shiftUpdateData?.comment
        )
          updateSuccess = await updateShift(x, shiftDetailsData?.id);
        else if (shiftUpdateData?.is_maintenance)
          updateSuccess = await updateShift(x, shiftDetailsData?.id);
      }

      // updateSuccess = await updateShift(shiftUpdateData, shiftDetailsData?.id);

      if (updateSuccess) {
        // setShowEditShiftModal(false);
        navigateTo(`/shift-manager/details/${shift_id}`);
      }
    } else {
      Toastr({
        message: t("Please fill up all required fields"),
        type: "warning",
      });
    }
  };

  useEffect(() => {
    if (
      shiftUpdateData?.is_maintenance &&
      shiftUpdateData?.start_date &&
      shiftUpdateData?.end_date &&
      shiftUpdateData?.start_time &&
      shiftUpdateData?.end_time
    ) {
      if (shiftUpdateData?.car_id && shiftUpdateData?.car_license_plate_number)
        setCanSubmit(true);
      else setCanSubmit(false);
    } else if (
      !shiftUpdateData?.is_maintenance &&
      shiftUpdateData?.start_date &&
      shiftUpdateData?.start_time &&
      shiftUpdateData?.end_time
    ) {
      if (
        shiftUpdateData?.car_id &&
        shiftUpdateData?.car_license_plate_number &&
        shiftUpdateData?.driver_user_id &&
        shiftUpdateData?.comment?.trim()
      )
        setCanSubmit(true);
      else setCanSubmit(false);
    }
  }, [shiftUpdateData]);

  useEffect(() => {
    let coDriverIDs = [];
    shiftDetailsData?.shift_drivers?.map((driver) => {
      if (!driver?.is_main_driver) coDriverIDs.push(driver?.driver_user_id);
    });
    setShiftUpdateData({
      id: shiftDetailsData?.id,
      driver_user_id: shiftDetailsData?.driver_user_id,
      co_drivers: coDriverIDs || [],
      car_id: shiftDetailsData?.car_id,
      car_license_plate_number: shiftDetailsData?.car?.car_license_plate_number,
      start_date: shiftDetailsData?.start_date,
      end_date: shiftDetailsData?.end_date,

      start_time: formatTimeHourMinutes(shiftDetailsData?.start_time),
      end_time: formatTimeHourMinutes(shiftDetailsData?.end_time),

      comment: shiftDetailsData?.comment,
      is_maintenance: shiftDetailsData?.is_maintenance,
    });
    setTime(formatTimeHourMinutes(shiftDetailsData?.end_time));
    setStartingTime(formatTimeHourMinutes(shiftDetailsData?.start_time));
    setResetCarDriverData(true);
    setIsUpdated(false);
  }, [shiftDetailsData]);

  return (
    <div>
      <div className="flex justify-between">
        <CommonTitle
          title={t("Edit Shift")}
          link="/shift-manager"
          icon={true}
        />

        <div className="flex space-x-5">
          <CommonButtonOutlined
            onClick={() => {
              navigateTo(`/shift-manager/details/${shift_id}`);
            }}
            colorType="danger"
            btnLabel={t("Cancel")}
          />
          <CommonButton
            isDisabled={!canSubmit}
            type="submit"
            btnLabel={t("Update")}
            onClick={handleSubmit}
          />
        </div>
      </div>
      <div className="flex space-x-[30px]">
        <form
          className="w-full flex flex-col gap-5"
          onSubmit={(e) => e.preventDefault()}
        >
          {/*l         maintenance state selection */}
          <div className="flex items-center space-x-2.5 cursor-pointer select-none w-full">
            <CommonCheckbox
              label={t("in maintenance")}
              checked={shiftUpdateData?.is_maintenance}
              onChange={async (e) => {
                setShiftUpdateData({
                  is_maintenance:
                    parseInt(shiftUpdateData?.is_maintenance) === 0 ? 1 : 0,
                  id: shiftDetailsData?.id,
                  driver_user_id: "",
                  co_drivers: [],
                  car_id: "",
                  car_license_plate_number: "",
                  start_date: "",
                  end_date: "",
                  start_time: "",
                  end_time: "",
                  comment: "",
                });
                setCanSubmit(false);
                setStartingTime("");
                setTime("");
                setIsDisableOptions(true);
              }}
            />
          </div>

          {/*e      date pickers */}
          <div className="grid grid-cols-2 items-center gap-7">
            <CommonDatePicker
              label={t("Start Date")}
              required={true}
              value={shiftUpdateData?.start_date}
              onChange={async (date) => {
                setShiftUpdateData({
                  ...shiftUpdateData,
                  start_date: getStringFromDateObject(date),
                  start_time: "", 
                  end_time: ""
                });

                setResetCarDriverData(true);
                defineEndDate(
                  getStringFromDateObject(date),
                  shiftUpdateData?.start_time,
                  shiftUpdateData?.end_time
                );
              }}
              show_asterisk={true}
              allowPastDate={false}
            />
            {parseInt(shiftUpdateData?.is_maintenance) ? (
              <CommonDatePicker
                label={t("end date")}
                required={
                  parseInt(shiftUpdateData?.is_maintenance) ? true : false
                }
                startDate={shiftUpdateData?.start_date}
                disabled={!shiftUpdateData?.start_date}
                value={shiftUpdateData?.end_date}
                onChange={(date) => {
                  setShiftUpdateData({
                    ...shiftUpdateData,
                    end_date: getStringFromDateObject(date),
                    start_time: "", 
                    end_time: ""
                  });
                  setResetCarDriverData(true);
                }}
                show_asterisk={true}
                allowPastDate={false}
              />
            ) : (
              <div></div>
            )}
          </div>

          {/*p      time pickers */}
          <div className="grid grid-cols-2 items-center gap-7 -mb-s11 mt-s6">
            <CommonTimePicker
              showExtendedTimeUi={false}
              label={t("start time")}
              required={true}
              disabled={!shiftUpdateData?.start_date}
              init_time={startingTime}
              onChange={(time) => {
                setStartingTime(time);
              }}
              show_asterisk={true}
              isCurrentTimeValidation={checkForCurrentDate(shiftUpdateData?.start_date)}
            />
            <CommonTimePicker
              showExtendedTimeUi={false}
              required={true}
              label={t("End Time")}
              disabled={!shiftUpdateData?.start_date}
              init_time={time}
              onChange={(time) => {
                setTime(time);
              }}
              show_asterisk={true}
            />
          </div>

          <div className={`items-center`}>
            <CommonSelect
              showExtendedTimeUi={false}
              disabled={isDisableOptions}
              label={t("Vehicle")}
              placeholder={t("Select vehicle")}
              dataArray={allShiftCarList}
              required={true}
              value={shiftUpdateData?.car_license_plate_number}
              onChange={(e) => {
                let car_id_found = allShiftCarList?.find(
                  (car) => car?.value === e.target.value
                );

                setShiftUpdateData({
                  ...shiftUpdateData,
                  car_id: parseInt(car_id_found?.id),
                  car_license_plate_number: e.target.value,
                });
              }}
              show_asterisk={true}
            />
          </div>

          <div className={`items-center mt-s1`}>
            <CommonSelect
              show_image={true}
              isRoundedFull={true}
              showExtendedTimeUi={false}
              label={t("Main Driver")}
              placeholder={t("Select main driver")}
              disabled={isDisableOptions || shiftUpdateData?.is_maintenance}
              required={
                parseInt(shiftUpdateData?.is_maintenance) ? false : true
              }
              // dataArray={allShiftDriverList}
              dataArray={allShiftDriverList?.filter(
                (item) => !shiftUpdateData?.co_drivers?.includes(item.value)
              )}
              value={shiftUpdateData?.driver_user_id}
              onChange={(e) => {
                if (e.target.value) {
                  setShiftUpdateData({
                    ...shiftUpdateData,
                    driver_user_id: e.target.value,
                  });
                } else {
                  setShiftUpdateData({
                    ...shiftUpdateData,
                    driver_user_id: e.target.value,
                    co_drivers: [],
                  });
                }
              }}
              show_asterisk={true}
            />
          </div>

          <div className="mt-s9">
            <CommonMultipleSelect
              // disabled={!shiftUpdateData?.driver_user_id}
              dataArray={allShiftDriverList.filter(
                (item) => item?.value !== shiftUpdateData?.driver_user_id
              )}
              disabled={!shiftUpdateData?.driver_user_id}
              label={t("Co-Drivers")}
              selectItem={allShiftDriverList?.filter((item) =>
                shiftUpdateData?.co_drivers?.includes(item?.value)
              )}
              onSelectItem={(e) => {
                if (!shiftUpdateData?.co_drivers?.includes(e?.value)) {
                  if (shiftUpdateData?.co_drivers?.length < 2) {
                    setShiftUpdateData({
                      ...shiftUpdateData,
                      co_drivers: [...shiftUpdateData?.co_drivers, e.value],
                    });
                  } else {
                    Toastr({
                      message: t("Maximum 2 co-drivers allowed"),
                      type: "warning",
                    });
                  }
                } else {
                  setShiftUpdateData({
                    ...shiftUpdateData,
                    co_drivers: shiftUpdateData?.co_drivers?.filter(
                      (value) => value !== e.value
                    ),
                  });
                }
              }}
              handleRemoveItem={(e) => {
                setShiftUpdateData({
                  ...shiftUpdateData,
                  co_drivers: shiftUpdateData?.co_drivers.filter(
                    (item) => item !== e.value
                  ),
                });
              }}
              noDataText={'No co-drivers available'}
            />
          </div>

          {/*g         textarea */}
          <div className="-mt-s15">
            <CommonInput
              textarea={true}
              max_input={255}
              labelText={t("Shift Instruction")}
              disabled={
                parseInt(shiftUpdateData?.is_maintenance) ? true : false
              }
              required={
                parseInt(shiftUpdateData?.is_maintenance) ? false : true
              }
              value={shiftUpdateData?.comment ?? ""}
              onChange={(e) => {
                setShiftUpdateData({
                  ...shiftUpdateData,
                  comment: e.target.value,
                });
              }}
              show_asterisk={true}
            />
          </div>
        </form>

        <div className="space-y-2 w-[500px] "></div>
      </div>
    </div>
  );
};

export default EditShift;
