import React from 'react'
import CommonTopTitleSection from '../../../../components/title/CommonTopTitleSection';
import useShiftStore from '../../../../app/stores/company/shiftStore';
import CommonButtonOutlined from '../../../../components/button/CommonButtonOutlined';
import CommonButton from '../../../../components/button/CommonButton';
import { useTranslation } from 'react-i18next';
import { global_request_path, k_request_paths, k_shift_status, request_details_types } from '../../../../app/utility/const';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import RequestDetailsTopBar from '../../../common/requests/requestDetails/components/requestDetailsTopBar/RequestDetailsTopBar';
import { k_create_request_paths } from '../../../../app/utility/AllRoute';
import TitleAndActions from '../../../common/createRequest/components/titleAndActions/TitleAndActions';
import Stepper from '../../../common/requests/requestDetails/components/requestDetailsTopBar/components/Stepper';
import CommonTitle from '../../../../components/title/CommonTitle';
import { removeHyphensFromString } from '../../../../app/utility/utilityFunctions';

const ShiftDetailsHeader = () => {

    const { t } = useTranslation();

    const { shiftDetailsData, setShowDeleteShiftModal } = useShiftStore();

    const params = useParams();

    const { type, shift_id } = params;

    const navigateTo = useNavigate();
    const location = useLocation();

    const canEdit = () => {
        let status = shiftDetailsData?.status_show;
        return (status == "Not Started" || status == "Upcoming" || status == "Planned Maintenance")
    }

    const isShiftInit = !shiftDetailsData?.request_count && !type && canEdit();
    const backLink = !type && "/shift-manager";
    const title = t('Shift Details');

    const rightSideComponent = isShiftInit ? (
        <div className='flex xl:flex-row flex-col xl:items-center items-baseline space-y-2.5 xl:space-y-0 xl:space-x-2.5'>
            <div className='flex items-center space-x-5' >
                <CommonButtonOutlined btnLabel={t('Delete')} colorType='danger' onClick={() => setShowDeleteShiftModal(true)} />
                <CommonButton
                    btnLabel={t('Edit')}
                    onClick={() => {
                        // setShowEditShiftModal(true);
                        navigateTo(`/shift-manager/edit/${shift_id}`)
                    }}
                />
            </div>
        </div>
    ) : "";

    const getPageTitle = () => {
        if(location?.state?.referrer === "awarded") return "Upcoming";
        if(location?.state?.referrer === "on-going") return "Ongoing";
        else return `${removeHyphensFromString(location?.state?.referrer)}`
      }

    return !!type ?(
            <RequestDetailsTopBar SectionTitle={t("Request Details")} />
        ) : (
            location.pathname === k_create_request_paths.shift_details + shift_id ?
                (location?.state?.redirectFromRequest || location?.pathname?.includes("edit")) && !((location?.state?.referrer === request_details_types?.awarded || location?.state?.referrer === request_details_types?.saved) && location?.state?.isCompanyCreated) && !location?.state?.isNotPlannedEditMode ?
                <div className='flex flex-col gap-1'>
                    <CommonTitle
                    title={getPageTitle()}
                    icon={true}
                    iconClass='!h-s21'
                    iconMarginRight='mr-s10'
                    link={
                        location?.state?.referrer ?
                        location?.state?.referrer === "global-request" ? global_request_path
                            : `/requests/${location?.state?.referrer}`
                        : null}
                    />
                    <Stepper />
                </div>
                :
                <TitleAndActions />
                :
                <CommonTopTitleSection
                    withBackLink={backLink}
                    title={title}
                    rightSideComponent={rightSideComponent}
                />
        ) 
    ;
}

export default ShiftDetailsHeader