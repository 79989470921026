import React, { useEffect, useState } from "react";

const CommonButtonOutlined = ({

    btnLabel = "Trek",
    colorType = "primary",
    isDisabled = false,
    isFilterDot = false,
    isFullRounded = false,
    CustomStyles,

    iconRight = "",
    iconRightHover = "",

    iconLeft = "",
    iconLeftHover = "",

    width = "min-w-[120px]",
    height = "",
    zIndex = "",
    onClick,
    smallSize = false,

}) => {
    // colorType="basic", "danger", "primary", "success", "warning"
    const [colorCode, setColorCode] = useState("border-cPlaceholder");
    const [textColorCode, setTextColorCode] = useState("text-cMainBlue");
    const [hoverColorCode, setHoverColorCode] = useState("text-cPlaceholder");
    const [hoverNow, setHoverNow] = useState(false);

    useEffect(() => {
        switch (colorType) {
            case "basic":
                setColorCode("border-cMainBlue");
                setHoverColorCode("hover:bg-cMainBlue hover:border-cMainBlue");
                setTextColorCode("text-cMainBlue");
                break;
            case "danger":
                setColorCode("border-cRed");
                setHoverColorCode("hover:bg-cRed hover:border-cRed");
                setTextColorCode("text-cRed");
                break;
            case "primary":
                setColorCode("border-cBrand");
                setHoverColorCode("hover:bg-cBrand hover:border-cBrand");
                setTextColorCode("text-cBrand");
                break;
            case "success":
                setColorCode("border-cSuccess");
                setHoverColorCode("hover:bg-cSuccess hover:border-cSuccess");
                setTextColorCode("text-cSuccess");
                break;
            case "warning":
                setColorCode("border-cPending");
                setHoverColorCode("hover:bg-cPending hover:border-cPending");
                setTextColorCode("text-cPending");
                break;
            default:
                setColorCode("border-cPlaceholder");
                setHoverColorCode("hover:bg-cPlaceholder hover:border-cPlaceholder");
                setTextColorCode("text-cPlaceholder");
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colorType]);

    useEffect(() => {
        if (isDisabled === true || isDisabled === 'true') {
            setColorCode("border-cGrey");
            setHoverColorCode("hover:bg-cGrey");
            setTextColorCode("text-cGrey");
        }
    }, [isDisabled])

    return (
        <div className="">
            <div
                onMouseEnter={() => { setHoverNow(true); }}
                onMouseLeave={() => { setHoverNow(false); }}
                onClick={onClick}
                disabled={isDisabled}
                className={`
                flex items-center font-medium whitespace-nowrap overflow-clip justify-center select-none border-[2px] relative duration-300 text-fs16 capitalize bg-white leading-5
                ${!isDisabled && 'hover:text-white'}
                ${smallSize ? 'h-[32px]' : `h-[40px] ${height}`}
                ${width}
                ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"}
                ${isFullRounded ? "rounded-full" : "rounded"}
                ${CustomStyles}
                ${zIndex}
                ${colorCode}
                ${textColorCode}
                ${!isDisabled && hoverColorCode}
            `}
            >
                {iconLeftHover ?
                    hoverNow ?
                        <img src={iconLeft} className="max-w-[16px] min-w-[16px] max-h-[16px] min-h-[16px] mr-s10" alt="" />
                        : <img src={iconLeftHover} className="w-s16 h-s16 mr-s10" alt="" /> : ""}

                {btnLabel}

                {iconRightHover ?
                    hoverNow ?
                        <img src={iconRightHover} className="w-s16 h-s16 ml-s15" alt="" />
                        : <img src={iconRight} className="w-s16 h-s16 ml-s15" alt="" /> : ""}
                {
                    isFilterDot ? <div className="w-s12 h-s12 rounded-full bg-cRed absolute -top-[1px] -right-[1px]"></div> : ""
                }
            </div>
        </div>
    );
};

export default CommonButtonOutlined;
