import React from 'react'
import { useTranslation } from 'react-i18next';
import { iLockIcon } from '../../../../app/utility/imageImports';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import { user_role as role } from '../../../../app/utility/const';

const NoSelectChat = () => {

    const { t } = useTranslation();

    const { user_role } = useGeneralStore();

    return (
        <div className='flex items-center justify-center h-[calc(100vh-160px)] space-x-1'>
            <img alt='' src={iLockIcon} className='' />
            <div className='flex items-start justify-start text-fs14 font-fw400 text-cGrayishBlue'>
                {
                    user_role === role.customer ?
                        t("Chat with admin, company and driver.") :
                        t("Chat with admin, customer and driver.")
                }
            </div>
        </div>
    )
}

export default NoSelectChat