import React from 'react'
import { useTranslation } from 'react-i18next';
import CommonTable from '../../../../components/table/CommonTable';
import { formatDate, removeSecFromDeliveryTime } from '../../../../app/utility/utilityFunctions';
import Clamp from "react-multiline-clamp";
import useShiftStore from '../../../../app/stores/company/shiftStore';
import RequestAccordion from '../../../../components/Accordion/RequestAccordion';
import useCreateRequestStore from '../../../../app/stores/others/createRequestStore';
import { k_shift_section } from '../../../../app/utility/const';

const CustomStopList = () => {

    const { t } = useTranslation();

    const { shiftDetailsData, setShowCustomStopModal, setCustomStopModalData } = useShiftStore();

    const { shiftExpandedAccordion, setShiftExpandedAccordion } = useCreateRequestStore();

    const headers = [
        { isActive: true, sort: 1, name: t("Title") },
        { isActive: true, sort: 1,name: t("Address") },
        { isActive: true, sort: 1,name: t("Schedule") },
    ]

    const contents = shiftDetailsData?.custom_stops?.map((item, index) => {
        return {
            contents: [
                {value: <Clamp withTooltip lines={1}>{item?.title}</Clamp>},
                {value: item?.address},
                {value: formatDate(item?.date) + ", " + removeSecFromDeliveryTime(item?.start_time) + " - " + removeSecFromDeliveryTime(item?.end_time)},
            ],
            clickAction: () => { setShowCustomStopModal(true); setCustomStopModalData(item); }
        }
    })

    return (
        <RequestAccordion
            showClearButton={false}
            isTable={true}
            isBorder={false}
            expandedPaddingWidth="p-0"
            showShift={true}
            showBorder={true}
            title={t("Custom Stops")}
            showBackgroundColor={false}
            isExpanded={shiftExpandedAccordion === k_shift_section.custom_stop}
            subTitleOne={`${shiftDetailsData?.custom_stops?.length ?? 0} ${t("Stops")}`}
            onExpand={() => { setShiftExpandedAccordion(shiftExpandedAccordion === k_shift_section.custom_stop ? null : k_shift_section.custom_stop) }}
        >
            <CommonTable
                tableTitle={""}
                tableHeaderToPaddingClassName="mb-s7"
                showSearchBox={false}
                sort={false}
                showTopRightFilter={false}
                showTakeOption={false}
                showPagination={false}
                showPageCountText={true}
                headers={headers}
                tableHeaders={headers}
                tableContents={contents}
                hasClickFunction={true}
                noDataRowVisible={true}
                widths="auto 35% 25%"
                tableBorderRadius="!rounded-[4px]"
                outerPadding='p-s0'
                topRightFilterComponentOnClick={() => { }}
                paginationObject={{}}
                withClearSearch={false}
                onSearchClear={() => { }}
                searchValue={""}
                searchOnChange={(e) => { }}
                currentTakeAmount={10}
                withReloader={true}
                onReload={() => { }}
                filtered={false}
                takeOptionOnChange={async (e) => { }}
                paginationOnClick={async (url) => { }}
                showCommonEmptyData={false}
                firstIndexColWidth={"w-28%"}
                headersFirstIndexFixApply={false}
                firstIndexColPosition='items-start justify-start'
                emptyDataMessage='No custom stops added!'
                tableEmptyDataMessage="No custom stops added!"
            />
        </RequestAccordion>
    )
}

export default CustomStopList