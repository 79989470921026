import React from 'react';
import { useTranslation } from 'react-i18next';
import useCreateRequestStore from '../../../../../../../app/stores/others/createRequestStore';
import useRequestStore from '../../../../../../../app/stores/others/requestStore';
import { create_request_steps } from '../../../../../../../app/utility/const';
import { formatDate } from '../../../../../../../app/utility/utilityFunctions';
import RequestAccordion from '../../../../../../../components/Accordion/RequestAccordion';
import CommonViewComponent from '../../../../../../../components/viewer/CommonViewComponent';

export default function AcknowledgeDetails() {

  const { request_details } = useRequestStore();
  const { t } = useTranslation();
  const { expandedAccordion, setExpandedAccordion } = useCreateRequestStore();

  return (
    <RequestAccordion
      showClearButton={false}
      title={t("Acknowledgement")}
      subTitleOne={request_details?.acknowledge ? `Acknowledged ${request_details?.acknowledged_at ? " | " + formatDate(request_details?.acknowledged_at) : ""}` : t('Company has not been acknowledged yet!')}
      isForm={true}
      isExpanded={expandedAccordion === create_request_steps.acknowledgement}
      onExpand={() => { setExpandedAccordion(expandedAccordion === create_request_steps.acknowledgement ? null : create_request_steps.acknowledgement) }}
    >
      <div className={`${expandedAccordion === create_request_steps.acknowledgement ? "pt-5" : "pt-8"} p-3 border-cGray200 rounded border-[1px]`}>
        {
          request_details?.acknowledge ?
            <div className='flex flex-col items-start justify-start space-y-2'>
              <CommonViewComponent
                labelText={t('Note')}
                value={request_details?.acknowledge ?? 'NA'}
              />

            </div>
            :
            <div className='font-normal text-cGrey500 text-fs14'>
              {t('Company has not been acknowledged yet!')}
            </div>
        }
      </div>
    </RequestAccordion>
  )
}
