import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import useSettingsStore, { getFaqList } from '../../app/stores/others/settingsStore';
import { useTranslation } from 'react-i18next';
import { iFaqArrow } from '../../app/utility/imageImports';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: ``,
  borderTop: ``,
  padding: '0px 0px 00px 0px',
  border: '0px 0px 0px 0px',
  '&:not(:last-child)': {
    borderBottom: 0,
    border: 0
  },
  '&:before': {
    display: 'none',
    border: 0
  },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  // borderTop: '1px solid #B2B2B2',
  margin: 0
}));


export default function SimpleAccordion() {

  const { faqList } = useSettingsStore();

  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  React.useEffect(() => {
    fetchFaq()
  }, [])

  const fetchFaq = async () => {
    await getFaqList()
  }

  return (
    <div>

      {faqList?.length > 0 ?
        faqList.map((item) => (
          <Accordion expanded={expanded === item?.id} onChange={handleChange(item?.id)}>
            <MuiAccordionSummary
              expandIcon={
                <img className={`mt-[12px] mb-[12px] max-w-[20px] min-w-[20px] max-h-[20px] min-h-[20px]`} src={iFaqArrow} alt='' />
              }
              sx={{
                padding: 0, // removes padding
                marginTop: "-12px", // removes margin
                border: 0, // removes border
                flexDirection: 'row', // Ensures the icon and title are in the same row
                alignItems: 'start', // Centers the icon and title vertically
                '& .MuiAccordionSummary-content': {
                  padding: 0, // removes padding from the content container
                  marginLeft: '', // Adjusts the space between the icon and title
                },

              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  color: '#0E1826',
                  fontWeight: '900',
                  padding: 0, // removes padding
                  margin: 0, // removes margin
                  border: 0, // removes border
                  width: "98%"
                }}
              >
                {item?.title}
              </Typography>
            </MuiAccordionSummary>


            <AccordionDetails>
              <Typography style={{ width: "95.3%", fontSize: "14px", color: '#6C7872', fontWeight: '400', marginBottom: "12px", marginTop: "-6px", }} >
                {item.description}
              </Typography>
            </AccordionDetails>
          </Accordion>

        )) : <span>{t('No FAQ Available')}</span>
      }
    </div>
  );
}