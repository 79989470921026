import { useEffect, useRef, useState } from "react";
import Clamp from "react-multiline-clamp";

export const InputField = ({
    unnecessaryCharacters = false,
    width,
    value,
    onChange,
    name,
    is_valid = true,
    is_textarea = false,
    maxLength = 255,
    type = "text",
}) => {


    function handleKeyPress(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        if (/[/#<>*+-.eE]/.test(keyValue)) {
            event.preventDefault();
        }
    }
    const handlePaste = (e) => {
        // Prevent pasting invalid values
        e.preventDefault();
        const clipboardData = e.clipboardData.getData("text/plain");
        if (!clipboardData.includes(".e")) {
            document.execCommand("insertText", false, clipboardData);
        } else if (!clipboardData.includes(".")) {
            document.execCommand("insertText", false, clipboardData);
        }
    };

    const [isEditing, setIsEditing] = useState(false);
    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef?.current) {
            const firstLine = textareaRef?.current.querySelector('div:first-child');

            if (firstLine) {
                firstLine.classList.add('py-2');
            }
        }
    }, []);

    const handleDivClick = () => {
        setIsEditing(true);
        textareaRef?.current?.focus();
    };

    useEffect(() => {
        if (isEditing) {
            // Focus on the textarea
            textareaRef.current?.focus();
        }
    }, [isEditing]);


    const handleTextareaBlur = () => {
        setIsEditing(false);
    };

    return (
        <>
            {is_textarea ? (
                <>
                    {isEditing ? (
                        <div className={`${!is_valid ? "border-cRed border-[1px]" : "border-white border-[1px]"} px-2 rounded bg-white ${width} min-h-[50px] max-h-[50px] flex justify-center items-center`}>
                            <textarea
                                rows={2}
                                ref={textareaRef}
                                className="text-cGray800 w-full custom-scrollbars leading-[16px]  flex justify-center items-center h-auto text-fs12 font-normal overflow-y-auto overflow-hidden resize-none rounded outline-none"
                                type="text"
                                name={name}
                                value={value}
                                onChange={onChange}
                                onBlur={handleTextareaBlur}
                                maxLength={maxLength}
                            />
                        </div>
                    ) : (
                        <div onClick={handleDivClick} className={`break-words w-full whitespace-pre-line text-cGray800 leading-[16px] text-fs12 font-normal break-all ${!is_valid ? "border-cRed border-[1px]" : "border-white border-[1px]"} px-2 rounded bg-white ${width} min-h-[50px] max-h-[50px] flex  items-center`}>
                            <Clamp lines={2}>{value}</Clamp>
                        </div>
                    )}
                </>
            ) : (
                <input
                    autoComplete="off"
                    autoCorrect="off"
                    onPaste={type === "number" ? handlePaste : undefined}
                    onKeyPress={unnecessaryCharacters ? handleKeyPress : undefined}
                    className={`min-h-[50px] max-h-[50px]
             ${width} outline-none overflow-y-auto overflow-hidden p-2 text-fs12 font-normal leading-[19.2px] rounded resize-none
             ${!is_valid
                            ? "border-cRed border-[1px]"
                            : "border-white border-[1px]"
                        }`}
                    name={name}
                    value={value}
                    onChange={onChange}
                    maxLength={maxLength}
                    rows={2}
                />
            )}
        </>
    );
};