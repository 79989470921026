/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import CommonInput from '../../../../../../../components/input/CommonInput'
import { useTranslation } from 'react-i18next';
import useRequestStore from '../../../../../../../app/stores/others/requestStore';

const AddAcknowledgement = () => {
    const { t } = useTranslation();

    const { acknowledgementComment, setAcknowledgementComment } = useRequestStore();

    useEffect(() => {
        setAcknowledgementComment("")
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div className='sub-title text-cGray800'>{t("Acknowledgement")}</div>
            <CommonInput className={'my-3'} name={'comment'} value={acknowledgementComment}
                onChange={(e) => {
                    if (e.target.value !== " ") {
                        const trimmedValue = e.target.value.replace(/\s+/g, " ");
                        setAcknowledgementComment(trimmedValue)
                    }
                }}
                labelText={t('Comment')} textarea={true} type={'text'} max_input={255} required={true} show_asterisk={false} />
        </div>
    )
}

export default AddAcknowledgement