import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";
import useGeneralStore from '../../../../../../../app/stores/others/generalStore';
import { create_request_steps, request_details_types, user_role as role } from '../../../../../../../app/utility/const';
import { removeHyphensFromString } from '../../../../../../../app/utility/utilityFunctions';
import BackLink from '../../../../../../../components/backLink/BackLink';
import useRequestStore from '../../../../../../../app/stores/others/requestStore';
import useCreateRequestStore from '../../../../../../../app/stores/others/createRequestStore';

const Stepper = () => {

    const { t } = useTranslation();

    const params = useParams();

    const { request_id, type, shift_id } = params;

    const location = useLocation();

    const { user_role } = useGeneralStore();
    const {setPickupDuration, setNotPlannedStops, request_details, setFetchReqDetails} = useRequestStore.getState();
    const {setCurrentSetup} = useCreateRequestStore()

    const isShiftDetails = window.location.pathname === `/requests/${type}/details/${request_id}/shift/${shift_id}` || window.location.pathname === `/requests/${type}/details/${request_id}/edit/shift/${shift_id}` || (location.pathname.includes('/request/create/shift/details'))
    const isAllShiftPath = window.location.pathname === `/request/create/all-shift` || window.location.pathname.includes("edit/all-shifts")

    const step_array = () => {
        return [
            { label: t("Request Details"), linkTo: '', isActive: getCurrentPath(type), linkFn: '' },
        ]
    }

    const getCurrentPath = (type, action = null) => {
        if (type && !action) {
            return location?.pathname === `/requests/${type}/details/${request_id}`
        } else if (type && action) {
            return location?.pathname === `/requests/${type}/details/${request_id}/${action}`
        }
        else {
            return false;
        }
    }

    const getLabelName = () => {
        let requestType = location?.state?.redirectFromRequest ? location?.state?.referrer : type
         return requestType !== "awarded" ? (requestType === "history" && user_role === role.customer) ? "Completed" : removeHyphensFromString(requestType, requestType !== "on-going") : "Upcoming"
    }

    const requestDetailsClickFunction = () => {
        let requestType = location?.state?.redirectFromRequest ? location?.state?.referrer : type;
        if(requestType == "invitation" || "global-request") return '';
        else {
            setPickupDuration(null)
            setNotPlannedStops([])
        }
    }

    const isCompanyCreated = request_details?.user?.user_role === role?.company

    return (
        <div>
            {
                step_array().length > 0 ?
                    <div className="mb-5">
                        <BackLink
                            // cursorPointer={false}
                            linksArray={!isShiftDetails ?
                                [

                                    {
                                        label: getLabelName(),
                                        isActive: false,
                                        cursorPointer: true,
                                        linkTo: location?.state?.referrer === "global-request" ? "/global-request" : "/requests/" + `${type || location?.state?.referrer}`,
                                        linkFn: ''
                                    },
                                    {
                                        label: "Request Details",
                                        isActive: location.pathname === `/requests/${type}/details/${request_id}`,
                                        linkTo: location?.pathname.includes("/request/create/all-shift") && location?.state?.redirectFromRequest ?
                                        location?.state?.referrer === "global-request" ? `/global-request/details/${location?.state?.request_id}` : `/requests/${location?.state?.referrer}/details/${location?.state?.request_id}` :
                                            location?.state?.referrer === "global-request" ? `/global-request/details/${location?.state?.request_id}` : `/requests/${type || location?.state?.referrer}/details/${request_id || location?.state?.request_id}`,
                                        linkFn: () => requestDetailsClickFunction(),
                                        state: {...location?.state, is_fetch_req_details: false, isEditMode: false, redirectFromAllShift: false},
                                        onClick: () => { setCurrentSetup(""); setFetchReqDetails(location?.state?.referrer !== "invitation"  && location?.state?.referrer !== "global-request" && !location?.pathname?.includes("/global-request"))}
                                    },
                                    {
                                        label: location.pathname?.includes(`/requests/${type}/details/${request_id}/edit`) ? "Edit" : (location?.pathname?.includes(`/requests/in-bidding/details/${request_id}/shift/`) && location?.state?.isEditMode) ? "Edit" : (location?.pathname?.includes("request/create/all-shift") && location?.state?.isEditMode) ? "Edit": location?.state?.isEditMode ? "Edit" : "",
                                        linkTo: location?.state?.referrer === request_details_types.awarded && isCompanyCreated ? `/request/edit/${location?.state?.request_id}` : `/requests/${type || location?.state?.referrer}/details/${request_id || location?.state?.request_id}/edit`,
                                        isActive: location.pathname === `/requests/${type}/details/${request_id}/edit`,
                                        linkFn: '',
                                        state: {...location?.state, is_fetch_req_details: false, redirectFromAllShift: false},
                                        onClick: () => { setCurrentSetup(""); setFetchReqDetails(false)}
                                    },
                                    {
                                        label: (location.pathname.includes("/request/create/all-shift") || location.pathname.includes("edit/all-shifts")) && location?.state?.redirectFromRequest ? "All Shifts" : "",
                                        linkTo: '',
                                        isActive: isAllShiftPath,
                                        linkFn: ''
                                    }

                                ] :
                                [{
                                    label: getLabelName(),
                                    isActive: false,
                                    cursorPointer: true,
                                    linkTo: location?.state?.referrer === "global-request" ? "/global-request" : "/requests/" + `${type || location?.state?.referrer}`,
                                    linkFn: ''
                                },
                                {
                                    label: "Request Details",
                                    isActive: location.pathname === `/requests/${type}/details/${request_id}`,
                                    linkTo: location?.pathname.includes("/request/create/all-shift") && location?.state?.redirectFromRequest ?
                                        `/requests/${location?.state?.referrer}/details/${location?.state?.request_id}` :
                                        location?.state?.referrer === "global-request" ? `/global-request/details/${location?.state?.request_id}` : `/requests/${type || location?.state?.referrer}/details/${request_id || location?.state?.request_id}`,
                                    linkFn: () => requestDetailsClickFunction(),
                                    state: {...location?.state, is_fetch_req_details: false, isEditMode: false, redirectFromAllShift: false},
                                    onClick: () => { setCurrentSetup(""); setFetchReqDetails(location?.state?.referrer !== "invitation"  && location?.state?.referrer !== "global-request" && !location?.pathname?.includes("/global-request")); }
                                },
                                
                                {
                                    label: location.pathname?.includes(`/requests/${type}/details/${request_id}/edit`) ? "Edit" : (location?.pathname?.includes(`/requests/in-bidding/details/${request_id}/shift/`) && location?.state?.isEditMode) ? "Edit" : (location?.pathname?.includes("request/create/all-shift") && location?.state?.isEditMode) ? "Edit": location?.state?.isEditMode ? "Edit" : "",
                                    linkTo: location?.state?.referrer === request_details_types.awarded && isCompanyCreated ? `/request/edit/${location?.state?.request_id}` : `/requests/${type || location?.state?.referrer}/details/${request_id || location?.state?.request_id}/edit`,
                                    isActive: location.pathname === `/requests/${type}/details/${request_id}/edit`,
                                    linkFn: '',
                                    state: {...location?.state, is_fetch_req_details: false, redirectFromAllShift: false},
                                    onClick: () => { setCurrentSetup(""); setFetchReqDetails(false); }
                                },
                                {
                                    label: location?.state?.redirectFromRequest && location?.state?.redirectFromAllShift? "All Shifts" : "",
                                    linkTo: '/request/create/all-shift',
                                    isActive: isAllShiftPath,
                                    linkFn: '',
                                    onClick: () => {
                                        setCurrentSetup(create_request_steps?.allShifts);
                                    }
                                },
                                { label: "Shift Details", isActive: true, linkTo: '', linkFn: '' }
                                ]
                            }
                        />
                    </div> :
                    <div className='h-[21px]'>

                    </div>
            }
        </div>
    );
};

export default Stepper;