import React from 'react';
import { useNavigate } from 'react-router-dom';
import { iLeftArrow } from '../../app/utility/imageImports';
import CommonReloader from '../reloader/CommonReloader';
import useUtilityStore from '../../app/stores/others/utilityStore';

const CommonTitle = ({ withConfirm = false, onClick = () => { }, title = "", className, count, icon, link = "", withReloader = false, onReload = () => { }, iconClass = "", iconMarginRight="mr-s8" }) => {

    const navigateTo = useNavigate();

    const { setPreviousBackConfirmModal, setPreviousBackLink } = useUtilityStore.getState();

    return (
        <div className={`title flex w-[500px] ${className}`}>
            {icon && link === "" ? <div className={`flex items-center justify-center mb-s10 ${iconMarginRight}`}>
                <img className={`cursor-pointer h-s17 mt-s7 ${iconClass}`} src={iLeftArrow} alt="" /></div>
                : ''}

            {icon && link && !withConfirm ? <div onClick={() => { onClick(); link !== "null" && navigateTo(link) }} className={`flex items-center justify-center ${iconMarginRight}`}>
                <img className={`cursor-pointer h-s17 ${iconClass}`} src={iLeftArrow} alt="" /></div>
                : ''}

            {icon && link && withConfirm ?
                <div onClick={() => { setPreviousBackConfirmModal(true); setPreviousBackLink(link) }} className={`flex items-center justify-center ${iconMarginRight}`}>
                    <img className={`cursor-pointer h-s17 ${iconClass}`} src={iLeftArrow} alt="" /></div>
                :
                ''}

            <div className="flex items-center">
                <div className='capitalize'>{title}</div>
                {count ? <div className='pl-1.5'>( {count} )</div> : ""}
                {withReloader ?

                    <div className='p-2 ml-4 rounded-full shadow-sm bg-cBgSideBar' title='Refresh' >
                        <CommonReloader onClick={onReload} />
                    </div>

                    : ""}
            </div>
        </div>
    );
};

export default CommonTitle;