/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Clamp from "react-multiline-clamp";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { getGlobalReqDataList } from "../../../app/stores/company/globlaReqStore";
import useGeneralStore from "../../../app/stores/others/generalStore";
import useRequestStore, {
  getTableViewGlobalRequestsData,
  handleRequestOrder,
  isTableFiltered,
} from "../../../app/stores/others/requestStore";
import { kuGlobalRequestTableIndex } from "../../../app/urls/companyUrl";
import {
  common_take,
  k_orderBy,
  k_requests_status,
} from "../../../app/utility/const";
import { formatDateTime } from "../../../app/utility/utilityFunctions";
import CommonTable from "../../../components/table/CommonTable";
import GlobalRequestFilter from "./components/GlobalRequestFilter";

export default function GlobalRequestTable({ tableTitleClassName }) {
  const {
    setNotPlannedStops,
    global_request,
    search_key,
    setSearchKey,
    request_take,
    setRequestTake,
    setGlobalRequestApiUrl,
    setRequestsOrder,
    setIsAsc,
    request_filter_form,
    resetRequestFilterForm,
    resetFilterRange,
    clearFilterRange,
    resetRequestFilterFormCopy,
    is_asc,
    requests_order,
    request_filter_form_copy,
    setPickupDuration,
    hasRequestFilter,
  } = useRequestStore();
  const { path_record } = useGeneralStore();

  const [showModal, setShowModal] = useState(false);
  const [searchValue] = useDebounce(search_key, 500);
  const navigateTo = useNavigate();
  const { t } = useTranslation();

  const headers = [
    // { index: 0, name: "Req. ID", onClickAction: () => { }, width: '2' },
    { isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true },

    { isActive: requests_order === k_orderBy.title, sort: is_asc, name: t("Title"), onClickAction: () => handleRequestOrder(k_orderBy.title, getData), showSortImage: true },

    { isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true },

    { isActive: requests_order === k_orderBy.pickup_address, sort: is_asc, name: t("Location"), onClickAction: () => handleRequestOrder(k_orderBy.pickup_address, getData), showSortImage: true },

    { isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true },

    { isActive: requests_order === k_orderBy.pickup_starts_at, sort: is_asc, name: t("Bid Ends"), onClickAction: () => handleRequestOrder(k_orderBy.pickup_starts_at, getData), showSortImage: true },
  ];

  const getData = () =>
    getTableViewGlobalRequestsData({
      filter: {
        transport_type: request_filter_form?.transport_types?.map(item => item.id),
        pickup_date_from: request_filter_form?.pickup_date_from,
        pickup_date_to: request_filter_form?.pickup_date_to,
        start_time: request_filter_form?.start_time,
        end_time: request_filter_form?.end_time,
        city: request_filter_form?.city?.map(item => item.id),
      },
    });

  const resetTable = async () => {
    await setGlobalRequestApiUrl(kuGlobalRequestTableIndex);
    if (searchValue?.length > 0) await setSearchKey("");
    if (request_take !== common_take) await setRequestTake(common_take);
    await setRequestsOrder(k_orderBy.pickup_starts_at);
    await setIsAsc(1);
    await resetRequestFilterForm();
    await resetFilterRange();
    await clearFilterRange();
    await resetRequestFilterFormCopy();
    getTableViewGlobalRequestsData({ filter: {}, hasFilter: false });
  };

  const handleInitialSetup = async () => {
    if (
      !hasRequestFilter ||
      !path_record?.old?.includes("/")
    ) {
      resetTable();
    } else {
      getData();
    }
  };

  useEffect(() => {
    handleInitialSetup();
    getGlobalReqDataList();
  }, []);

  useEffect(() => {
    getData();
  }, [searchValue]);

  const contents = global_request?.data?.map((item, index) => {
    return {
      contents: [
        { value: item?.request_id },
        { value: <Clamp lines={1}> {item?.title ?? ""}</Clamp> },
        { value: item?.transport_type },
        { value: <Clamp lines={1}>{item?.pickup_address ?? ""} </Clamp> },
        {
          value: (
            <Tooltip title={"Request | Stops | Packages"}>
              {"1 | " +
                (item?.stops_count ?? 0) +
                " | " +
                (item?.products_count ?? 0)}
            </Tooltip>
          ),
        },
        {
          value: formatDateTime(item?.pickup_starts_at, item?.pickup_starts_time),
        },
      ],
      clickAction: () => {
        setPickupDuration("");
        setNotPlannedStops([]);
        navigateTo("details/" + item?.id, {
          state: {
            referrer: "global-request",
            role: `company`,
            request_id: item?.id,
          },
        });
      },
    };
  });

  return (
    <>
      <CommonTable
        tableTitle={t("Global Request") + ` (${global_request?.total ?? 0})`}
        showSearchBox={true}
        showTopRightFilter={true}
        showTakeOption={true}
        showPagination={true}
        firstIndexColWidth="flex justify-start !w-max"
        showPageCountText={true}
        headers={headers}
        tableHeaders={headers}
        tableContents={contents}
        hasClickFunction={true}
        widths="90px auto 15% 25% 120px 15%"
        outerPadding="p-s0"
        topRightFilterComponentOnClick={() => setShowModal(true)}
        tableTitleClassName={tableTitleClassName}
        paginationObject={global_request}
        withClearSearch={true}
        onSearchClear={() => {
          setSearchKey("");
        }}
        searchValue={search_key}
        searchOnChange={(e) => {
          setGlobalRequestApiUrl(kuGlobalRequestTableIndex);
          setSearchKey(e.target.value);
        }}
        currentTakeAmount={request_take}
        withReloader={true}
        onReload={resetTable}
        filtered={isTableFiltered(
          k_requests_status.global,
          request_filter_form_copy,
        )}
        takeOptionOnChange={async (e) => {
          await setRequestTake(e.target.value);
          await setGlobalRequestApiUrl(kuGlobalRequestTableIndex);
          getData();
        }}
        paginationOnClick={async (url) => {
          await setGlobalRequestApiUrl(url);
          getData();
        }}
      />

      <GlobalRequestFilter showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
