/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import ReactPasswordChecklist from 'react-password-checklist';
import useSettingsStore, { submitChangePassword } from '../../../app/stores/others/settingsStore';
import { changePageTitle, Toastr, validatePassword } from '../../../app/utility/utilityFunctions';
import CommonButton from '../../../components/button/CommonButton';
import CommonInput from '../../../components/input/CommonInput';
import { useTranslation } from 'react-i18next';
import { iChangePasswordSelected } from '../../../app/utility/imageImports';
import CommonTitle from '../../../components/title/CommonTitle';

function ChangePassword() {

    const { t } = useTranslation();

    const { changePasswordForm, setChangePasswordForm } = useSettingsStore();
    const [validPassword, setValidPassword] = useState(false);


    const handleCpChange = (e) => {
        setChangePasswordForm({ ...changePasswordForm, [e.target.name]: e.target.value.trim() });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationResult = await validatePassword(changePasswordForm.password, changePasswordForm.password_confirmation);

        if (!validationResult.valid) {
            Toastr({ message: validationResult.message, type: "warning" });
            return false;
        } else setValidPassword(true);

        let addSuccess = await submitChangePassword()
        if (addSuccess) {
            setChangePasswordForm({ old_password: "", password: "", password_confirmation: "" });
        }
    };

    const isValidPass = validatePassword(changePasswordForm.password, changePasswordForm.password_confirmation)?.valid

    useEffect(() => {
        changePageTitle(t('Settings | Change Password'));
        setChangePasswordForm({ old_password: "", password: "", password_confirmation: "" });
    }, [])



    return (
        <form onSubmit={handleSubmit} className='flex space-x-10'>

            <div className='w-full'>
                <CommonTitle title={t("Change Password")} />

                <CommonInput
                    name="old_password"
                    type="password"
                    labelText={t('Current Password')}
                    value={changePasswordForm?.old_password}
                    required={true}
                    onChange={handleCpChange}
                    show_asterisk={false}
                />
                <CommonInput
                    name="password"
                    type="password"
                    labelText={t('Type New Password')}
                    value={changePasswordForm.password}
                    required={true}
                    onChange={handleCpChange}
                    show_asterisk={false} />
                <CommonInput
                    name="password_confirmation"
                    type="password"
                    labelText={t('Confirm Password')}
                    value={changePasswordForm.password_confirmation}
                    required={true}
                    onChange={handleCpChange}
                    show_asterisk={false} />

                <div className='flex justify-between mt-s18'>

                    {changePasswordForm.password ? <div className='flex justify-end'>
                        <ReactPasswordChecklist
                            rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                            minLength={8}
                            value={changePasswordForm.password}
                            valueAgain={changePasswordForm.password_confirmation}
                        // onChange={(isValid) => { setPassValid(isValid) }}
                        />
                    </div> :
                        ""
                    }

                </div>
            </div>


            <div className='flex justify-end max-w-[400px] min-w-[400px]'>
                <CommonButton
                    width="w-[130px]"
                    type='submit'
                    btnLabel={t('Update')}
                    isDisabled={!changePasswordForm?.old_password || !changePasswordForm?.password || !changePasswordForm?.password_confirmation || !isValidPass}

                />
            </div>
        </form>
    )
}

export default ChangePassword;
