import React from 'react'
import useCarStore, { getLicenseStatusText } from '../../../../app/stores/company/carStore';
import { useTranslation } from 'react-i18next';
import CommonViewComponent from '../../../../components/viewer/CommonViewComponent';

const LicenseInfo = () => {

    const { t } = useTranslation();

    const { carDetails } = useCarStore();

    return (
        <div>
            <div className='sub-title'>{t("License Info")}</div>
            <div className='mt-1 space-y-3'>
                <CommonViewComponent labelText={t('License Name')} value={carDetails?.license_name} />
                <CommonViewComponent capitalizedData={true} labelText={t('Status')} value={getLicenseStatusText(carDetails?.license_status)} />
                <CommonViewComponent labelText={t('Price')} value={`DKK ${carDetails?.license_price?.toLocaleString("da-DK") ?? 0}`} />
                <CommonViewComponent labelText={t('Duration')} value={carDetails?.license_duration_show} />
                <CommonViewComponent labelText={t('Description')} value={carDetails?.license_description} />
            </div>
        </div>
    )
}

export default LicenseInfo