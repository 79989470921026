import { FormControlLabel, Radio } from "@mui/material";
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Clamp from "react-multiline-clamp";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useShiftStore, {
    calculateShiftOverView,
    defineScheduleInfo,
} from "../../../../../../../app/stores/company/shiftStore";
import useCreateRequestStore, { saveRequest } from "../../../../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../../../../app/stores/others/generalStore";
import useRequestStore, {
    isTableFiltered,
} from "../../../../../../../app/stores/others/requestStore";
import { k_create_request_paths } from "../../../../../../../app/utility/AllRoute";
import {
    create_request_steps,
    k_cr_actions,
    k_requests_status,
    request_details_types,
    user_role as role,
} from "../../../../../../../app/utility/const";
import {
    iFilterGreen,
    iFilterWhite
} from "../../../../../../../app/utility/imageImports";
import CommonButton from "../../../../../../../components/button/CommonButton";
import CommonButtonOutlined from "../../../../../../../components/button/CommonButtonOutlined";
import CommonTable from "../../../../../../../components/table/CommonTable";
import AvailableShiftFilter from "./AvailableShiftFilter";
import CommonEmptyView from "./CommonEmptyView";

const AvailableShifts = ({ selectedId, onChange }) => {

    const { t } = useTranslation();
    const { user_role } = useGeneralStore();

    const {
        current_step,
        available_shift_search_key,
        setAvailableShiftSearchKey,
        setSavedCrFormRes,
        cr_form,
        changeCrForm,
        setCrFormSelectedShift,
        crFormSelectedShift,
        setCurrentSetup
    } = useCreateRequestStore();
    const { available_shifts, selectedNewShiftId, setSelectedNewShiftId, request_details, setRedirectingToShiftDetails } = useRequestStore();
    const { setShowAddShiftModal, filter_form_copy, setAllShiftCarList, setAllShiftDriverList, setShiftDetailsData } = useShiftStore();

    const location = useLocation();
    const { type, request_id } = useParams();

    const navigateTo = useNavigate();

    const allShiftHeaders = [
        { name: "#", placement: "justify-center items-center" },
        { name: t("Drivers") },
        { name: t("License") },
        { name: t("Date & Time") },
        { name: t("Location") },
        { name: t("Load") },
        { name: t("Status") }
    ];

    const headers = [
        { name: "#", placement: "justify-center items-center" },
        { name: t("Drivers") },
        { name: t("Schedule") },
        { name: t("Load") },
    ];

    function checkEditMode() {
        if(location?.state?.referrer === "invitation" || location?.state?.referrer === "global-request") {
            return false;
        }
        if (location?.pathname === `/request/create`) {
            return true;
        }
        else if (location?.state?.isEditMode === true) {
            return true;
        } else if (location.pathname?.includes(`/request/edit/${request_id}`)) {
            return true;
        } else if (location.pathname?.includes(`/details/${request_id ?? location?.state?.request_id}/edit`)) {
            return true;
        } else {
            return false;
        }
    }

    const canSelectShift = () => {
        if (location?.pathname === `/request/create`) {
            return true;
        }
        else if (location?.state?.isEditMode === true && location?.state?.referrer !== request_details_types.in_bidding) {
            return true;
        } else if (location.pathname?.includes(`/request/edit/${request_id}`)) {
            return true;
        } else if (location.pathname?.includes(`/details/${request_id ?? location?.state?.request_id}/edit`)) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        return () => {
            setSelectedNewShiftId(null)
        }
    }, [])

    // const isAllShift = current_step === create_request_steps.allShifts;
    const isAllShift = location?.pathname?.includes("/request/create/all-shift") || location.pathname.includes(`/awarded/details/${request_id}/edit/all-shifts`);
    const isCreateRequestPage = location?.pathname?.includes("request/create");
    const isNotPlannedEditMode = location.pathname.includes(`requests/not-planned/details/${request_id}/edit`);
    const isEditMode = checkEditMode();

    function reduceArrayWithSelected(arr, selectedId) {
        let selectedIndex = -1
        let selectedShift = null
        if(selectedId) {
            selectedIndex = arr.findIndex(item => item?.id == selectedId);
        }
        if (selectedIndex !== -1 && selectedIndex > 9) {
            selectedShift = arr[selectedIndex]
            arr.splice(selectedIndex, 1);

            return [...arr?.slice(0, 9), selectedShift]
        }
        else return arr.slice(0, 10);
    }

    const shiftList = !isAllShift
        ? reduceArrayWithSelected(Object.values(available_shifts), selectedId) //Object.values(available_shifts)?.slice(0, 10)
        : Object.values(available_shifts);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (selectedNewShiftId && selectedNewShiftId !== crFormSelectedShift) {
            let list = !isAllShift ? shiftList : available_shifts;
            const selectedShift = list?.find(item => item.id === selectedNewShiftId);
            if (selectedShift?.id) {
                changeCrForm("shift_plan", selectedShift);
                changeCrForm("shift_id", selectedShift?.id);
                setCrFormSelectedShift(selectedShift?.id)
            }
        }
    }, [selectedNewShiftId, available_shifts?.length])

    const tableHeader = location?.pathname?.includes("all-shift") ? allShiftHeaders : headers;

    const isCompanyCreated = request_details?.user?.user_role === role.company;

    const setLocalUrl = () => {
        let backUrl = location.pathname
        let allShiftBackUrl = localStorage?.getItem("goBack")
        if(location.pathname?.includes("all-shift")) {
            backUrl = allShiftBackUrl
        }
        localStorage.setItem("shiftDetailsGoBack", backUrl)
    }

    const contents = shiftList
        ?.map((item, index) => {
            const handleRadioChange = async (e) => {
                e.stopPropagation();
                await onChange(item);
            };

            const shiftDetailsData = calculateShiftOverView(item);
            return tableHeader?.length === 7 ? {
                contents: [{
                    value: canSelectShift() ?
                        <div className="ml-s25">
                            <FormControlLabel
                                size="small"
                                checked={item?.id === selectedId}
                                value={true}
                                control={
                                    <Radio
                                        disableRipple
                                        style={{
                                            color: item?.id === selectedId ? "#60B436" : "#939D98",
                                        }}
                                    />
                                }
                                onClick={handleRadioChange}
                            />
                        </div>
                        : (index + 1)
                },
                {
                    value: <Clamp withTooltip lines={1}>
                        {item?.shift_drivers?.length > 0
                            ? item?.shift_drivers
                                ?.map((driver) =>
                                    driver?.is_main_driver
                                        ? driver?.driver?.name + " (M)"
                                        : driver?.driver?.name
                                )
                                .join(", ")
                            : item?.driver_user?.name + " (M)" ?? "NA"}
                    </Clamp>
                },
                { value: item?.car_number },
                {
                    value: defineScheduleInfo({
                        start_date: item?.shift_start_date,
                        end_date: item?.shift_end_date,
                        start_time: item?.shift_start_time,
                        end_time: item?.shift_end_time,
                    })
                },
                { value: item?.location },
                {
                    value: <Tooltip
                        title={t("Request | Stops | Packages")}
                        color={"#242826"}
                        placement="topRight"
                    >{`${shiftDetailsData?.request_count ?? 0} | ${shiftDetailsData?.stops_count ?? 0} | ${shiftDetailsData?.products_count ?? 0}`}
                    </Tooltip>
                },
                {
                    value: item?.status_show === "Not Started" ? "Upcoming" : item?.status_show
                }
                ],
                clickAction: async () => {
                    await setCurrentSetup(null);
                    setShiftDetailsData(null)

                    if (location?.pathname === k_create_request_paths.create || location?.pathname?.includes(`request/edit/${request_id}`)) await saveRequest(k_cr_actions.save, false);
                    else setSavedCrFormRes(cr_form);

                    if(location?.pathname?.includes(`/requests/awarded/details/${request_id}/edit`) && !isCompanyCreated) {
                        setRedirectingToShiftDetails(true);
                        setLocalUrl()
                        navigateTo(
                            `/requests/${type}/details/${request_id}/edit/shift/${item?.id}`,
                            {
                                state: {
                                    ...location?.state,
                                    request_id: location?.state?.request_id ?? item?.id,
                                    referrer: location?.state?.referrer || type,
                                    role: user_role,
                                    isEditMode: isEditMode,
                                    isAllShiftExists: isAllShift,
                                    redirectFromAllShift: location?.state?.redirectFromRequest && location?.pathname?.includes("all-shifts") ? true : false,
                                    redirectFromRequest: location?.pathname?.includes("edit"),
                                    isCompanyCreated: isCompanyCreated,
                                    isNotPlannedEditMode: isNotPlannedEditMode || location?.state?.isNotPlannedEditMode,
                                }
                            }
                        )
                    }
                    else {
                        setRedirectingToShiftDetails(true);
                        setLocalUrl()
                        navigateTo(
                        k_create_request_paths.shift_details + item?.id,
                        {
                            state: {
                                ...location?.state,
                                request_id: location?.state?.request_id ?? item?.id,
                                referrer: location?.state?.referrer,
                                role: user_role,
                                isEditMode: isEditMode,
                                isAllShiftExists: isAllShift,
                                redirectFromAllShift: location?.state?.redirectFromRequest && isAllShift,
                                isNotPlannedEditMode: isNotPlannedEditMode || location?.state?.isNotPlannedEditMode,
                            }
                        }
                    )};
                }
            } : {
                contents: [{
                    value: canSelectShift() ? <div className="ml-s25"><FormControlLabel
                        size="small"
                        checked={item?.id === selectedId}
                        value={true}
                        control={
                            <Radio
                                disableRipple
                                style={{
                                    color: item?.id === selectedId ? "#60B436" : "#939D98",
                                }}
                            />
                        }
                        onClick={handleRadioChange}
                    /></div>
                        : (index + 1)
                }, {
                    value: <Clamp withTooltip lines={1}>
                        {item?.shift_drivers?.length > 0
                            ? item?.shift_drivers
                                ?.map((driver) =>
                                    driver?.is_main_driver
                                        ? driver?.driver?.name + " (M)"
                                        : driver?.driver?.name
                                )
                                .join(", ")
                            : item?.driver_user?.name + " (M)" ?? "NA"}
                    </Clamp>
                }, {
                    value: defineScheduleInfo({
                        start_date: item?.shift_start_date,
                        end_date: item?.shift_end_date,
                        start_time: item?.shift_start_time,
                        end_time: item?.shift_end_time,
                    })
                }, {
                    value: <Tooltip
                        title={t("Request | Stops | Packages")}
                        color={"#242826"}
                        placement="topRight"
                    >{`${shiftDetailsData?.request_count ?? 0} | ${shiftDetailsData?.stops_count ?? 0} | ${shiftDetailsData?.products_count ?? 0}`}
                    </Tooltip>
                },
                ],
                clickAction: async () => {
                    await setCurrentSetup(null);
                    setShiftDetailsData(null)

                    if (location?.pathname === k_create_request_paths.create || location?.pathname?.includes(`request/edit/${request_id}`)) await saveRequest(k_cr_actions.save, false);
                    else setSavedCrFormRes(cr_form);

                    if(location?.pathname?.includes(`/requests/awarded/details/${request_id}/edit`) && !isCompanyCreated) {
                        setRedirectingToShiftDetails(true);
                        setLocalUrl()
                        navigateTo(
                            `/requests/${type}/details/${request_id}/edit/shift/${item?.id}`,
                            {
                                state: {
                                    ...location?.state,
                                    request_id: location?.state?.request_id ?? item?.id,
                                    referrer: location?.state?.referrer || type,
                                    role: user_role,
                                    isEditMode: isEditMode,
                                    isAllShiftExists: isAllShift,
                                    redirectFromAllShift: location?.state?.redirectFromRequest && location?.pathname?.includes("all-shifts") ? true : false,
                                    redirectFromRequest: location?.pathname?.includes("edit"),
                                    isCompanyCreated: isCompanyCreated,
                                    isNotPlannedEditMode: isNotPlannedEditMode,
                                }
                            }
                        )
                    }
                    else {
                        setRedirectingToShiftDetails(true);
                        setLocalUrl()
                        navigateTo(
                        k_create_request_paths.shift_details + item?.id,
                        {
                            state: {
                                ...location?.state,
                                request_id: location?.state?.request_id ?? item?.id,
                                referrer: location?.state?.referrer || type,
                                role: user_role,
                                isEditMode: isEditMode,
                                isAllShiftExists: isAllShift,
                                redirectFromAllShift: location?.state?.redirectFromRequest && location?.pathname?.includes("all-shift") ? true : false,
                                redirectFromRequest: location?.pathname?.includes("edit"),
                                isCompanyCreated: isCompanyCreated,
                                isNotPlannedEditMode: isNotPlannedEditMode,
                            }
                        }
                    )}
                }
            }
        })

    return (
        <div>
            {isAllShift ||
                (Object.values(available_shifts)?.length > 0) ? (
                <CommonTable
                    tableTitle={t("")}
                    tableTitleClassName={"sub-title"}
                    showSearchBox={isAllShift}
                    searchBoxHeight={"h-9"}
                    firstIndexColWidth="w-[30px]"
                    sort={false}
                    searchFilterTopSpacing="space-x-4"
                    tableHeaderToPaddingClassName={isAllShift ? "mb-s18" : "mb-s7"}
                    showTopRightFilter={isAllShift}
                    showTakeOption={false}
                    showPagination={false}
                    showPageCountText={true}
                    outerPadding="p-s0"
                    topRightFilterComponentOnClick={() => { }}
                    paginationObject={{}}
                    withClearSearch={true}
                    onSearchClear={() => {
                        setAvailableShiftSearchKey("");
                    }}
                    tableHeaders={location?.pathname?.includes("all-shift") ? allShiftHeaders : headers}
                    tableContents={contents}
                    hasClickFunction={true}
                    widths={
                        location?.pathname?.includes("all-shift") ?
                            "50px auto 10% 25% 15% 120px 10%"
                            : "50px auto 35% 120px"
                    }
                    searchValue={available_shift_search_key}
                    searchOnChange={(e) => {
                        setAvailableShiftSearchKey(e.target.value);
                    }}
                    currentTakeAmount={10}
                    withReloader={true}
                    onReload={() => { }}
                    emptyDataMessage={t("No shifts available!")}
                    topRightMainComponent={
                        isAllShift && canSelectShift() ? (
                            <CommonButton
                                btnLabel={t("Add Shift")}
                                width="w-[130px]"
                                className="h-9"
                                type="button"
                                onClick={() => {
                                    setShowAddShiftModal(true);
                                    setAllShiftDriverList([]);
                                    setAllShiftCarList([]);
                                }}
                            />
                        ) : null
                    }
                    topRightFilterComponent={
                        <div className="relative">
                            {isTableFiltered(k_requests_status?.shift, filter_form_copy) && (
                                <div className="absolute z-50 w-2 h-2 rounded-full bg-cBrand right-1 top-1"></div>
                            )}
                            <CommonButtonOutlined
                                height="h-9"
                                btnLabel={t("Filter")}
                                onClick={() => setShowModal(true)}
                                colorType="primary"
                                iconLeft={iFilterWhite}
                                iconLeftHover={iFilterGreen}
                            />
                        </div>
                    }
                    headersFirstIndexFixApply={false}
                    takeOptionOnChange={async (e) => { }}
                    paginationOnClick={async (url) => { }}
                />
            ) : (
                <CommonEmptyView message={t("No shifts available!")} />
            )}

            <AvailableShiftFilter showModal={showModal} setShowModal={setShowModal} />
        </div>
    );
};

export default AvailableShifts;