import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import useCreateRequestStore from '../../../../../../../app/stores/others/createRequestStore'
import useGeneralStore from '../../../../../../../app/stores/others/generalStore'
import useRequestStore from '../../../../../../../app/stores/others/requestStore'
import { create_request_steps, request_details_types, user_role as role } from '../../../../../../../app/utility/const'
import { iCrossOutlined } from '../../../../../../../app/utility/imageImports'
import { extModifierDateTime, formatSchedule, getImageExtension, isEndTimeSmaller, Toastr } from '../../../../../../../app/utility/utilityFunctions'
import RequestAccordion from '../../../../../../../components/Accordion/RequestAccordion'
import ImageViewer from '../../../../../../../components/image/ImageViewer'
import CommonInput from '../../../../../../../components/input/CommonInput'
import CommonViewComponent from '../../../../../../../components/viewer/CommonViewComponent'
import PickupOverview from './deliveries/components/PickupOverview'

export default function Pickup({ data }) {
  const params = useParams();
  const { type, request_id } = params;
  const { t } = useTranslation();
  const { user_role } = useGeneralStore();

  const [topSpaceAllowed, setTopSpaceAllowed] = useState(false);

  const { pickup_duration, setPickupDuration, request_details, setNotPlannedStops, not_planned_stops } = useRequestStore();
  const { expandedAccordion, setExpandedAccordion } = useCreateRequestStore();
  const location = useLocation()

  const editMode = window.location.pathname === `/requests/${type}/details/${request_id}/edit`;

  const getFormattedSchedule = (data) => {
    if (data?.pickup_date || data?.pickup_start_time || data?.pickup_end_time) {
      return formatSchedule(data?.pickup_date, data?.pickup_start_time, data?.pickup_end_time, " ", (isEndTimeSmaller(data?.pickup_start_time, data?.pickup_end_time) || (data?.pickup_start_time === data?.pickup_end_time)));
    } else {
      return "No date & time";
    }
  };


  useEffect(() => {
    if (!pickup_duration) {
      setPickupDuration(request_details?.pickup_duration);
    }
  }, [request_details])

  useEffect(() => {
    if (type === request_details_types?.invitation || type === request_details?.in_bidding || location.pathname.includes("global-request/details")) {
    }
    else if (location.pathname.includes("edit")) {
      setPickupDuration(pickup_duration ? pickup_duration : request_details?.pickup_duration)
    }
    else setPickupDuration(request_details?.pickup_duration)
  }, [location])

  return (
    <>

      <RequestAccordion
        showClearButton={false}
        title={t("Pickup")}
        subTitleOne={data?.transport_type}
        subTitleTwo={data?.pickup_address}
        isExpanded={expandedAccordion === create_request_steps.pickup}
        onExpand={() => { setExpandedAccordion(expandedAccordion === create_request_steps.pickup ? null : create_request_steps.pickup) }}
      >
        <div className={`space-y-2 border-cGray200 rounded border-[1px] ${(user_role === role.company) && (type === request_details_types.ongoing || type === request_details_types.history || type === request_details_types.completed) ? "px-4 pb-4" : "p-4"}`}>
          <div className='space-y-3 relative'>

            {
              (type === request_details_types.ongoing || type === request_details_types.history || type === request_details_types.completed) ?
                <div className="absolute right-0 top-0 text-sm leading-6 text-cGray500">
                  {
                    extModifierDateTime(
                      "pickup",
                      data?.pickup_status,
                      data?.pickup_expected_date,
                      data?.pickup_expected_time,
                      data?.pickup_driver_complete_date,
                      data?.pickup_driver_complete_time
                    )
                  }
                </div>
                : <></>
            }

            <CommonViewComponent labelText={t('Title')} value={data?.title ?? "No title"} />
            <CommonViewComponent labelText={t('Type')} value={data?.transport_type ?? "No type"} />
            <CommonViewComponent labelText={t('Pickup Address')} value={data?.pickup_address ?? "No address"} />
            <CommonViewComponent labelText={t('Floor number')} value={data?.pickup_floor_number ?? "No floor number"} />

            <CommonViewComponent
              labelText={t('Date & Time')}
              value={getFormattedSchedule(data)}
            />

            {
              (user_role === role.company) &&
              <div className=''>
                {
                  ((
                    (type === request_details_types.not_planned && data?.awarded?.status === 'init') ||
                    type === request_details_types.in_bidding ||
                    type === request_details_types.invitation ||
                    (type === request_details_types.awarded && user_role === role.company)
                  )
                    && editMode) || (type === request_details_types.invitation || window.location.pathname.includes("/global-request/details")) ?
                    <div className='grid grid-cols-2 gap-x-5'>
                      <div className='mt-[-14px] relative flex !w-[170px]'>
                        <CommonInput
                          type='number'
                          labelText={t('Duration (min)')}
                          name={'pickup_duration'}
                          value={pickup_duration ?? ""}
                          max_input={5}
                          isInvalid={pickup_duration && pickup_duration < 3}
                          className={!pickup_duration && !topSpaceAllowed ? '!mt-0 transition-all duration-100 ease-in-out' : 'transition-all duration-75 ease-in-out'}
                          onChange={(e) => {
                            console.log("PICK UP DURATION: ", e.target.value);

                            setPickupDuration(e.target.value);
                          }}
                          onFocusChange={(e) => {
                            setTopSpaceAllowed(e);
                          }}
                          onBlurEvent={(e) => {
                            if (e.target.value && e.target.value < 3) {
                              setPickupDuration(3);
                              return Toastr({ message: "Pickup duration can not be less than 3 minutes!", type: "warning" })
                            }
                          }}
                        />
                        <div className='absolute right-0 flex space-x-2 bottom-1'>
                          {
                            pickup_duration ?
                              <Tooltip title={t('Clear Duration')} color={"#242826"} >
                                <img onClick={() => {
                                  setPickupDuration("")
                                }} className='w-5 h-5 cp' src={iCrossOutlined} alt="" />
                              </Tooltip> :
                              <></>
                          }
                          {/* <img className='w-5 h-5' src={iClockGray} alt="" /> */}
                        </div>

                      </div>
                    </div>
                    :
                    <CommonViewComponent labelText={t('Duration (min)')} value={data?.pickup_duration ? `${data?.pickup_duration} min` : 'No duration'} />
                }
              </div>
            }

            <CommonViewComponent labelText={t('Comment')} value={data?.pickup_comment ?? 'No comment'} />

            {data?.pickup_attachment && <ImageViewer src={data?.pickup_attachment} label={t('Attachment') + "." + getImageExtension(data?.pickup_attachment)} />}

            {((type === request_details_types.ongoing || type === request_details_types.history || type === request_details_types.completed)) &&
              <PickupOverview comment={data?.pickup_driver_comment} attachment={data?.pickup_driver_attachment} signature={data?.pickup_driver_signature} />}
          </div>
        </div>
      </RequestAccordion>
    </>
  )
}
