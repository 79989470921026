import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useNotificationStore, { getNotification, notificationSeenFn } from '../../../app/stores/others/notificationStore';
import NotificationDropdownItem from './NotificationDropdownItem';
import { useTranslation } from 'react-i18next';

const NotificationDropdown = () => {

    const { notificationList, setNotificationDropDownOpen, setShowNotificationDetailsModal, setNotificationDetails } = useNotificationStore();

    const navigateTo = useNavigate();
    const { t } = useTranslation();


    useEffect(() => {
        getNotification(true)
    }, [])

    return (
        <div>
            <div className="max-h-screen p-5 overflow-y-auto bg-white shadow-lg scroll-smooth rounded-br8">
                <div className="flex justify-between mb-3">
                    <div className="text-cGray800 text-fs28 font-fw600">{t('Notification')}</div>

                    <div className="flex items-center justify-center cursor-pointer text-fs16">
                        {notificationList?.data?.length > 0 &&
                            <div
                                onClick={() => {
                                    setNotificationDropDownOpen(false)
                                    navigateTo("/notification");
                                }}
                                className='no-underline text-cBrand text-fs16 font-fw500'>
                                {t('See all')}
                            </div>}
                    </div>
                </div>

                <div className='w-full'>
                    {notificationList?.data?.length > 0 ? notificationList?.data?.slice(0, 5)?.map((item, index) => (
                        <div key={index}>
                            <div className=''>
                                <NotificationDropdownItem
                                    onClick={() => {
                                        notificationSeenFn(item?.id);
                                        setNotificationDetails(item);
                                        setShowNotificationDetailsModal(true)
                                    }}
                                    seen={item?.is_seen}
                                    title={item?.title}
                                    details={item?.description}
                                    date={item?.created_date}
                                    time={item?.created_time}
                                />
                            </div>
                            {notificationList?.data?.[4]?.id !== item.id && <div className='my-2'><hr></hr></div>}
                        </div>
                    )) : <div className='flex items-center justify-center py-s40'>
                        <span>{t('Nothing in Notification!')}</span>
                    </div>}


                </div>
            </div>
        </div>
    );
};

export default NotificationDropdown;