/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useCommonHomeStore, { getCommonHomeIndex } from '../../../app/stores/others/commonHomeStore';
import TopHeader from './components/TopHeader';
import useLayoutStore from '../../../app/stores/others/layoutStore';
import NotPlanned from '../../common/requests/requestList/notPlanned/NotPlanned';
import { getAllShiftCarsAndDriversList } from '../../../app/stores/company/shiftStore';

const HomePage = () => {

    const { setSelectedReqType } = useCommonHomeStore();

    const { setShowExpandedSidebarItem } = useLayoutStore();

    useEffect(() => { fetchData() }, []);

    const fetchData = async () => {
        await getCommonHomeIndex();
        await setSelectedReqType(0);
        setShowExpandedSidebarItem(false);
        getAllShiftCarsAndDriversList("", "", "", "", false)
    }

    return (
        <>
            <TopHeader />

            <div className='mb-6'></div>
            <NotPlanned seeAllText={true}/>
            {/* <AllRequestsTable /> */}
        </>
    );
};

export default HomePage;