import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BUTTON_WIDTH,
  k_request_action,
  request_details_types,
  user_role as role,
} from "../../../../../../../app/utility/const";
import CommonButtonOutlined from "../../../../../../../components/button/CommonButtonOutlined";
import { useTranslation } from "react-i18next";
import useGeneralStore from "../../../../../../../app/stores/others/generalStore";
import { useRequestDetailsPathChecks } from "../../../../../../../app/hooks/useRequestDetailsPathChecks";
import ActionButton from "../../../../../../../components/button/ActionButton";
import { Toastr } from "../../../../../../../app/utility/utilityFunctions";
import useRequestStore, {
  acknowledgeRequest,
  downloadRequest,
} from "../../../../../../../app/stores/others/requestStore";
import CommonButton from "../../../../../../../components/button/CommonButton";
import useUtilityStore from "../../../../../../../app/stores/others/utilityStore";

const CompletedTopBar = () => {
  const params = useParams();
  const { request_id, type, shift_id } = params;
  const navigateTo = useNavigate();
  const { t } = useTranslation();
  const { user_role } = useGeneralStore();

  const { acknowledgementComment } = useRequestStore();
  const { setShowCommonShareModal } = useUtilityStore();

  const { detailsPath, isDetailsPath, isAddAcknowledgement } =
    useRequestDetailsPathChecks(type, request_id, shift_id);

  const getShareAction = (button = false) =>
    button ? (
      <CommonButtonOutlined
        width={BUTTON_WIDTH}
        onClick={async () => {
          await setShowCommonShareModal(true);
        }}
        btnLabel={t("Share")}
      />
    ) : (
      {
        title: t("Share"),
        action: () => {
          setShowCommonShareModal(true);
        },
      }
    );

  const getDownloadAction = (button = false) =>
    button ? (
      <CommonButtonOutlined
        onClick={async () => {
          await downloadRequest(request_id);
        }}
        width={BUTTON_WIDTH}
        btnLabel={t("Download")}
      />
    ) : (
      {
        title: t("Download"),
        action: async () => {
          await downloadRequest(request_id);
        },
      }
    );

  return (
    <>
      {type === request_details_types.completed && (
        <>
          {user_role === role.company && (

            <>
              {getDownloadAction(true)}
              {getShareAction(true)}

              <CommonButton
                width="w-[150px]"
                btnLabel={t("Acknowledge")}
                isDisabled={!acknowledgementComment}
                onClick={async () => {
                  if (!acknowledgementComment) {
                    Toastr({
                      message: t("Please fill up required filed"),
                      type: "warning",
                    });
                    return;
                  }
                  const success = await acknowledgeRequest(request_id);
                  if (success) {
                    navigateTo(`/requests/${type}`);
                  }
                }}
              />
            </>
          )}
          {/* {isDetailsPath ? (
            <>
              {getShareAction(true)}
              {getDownloadAction(true)}
            </>
          ) : (
            <>
              {getShareAction(true)}
              {getDownloadAction(true)}
            </>
          )} */}
        </>
      )}
    </>
  );
};

export default CompletedTopBar;
