import React from "react";
import SubmittedForGlobal from "../../../../../createRequest/components/content/selectCompany/components/SubmittedForGlobal";
import useRequestStore, { getRequestDetails } from "../../../../../../../app/stores/others/requestStore";
import { iFavCompanyGray } from "../../../../../../../app/utility/imageImports";
import CommonEmptyData from "../../../../../../../components/emptyData/CommonEmptyData";
import { useTranslation } from "react-i18next";
import CompanyListItem from "../../../../../../../components/listItems/CompanyListItem";
import { useState } from "react";
import CommonCompanyDetailsModal from "../../../../../../../components/modal/CommonCompanyDetailsModal";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const SavedCompany = () => {

    const { request_details } = useRequestStore();

    const { t } = useTranslation();

    const { request_id, type } = useParams();

    const [showModal, setShowModal] = useState(false);

    const [selectCompanyId, setSelectCompanyId] = useState(null);


    return (
        <div>
            {
                request_details?.is_global ? (
                    <div className="mb-5">
                        <SubmittedForGlobal disabled={true} checked={true} />
                    </div>
                ) : (
                    ""
                )}

            <div className="sub-title text-cGray800">
                {t("Selected Companies")} ({request_details?.invitation_data?.length ?? 0})
            </div>

            {request_details?.invitation_data?.length ? (
                <div className="mt-2 space-y-2">
                    {request_details?.invitation_data?.map((company, index) => (
                        <div key={index}>

                            <CompanyListItem
                                title={company?.name}
                                image={company?.image}
                                rating={company?.rate}
                                is_favorite={company?.is_favorite}
                                onClick={() => {
                                    setSelectCompanyId(company?.user_id);
                                    setShowModal(true)
                                }}
                                onActionBtnClick={() => { }}
                                dummyImage={iFavCompanyGray}
                                withCheckbox={false}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <CommonEmptyData title={t("No Selected Company")} />
            )}

            <CommonCompanyDetailsModal
                company_id={selectCompanyId}
                setShowModal={setShowModal}
                showModal={showModal}
            />
        </div>
    );
};

export default SavedCompany;
