export const k_create_request_paths = {
    create: '/request/create',
    edit: '/request/edit/',
    shift_details: '/request/create/shift/details/',
}

export const k_fav_company_paths = {
    list: '/favorite-companies',
    add: '/favorite-companies/add',
}


export const k_fav_address_paths = {
    list: '/favorite-address',
    add: '/favorite-address/add',
    details: '/favorite-address/details/',
}

export const k_shift_paths = {
    list: '/shift-manager',
    shift_details: '/request/shift/details/',
}

export const k_driver_paths = {
    list: '/driver-manager',
    add: '/driver-manager/add',
    details: '/driver-manager/details/',
}

export const k_car_paths = {
    list: '/car-manager',
    add: '/car-manager/add',
    details: '/car-manager/details/',
}