import React from 'react';
import { formatDate } from '../../../app/utility/utilityFunctions';

const NotificationDropdownItem = ({ seen = false, title = "NA", details = 'NA', date = "NA", time = "NA", onClick = () => { } }) => {
    return (
        <div className='cursor-pointer' onClick={onClick}>
            <div className={`${!seen ? 'text-cGray800' : 'text-cGray600'} text-fs16 font-fw500`}>{title}</div>
            <div className={`${!seen ? 'text-cGray800' : 'text-cGray600'} text-fs16 font-fw400 mb-2 mt-1 truncate`}>{details}</div>
            <div className='text-fs12 font-fw400 text-cGray800'>{formatDate(date)}, {time}</div>
        </div>
    );
};

export default NotificationDropdownItem;