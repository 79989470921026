import useChatStore from "../../../app/stores/others/chatStore";
import useCreateRequestStore, { createRequestClear } from "../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../app/stores/others/generalStore";
import useRequestStore from "../../../app/stores/others/requestStore";
import { resetTrackAndTrace } from "../../../app/stores/others/trackTraceStore";
import useUtilityStore from "../../../app/stores/others/utilityStore";
import { kuGetRequestsNew } from "../../../app/urls/commonUrl";
import { create_request_path, k_requests_status } from "../../../app/utility/const";
import { iAdminAccessNormal, iAdminAccessSelected, iAwardedNormal, iAwardedSelected, iBiddingNormal, iBiddingSelected, iChangePasswordNormal, iChangePasswordSelected, iChatNormal, iChatSelected, iCompletedNormal, iCompletedSelected, iCreateReqNormal, iCreateReqSelected, iFaqNormal, iFaqSelected, iFavAddressNormal, iFavAddressSelected, iFavCompanyNormal, iFavCompanySelected, iHistoryNormal, iHistorySelected, iHomeNormal, iHomeSelected, iLanguageNormal, iLanguageSelected, iLogout, iNotificationsNormal, iNotificationsSelected, iOnGoingNormal, iOnGoingSelected, iProfileNormal, iProfileSelected, iReqNormal, iReqSelected, iSavedReqNormal, iSavedReqSelected, iSettingsNormal, iSettingsSelected, iTermsNormal, iTermsSelected, iTrackTraceNormal, iTrackTraceSelected } from "../../../app/utility/imageImports";

const { setRequestApiUrl } = useRequestStore.getState();
const { setIsResolve, resetCreateRequest, setSelectedCompanies, setCurrentSetup } = useCreateRequestStore.getState();
const { setSelectChat, setChatSearch } = useChatStore.getState();
const { setShowLogoutModal } = useUtilityStore.getState();
const { setAvailableShifts } = useRequestStore.getState();

const CustomerSideBarList = [

    // home
    {
        onClick: () => { },
        title: "Dashboard",
        linkTo: "/",
        isActiveLink: "home",
        normalIcon: iHomeNormal,
        selectedIcon: iHomeSelected,
    },


    // Track & Trace
    {
        onClick: () => {
            createRequestClear();
            resetTrackAndTrace(true);
        },
        title: "Track & Trace",
        linkTo: "/track-trace",
        isActiveLink: "track-trace",
        normalIcon: iTrackTraceNormal,
        selectedIcon: iTrackTraceSelected,
    },

    //chat
    {
        onClick: () => {
            setSelectChat({});
            setChatSearch("");
        },
        title: "Chat",
        linkTo: "/chat",
        isActiveLink: "chat",
        normalIcon: iChatNormal,
        selectedIcon: iChatSelected,
    },

    // request part (expandable)
    {
        onClick: () => { },
        title: "requests",

        isActiveLink: "expanded_bar",
        normalIcon: iReqNormal,
        selectedIcon: iReqSelected,
        expandedItems: [
            {
                title: "saved",
                link: "/requests/saved",
                normalIcon: iSavedReqNormal,
                selectedIcon: iSavedReqSelected,
                isActiveItem: false,
                expandedOnClick: () => { },
                name: k_requests_status.saved
            },
            {
                title: "in bidding",
                link: "/requests/in-bidding",
                normalIcon: iBiddingNormal,
                selectedIcon: iBiddingSelected,
                isActiveItem: true,
                expandedOnClick: () => { },
                name: k_requests_status.in_bidding
            },
            {
                title: "upcoming",
                link: "/requests/awarded",
                normalIcon: iAwardedNormal,
                selectedIcon: iAwardedSelected,
                isActiveItem: true,
                expandedOnClick: () => { setRequestApiUrl(kuGetRequestsNew); },
                name: k_requests_status.awarded
            },
            {
                title: "ongoing",
                link: "/requests/on-going",
                normalIcon: iOnGoingNormal,
                selectedIcon: iOnGoingSelected,
                isActiveItem: true,
                expandedOnClick: () => { },
                name: k_requests_status.ongoing
            },
            {
                title: "completed",
                link: "/requests/history",
                normalIcon: iCompletedNormal,
                selectedIcon: iCompletedSelected,
                isActiveItem: true,
                expandedOnClick: () => { },
                name: k_requests_status.history
            },
        ],
    },

    // create request
    {
        onClick: () => {
            setCurrentSetup(null);
            setSelectedCompanies([]);
            createRequestClear();
            setIsResolve(false);
            if (useGeneralStore.getState().path_record?.old?.includes('/request/edit'))
                useCreateRequestStore.getState().resetCreateRequest();
            else {
                resetCreateRequest();
                setAvailableShifts([]);
            }
        },
        title: "Create Request",
        linkTo: create_request_path,
        isActiveLink: "create",
        normalIcon: iCreateReqNormal,
        selectedIcon: iCreateReqSelected,
    },


    // Settings part (expandable)
    {
        onClick: () => { },
        title: "settings",
        isActiveLink: "settings",
        normalIcon: iSettingsNormal,
        selectedIcon: iSettingsSelected,
        expandedItems: [
            {
                title: "profile",
                link: "/settings/profile",
                normalIcon: iProfileNormal,
                selectedIcon: iProfileSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "FAQ’s",
                link: "/settings/faq",
                normalIcon: iFaqNormal,
                selectedIcon: iFaqSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "Language",
                link: "/settings/language",
                normalIcon: iLanguageNormal,
                selectedIcon: iLanguageSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "Terms & Conditions",
                link: "/settings/terms-conditions",
                normalIcon: iTermsNormal,
                selectedIcon: iTermsSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "Password",
                link: "/settings/change-password",
                normalIcon: iChangePasswordNormal,
                selectedIcon: iChangePasswordSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "Notification",
                link: "/settings/push-notification",
                normalIcon: iNotificationsNormal,
                selectedIcon: iNotificationsSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "Admin Access",
                link: "/settings/admin-access",
                normalIcon: iAdminAccessNormal,
                selectedIcon: iAdminAccessSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "Favorite Addresses",
                link: "/favorite-address",
                normalIcon: iFavAddressNormal,
                selectedIcon: iFavAddressSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            // fav companies
            {
                expandedOnClick: () => { },
                title: "Favorite Companies",
                link: "/favorite-companies",
                isActiveItem: true,
                normalIcon: iFavCompanyNormal,
                selectedIcon: iFavCompanySelected,
            },
        ],
    },

    // Logout
    {
        onClick: () => { setShowLogoutModal(true) },
        title: "Logout",
        linkTo: null,
        isActiveLink: "set",
        normalIcon: iLogout,
        selectedIcon: iLogout,
    },

    //    playground
    // {
    //     onClick: () => { },
    //     title: "playground",
    //     linkTo: "/play",
    //     isActiveLink: "playground",
    //     normalIcon: iSettingsNormal,
    //     selectedIcon: iSettingsSelected,
    // },
];

export default CustomerSideBarList;