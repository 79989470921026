import React, { useState } from 'react';
import Test from './Test';
import CommonTimePicker from '../../../components/input/CommonTimePicker';


const PlayGround = () => {
  const [timeValue, setTimeValue] = useState("00:00");

  const customTime = "12:30";
  return (
    <div className='w-full rounded-lg p-5 min-h-[500px] bg-white'>
      <div className="text-4xl">{timeValue}</div>
      <div className="mt-10 mb-20 w-40">
        <CommonTimePicker
          init_time={timeValue}
          init_date={new Date()}

          onChange={(time) => setTimeValue(time)}
          showExtendedTimeUi={false}

          isCustomMinTimeValidation={true}
          customMinDateValue={new Date()}
          customMinTimeValue={customTime}

        />
      </div>

      <Test />
    </div >
  )
}

export default PlayGround