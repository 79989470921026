import React from 'react'
import useCreateRequestStore from '../../../../../../../app/stores/others/createRequestStore';
import { iFavCompanyGray } from '../../../../../../../app/utility/imageImports';
import CommonEmptyData from '../../../../../../../components/emptyData/CommonEmptyData';
import CompanyListItem from '../../../../../../../components/listItems/CompanyListItem'
import { useTranslation } from 'react-i18next';
import useSettingsStore from '../../../../../../../app/stores/others/settingsStore';
import CommonCompanyDetailsModal from '../../../../../../../components/modal/CommonCompanyDetailsModal';

export default function Suggestions({ companies, isReadOnly = false, collectUserId }) {

  const { setSelectedCompanies, selected_companies } = useCreateRequestStore();
  const { setSelectedCompanyId } = useSettingsStore();
  const [showModal, setShowModal] = React.useState(false);
  const [company_id, setCompanyId] = React.useState(null);
  const { t } = useTranslation();

  const handleCompanyClick = (company) => {
    if (selected_companies.find(i => i.id === company.id))
      return setSelectedCompanies(selected_companies.filter(i => i.id !== company.id))
    else setSelectedCompanies([...selected_companies, company]);
  };

  return (
    <div
      onClick={() => {
        console.log("selected_companies: ", selected_companies);
        console.log("companies: ", companies);
      }}
    >
      {companies?.length ?
        <div className='space-y-2'>
          {
            companies?.map((company, index) => (
              <CompanyListItem
                withCheckbox={isReadOnly ? false : true}
                key={index}
                title={company?.name}
                rating={company?.rate}
                image={company?.image}
                dummyImage={iFavCompanyGray}
                is_favorite={company?.is_favorite}
                has_action_btn={false}
                is_add_type={true}
                onActionBtnClick={() => { handleCompanyClick(company) }}
                selected={selected_companies?.findIndex(x => x?.id === company?.id) !== -1}
                onClick={() => {
                  if (collectUserId) {
                    setCompanyId(company?.id);
                  }
                  else {
                    setCompanyId(company?.id);
                  }
                  setSelectedCompanyId(company?.company_id);
                  setShowModal(true);
                }}
              />
            ))
          }
        </div>
        :
        <CommonEmptyData title={t('No Company Available')} details='' />
      }

      <CommonCompanyDetailsModal showModal={showModal} setShowModal={setShowModal} company_id={company_id} bidding_details={false} />
    </div>
  )
}
