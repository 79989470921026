/* eslint-disable no-use-before-define */
import React from 'react'
import useCreateRequestStore from '../../../../../app/stores/others/createRequestStore'
import { create_request_steps, user_role as role } from '../../../../../app/utility/const';
import SelectCompany from './selectCompany/SelectCompany';
import SelectShift from './selectShift/SelectShift';
import Delivery from './pickupAndDeliveryForm/components/Delivery';
import Pickup from './pickupAndDeliveryForm/components/Pickup';
import ProposalInfo from './proposalInfo/ProposalInfo';
import { useEffect } from 'react';
import useGeneralStore from '../../../../../app/stores/others/generalStore';

export default function Content() {

  const { current_step, cr_form, setExpandedAccordion } = useCreateRequestStore();

  useEffect(() => {
    window.scroll(0, 0)
  }, [current_step])

  const { user_role } = useGeneralStore();

  useEffect(() => {
    setExpandedAccordion(create_request_steps.pickup);
  }, []);

  return (
    <div className='w-[calc(100%-380px)] relative flex flex-col'>
      {cr_form?.request_id && <RequestID request_id={cr_form?.request_id} current_step={current_step} />}
      <div className='flex flex-col gap-y-5 mt-1.5'>

        <Pickup />

        <Delivery />

        {user_role === role.customer && <SelectCompany />}

        {
          user_role === role.company &&
          <>
            <SelectShift />
            <ProposalInfo />
          </>
        }
      </div>
    </div>

  )
}


const RequestID = ({ current_step, request_id }) =>
  current_step !== create_request_steps.tableView &&
  current_step !== create_request_steps.allShifts && (
    <div className="font-normal leading-[31.2px] text-fs24 mb-3 -mt-1.5 text-cGray800">
      Request ID: {request_id}
    </div>
  );