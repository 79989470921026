import React, { useRef } from "react";
import CommonModal from "../../../../../../../components/modal/CommonModal";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../../../../../components/button/CommonButton";
import CommonSelect from "../../../../../../../components/select/CommonSelect";
import useShiftStore, {
    FormatCarsArrayForSelectionList,
    FormatDriversArrayForSelectionList,
    getAllShiftCarsAndDriversList,
} from "../../../../../../../app/stores/company/shiftStore";
import { k_shift_status } from "../../../../../../../app/utility/const";
import { useEffect } from "react";
import useRequestStore, { getRequestShiftPlannerList } from "../../../../../../../app/stores/others/requestStore";
import useCreateRequestStore from "../../../../../../../app/stores/others/createRequestStore";
import MultipleSelectCombo from "../../../../../../../components/select/MultipleSelectCombo";
import { useLocation } from "react-router-dom";
import CommonSingleSelect from "../../../../../../../components/select/CommonSingleSelect";

const AvailableShiftFilter = ({ showModal = false, setShowModal }) => {
    const {
        resetFilterFormCopy,
        filter_form,
        changeFilterForm,
        filterShiftCarList,
        resetFilterForm,
        filterShiftDriverList,
        setFilterShiftCarList,
        setFilterShiftDriverList,
        updateFilterFormCopy,
    } = useShiftStore();

    const { cr_form } = useCreateRequestStore();
    const { request_details } = useRequestStore();

    const divRef = useRef(null);

    const { t } = useTranslation();
    const location = useLocation()

    const shift_status = [
        { id: k_shift_status.init, title: t("Upcoming") },
        { id: k_shift_status.ongoing, title: t("Ongoing") },
    ];

    useEffect(() => {
        if (divRef.current) divRef.current.focus();
    }, []);

    useEffect(() => {
        if (showModal) {
            fetchData();
        }
    }, [showModal]);

    const getAvailableShift = async () => {
        
        return location?.state?.redirectFromRequest ? await getRequestShiftPlannerList(
            request_details?.pickup_start_time,
            request_details?.pickup_end_time,
            request_details?.pickup_date,
            true,
            true
        ) : await getRequestShiftPlannerList(
            cr_form?.pickup?.start_time,
            cr_form?.pickup?.end_time,
            cr_form?.pickup?.date,
            true,
            true
        );
    }

    const fetchData = async () => {
        if (filterShiftCarList?.length > 0 && filterShiftDriverList?.length > 0)
            return;

        const success = await getAllShiftCarsAndDriversList(null, null, null, null, true);
        setFilterShiftCarList(FormatCarsArrayForSelectionList(success?.cars));
        setFilterShiftDriverList(
            FormatDriversArrayForSelectionList(success?.drivers)
        );
    };

    const driverIds = filter_form?.drivers?.map(item => {
        return item?.id;
      });
    
    const carIds = filter_form?.cars?.map(item => {
    return item?.id;
    });

    const driversArray = filterShiftDriverList?.filter(item => !driverIds?.includes(item?.value)).map(item => {return {title: item?.title, id: item?.value}});

    const carsArray = filterShiftCarList?.filter(item => !carIds?.includes(item?.value)).map(item => {return {title: item?.title, id: item?.value}});

    return (
        <div>
            <CommonModal
                showModal={showModal}
                setShowModal={setShowModal}
                modalTitle={
                    <div className="flex items-baseline">
                        <div>{t("Filter")}</div>
                        <div
                            className="pl-4 text-base cursor-pointer select-none drop-shadow-sm text-cRed"
                            onClick={async () => {
                                await resetFilterFormCopy();
                                await resetFilterForm();
                                const success = await getAvailableShift();
                                if (success) setShowModal(false);
                            }}
                        >
                            {t("Clear")}
                        </div>
                    </div>
                }
                mainContent={
                    <>
                        <form
                            className="mt-6"
                            onSubmit={async (e) => {
                                console.log("click")
                                e.preventDefault();
                                const success = await getAvailableShift();

                                if (success) {
                                    updateFilterFormCopy(filter_form);
                                    setShowModal(false);
                                }
                            }}
                        >
                            <div tabIndex="0" ref={divRef} className="pt-0"></div>

                            <div className="mt-[9px]">
                                <MultipleSelectCombo
                                    label={t("Select Car/License Plate")}
                                    dataArray={carsArray}
                                    onChange={(value) => changeFilterForm('cars', value)}
                                    selectedId={filter_form?.cars}
                                />

                            </div>
                            <div className="mt-s20">
                                <MultipleSelectCombo
                                    label={t("Select Driver")}
                                    dataArray={driversArray}
                                    onChange={(value) => changeFilterForm('drivers', value)}
                                    selectedId={filter_form?.drivers}
                                    showClear={true}
                                />
                            </div>

                            <div className="mt-[21px] grid grid-cols-2 items-center gap-7">
                                <CommonSingleSelect
                                    label={t("Shift Status")}
                                    placeholder={t("Shift Status")}
                                    dataArray={shift_status}
                                    has_subtitle={false}
                                    onChange={(value) => {
                                        changeFilterForm("status", value);
                                    }}
                                    value={filter_form?.status}
                                    isCapitalize={true}
                                />
                            </div>

                            <div className="flex flex-row items-center justify-end w-full mt-s20">
                                <CommonButton btnLabel={t("Apply Filter")} type="submit" />
                            </div>
                        </form>
                    </>
                }
            />
        </div>
    );
};

export default AvailableShiftFilter;
