/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useState } from 'react';
import useGeneralStore from '../../../app/stores/others/generalStore';
import useSettingsStore, { fetchDeleteAccountRequirements } from '../../../app/stores/others/settingsStore';
import { changePageTitle, Toastr } from '../../../app/utility/utilityFunctions';
import CommonButton from '../../../components/button/CommonButton';
import CommonTitle from '../../../components/title/CommonTitle';
import { useTranslation } from 'react-i18next';
import { k_settings_paths, user_role as role } from '../../../app/utility/const';

const DeleteAccount = () => {

    const { delete_account_requirements, setShowDeleteAccountModal } = useSettingsStore();

    const [is_checked, setIsChecked] = useState(false);

    const { user_role } = useGeneralStore();

    const { t } = useTranslation();

    useEffect(() => {
        fetchData();
        changePageTitle(t('Settings | Delete Account'));
    }, [])

    const fetchData = async () => {
        await fetchDeleteAccountRequirements(user_role === role.customer ? 'customer' : 'company')
    }

    const link = k_settings_paths.profile;

    return (
        <div>
            <CommonTitle link={`${link}`} title={t('Delete Account')} icon={true} />

            <div>
                <div className='text-fs24 font-fw500 text-cMainBlack my-s20'>{t("Read carefully before you proceed!")}</div>
                <div
                    className="whitespace-pre-line text-fs13 font-fw400 text-cMainBlack"
                    dangerouslySetInnerHTML={{ __html: delete_account_requirements?.description ?? '' }} />


                <div className="flex flex-row items-center py-5">
                    <input
                        id="checkDelete"
                        className="mr-2 accent-red-500"
                        type="checkbox"
                        name=""
                        value={is_checked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <label
                        htmlFor="checkDelete"
                        className="cursor-pointer select-none font-regular text-fs14 text-cMainBlack"
                    >
                        {t("I agree to delete this account with knowing my losses of deleting this account.")}
                    </label>
                </div>

                <div className="flex justify-start">
                    <CommonButton
                        width='w-[150px]'
                        colorType={"danger"}
                        btnLabel={t("Delete Account")}
                        onClick={() => {
                            if (!is_checked) {
                                Toastr({ message: 'Please check the box before proceeding.', type: "warning" });
                            } else {
                                setShowDeleteAccountModal(true);
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default DeleteAccount;