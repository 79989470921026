/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import CommonInput from '../../../../../../../components/input/CommonInput';
import useRequestStore from '../../../../../../../app/stores/others/requestStore';
import { useParams } from 'react-router';
import { request_details_types, user_role as role } from '../../../../../../../app/utility/const';
import { useEffect } from 'react';
import useGeneralStore from '../../../../../../../app/stores/others/generalStore';

const UpDateProposalInfo = () => {

    const { t } = useTranslation();

    const { user_role } = useGeneralStore();
    // (data?.status === "in_bidding" || data?.status === "in_bidding_red") && parseInt(data?.is_global)
    const { type } = useParams();

    const { place_bid_form, setPlaceBidForm, request_details, updatePlaceBidForm } = useRequestStore();

    const create_by_customer = ((user_role === role.company) && ((type === "in-bidding") && parseInt(request_details?.is_global) === 0))

    console.log("create_by_customer", create_by_customer)
    useEffect(() => {
        updatePlaceBidForm({ budget: request_details?.my_bid?.budget, description: request_details?.my_bid?.details });
    }, [])

    return (
        <div>

            <div className='mb-3 sub-title text-cGray800'>{t("Proposal Info")}</div>
            <form>
                <div>
                    <div className='grid grid-cols-2 gap-x-6'>
                        <CommonInput required={true} name={'budget'} value={place_bid_form?.budget} onChange={setPlaceBidForm} labelText={t('Budget')} className={'mb-2 mt-0'} type={'number'} max_input={8} show_asterisk={false} />

                        <CommonInput
                            disabled={(type === request_details_types.awarded) || create_by_customer}
                            show_asterisk={false}
                            required={true}
                            className={'mb-2 mt-0'}
                            labelText={t('Customer')}
                            type='text'
                            name={'customer_name'}
                            value={place_bid_form?.customer_name}
                            onChange={setPlaceBidForm}
                        />
                    </div>

                    <CommonInput required={true} className={'mt-1'} name={'description'} value={place_bid_form?.description} onChange={setPlaceBidForm} labelText={t('Description')} textarea={true} type={'text'} max_input={255} show_asterisk={false} />
                </div>
            </form>
        </div>
    );
};

export default UpDateProposalInfo;