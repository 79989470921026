import React from 'react';
import { iLicenseCard, iLicenseCardGray } from '../../../../app/utility/imageImports';

const LicenseCard = ({ onClick, active, number, amount,disabled=false }) => {
    return (
        <div onClick={onClick} className={`cursor-pointer bg-cPrimary50 ${active ? "ring-2 ring-cBrand " : "ring-2 ring-cGray50"} license_card_width py-7 flex flex-col justify-center items-center rounded-lg`}>
            <img className='image_padding' src={disabled? iLicenseCardGray : iLicenseCard} alt="" />
            <div className={`text-fs24 leading-[40px] font-fw900 ${disabled ? "text-cGray500":"text-cBrand"}`}>DKK {amount.toLocaleString("da-DK")}</div>
            <div className={`text-fs18 font-fw900  ${disabled ? "text-cGray500":"text-cBrand"}`}>{number}</div>
        </div>
    );
};


export default LicenseCard;