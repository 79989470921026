/* eslint-disable react-hooks/exhaustive-deps */
import { Rating } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCreateRequestStore from '../../../../../../../app/stores/others/createRequestStore';
import useGeneralStore from '../../../../../../../app/stores/others/generalStore';
import useRequestStore, { writeReview } from '../../../../../../../app/stores/others/requestStore';
import { create_request_steps, user_role as role } from '../../../../../../../app/utility/const';
import { formatDate, Toastr } from '../../../../../../../app/utility/utilityFunctions';
import RequestAccordion from '../../../../../../../components/Accordion/RequestAccordion';
import CommonInput from '../../../../../../../components/input/CommonInput';
import CommonViewComponent from '../../../../../../../components/viewer/CommonViewComponent';
import CommonEmptyView from '../../../../../createRequest/components/content/selectShift/components/CommonEmptyView';

const Review = () => {

    const { request_details, setReadyToReview, readyToReview } = useRequestStore();
    const { t } = useTranslation();
    const { user_role } = useGeneralStore.getState();
    const { expandedAccordion, setExpandedAccordion } = useCreateRequestStore();

    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(5);

    useEffect(() => {
        // Validate input values
        if (!comment) setReadyToReview(false);
        if (rating < 1) setReadyToReview(false);
        setReadyToReview({
            comment: comment,
            rating: rating
        });
    }, [rating, comment]);

    useEffect(() => {
        console.log({ readyToReview });

        if (readyToReview === null) {
            setComment('');
            setRating(5);
        }
    }, [readyToReview]);

    return (
        <RequestAccordion
            showClearButton={false}
            // titleRightComponent={"Selected Companies (" + request_details?.invitation_data?.length + ")"}
            // titleRightComponentOnClick={() => { setSelectedCompaniesModal(true) }}
            title={t("My Review")}
            subTitleOne={request_details?.rate ? "Rated | " + formatDate(request_details?.rated_at) : "Not Rated Yet"}
            isForm={true}
            isExpanded={expandedAccordion === create_request_steps.myReview}
            onExpand={() => { setExpandedAccordion(expandedAccordion === create_request_steps.myReview ? null : create_request_steps.myReview) }}
        >
            <div className={`${expandedAccordion === create_request_steps.myReview ? "mt-3" : "mt-4"} border-cGray200 rounded ${request_details?.is_rated ? "p-3 border" : ""}`}>
                {
                    !request_details?.acknowledge ?
                        <div>
                            <CommonEmptyView message="Company has to acknowledge the request first. Then it will be your turn to rate them." />
                        </div>
                        :
                        request_details?.is_rated ?
                            <>
                                <div className='flex justify-between mb-3'>
                                    {
                                        (request_details?.rate && user_role === role.customer) &&
                                        <div className='flex items-center justify-center'>
                                            <Rating
                                                name="size-large"
                                                size="small"
                                                value={Math.round(request_details?.rate) ?? 0}
                                                datatype={'number'}
                                                readOnly
                                            />
                                        </div>
                                    }
                                </div>


                                <div className='flex flex-col items-start justify-start space-y-2'>
                                    {
                                        (
                                            request_details?.is_rated && user_role === role.customer) ?
                                            <CommonViewComponent
                                                labelText={t('Comment')}
                                                value={request_details?.review ?? 'NA'}
                                            /> :
                                            <div className='font-normal text-cGrey500 text-fs14'>
                                                {t('Please rate the company!')}
                                            </div>
                                    }

                                </div>
                            </>
                            :
                            <>

                                <div className=' mb-5'>
                                    <div className="text-xs">Select Rating</div>
                                    <div className="-ml-1 pt-1">
                                        <Rating
                                            name="size-large"
                                            size="large"
                                            defaultValue={5}
                                            value={rating}
                                            max={5}
                                            onChange={(event, newValue) => setRating(newValue)}
                                        />
                                    </div>
                                </div>

                                <CommonInput
                                    className=""
                                    name={'Comment'}
                                    value={comment}
                                    onChange={(e) => {
                                        if (e.target.value !== " ") {
                                            const trimmedValue = e.target.value.replace(/\s+/g, " ");
                                            setComment(trimmedValue)
                                        }
                                    }}

                                    labelText={t('Comment')}
                                    textarea={true}
                                    type={'text'}
                                    max_input={255}
                                    required={true}
                                    rows={3}
                                    max_rows={5}
                                    show_asterisk={false}
                                />

                                {/* <div className='flex justify-end mt-s30'>
                                <CommonButton
                                    btnLabel={t('Submit')}
                                    width='w-[100px]'
                                    type='submit'
                                />

                            </div> */}

                            </>
                }


            </div>
        </RequestAccordion>
    );
};

export default Review;