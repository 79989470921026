/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import da from 'dayjs/locale/da';
import useSettingsStore from '../../app/stores/others/settingsStore';
import { Tooltip } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { ClickAwayListener } from '@mui/material';
import { t } from 'i18next';
import { iCalender } from '../../app/utility/imageImports';

const MassImportDatePicker = ({
    startDate,
    endDate,
    allowPastDate = true,
    allowFutureDate = true,
    required = false,
    disabled = false,
    label = "Select Date",
    value = null,
    show_asterisk = true,
    onChange = () => { },
    onError = () => { },
    shouldUpdateValue = false,
    button = <></>
}) => {
    const [valueLocal, setValueLocal] = useState(null);
    const [foundError, setFoundError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [openCalendar, setOpenCalendar] = useState(false);
    const [safeToCloseCalendar, setSafeToCloseCalendar] = useState(true);

    const { app_lang_code } = useSettingsStore();
    const datePickerRef = useRef(null);
    const datePickerButtonRef = useRef(null);

    // useEffect(() => {
    //     switch (foundError) {
    //         case "minDate":
    //             setErrorMessage("Invalid date, must be: " + startDate + " or later");
    //             onError("Invalid date, must be: " + startDate + " or later");
    //             break;
    //         case "maxDate":
    //             setErrorMessage("Invalid date, must be: " + endDate + " or earlier");
    //             onError("Invalid date, must be: " + endDate + " or earlier");
    //             break;
    //         case "disablePast":
    //             setErrorMessage(t("Invalid date, past date not allowed here."));
    //             onError("Invalid date, past date not allowed here.");
    //             break;
    //         case "disableFuture":
    //             setErrorMessage("Invalid date, future date not allowed here.");
    //             onError("Invalid date, future date not allowed here.");
    //             break;
    //         case "invalidDate":
    //             setErrorMessage("Invalid date, please use correct format.");
    //             onError("Invalid date, please use correct format.");
    //             break;
    //         default:
    //             setErrorMessage("");
    //             break;
    //     }
    // }, [foundError]);

    useEffect(() => {
        setValueLocal(value === "" ? null : value);
    }, [value]);

    useEffect(() => {
        if (shouldUpdateValue && openCalendar) {
            if (new Date(value) < new Date()) {
                setValueLocal(new Date());
                onChange(new Date());
            } else {
                setValueLocal(value === "" ? null : value);
            }
        }
    }, [openCalendar]);

    return (
        <div className='w-full'>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={app_lang_code === 'da' ? da : ''}>
                <ClickAwayListener onClickAway={(e) => {
                    if (datePickerRef.current && !datePickerRef.current.contains(e.target)) {
                        setOpenCalendar(false);
                    }
                }}>
                    <div ref={datePickerRef}>
                        <DatePicker
                            open={openCalendar}
                            onClose={() => setOpenCalendar(false)}
                            className='w-full'
                            components={{
                                OpenPickerIcon: () => (
                                    <div style={{
                                        position: 'absolute',
                                        bottom: '-1px',
                                        right: '12px',
                                    }}>
                                        <img
                                            className='max-w-[20px] min-w-[20px] max-h-[20px] min-h-[20px] h-5'
                                            src={iCalender}
                                            alt='calender icon'
                                            style={{ pointerEvents: 'none' }}
                                        />
                                    </div>
                                )
                            }}
                            PaperProps={{
                                sx: {
                                    width: '100%',

                                    "& label": {
                                        color: '#BCC2BF',
                                        fontFamily: 'fEuclidLimadiRegular',
                                        fontWeight: !valueLocal ? '' : 'bold',
                                        textTransform: 'capitalize',
                                    },


                                    "& .PrivatePickersYear-yearButton": {
                                        "&.Mui-selected.Mui-selected": {
                                            backgroundColor: '#285D43', // Set the selected year color
                                            color: '#FFFFFF',
                                            "&:hover": {
                                                backgroundColor: '#285D43', // Set the hover color for selected year
                                                color: '#FFFFFF', // Set the text color for hover on selected year
                                            },
                                        },
                                        "&:hover": {
                                            backgroundColor: '#CBE9DA', // Set the hover color for non-selected years
                                        },
                                    },


                                    "& .MuiPickersDay-root.MuiPickersDay-root": {
                                        "&.Mui-selected.Mui-selected": {
                                            backgroundColor: '#285D43',
                                            color: '#FFFFFF',
                                            "&:hover": {
                                                backgroundColor: '#285D43',
                                                color: '#FFFFFF',
                                            },
                                        },
                                        "&.MuiPickersDay-dayDisabled.MuiPickersDay-dayDisabled": {
                                            backgroundColor: '#285D43',
                                        },
                                        "&:hover": {
                                            backgroundColor: '#CBE9DA',
                                        },
                                    },
                                    "& .MuiPickersDay-root": {
                                        "&.Mui-selected": {
                                            backgroundColor: '#285D43',
                                            color: '#FFFFFF',
                                            "&:hover": {
                                                backgroundColor: '#285D43',
                                                color: '#FFFFFF',
                                            },
                                        },
                                        "&.MuiPickersDay-dayDisabled": {
                                            backgroundColor: '#285D43',
                                        },
                                        "&:hover": {
                                            backgroundColor: '#CBE9DA',
                                            color: '#242826',
                                        },
                                        cursor: 'pointer',
                                    },
                                    "& label.Mui-focused": {
                                        color: '#BCC2BF',
                                        fontWeight: 'bold',
                                    },
                                }
                            }}
                            PopperProps={{
                                anchorEl: datePickerButtonRef.current,
                                placement: 'bottom-start',
                                style: {
                                    // width: '200px', // Adjust as needed
                                    height: '100px', // Adjust as needed
                                    padding: "0px"
                                },
                            }}
                            label={(required && show_asterisk) ? <div>{label}<span className={`${disabled ? "text-[#939d98]" : "text-[#48504C]"} ml-s2`}>*</span></div> : label}
                            value={valueLocal}
                            onMonthChange={() => { setSafeToCloseCalendar(false); }}
                            onYearChange={() => { setSafeToCloseCalendar(false); }}
                            onChange={(newValue) => {
                                setSafeToCloseCalendar(true);
                                setOpenCalendar(false);
                                setValueLocal(newValue);
                                onChange(new Date(newValue));
                            }}
                            inputFormat={app_lang_code === 'da' ? "DD. MMM YYYY" : "DD. MMM YYYY"}
                            disabled={disabled}
                            minDate={startDate}
                            maxDate={endDate}
                            disablePast={!allowPastDate}
                            disableFuture={!allowFutureDate}
                            onError={(e) => { setFoundError(e); }}
                            disableMaskedInput={true}
                            renderInput={(params) => (
                                <div ref={datePickerButtonRef} onClick={() => { if (!disabled) { setOpenCalendar(!openCalendar); } }}>
                                    <Tooltip color={'#242826'} placement='top' title={errorMessage ? errorMessage : disabled ? "not editable" : ""}>
                                        {button}
                                    </Tooltip>
                                </div>
                            )}
                        />
                    </div>
                </ClickAwayListener>
            </LocalizationProvider>
        </div>
    );
}

export default MassImportDatePicker;
