import React from 'react'
import CarLicensePackage from './CarLicensePackage'
import useCarStore, { addCar } from '../../../../app/stores/company/carStore';
import { useState } from 'react';

const AddLicense = () => {

    const { addCarForm, isNoLicenseApply } = useCarStore();

    const [carDetails, setCarDetails] = useState({
        id: null,
        name: addCarForm?.name,
        car_license_plate_number: addCarForm?.car_license_plate_number,
    });

    const handleAddCar = async () => {
        if (addCarForm?.name && addCarForm?.car_license_plate_number && addCarForm?.comment) {
            const success = await addCar(addCarForm, Boolean(addCarForm?.image), !isNoLicenseApply);
            if (success?.success) {
                await setCarDetails(success?.data.car)
                return {
                    success: true,
                    data: success?.data.car
                };
            } else {
                return {
                    success: false,
                    data: success?.data
                };

            }
        }
    }

    return (
        <div>
            <CarLicensePackage
                carDetails={carDetails}
                handleAddCar={handleAddCar}
            />
        </div>
    )
}

export default AddLicense