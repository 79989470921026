/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import CommonTable from '../../../../../components/table/CommonTable';
import InBiddingFilter from './components/InBiddingFilter';
import { defineOfferedPrice, getTableViewRequestsData, handleRequestOrder, isTableFiltered } from '../../../../../app/stores/others/requestStore';
import { k_requests_status, k_orderBy, k_request_paths, user_role as role, common_take } from '../../../../../app/utility/const';
import useRequestStore from '../../../../../app/stores/others/requestStore';
import { formatDate, formatDateTime } from '../../../../../app/utility/utilityFunctions';
import { kuGetRequestsNew } from '../../../../../app/urls/commonUrl';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import useGeneralStore from '../../../../../app/stores/others/generalStore';
import Clamp from 'react-multiline-clamp';
import { useTranslation } from 'react-i18next';
import useLayoutStore from '../../../../../app/stores/others/layoutStore';
import { Tooltip } from 'antd';

export default function InBiddings({ tableTitleClassName, seeAllText = false }) {

  const { in_bidding, search_key, setSelectBiddingId, setSearchKey, request_take, setRequestTake, setRequestApiUrl, setRequestsOrder, setIsAsc, request_filter_form, resetRequestFilterForm, resetFilterRange, clearFilterRange, resetRequestFilterFormCopy, is_asc, requests_order, request_filter_form_copy, hasRequestFilter } = useRequestStore();

  const { path_record, user_role } = useGeneralStore();

  const { setSideBarSelectItemTitle } = useLayoutStore();

  const [showModal, setShowModal] = useState(false);

  const [searchValue] = useDebounce(search_key, 500);

  const navigateTo = useNavigate();

  const { t } = useTranslation();


  const headers = user_role === role.customer ?
    [
      { isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true},

      { isActive: requests_order === k_orderBy.title, sort: is_asc, name: t("Title"), onClickAction: () => handleRequestOrder(k_orderBy.title, getData), showSortImage: true},

      { isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true},

      { isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true},

      { isActive: requests_order === k_orderBy.submitted_to, sort: is_asc, name: t("Submitted"), onClickAction: () => handleRequestOrder(k_orderBy.submitted_to, getData), showSortImage: true},

      { isActive: requests_order === k_orderBy.pickup_starts_at, sort: is_asc, name: t("Bid Ends"), onClickAction: () => handleRequestOrder(k_orderBy.pickup_starts_at, getData), showSortImage: true},

      { isActive: requests_order === k_orderBy.min_budget, sort: is_asc, name: t("Offered Price"), onClickAction: () => handleRequestOrder(k_orderBy.min_budget, getData), showSortImage: true},
    ] :
    [
      { isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true},

      { isActive: requests_order === k_orderBy.customer_from, sort: is_asc, name: t("Customer"), onClickAction: () => handleRequestOrder(k_orderBy.customer_from, getData), showSortImage: true},

      { isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true},

      { isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true},

      { isActive: requests_order === k_orderBy.pickup_starts_at, sort: is_asc, name: t("Bid Ends"), onClickAction: () => handleRequestOrder(k_orderBy.pickup_starts_at, getData), showSortImage: true},

      { isActive: requests_order === k_orderBy.my_bid, sort: is_asc, name: t("Budget"), onClickAction: () => handleRequestOrder(k_orderBy.my_bid, getData), showSortImage: true },
    ];

  const getData = () => getTableViewRequestsData({
    data_set: k_requests_status.in_bidding, filter: {
      transport_type: request_filter_form?.transport_types?.map(item => {return item?.id}),
      pickup_date_from: request_filter_form?.pickup_date_from,
      pickup_date_to: request_filter_form?.pickup_date_to,
      min_budget: request_filter_form?.min_budget,
      max_budget: request_filter_form?.max_budget,
    }
  });

  const resetTable = async () => {
    if (searchValue?.length > 0) await setSearchKey('');
    await setRequestApiUrl(kuGetRequestsNew);
    if (window.location.pathname === "/") {
      if (request_take !== common_take) await setRequestTake(common_take);
    } else {
      if (request_take !== common_take) await setRequestTake(common_take);
    }
    await setRequestsOrder(k_orderBy.pickup_starts_at);
    await setIsAsc(1);
    await resetRequestFilterForm();
    await resetFilterRange();
    await clearFilterRange();
    await resetRequestFilterFormCopy();
    getTableViewRequestsData({ data_set: k_requests_status.in_bidding, filter: {}, hasFilter: false });
  }

  const handleInitialSetup = async () => {
    window.scrollTo(0, 0);
    if (window?.location?.pathname === "/" || !hasRequestFilter || !path_record?.old?.includes('/')) {
      resetTable();
    } else {
      getData();
    }
  }

  useEffect(() => { handleInitialSetup() }, []);

  useEffect(() => { handleInitialSetup() }, [window?.location?.pathname === "/"]);

  useEffect(() => { getData() }, [searchValue])

  const contents = in_bidding?.data?.map(item => {
    return user_role === role.company ? {
      contents: [
        {value: item?.request_id},
        {value: <Clamp lines={1}> {item?.customer_from === "global" ? "Global" : item?.customer_from}</Clamp>},
        {value: item?.transport_type},
        {value: "1 | " +
          (item?.stops_count ?? 0) +
          " | " +
          (item?.products_count ?? 0)},
        {value: formatDateTime(item?.pickup_starts_date, item?.pickup_starts_time)}, 
        {value: item?.my_bid?.length > 0 && ('DKK ' + item?.my_bid[0]?.budget?.toLocaleString("da-DK") ?? '')},
      ],
      clickAction: () => {
        setSideBarSelectItemTitle(k_request_paths.in_bidding + `/details/${item?.id}`)
        navigateTo(k_request_paths.in_bidding + `/details/${item?.id}`);
      }
    } : 
    {
      contents: [
        {value: item?.request_id},
        {value: item?.title},
        {value: item?.transport_type},
        {value: <Tooltip title={"Request | Stops | Packages"}>{"1 | " + (item?.stops_count ?? 0) + " | " + (item?.products_count ?? 0)}</Tooltip>},
        {value: item?.submitted_to},
        {value: formatDateTime(item?.pickup_starts_date, item?.pickup_starts_time)}, 
        {value: <Tooltip title={defineOfferedPrice(item)}><Clamp lines={1}>{defineOfferedPrice(item)}</Clamp></Tooltip>},
      ],
      clickAction: () => {
        setSelectBiddingId(null);
        setSideBarSelectItemTitle(k_request_paths.in_bidding + `/details/${item?.id}`)
        navigateTo(k_request_paths.in_bidding + `/details/${item?.id}`);
      }
    }
  })

  return (
    <>
      <CommonTable
        tableTitle={t('In Bidding') + (window?.location?.pathname !== "/" ? ` (${in_bidding?.total ?? 0})` : '')}
        showSearchBox={!seeAllText}
        showTopRightFilter={!seeAllText}
        showTakeOption={!seeAllText}
        showPagination={!seeAllText}
        firstIndexColWidth='flex justify-start !w-max'
        showPageCountText={!seeAllText}
        outerPadding='p-s0'
        topRightFilterComponentOnClick={() => setShowModal(true)}
        tableTitleClassName={tableTitleClassName}
        paginationObject={in_bidding}
        tableHeaders={headers}
        tableContents={contents}
        widths={user_role === role.company ? "90px auto 20% 120px 15% 10%" : "90px auto 15% 120px 10% 15% 15%"}
        hasClickFunction={true}
        withClearSearch={true}
        onSearchClear={() => { setSearchKey("") }}
        searchValue={search_key}
        searchOnChange={(e) => {
          setRequestApiUrl(kuGetRequestsNew);
          setSearchKey(e.target.value);
        }}

        seeAllText={seeAllText && in_bidding?.total ? t("See all") : null}
        seeAllLink={k_request_paths.in_bidding}
        seeAllOnClick={() => {
          setSideBarSelectItemTitle(k_request_paths.in_bidding)
          resetTable();
        }}


        currentTakeAmount={request_take}
        withReloader={true}
        onReload={resetTable}
        filtered={isTableFiltered(k_requests_status.in_bidding, request_filter_form_copy)}
        takeOptionOnChange={async (e) => {
          await setRequestTake(e.target.value);
          await setRequestApiUrl(kuGetRequestsNew);
          getData();
        }}
        paginationOnClick={async (url) => {
          await setRequestApiUrl(url);
          getData();
        }}
      />

      <InBiddingFilter showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}
