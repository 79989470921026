import React from 'react'
import { ThreeDots } from 'react-loader-spinner';

const PageScrollLoader = () => {
    return (
        <div className='flex justify-center w-full'>
            <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#285D43"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    )
}

export default PageScrollLoader