import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { changePageTitle } from '../../../app/utility/utilityFunctions';
import { t } from 'i18next';

const ShiftManager = () => {
    useEffect(() => { changePageTitle(t('Limadi | Shift Manager')); }, []);
    return (
        <div className=''>
            <Outlet />
        </div>
    )
}

export default ShiftManager
