/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FormControlLabel, Radio } from "@mui/material";
import { useTranslation } from "react-i18next";
import useRequestStore, {
  getAdvanceCalculationData,
  getRequestShiftPlannerList,
} from "../../../../../app/stores/others/requestStore";
import CommonEmptyData from "../../../../../components/emptyData/CommonEmptyData";
import { defineScheduleInfo } from "../../../../../app/stores/company/shiftStore";
import CommonTable from "../../../../../components/table/CommonTable";
import ShiftDetailsModal from "../../../createRequest/components/content/selectShift/components/ShiftDetailsModal";
import { useParams } from "react-router-dom";
import Clamp from "react-multiline-clamp";
import { request_details_types } from "../../../../../app/utility/const";
export default function AssignNotPlannedRequestShift() {
  const {
    setSelectedShiftIndex,
    request_details,
    available_shifts,
    selected_shift_index,
    not_planned_stops,
  } = useRequestStore();

  const { t } = useTranslation();

  const [shiftDetailsSelectIndex, setShiftDetailsSelectIndex] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const headers = [
    { name: "#", placement: "justify-center items-center" },
    { name: t("Driver") },
    { name: t("Vehicle") },
    { name: t("Date & Time") },
  ];

  const params = useParams();
  const { request_id, type } = params;

  const smallestStartTime = not_planned_stops?.reduce(
    (min, p) => (p?.start_time < min ? p?.start_time : min),
    not_planned_stops[0]?.start_time,
  );
  const largestEndTime = not_planned_stops?.reduce(
    (max, p) => (p?.end_time > max ? p?.end_time : max),
    not_planned_stops[0]?.end_time,
  );

  useEffect(() => {
    if (type === request_details_types.awarded) {
      getRequestShiftPlannerList(
        request_details?.pickup_start_time,
        request_details?.pickup_end_time,
        request_details?.pickup_date,
      );
    } else {
      getRequestShiftPlannerList(
        smallestStartTime ?? request_details?.pickup_start_time,
        largestEndTime ?? request_details?.pickup_end_time,
        request_details?.pickup_date,
      );
    }
  }, [request_details]);

  useEffect(() => {
    fetchData();
  }, [available_shifts, request_details?.shift_plan?.id]);

  const fetchData = async () => {
    if (type === request_details_types.awarded) {
      setSelectedShiftIndex(
        available_shifts.findIndex(
          (shift) => shift?.id === request_details?.shift_plan?.id,
        ),
      );
    }
  };

  const contents = available_shifts?.map((item, index) => {
    const handleRadioChange = async (e) => {
      e.stopPropagation();
      if (index === selected_shift_index) {
        setSelectedShiftIndex(null);
      } else {
        setSelectedShiftIndex(index);
      }
    };

    return {
      contents: [
        {
          value: (
            <FormControlLabel
              size="small"
              checked={index === selected_shift_index}
              value={true}
              control={
                <Radio
                  style={{
                    color:
                      index === selected_shift_index ? "#60B436" : "#939D98",
                    maxWidth: "10px",
                    minWidth: "10px",
                    height: "10px",
                  }}
                />
              }
              onClick={handleRadioChange}
            />
          ),
        },
        {
          value: (
            <Clamp withTooltip lines={1}>
              {item?.shift_drivers?.length > 0
                ? item?.shift_drivers
                    ?.map((driver) =>
                      driver?.is_main_driver
                        ? driver?.driver?.name + " (M)"
                        : driver?.driver?.name,
                    )
                    .join(", ")
                : item?.driver_user?.name + " (M)" ?? "NA"}
            </Clamp>
          ),
        },
        { value: <Clamp lines={1}>{item?.car?.name}</Clamp> },
        { value: <Clamp lines={1}>{defineScheduleInfo(item)}</Clamp> },
      ],
      clickAction: async () => {
        setShiftDetailsSelectIndex(index);
        await getAdvanceCalculationData(request_id, item?.id);
        setShowModal(true);
      },
    };
  });

  return (
    <>
      <div>
        {available_shifts?.length > 0 ? (
          <CommonTable
            tableTitle={t("Select Shift")}
            tableTitleClassName={"sub-title"}
            showSearchBox={false}
            sort={false}
            showTopRightFilter={false}
            showTakeOption={false}
            showPagination={false}
            showPageCountText={true}
            tableHeaders={headers}
            tableContents={contents}
            hasClickFunction={true}
            widths="100px auto 20% 20%"
            outerPadding="p-s0"
            topRightFilterComponentOnClick={() => {}}
            paginationObject={{}}
            withClearSearch={false}
            onSearchClear={() => {}}
            searchValue={""}
            searchOnChange={(e) => {}}
            currentTakeAmount={10}
            withReloader={true}
            onReload={() => {}}
            filtered={false}
            headersFirstIndexFixApply={false}
            takeOptionOnChange={async (e) => {}}
            paginationOnClick={async (url) => {}}
          />
        ) : (
          <CommonEmptyData
            title={t("No Shift Available!")}
            details={t("No Shift Available to Plan!")}
          />
        )}
      </div>

      <ShiftDetailsModal
        showModal={showModal}
        setShowModal={setShowModal}
        selected_shift_index={shiftDetailsSelectIndex}
      />
    </>
  );
}
