// import { Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as React from 'react';
import { useEffect } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const CommonMultiSelect = ({
    dataArray = [
        'Oliver Hansen',
        'Van Henry',
        'April Tucker',
        'Ralph Hubbard',
        'Omar Alexander',
        'Carlos Abbott',
        'Miriam Wagner',
        'Bradley Wilkerson',
        'Virginia Andrews',
        'Kelly Snyder',
    ],
    label = 'Select Some items',
    value = [],
    onChange = () => { },
    disabled = false,
    required = false,
    show_asterisk = true,
}) => {
    const [personName, setPersonName] = React.useState(value);
    const [isOnFocus, setIsOnFocus] = React.useState(false);

    const selectRef = React.useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                selectRef.current.blur();
                setIsOnFocus(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            setIsOnFocus(false);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const handleChange = (event) => {
        onChange(event);
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        setPersonName(value);
    }, [value]);

    const handleClose = () => {
        setIsOnFocus(false);
    }

    return (
        // <Tooltip
        //     placement="top"
        //     title={personName ? personName?.map((item, index) => item + (index < personName?.length - 1 ? ',  ' : ' ')) : ''}
        // >
        <FormControl focused={isOnFocus}
            autoFocus={false} ref={selectRef} variant="standard"

            sx={{
                // normal label color
                "& label": {
                    color: '#48504C',
                    fontFamily: 'fEuclidLimadiRegular',
                    fontWeight: !value ? '' : 'normal',
                },

                //label color when input is focused
                "& label.Mui-focused": {
                    color: '#48504C',
                    fontWeight: 'normal',
                },


                // focused color for input with variant='standard'
                "& .MuiInput-underline:after": {
                    borderBottomColor: '#949494',
                },
                '&:hover .MuiInput-underline:after': {
                    borderBottom: disabled ? "1px solid #BCC2BF" : '2px solid #949494',
                },
                "& .MuiInputBase-input": {
                    fontSize: 14,
                    fontFamily: 'fEuclidLimadiRegular',
                    fontWeight: 'normal',
                },
                minWidth: 120,
                // marginTop: '15px',
            }}
            className='w-full h-[35px] max-w-[100%]'
        >
            <InputLabel focused={isOnFocus}
                autoFocus={false} id="demo-simple-select-standard-label">{(required && show_asterisk) ? <div>{label}<span className='text-cRed ml-s2'>*</span> </div> : label}</InputLabel>

            <Select
                select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                title={personName ? personName?.map((item, index) => item + (index < personName?.length - 1 ? ',  ' : ' ')) : ''}
                multiple
                value={personName}
                onChange={handleChange}
                disabled={disabled}
                required={required}
                focused={isOnFocus}
                autoFocus={false}
                onFocus={() => setIsOnFocus(true)}
                onBlur={() => setIsOnFocus(false)}
                renderValue={(selected) => selected.join(',  ')}
                MenuProps={MenuProps}
                SelectProps={{
                    onClose: handleClose,
                }}
                IconComponent={() => (
                    <div style={{ position: 'absolute', bottom: '3px', right: '-7.5px', padding: '0', margin: '0' }}>
                        <ArrowDropDownIcon style={{ color: '#6C7872' }} /> {/* Change the color here */}
                    </div>
                )}
            >

                {dataArray?.map((name) => (
                    <MenuItem style={{ backgroundColor: 'white' }} key={name} value={name}>
                        <Checkbox
                            sx={{
                                color: '#989898',
                                width: 'fit-content',
                                padding: 0,
                                marginLeft: '8px',
                                marginRight: '10px',
                                backgroundColor: 'transparent !important',
                                fontFamily: 'fEuclidLimadiRegular',
                                '&.Mui-checked': {
                                    color: '#285D43',
                                    fontFamily: 'fEuclidLimadiRegular',
                                },
                                '&:hover': {
                                    color: "", // replace with your desired hover color
                                },
                            }
                            }
                            checked={personName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        // </Tooltip>
    );
}

export default CommonMultiSelect