import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRequestDetailsPathChecks } from '../../../../../../app/hooks/useRequestDetailsPathChecks';
import useRequestStore, { generateSummaryContent } from '../../../../../../app/stores/others/requestStore';
import Summary from '../../../../../../components/utility/summary/Summary';

export default function RequestDetailsSummary() {

  const { request_details } = useRequestStore();

  const params = useParams();

  const { type, request_id, shift_id } = params;

  const { isAssignNotPlannedRequestShiftPath, isEditPath } = useRequestDetailsPathChecks(type, request_id, shift_id);

  return (
    <div>
      {request_details?.id &&
        <Summary content={generateSummaryContent(request_details, type, isAssignNotPlannedRequestShiftPath, isEditPath)} />
      }
    </div>
  )
}
