/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import CommonTable from '../../../../../components/table/CommonTable';
import useGeneralStore from '../../../../../app/stores/others/generalStore';
import useRequestStore, { getTableViewRequestsData, handleRequestOrder, isTableFiltered } from '../../../../../app/stores/others/requestStore';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import { k_requests_status, k_orderBy, k_request_paths, common_take, user_role as role } from '../../../../../app/utility/const';
import { kuGetRequestsNew } from '../../../../../app/urls/commonUrl';
import { formatDateTime } from '../../../../../app/utility/utilityFunctions';
import CompletedFilter from './components/CompletedFilter';
import Clamp from 'react-multiline-clamp';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

export default function Completed({ tableTitleClassName }) {

  const { completed, search_key, setSearchKey, request_take, setRequestTake, setRequestApiUrl, setRequestsOrder, setIsAsc, request_filter_form, resetRequestFilterForm, resetFilterRange, clearFilterRange, resetRequestFilterFormCopy, is_asc, requests_order, request_filter_form_copy, hasRequestFilter } = useRequestStore();
  const { path_record, user_role } = useGeneralStore();

  const [showModal, setShowModal] = useState(false);
  const [searchValue] = useDebounce(search_key, 500);
  const navigateTo = useNavigate();
  const { t } = useTranslation();


  const headers = user_role === role.customer ? [
    { isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true},
    { isActive: requests_order === k_orderBy.customer_from, sort: is_asc, name: t("Company"), onClickAction: () => handleRequestOrder(k_orderBy.customer_from, getData), showSortImage: true},
    { isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.completed_at, sort: is_asc, name: t("Completed"), onClickAction: () => handleRequestOrder(k_orderBy.completed_at, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.budget, sort: is_asc, name: t("Budget"), onClickAction: () => handleRequestOrder(k_orderBy.budget, getData) },
  ]
    :
    [
      { isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true },
      { isActive: requests_order === k_orderBy.customer_from, sort: is_asc, name: t("Customer"), onClickAction: () => handleRequestOrder(k_orderBy.customer_from, getData), showSortImage: true},
      { isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true },
      { isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true },
      { isActive: requests_order === k_orderBy.budget, sort: is_asc, name: t("Price"), onClickAction: () => handleRequestOrder(k_orderBy.budget, getData), showSortImage: true },
      { isActive: requests_order === k_orderBy.completed_at, sort: is_asc, name: t("Completed"), onClickAction: () => handleRequestOrder(k_orderBy.completed_at, getData), showSortImage: true },
    ]
    ;

  const getData = async () => {
    const success = await getTableViewRequestsData({
      data_set: k_requests_status.completed, filter: {
        transport_type: request_filter_form?.transport_types?.map(item => item?.id),
        customer_from: request_filter_form?.customer_from ? [request_filter_form?.customer_from] : [],
        completed_to: request_filter_form?.completed_to,
        completed_from: request_filter_form?.completed_from,
        min_budget: request_filter_form?.min_budget,
        max_budget: request_filter_form?.max_budget,
      }
    })
    if (success) window.scrollTo(0, 0);
  };

  const resetTable = async () => {
    if (searchValue?.length > 0) await setSearchKey('');
    await setRequestApiUrl(kuGetRequestsNew);
    if (request_take !== common_take) await setRequestTake(common_take);
    await setRequestsOrder(k_orderBy.completed_at);
    await setIsAsc(1);
    await resetRequestFilterForm();
    await resetFilterRange();
    await clearFilterRange();
    await resetRequestFilterFormCopy();
    const success = await getTableViewRequestsData({ data_set: k_requests_status.completed, filter: {}, hasFilter: false });
    if (success) window.scrollTo(0, 0);
  }

  const handleInitialSetup = async () => {
    if (!hasRequestFilter) {
      resetTable();
    } else {
      getData();
    }
  }

  useEffect(() => { handleInitialSetup() }, []);

  useEffect(() => { getData() }, [searchValue]);

  const contents = completed?.data?.map(item => {
    return {
      contents: [
        {value: item?.request_id},
        {value: <Clamp lines={1}>{item?.creator?.user_role === role.company ? t("Created by company") : item?.customer_from ?? ''}</Clamp>},
        {value: item?.transport_type},
        {value: <Tooltip title={"Request | Stops | Packages"}>{`1/1 | ${item?.stops_complete_count ?? 0}/${item?.stops_count ?? 0} | ${item?.products_complete_count ?? 0}/${item?.products_count ?? 0}`}</Tooltip>},
        {value: item?.budget ? 'DKK ' + item?.budget?.toLocaleString("da-DK") : ''},
        {value: formatDateTime(item?.completed_at, item?.completed_at, false)}
      ],
      clickAction: () => navigateTo(`/requests/completed/details/${item?.id}`)
    }
  })

  return (
    <>
      <CommonTable
        tableTitle={t('Completed') + ` (${completed?.total ?? 0})`}
        showSearchBox={true}
        firstIndexColWidth='flex justify-start !w-max'
        showTopRightFilter={true}
        showTakeOption={true}
        showPagination={true}
        showPageCountText={true}
        headers={headers}
        outerPadding='p-s0'
        topRightFilterComponentOnClick={() => setShowModal(true)}
        tableTitleClassName={tableTitleClassName}
        paginationObject={completed}
        tableHeaders={headers}
        tableContents={contents}
        hasClickFunction={true}
        widths="90px auto 25% 180px 13% 13%"
        emptyDataMessage={"No completed found!"}

        withClearSearch={true}
        onSearchClear={() => { setSearchKey("") }}
        searchValue={search_key}
        searchOnChange={(e) => {
          setRequestApiUrl(kuGetRequestsNew);
          setSearchKey(e.target.value);
        }}

        currentTakeAmount={request_take}
        withReloader={true}
        onReload={resetTable}
        filtered={isTableFiltered(k_requests_status.completed, request_filter_form_copy)}
        takeOptionOnChange={async (e) => {
          await setRequestTake(e.target.value);
          await setRequestApiUrl(kuGetRequestsNew);
          getData();
        }}
        paginationOnClick={async (url) => {
          await setRequestApiUrl(url);
          getData();
        }}
      />

      <CompletedFilter showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}
