import React, { useEffect } from 'react'
import AvailableShifts from './AvailableShifts'
import useCreateRequestStore from '../../../../../../../app/stores/others/createRequestStore'
import TitleAndActions from '../../../titleAndActions/TitleAndActions';
import { useLocation, useNavigate } from 'react-router-dom';
import Stepper from '../../../../../requests/requestDetails/components/requestDetailsTopBar/components/Stepper';
import CommonTitle from '../../../../../../../components/title/CommonTitle';
import { create_request_path, create_request_steps, global_request_path, k_request_paths } from '../../../../../../../app/utility/const';
import useRequestStore from '../../../../../../../app/stores/others/requestStore';
import { removeHyphensFromString } from '../../../../../../../app/utility/utilityFunctions';

const AllShift = () => {

  const { cr_form, changeCrForm, crFormSelectedShift, setCrFormSelectedShift, current_step } = useCreateRequestStore();
  const { setFetchReqDetails } = useRequestStore()
  const location = useLocation();
  const navigateTo = useNavigate();

  useEffect(() => {
    let navigateUrl = localStorage?.getItem("goBack")
    if (current_step !== create_request_steps?.allShifts && navigateUrl) {
      setFetchReqDetails(true)
      navigateTo(navigateUrl ? navigateUrl : create_request_path, { state: { ...location?.state, is_fetch_req_details: true, isAllShiftExists: false } })
    }
  }, [current_step])

  const getPageTitle = () => {
    if(location?.state?.referrer === "awarded") return "Upcoming";
    else return `${removeHyphensFromString(location?.state?.referrer)}`
  }

  return (
    <div className='w-full'>
      {location?.state?.redirectFromRequest ?
        <div className='flex flex-col gap-1'>
          <CommonTitle
            title={getPageTitle()}
            icon={true}
            iconClass='!h-s21'
            iconMarginRight='mr-s10'
            link={
              location?.state?.referrer ?
                location?.state?.referrer === "global-request" ? global_request_path
                  : `/requests/${location?.state?.referrer}`
                : null}
          />
          <Stepper />
        </div> :
        <TitleAndActions />
      }
      <AvailableShifts
        selectedId={crFormSelectedShift}
        onChange={async (item) => {
          if (item?.id === crFormSelectedShift) {
            await changeCrForm("shift_plan", null);
            await changeCrForm("shift_id", null);
            await setCrFormSelectedShift(null)
          } else {
            await changeCrForm("shift_plan", item);
            await changeCrForm("shift_id", item?.id);
            await setCrFormSelectedShift(item?.id)
          }
        }}
      />
    </div>
  )
}

export default AllShift