import React from 'react'
import CommonModal from './CommonModal';
import { useTranslation } from 'react-i18next';
import CommonButton from '../button/CommonButton';
import { useNavigate, } from 'react-router-dom';
import useUtilityStore from '../../app/stores/others/utilityStore';

const PreviousBackConfirmModal = () => {

    const { setPreviousBackConfirmModal, isPreviousBackConfirmModal, previousBackLink } = useUtilityStore();

    const { t } = useTranslation();

    const navigateTo = useNavigate();

    return (
        <div>
            <CommonModal
                showModal={isPreviousBackConfirmModal}
                setShowModal={setPreviousBackConfirmModal}
                modalTitle={t("Confirmation")}
                mainContent={
                    <>
                        <div className='mt-s20'>{t("Are you sure you want to navigate back?")}</div>
                        <div className='flex justify-end mt-s20'>
                            <CommonButton
                                onClick={() => {
                                    setPreviousBackConfirmModal(false);
                                    previousBackLink === null && window.history.back()
                                    previousBackLink !== null && navigateTo(previousBackLink)

                                }}
                                btnLabel={t('Confirm')}
                                colorType='danger'
                            />
                        </div>
                    </>
                }
            />
        </div>
    )
}

export default PreviousBackConfirmModal