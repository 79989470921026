/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import useLayoutStore from '../../../app/stores/others/layoutStore';
import { iBrandArrowUP } from '../../../app/utility/imageImports';
import { useTranslation } from 'react-i18next';
import { getRequestCounter } from '../../../app/utility/utilityFunctions';
import { k_request_paths } from '../../../app/utility/const';
import { k_car_paths, k_driver_paths, k_shift_paths } from '../../../app/utility/AllRoute';

const ExpandableSideBarItem = ({
    title,
    onClick,
    normalIcon,
    selectedIcon,
    expandedItems = [],
    selected,
    index,
    request_counter,
}) => {
    const { isSidebarOpen, showExpandedSidebarItem, sideBarSelectItemTitle, activeSection } = useLayoutStore();

    const { t } = useTranslation();

    const { request_id } = useParams();
    const location = useLocation();

    const [isExpanded, setIsExpanded] = useState(showExpandedSidebarItem);

    let heightCalculated = (expandedItems?.length * 50) + (title === "Requests" || title === "Anmodninger" ? 60 : 60);

    const section_title = [
        "home",
        "chat",
        "create",
        "track-trace",
        "track-trace",
        "global-request",
        "notification",
    ]

    const requestTitles = Object.values(k_request_paths);

    useEffect(() => {
        const path = window?.location?.pathname;

        const isManagementOrSettings = title === "Management" || title === "Settings";
        const isSettingsOrRequests = title === "Settings" || title === "Requests";

        const isRequestsPath = path.includes("/requests");
        const isManagerPath = path.includes(k_shift_paths.list) || path.includes(k_driver_paths.list) || path.includes(k_car_paths.list);

        if ((isRequestsPath && isManagementOrSettings) || (isManagerPath && isSettingsOrRequests)) {
            setIsExpanded(false);
        } else if (path.includes(k_shift_paths.list) && title === "Management") {
            setIsExpanded(true);
        }
    }, [window?.location?.pathname, title]);

    useEffect(() => { if (selected !== index) { setIsExpanded(false) } }, [selected]);
    useEffect(() => { if (section_title?.includes(activeSection)) { setIsExpanded(false) } }, [activeSection]);

    useEffect(() => {
        if ((requestTitles?.includes(window.location.pathname)) && title === "Requests") {
            setIsExpanded(true);
        } else if ((sideBarSelectItemTitle === window.location.pathname + "Settings") && title === "Settings") {
            setIsExpanded(true);
        } else if ((sideBarSelectItemTitle === window.location.pathname + "Management") && title === "Management") {
            setIsExpanded(true);
        } else if ((window.location.pathname.includes("/requests")) && !window.location.pathname.includes("global-request") && title === "Requests") {
            setIsExpanded(true);
        }

    }, [window?.location?.pathname]);

    // const isActiveLinkExpanded = (link) => window.location.pathname.includes(link) || (link?.includes(location?.state?.referrer) && location.pathname === `/request/edit/${request_id}`) || (link?.includes(location?.state?.referrer) && location.pathname === `/request/create/shift/details`) || (link?.includes(location?.state?.referrer) && location.pathname === `/request/create/all-shift`);
    function isActiveLinkExpanded(link) {
        const currentPath = window.location.pathname;
        const referrer = location?.state?.referrer;

        if (currentPath.includes(link)) {
            return true;
        } else if (link?.includes(referrer) && currentPath === `/request/edit/${request_id}`) {
            return true;
        } else if (link?.includes(referrer) && currentPath.includes(`/request/create/shift/details`)) {
            return true;
        } else if (link?.includes(referrer) && currentPath === `/request/create/all-shift`) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div
            style={{ height: isExpanded ? heightCalculated : 44 }}
            className={` bg-cBgSelectedSideBar ${isExpanded ? `h-[${heightCalculated}px]` : "h-[44px]"}
            transition-height duration-500
            overflow-hidden capitalize rounded-br8 
            ${isExpanded === true ? "ring-2 ring-cBrand" : ""}
            ${isSidebarOpen ? "" : "w-20"}`}
            onClick={onClick}
        >
            <div className="cursor-pointer">
                <div
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}
                    className={`flex items-center h-[44px]
                    ${(!isExpanded) && "hover:bg-cPrimary100"}
                        ${isExpanded ? "" : "bg-cBgSideBar"}
                        ${isSidebarOpen ? "pl-s9  rounded-br8 justify-between" : "justify-center"}
                    `}
                >
                    <Tooltip
                        title={isSidebarOpen ? "" : title}
                        placement="right"
                        color={'#242826'}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    textTransform: "capitalize"
                                }
                            }
                        }}
                    >
                        <div className="flex items-center">
                            <div
                                className={`flex justify-center items-center rounded-br8 
                                ${isSidebarOpen ? "my-[7px] p-[5px] w-[30px] h-[30px]" : "w-s30 h-s30"}`}
                            >
                                <img
                                    className="object-contain w-s20 h-s20"
                                    src={isExpanded ? selectedIcon : normalIcon}
                                    alt=""
                                />
                            </div>

                            {isSidebarOpen ? (
                                <div className={`pl-3 text-fs14 text-left ${isExpanded ? "text-cBrand" : "text-cSideBarNormalText"} font-fw700`}>
                                    {t(title)}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </Tooltip>

                    {isSidebarOpen ? (
                        <div className="pr-5">
                            <img
                                src={iBrandArrowUP}
                                alt=""
                                className={`${isExpanded === false ? "rotate-180" : "rotate-0"} w-s18 h-s18 transition-all duration-500 ease-in-out`}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>

            <div className={`flex flex-col items-start justify-center px-2 space-y-2 ${isExpanded ? "pt-[10px] border-t-2 border-cBgSideBar" : ""}  `}>
                {
                    isExpanded &&
                    expandedItems?.map((item, index) =>
                        <Tooltip
                            color={'#242826'}
                            key={index}
                            title={isSidebarOpen ? "" : t(item?.title)}
                            placement="right"
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        textTransform: "capitalize"
                                    }
                                }
                            }}
                        >
                            <NavLink
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    item?.expandedOnClick()
                                }}
                                to={item?.link}
                                className={`cursor-pointer rounded-br8 flex items-center font-fw500 text-fs14 w-full py-s10 
                                    ${isSidebarOpen ? "pl-[7px]" : "justify-center"}
                                    ${isActiveLinkExpanded(item?.link) ?
                                        "font-fw600 bg-cBrand  text-cBaseWhite" : "text-cSideBarNormalText"}
                                    ${(isExpanded && !isActiveLinkExpanded(item?.link)) && "hover:bg-cPrimary100"}
                                `}>

                                <img
                                    src={
                                        isActiveLinkExpanded(item?.link)
                                            ?
                                            item?.selectedIcon
                                            :
                                            item?.normalIcon
                                    }
                                    alt=""
                                    className="object-contain w-s20 h-s20"
                                />
                                {
                                    isSidebarOpen ?
                                        <div className='flex justify-between w-full pr-2'>
                                            <div className="flex items-center justify-center pl-2 font-fw500 text-fs14">{t(item?.title)}</div>
                                            {
                                                getRequestCounter(request_counter, item?.name) ?
                                                    <div className='min-w-[22px] max-w-[22px] max-h-[22px] min-h-[22px] flex justify-center items-center text-fs10 rounded-fulls'>
                                                        {/* bg-cRed500 text-cBaseWhite */}
                                                        {/* {getRequestCounter(request_counter, item?.name)} */}
                                                    </div> :
                                                    <div className="min-w-[22px] max-w-[22px] max-h-[22px] min-h-[22px]">
                                                    </div>
                                            }
                                        </div>
                                        :
                                        ""
                                }

                            </NavLink>
                        </Tooltip>
                    )
                }

            </div>

        </div>
    )
}

export default ExpandableSideBarItem

