/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import CommonTitle from '../../../../components/title/CommonTitle';
import CommonButtonOutlined from '../../../../components/button/CommonButtonOutlined';
import { useTranslation } from 'react-i18next';
import useTrackTraceStore, { getTrackAndTrace } from '../../../../app/stores/others/trackTraceStore';
import { iFilterGreen, iFilterWhite } from '../../../../app/utility/imageImports';
import useUtilityStore from '../../../../app/stores/others/utilityStore';
import { isTableFiltered } from '../../../../app/stores/others/requestStore';
import { k_requests_status, user_role as role } from '../../../../app/utility/const';
import { kuTrackAndTraceList } from '../../../../app/urls/commonUrl';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import { useDebounce } from 'use-debounce';
import CommonSearchBox from '../../../../components/input/CommonSearchBox';
import { countEmptySpaces } from '../../../../app/utility/utilityFunctions';

const TrackTraceTopBar = () => {

    const { t } = useTranslation();

    const { isLoadingSearch } = useUtilityStore();

    const { user_role } = useGeneralStore();

    const { resetFilterForm, filterForm, apiUrl, setApiUrl, setTrackTraceList, searchKey, setSearchKey, setShowTraceFilterModal, copyFilterForm } = useTrackTraceStore();

    const [searchValue] = useDebounce(searchKey, 500);

    const isFilterActive = isTableFiltered(k_requests_status.trackTrace, copyFilterForm);

    const clearSearch = async () => {
        if (!isFilterActive) setTrackTraceList([])
        if (isFilterActive) await getTrackAndTrace({ isLoading: false, ...filterForm });
    }

    const handleSearch = async (e) => {
        if (searchKey) {
            if (apiUrl !== kuTrackAndTraceList) await setApiUrl(kuTrackAndTraceList);
            await getTrackAndTrace({ isLoading: false, ...filterForm });
        }
    }


    useEffect(() => {
        if (searchKey) {
            handleSearch()
        } else clearSearch();
    }, [searchValue]);

    return (
        <>
            <div className='flex justify-between mb-5'>
                <CommonTitle title={t('Track & trace')} />
                <div className="relative">
                    {
                        isFilterActive &&
                        <div className="absolute z-50 w-2 h-2 rounded-full bg-cBrand right-1 top-1"></div>}
                    <CommonButtonOutlined
                        btnLabel={t('Filter')}
                        onClick={async () => {
                            if (!isFilterActive) await resetFilterForm();
                            setShowTraceFilterModal(true)
                        }}
                        colorType="primary"
                        iconLeft={iFilterWhite}
                        iconLeftHover={iFilterGreen}
                    />
                </div>
            </div>

            <div className="flex items-center justify-center mb-4">
                <div className='w-[650px]'>
                    <CommonSearchBox
                        value={searchKey}
                        onChange={(e) => {
                            if (countEmptySpaces(e.target.value) > 0) {
                                setSearchKey('');
                            } else {
                                const trimmedValue = e.target.value.replace(/\s+/g, " ");
                                setSearchKey(trimmedValue)
                            }

                        }}
                        search_loading={isLoadingSearch}
                        fullWidth={true}
                        onSearchClear={(e) => setSearchKey("")}
                        withClearSearch={true}
                        placeholder={user_role === role.company ? t("Search after title, customer name, driver, email, package or address") :
                            t(`Search after title, company name, email, package or address`)}
                    />
                </div>
            </div>
        </>
    );
};

export default TrackTraceTopBar;