import React from 'react'
import StopsTable from '../../massImport/components/StopsTable/StopsTable'
import EditDeliveryModal from '../../../../modal/EditDeliveryModal'
import useCreateRequestStore from '../../../../../../../app/stores/others/createRequestStore'
import { useEffect } from 'react'

const TableView = () => {

    const { selectStop, deliverySelectIndex, showEditDeliveryModal, setShowEditDeliveryModal } = useCreateRequestStore();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>
            <StopsTable />
            {<EditDeliveryModal
                onFocus={(event) => event.preventDefault()}
                showModal={showEditDeliveryModal}
                setShowModal={setShowEditDeliveryModal}
                selectStop={selectStop}
                index={deliverySelectIndex ?? 0}
            />}
        </div>
    )
}

export default TableView