import React from "react";
import { secondsToHms, secondsToHmsFormat } from "../../../../app/utility/utilityFunctions";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { request_details_types } from "../../../../app/utility/const";

const ShiftSummary = ({ title = false, shiftDetailsData, outlinedBox = false }) => {
  const { t } = useTranslation();

  const params = useParams();
  const { type } = params;

  let background =
    type === request_details_types?.ongoing ||
      type === request_details_types?.completed ||
      type === request_details_types?.history
      ? ""
      : "bg-cSecondary50";

  let withBorder =
    type === request_details_types?.ongoing ||
    type === request_details_types?.completed ||
    type === request_details_types?.awarded ||
    type === request_details_types?.history;

  return (
    <div>
      {title && (
        <div className="mb-3 text-cGray800 sub-title">{t("Shift Details")}</div>
      )}
      <div className="grid w-full grid-cols-3 gap-2 md:grid-cols-6">
        <OverViewItem
          title={t("requests")}
          data={
            (shiftDetailsData?.request_completed ?? 0) +
            "/" +
            (shiftDetailsData?.request_count ?? 0)
          }
          background={outlinedBox ? "" : background}
          withBorder={withBorder}
        />
        <OverViewItem
          title={t("stops")}
          data={
            (shiftDetailsData?.stops_completed ?? 0) +
            "/" +
            (shiftDetailsData?.stops_count ?? 0)
          }
          background={outlinedBox ? "" : background}
          withBorder={withBorder}
        />
        <OverViewItem
          title={t("packages")}
          data={
            (shiftDetailsData?.products_completed ?? 0) +
            "/" +
            (shiftDetailsData?.products_count ?? 0)
          }
          background={outlinedBox ? "" : background}
          withBorder={withBorder}
        />

        <OverViewItem
          title={t("breaks")}
          data={secondsToHmsFormat(shiftDetailsData?.breaks || 0)}
          background={outlinedBox ? "" : background}
          withBorder={withBorder}
        />
        <OverViewItem
          title={t("working hours")}
          data={secondsToHmsFormat(shiftDetailsData?.work_time ?? 0)}
          background={outlinedBox ? "" : background}
          withBorder={withBorder}
        />
        <OverViewItem
          title={t("total hours")}
          data={secondsToHmsFormat(shiftDetailsData?.shift_hours ?? 0)}
          background={outlinedBox ? "" : background}
          withBorder={withBorder}
        />
      </div>
    </div>
  );
};

export default ShiftSummary;

const OverViewItem = ({
  title = "Title",
  data = "DATA",
  background = "bg-cSecondary50",
  withBorder = false,
}) => {
  return (
    <div
      className={`flex flex-col items-center justify-center px-2 py-2 rounded-sm ${background} ${withBorder ? "rounded border-[1px] border-cGray300" : ""
        }`}
    >
      <div className="font-normal leading-5 text-center capitalize text-fs12 text-cGray800">
        {title}
      </div>
      <div className="leading-6 text-fs16 text-medium text-cGray800 w-max">
        {data}
      </div>
    </div>
  );
};
