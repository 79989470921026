import axios from "axios";
import { create } from "zustand";
import { kuGetTableViewNotification, kuNotificationSeen } from "../../urls/commonUrl";
import { Toastr } from "../../utility/utilityFunctions";
import useGeneralStore from "./generalStore";
import useUtilityStore from "./utilityStore";
import { common_take } from "../../utility/const";

const { setLoading, setHasUnseenNotification } = useGeneralStore.getState();

const { setLoadingSearch } = useUtilityStore.getState();

const useNotificationStore = create((set) => ({

    notificationList: [],
    setNotificationList: (value) => set({ notificationList: value }),

    notificationDetails: {},
    setNotificationDetails: (value) => set({ notificationDetails: value }),

    //All Modal

    notificationDropDownOpen: false,
    setNotificationDropDownOpen: (value) => set({ notificationDropDownOpen: value }),

    showNotificationDetailsModal: false,
    setShowNotificationDetailsModal: (value) => set({ showNotificationDetailsModal: value }),

    // table view
    order: null,
    setOrder: (order) => {
        set({ order: order });
        return;
    },

    is_asc: 0,
    setIsAsc: (data) => {
        set({ is_asc: data });
        return;
    },

    take: common_take,
    setTake: (data) => {
        set({ take: data });
        return;
    },

    search_key: '',
    setSearchKey: (key) => {
        set({ search_key: key })
        return;
    },

    api_url: kuGetTableViewNotification,
    setApiUrl: (url) => {
        set({ api_url: url })
        return;
    },

    table_data: null,
    setTableData: (data) => set({ table_data: data }),

    showDetailsModal: false,
    setShowDetailsModal: (data) => set({ showDetailsModal: data }),

}));

export default useNotificationStore;

export const getNotification = async (hideLoading=false) => {

    const { setTableData, is_asc, take, order, search_key, api_url, setNotificationList } = useNotificationStore.getState();

    let body = {
        take: take,
        search: search_key,
        order_by: order,
        is_asc: is_asc,
    };

    console.log('body', body);

    try {
        if(!hideLoading) {if(search_key) setLoadingSearch(true); else setLoading(true)}
        const res = await axios.get(api_url ?? kuGetTableViewNotification, { params: body });
        console.log('getNotification:::', res.data);

        if (res?.data?.success) {
            setTableData(res?.data?.data);
            setNotificationList(res?.data?.data);
            if(!hideLoading){
            if(search_key) setLoadingSearch(false); else setLoading(false);}
            return true;
        } else {
            Toastr({ "message": res?.data?.message, type: 'error' });
            if(!hideLoading){
            if(search_key) setLoadingSearch(false); else setLoading(false);}
            return false;
        }
    } catch (error) {
        console.log('getNotification:::', error);
        Toastr({ "message": "An error occurred!", type: 'error' });
        if(!hideLoading) {
            if(search_key) setLoadingSearch(false); else setLoading(false);
        }
        return false;
    }
};

export const handleNotificationOrder = async (order_by, action) => {
    const { setIsAsc, is_asc, setOrder, setApiUrl, order } = useNotificationStore.getState();
    await setOrder(order_by);
    if (order !== order_by) await setIsAsc(1);
    else await setIsAsc(is_asc ? 0 : 1);
    await setApiUrl(kuGetTableViewNotification);
    const success = await action();
    if (!success) setIsAsc(is_asc ? 0 : 1);
    if (!success && order !== order_by) setIsAsc(1);
}

// Notification seen
export const notificationSeenFn = async (id, index) => {

    const { notificationList } = useNotificationStore.getState();

    try {
        // setLoading(true)
        const body = { id: id };
        const res = await axios.post(kuNotificationSeen, body);

        console.log('notificationSeenFn::::::', res.data);

        if (res?.data?.success) {
            let x = localStorage.getItem('numOfUnreadNotification').toString();
            let y = parseInt(x);
            localStorage.setItem("numOfUnreadNotification", --y);
            setHasUnseenNotification();
            getNotification(false);
        } else {
            notificationList[index].is_seen = 0;
            Toastr({ "message": res?.data?.message, type: 'error' });
        }
    } catch (error) {
        console.log('notificationSeenFn Error::::::', error);
        Toastr({ "message": "An error occurred!", type: 'error' });
        // setLoading(false);
        return false;
    }
};

export const selectNotification = (index, is_seen) => {
    const { setSelectedIndex, setSelectedNotification, notificationList, setNotificationList } = useNotificationStore.getState();
    setSelectedIndex(0);
    setSelectedNotification(notificationList[index]);
    setNotificationList([notificationList[index], ...notificationList?.filter(i => i?.id !== notificationList[index]?.id)])
    notificationList[index].is_seen = 1;
    (is_seen === 0) && notificationSeenFn(notificationList[index].id, index);
};

export const checkUnseenNotification = (notifications) => {
    let count = 0;
    for (let index = 0; index < notifications.length; index++) {
        if (notifications[index].is_seen === 0) count++;
    }
    localStorage.setItem("numOfUnreadNotification", count);
    setHasUnseenNotification();
}
