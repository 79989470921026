import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export default function BackLink({
  linksArray = [
    { label: "one", linkTo: "/test/one", linkFn: '' },
    { label: "two", linkTo: "/test/two" }
  ],
  cursorPointer = false
}) {

  const navigateTo = useNavigate();
  const location = useLocation();

  return (
    <div
      onClick={() => {
        console.log("BACK LINK ARRAY::", linksArray);
      }}
      className='flex items-center font-normal text-fs14'>
      {
        linksArray?.filter(item => item !== null)?.map((item, index) =>
          item?.label !== "" &&
          (<div key={index}>
            <div key={index} onClick={() => {
              item?.onClick && item?.onClick();
              item?.linkFn && item?.linkFn();
              item?.linkTo && !item?.isActive && navigateTo(item?.linkTo, { state: item?.state ?? location?.state });
            }}
              className={`${!item?.isActive && "cursor-pointer"}  capitalize flex items-center `} >
              {index !== 0 ?
                <div className='px-1 text-cGray600'>/</div>
                : ""}
              <div className={`${item?.isActive ? "text-cGray800" : "text-cGray600"}`}>{item?.label}</div>
            </div>
          </div>)
        )
      }
    </div>
  )
}