import React, { useRef } from 'react'
import useRequestStore from '../../../../app/stores/others/requestStore';
import { formatDateOrTime, calculateDistance, formatSchedule } from '../../../../app/utility/utilityFunctions';
import CommonEmptyData from '../../../emptyData/CommonEmptyData';
import CommonStopsList from '../../../listItems/CommonStopsList'
import { useTranslation } from 'react-i18next';
import CommonStopHeader from '../../../../views/company/shiftManager/components/CommonStopHeader';
import { googleMapLinkPrefix } from '../../../../app/utility/const';
import { useEffect } from 'react';

export default function Stops({ showModal }) {

  const { advanceCalculationData } = useRequestStore();

  const { t } = useTranslation();

  const stopRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      const scrollDiv = stopRef?.current;
      if (scrollDiv) {
        scrollDiv.scrollTop = 0;
      }
    }, 0);
  }, [showModal])


  return (
    <div>

      <div className='mt-5 mb-3'>
        <CommonStopHeader list={advanceCalculationData} />
      </div>
      <div ref={stopRef} className='grid grid-cols-1 lg-xl:gap-x-7 gap-y-7'>
        {
          advanceCalculationData?.stop_list?.length > 0 ?
            advanceCalculationData?.stop_list?.map((item, index) => (
              <div key={index} className='w-full'>
                <CommonStopsList
                  title={item?.stop_details?.title}
                  subTitleTwo={formatSchedule(item?.stop_approx_time, item?.stop_details?.start_time, item?.stop_details?.end_time) ?? "NA"}
                  subTitleOne={
                    item?.stop_details?.address ?
                      <a target='_blank'
                        href={`${googleMapLinkPrefix}${item?.stop_details?.address}`}
                        rel='noreferrer'
                        className='break-all cursor-pointer'
                      >
                        {item?.stop_details?.address}</a>
                      : "NA"
                  }
                  subTitleThree={(item?.stop_products) + ' Packages, ' + `${item?.stop_details?.duration ? `Duration: ${item?.stop_details?.duration} min` : "Duration: 0 min"}`}
                  accentBorderType={'default'}
                  routeType={item?.stop_details?.type}
                  circleColorType={item?.is_time_fit ? 'warning' : 'danger'}
                  count={item?.stop_index}
                  time={formatDateOrTime(item?.stop_approx_time)}
                  totalKm={calculateDistance(parseInt(item?.stop_distance)).distance?.toLocaleString("da-DK") + ' ' + t(calculateDistance(parseInt(item?.stop_distance))?.unit?.toLocaleString("da-DK"))}
                  topRightComponent={getTopRightComponent(item)}
                  topRightComponentType={'danger'}
                  title_max_width='500'
                />
              </div>
            ))
            :
            <CommonEmptyData title={t('No Stops Available')} />
        }
      </div>
    </div>
  )
}


const getTopRightComponent = (item) => {

  if (!item?.is_time_fit) return 'Time Conflict'
  if (item?.status === "init") return ("Not started")
  else {
    return ""
  }
}