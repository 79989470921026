import React from 'react'
import { useTranslation } from 'react-i18next';
import { iLockIcon } from '../../../../app/utility/imageImports';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import { user_role as role } from '../../../../app/utility/const';

const NoMessage = ({ textFieldCount="305px" }) => {

    const { t } = useTranslation();

    const { user_role } = useGeneralStore();

    return (
        <div
            style={{ height: textFieldCount }}
            className="flex items-center justify-center bg-cLightBlue">
            <div className='flex items-start justify-start px-5 space-x-1'>
                <img alt='' src={iLockIcon} className='' />
                <div className='flex items-center justify-center text-center text-fs14 font-fw400 text-cGrayishBlue'>
                    {
                        user_role === role.customer ?
                            t("Chat with admin, company and driver.") :
                            t("Chat with admin, customer and driver.")
                    }
                </div>
            </div>
        </div>
    )
}

export default NoMessage