/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { changePageTitle } from '../../../app/utility/utilityFunctions';
import SimpleAccordion from '../../../components/Accordion/SimpleAccordion';
import { useTranslation } from 'react-i18next';
import CommonTitle from '../../../components/title/CommonTitle';

const Faq = () => {

    useEffect(() => {
        changePageTitle(t('Settings | FAQ'));
        pageScrollTop()
    }, [])

    const pageScrollTop = async () => {
        await window.scrollTo(0, 0);
    }

    const { t } = useTranslation();

    return (
        <div>
            <CommonTitle title={t("FAQ")} />
            <div className='w-[65%] mt-4'>
                <SimpleAccordion />
            </div>
        </div>
    );
};

export default Faq;