import React from 'react'
import { useTranslation } from 'react-i18next';

export default function SummaryInfoItem({ title = 'NA', description = 'NA', className, titleClassName, onClick }) {
  const { t } = useTranslation();

  return (
    <>
      <div
        onClick={() => {
          console.log("SUMMARY INFO ITEM DSC:::", description);

        }}
        className={`flex flex-col gap-y-1 ${titleClassName}`}>
        <div className='font-fw600 text-fs10 leading-3 text-cSummeryTitle'>{title}</div>
        <div onClick={onClick} className={` overflow-clip font-fw600 text-fs14 leading-5 text-cMainBlack whitespace-nowrap truncate w-full ${className}`}>
          {description ? description : <div className='text-cSecondary50 mt-[21px]'></div>}
        </div>
      </div>
    </>

  )
}
