/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { RadioGroup } from "@mui/material";
import { useTranslation } from 'react-i18next';
import useSettingsStore, { getToggleNotificationState, handleNotificationToggle } from '../../../app/stores/others/settingsStore';
import { changePageTitle } from '../../../app/utility/utilityFunctions';
import CommonRadioItemItem from '../../../components/Radio/CommonRadioItemItem';
import CommonTitle from '../../../components/title/CommonTitle';

const Notification = () => {

    const { pushNotification, emailNotification } = useSettingsStore();

    const { t } = useTranslation();

    useEffect(() => {
        changePageTitle(t('Settings | Notification'));
        fetchData();
    }, [])

    const fetchData = async () => {
        await getToggleNotificationState();
    }


    return (
        <div>

            <CommonTitle title={t("Notification")} />

            <div className='flex space-x-[100px] mt-4 mb-5'>
                <div className='flex w-[180px] items-center justify-start font-fw500 text-fs16 text-cMainBlack'>{t("E-mail Notification")}</div>
                <RadioGroup
                    className="w-full"
                    required={true}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >
                    <div className="flex w-full space-x-5">

                        <CommonRadioItemItem
                            isActive={emailNotification}
                            checked={emailNotification}
                            value="en"
                            required={true}
                            label={t("On")}
                            onChange={(e) => { handleNotificationToggle({ "is_email": true }, "is_email") }}
                        />

                        <CommonRadioItemItem
                            isActive={!emailNotification}
                            checked={!emailNotification}
                            value="da"
                            required={true}
                            label={t("Off")}
                            onChange={(e) => {
                                handleNotificationToggle({ "is_email": false }, "is_email")
                            }}
                        />
                    </div>
                </RadioGroup>
            </div>


            <div className='flex space-x-[100px]'>
                <div className='flex items-center justify-start w-[180px] font-fw500 text-fs16 text-cMainBlack'>{t("Push Notification")}</div>
                <RadioGroup
                    className="w-full"
                    required={true}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >
                    <div className="flex w-full space-x-5">

                        <CommonRadioItemItem
                            isActive={pushNotification}
                            checked={pushNotification}
                            value="en"
                            required={true}
                            label={t("On")}
                            onChange={(e) => { handleNotificationToggle({ "is_push": true }, "is_push") }}
                        />

                        <CommonRadioItemItem
                            isActive={!pushNotification}
                            checked={!pushNotification}
                            value="da"
                            required={true}
                            label={t("Off")}
                            onChange={(e) => {
                                handleNotificationToggle({ "is_push": false }, "is_push")
                            }}
                        />
                    </div>
                </RadioGroup>
            </div>
        </div>
    );
};

export default Notification;