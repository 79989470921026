import React from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const ShiftInstruction = ({ shiftDetailsData }) => {

    const { t } = useTranslation();

    const { shift_id } = useParams();

    const isShiftDetailsPath = window.location.pathname === `/shift-manager/details/${shift_id}`


    return (
        <div>
            {
                shiftDetailsData?.is_maintenance === 1 ? "" :
                    <>
                        {
                            shiftDetailsData?.comment ?
                                <div>
                                    <div className={`mb-1 item-title  text-cGray800`}>
                                        {t("Shift Instruction")}
                                    </div>
                                    <div className="w-full font-normal break-all whitespace-pre-line text-cGray800 text-fs14 leading-[25.2px]">{shiftDetailsData?.comment ?? "NA"}</div>
                                </div> :
                                <div className='p-3 font-normal text-center border-2 rounded-lg border-cGray200 text-fs14 leading-[25.2px] text-cGray500'>
                                    {t("No shift instruction!")}
                                </div>
                        }
                    </>
            }
        </div>
    )
}

export default ShiftInstruction