/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CommonTopTitleSection from '../../../../components/title/CommonTopTitleSection';
import CommonSearchBox from '../../../../components/input/CommonSearchBox';
import useFavoriteCompaniesStore, { addFavoriteCompany, getNotFavoriteCompany } from '../../../../app/stores/customer/favoriteCompaniesStore';
import { useDebounce } from 'use-debounce';
import CompanyListItem from '../../../../components/listItems/CompanyListItem';
import { iFavCompanyGray } from '../../../../app/utility/imageImports';
import CommonCompanyDetailsModal from '../../../../components/modal/CommonCompanyDetailsModal';
import RatingChipContent from '../../../common/createRequest/components/content/selectCompany/components/RatingChipContent';
import CommonEmptyData from '../../../../components/emptyData/CommonEmptyData';
import CommonButtonOutlined from '../../../../components/button/CommonButtonOutlined';
import { useNavigate } from 'react-router-dom';
import { k_fav_company_paths } from '../../../../app/utility/AllRoute';

const AddFavoriteCompanies = () => {

    const { t } = useTranslation();

    let { searchRating, setSearchRating, showAddFavoriteCompaniesModal, notFavoriteCompanyList, searchValueNotFavoriteCompany, setSearchValueNotFavoriteCompany } = useFavoriteCompaniesStore();

    const [showModal, setShowModal] = React.useState(false);

    const [company_id, setCompanyId] = React.useState(null);

    const navigateTo = useNavigate();

    useEffect(() => {
        if (searchValueNotFavoriteCompany !== "" || searchValueNotFavoriteCompany !== null) setSearchValueNotFavoriteCompany("")
        if (searchRating !== 0) { setSearchRating(0) }
        if (showAddFavoriteCompaniesModal) {
            getNotFavoriteCompany();
        }
    }, [])


    const [searchValue] = useDebounce(searchValueNotFavoriteCompany, 500);

    useEffect(() => {

        let stars = [];
        for (let i = searchRating; i <= 5; i++) {
            stars?.push(i);
        }
        if (searchRating === 0) getNotFavoriteCompany(searchValue, []);
        else getNotFavoriteCompany(searchValue, stars);

    }, [searchValue, searchRating])

    return (
        <div>
            <CommonCompanyDetailsModal showModal={showModal} setShowModal={setShowModal} company_id={company_id} bidding_details={false} />
            <div className='flex justify-between'>
                <CommonTopTitleSection withBackLink={k_fav_company_paths.list} title={t("Add Favorite Companies")} />
                <CommonButtonOutlined colorType='danger' onClick={() => { navigateTo(k_fav_company_paths.list) }} btnLabel={t("Cancel")} />
            </div>

            <div className='flex justify-between mt-4 w-[70%]'>
                <CommonSearchBox
                    name="searchKey"
                    fullWidth={false}
                    onSearchClear={() => { setSearchValueNotFavoriteCompany("") }}
                    withClearSearch={true}
                    value={searchValueNotFavoriteCompany}
                    onChange={(e) => { setSearchValueNotFavoriteCompany(e.target.value) }}
                />

                <div className='flex space-x-2'>
                    <div className='py-2 border-2 rounded px-s10 w-fit' >
                        <RatingChipContent value={searchRating}
                            onChange={(event, newValue) => {
                                setSearchRating(newValue)
                            }}
                        />
                    </div>
                </div>
            </div>


            <div className='mt-6 mb-4 sub-title text-cGray800'>{t("Suggestions")} ({notFavoriteCompanyList?.length ?? 0})</div>

            {
                notFavoriteCompanyList?.length > 0 ?
                    <div className='space-y-2 w-[70%]'>
                        {
                            notFavoriteCompanyList?.map((company, index) => (
                                <CompanyListItem
                                    key={index}
                                    title={company?.name}
                                    rating={company?.rate}
                                    image={company?.image}
                                    dummyImage={iFavCompanyGray}
                                    is_favorite={company?.is_fav}
                                    has_action_btn={false}
                                    is_add_type={true}
                                    withCheckbox={false}
                                    onClick={() => {
                                        setCompanyId(company?.id);
                                        setShowModal(true);
                                    }}
                                    topRightComponent={
                                        <>
                                            <div
                                                onClick={async (e) => {
                                                    e.stopPropagation();
                                                    await addFavoriteCompany(company?.id);
                                                }}
                                                className='font-medium cursor-pointer text-fs16'>
                                                {
                                                    parseInt(company?.is_fav) === 0 ?
                                                        <div className='text-cBrand'>{t("Add Favorite")}</div>
                                                        :
                                                        <div btnLabel={t('Remove')} className='text-cRed500'>{t("Remove Favorite")}</div>
                                                }

                                            </div>
                                        </>
                                    }
                                />
                            ))}
                    </div>
                    :
                    <CommonEmptyData title={t("No company found!")} />
            }

        </div>
    )
}

export default AddFavoriteCompanies