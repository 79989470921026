import React from 'react'

const CommonDetailsItem = ({ title = "NA", value = "NA" }) => {
    return (
        <div className='text-cMainBlack text-fs14 mb-s2'>
            <span className='font-fw600'>{title}: </span>
            <span className='break-all font-fw500 whitespace-pre-line'>{value ? value : 'NA'}</span>
        </div>
    )
}

export default CommonDetailsItem