/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import CommonModal from '../../../../components/modal/CommonModal';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/button/CommonButton';
import useDriverStore, { driverChangePassword } from '../../../../app/stores/company/driverStore';
import CommonInput from '../../../../components/input/CommonInput';

function DriverResetPasswordModal() {

    const { t } = useTranslation();

    const { resetPasswordForm, changeResetPasswordForm, showDriverResetPasswordModal, setDriverResetPasswordModal, driverDetails, resetResetPasswordForm } = useDriverStore();

    const handleResetPassword = async() => {
        const success =await driverChangePassword(driverDetails?.user_id);
        if (success) {
            setDriverResetPasswordModal(false);
            resetResetPasswordForm()
        }
    }

    return (
        <div>
            <CommonModal
                showModal={showDriverResetPasswordModal}
                setShowModal={setDriverResetPasswordModal}
                modalTitle={t("Reset Password")}
                mainContent={
                    <>
                        <form>
                            <CommonInput
                                labelText={t('Password')}
                                value={resetPasswordForm?.password}
                                type='password'
                                name={'password'}
                                required={true}
                                max_input={55}
                                onChange={(e) => { changeResetPasswordForm(e) }}
                            />
                            <div className='mt-s5'></div>
                            <CommonInput
                                labelText={t('Confirm password')}
                                value={resetPasswordForm?.password_confirmation}
                                type='password'
                                name={'password_confirmation'}
                                required={true}
                                max_input={55}
                                onChange={(e) => { changeResetPasswordForm(e) }}
                            />
                        </form>

                        <div className='flex justify-end mt-5'>
                            <CommonButton
                                onClick={handleResetPassword}
                                btnLabel={t('Confirm')}
                                colorType='danger'
                            />
                        </div>
                    </>
                }
            />
        </div>
    )
}

export default DriverResetPasswordModal
