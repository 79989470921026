import React from "react";
import { useTranslation } from "react-i18next";
import { calculateDistance } from "../../../../app/utility/utilityFunctions";

const CommonStopHeader = ({ list = [] }) => {
    const { t } = useTranslation();

    // const calculateTotalKm = (list) => {
    //     return list.reduce((total, item) => {
    //         if (item?.status === k_shift_stop_status?.hold) {
    //             return total;
    //         }
    //         return total + (parseInt(item?.distance || item?.stop_distance) ?? 0);
    //     }, 0);
    // };

    // const calculateDuration = (list) => {
    //     return list.reduce((total, item) => {
    //         if (item?.status === k_shift_stop_status?.hold) {
    //             return total;
    //         }
    //         return total + (item?.stop_details?.duration ? parseInt(item?.stop_details?.duration) : 0);

    //     }, 0);
    // };

    function formatDuration(duration) {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        return `${hours > 0 ? `${hours} hrs` : ""}${minutes > 0 ? ` ${minutes} min` : ""
            }`;
    }

    return (
        <div className="flex items-center justify-between w-full">
            <div className="font-medium text-fs20 text-cGray800">
                {/* {`${t("Stops")} (` + (list?.stop_list?.length ?? 0) + ')'} */}
                {t("Calculated Stops")}
            </div>
            <div>
                {list?.stop_list?.length > 0 ? (
                    <div className="flex items-center justify-end font-normal text-fs16 text-cGray800">
                        {calculateDistance(list?.distance)?.distance?.toLocaleString("da-DK")}
                        {" " +
                            calculateDistance(list?.distance)?.unit?.toLocaleString("da-DK")}
                        {list?.duration ? "," : ""}
                        {/* {' ' + calculateDuration(list?.stop_list) + ' ' + t('min')} */}
                        {" " + formatDuration(list?.duration)}
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default CommonStopHeader;
