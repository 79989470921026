import axios from "axios";
import { create } from "zustand";
import { kuEditDriver, kuAddDriver, kuDeleteDriver, kuDriverTableData, kuDriverDetails, kuDriverChangePassword } from "../../urls/companyUrl";
import { Toastr, validatePassword } from "../../utility/utilityFunctions";
import useGeneralStore from "../others/generalStore";
import useUtilityStore from "../others/utilityStore";
import { t } from "i18next";
import { common_take } from "../../utility/const";
const { setLoading } = useGeneralStore.getState();
const { setLoadingSearch } = useUtilityStore.getState();

const useDriverStore = create((set, get) => ({

  driverList: [],
  setDriverList: (value) => set({ driverList: value }),

  driverDetails: {},
  setDriverDetails: (value) => set({ driverDetails: value }),

  driverSearchValue: "",
  setDriverSearchValue: (value) => set({ driverSearchValue: value }),

  addDriver_form: { email: "", name: "", phone: "", comment: "" },
  changeAddDriverForm: (e) => set({
    addDriver_form: { ...get().addDriver_form, [e.target.name]: e.target.value }
  }),
  setAddDriver_form: (value) => set({ addDriver_form: value }),


  editDriver_form: { id: "", email: "", name: "", phone: "", comment: "" },
  changeEditDriverForm: (e) => set({ editDriver_form: { ...get().editDriver_form, [e.target.name]: e.target.value } }),
  setEditDriver_form: (value) => set({ editDriver_form: value }),



  resetPasswordForm: { password: "", password_confirmation: "" },
  changeResetPasswordForm: (e) => set({ resetPasswordForm: { ...get().resetPasswordForm, [e.target.name]: e.target.value } }),
  resetResetPasswordForm: () => set({
    resetPasswordForm: {
      password: "", password_confirmation: ""
    }
  }),

  //All Modal
  showAddDriverModal: false,
  setShowAddDriverModal: (value) => set({ showAddDriverModal: value }),

  showDriverResetPasswordModal: false,
  setDriverResetPasswordModal: (value) => set({ showDriverResetPasswordModal: value }),

  showDriverDeleteModal: false,
  setShowDriverDeleteModal: (value) => set({ showDriverDeleteModal: value }),

  // table view
  order: null,
  setOrder: (order) => {
    set({ order: order });
    return;
  },

  is_asc: 1,
  setIsAsc: (data) => {
    set({ is_asc: data });
    return;
  },

  take: common_take,
  setTake: (data) => {
    set({ take: data });
    return;
  },

  search_key: null,
  setSearchKey: (key) => {
    set({ search_key: key })
    return;
  },

  api_url: kuDriverTableData,
  setApiUrl: (url) => {
    set({ api_url: url })
    return;
  },

  driver_table_data: { data: [], current_page: 1 },
  setDriverTableData: (data) => set({ driver_table_data: data }),

  showDetailsModal: false,
  setShowDetailsModal: (data) => set({ showDetailsModal: data })

}));

export default useDriverStore;

// driver list
export const getDriversTableData = async (isLoading = true) => {

  const { setDriverTableData, is_asc, take, order, search_key, api_url } = useDriverStore.getState();

  let body = {
    take: take,
    search: search_key,
    order_by: order,
    is_asc: is_asc,
  };

  console.log('body', body);

  try {
    if (search_key) { if (isLoading) setLoadingSearch(true) } else { setLoading(true) }
    const res = await axios.get(api_url ?? kuDriverTableData, { params: body });
    console.log('getDrivers:::', res.data);

    if (res?.data?.success) {
      setDriverTableData(res?.data?.data)
      return true;
    } else {
      Toastr({ "message": res?.data?.message, type: 'error' });
      return false;
    }
  } catch (error) {
    console.log('getDrivers:::', error);
    Toastr({ "message": t("An error occurred!"), type: 'error' });
    if (search_key === "") { setLoading(false) } else { setLoadingSearch(false) }
    return false;
  } finally {
    if (search_key) { setLoadingSearch(false) } else { setLoading(false) }
  }
};

// add Driver
export const addDriver = async () => {

  const { addDriver_form } = useDriverStore.getState();

  try {
    setLoading(true)
    const res = await axios.post(kuAddDriver, addDriver_form);
    console.log('addDriver:::', res.data);
    if (res?.data?.success) {
      Toastr({ "message": res?.data?.message, type: 'success' });
      return true;
    } else {
      Toastr({ "message": res?.data?.message, type: 'error' });
      return false
    }
  } catch (error) {
    console.log('addDriver:::', error);
    Toastr({ "message": t("An error occurred!"), type: 'error' });
    return false;
  } finally {
    setLoading(false);
  }
};

// edit Driver
export const editDriver = async () => {

  const { addDriver_form } = useDriverStore.getState();

  try {
    setLoading(true)
    const res = await axios.post(kuEditDriver, addDriver_form);
    console.log('editDriver:::', res.data);
    if (res?.data?.success) {
      Toastr({ "message": res?.data?.message, type: 'success' });
      return true;
    } else {
      Toastr({ "message": res?.data?.message, type: 'error' });
      return false;
    }

  } catch (error) {
    console.log('editDriver:::', error);
    Toastr({ "message": t("An error occurred!"), type: 'error' });
    return false;
  } finally {
    setLoading(false);
  }
};

// Delete Driver
export const deleteDriver = async (driver_id) => {

  const { setApiUrl } = useDriverStore.getState();

  try {
    setLoading(true);
    const res = await axios.post(kuDeleteDriver, { id: driver_id, deleted_by: "company" });
    console.log('deleteDriver:::', res.data);

    if (res?.data?.success) {
      Toastr({ "message": "Delete driver successfully", type: 'success' });
      await setApiUrl(kuDriverTableData);
      await getDriversTableData();
      return true;
    }

    Toastr({ "message": res?.data?.message, type: 'error' });
    return false;

  } catch (error) {
    console.log('deleteDriver:::', error);
    Toastr({ "message": t("An error occurred!"), type: 'error' });
    return false;
  } finally {
    setLoading(false);
  }
};


export const handleOrder = async (order_by, action) => {
  const { setIsAsc, is_asc, setOrder, setApiUrl, order } = useDriverStore.getState();
  await setOrder(order_by);
  if (order !== order_by) await setIsAsc(1);
  else await setIsAsc(is_asc ? 0 : 1);
  await setApiUrl(kuDriverTableData);
  const success = await action();
  if (!success) setIsAsc(is_asc ? 0 : 1);
  if (!success && order !== order_by) setIsAsc(1);
}

// get Driver Details
export const getDriverDetails = async (driver_id) => {

  const { setDriverDetails } = useDriverStore.getState();

  try {
    setLoading(true)
    const res = await axios.get(kuDriverDetails, { params: { id: driver_id } });
    console.log('getDriverDetails:::', res.data);
    if (res?.data?.success) {
      await setDriverDetails(res?.data?.data)
      return res?.data?.data;
    } else {
      Toastr({ "message": res?.data?.message, type: 'error' });
      return false
    }

  } catch (error) {
    console.log('getDriverDetails:::', error);
    Toastr({ "message": t("An error occurred!"), type: 'error' });
    return false;
  } finally {
    setLoading(false);
  }
};


// driver change password

export const driverChangePassword = async (driver_user_id) => {
  const { resetPasswordForm } = useDriverStore.getState();
  const validationResult = await validatePassword(resetPasswordForm?.password, resetPasswordForm?.password_confirmation);

  if (!validationResult.valid) {
    Toastr({ message: validationResult.message, type: "warning" });
    return false;
  }

  try {
    setLoading(true);
    const res = await axios.post(kuDriverChangePassword, { ...resetPasswordForm, user_id: driver_user_id });
    if (res?.data?.success) {
      Toastr({ "message": res?.data?.message, type: 'success' });
      return true;
    } else {
      Toastr({ "message": res?.data?.message, type: 'error' });
      return false;
    }
  } catch (error) {
    Toastr({ "message": t("An error occurred!"), type: 'error' });
    return false;
  } finally {
    setLoading(false);
  }
};




export const resetForm = (setAddDriver_form) => {
  setAddDriver_form({ name: "", email: "", phone: "", comment: "" });
};


export const setDriverFormData = (data) => {
  const { setAddDriver_form } = useDriverStore.getState();
  setAddDriver_form({
    id: data?.id,
    name: data?.name,
    email: data?.email,
    phone: data?.phone_from_driver,
    comment: data?.comment ? data?.comment : "",
    // image: data?.image
  })
};