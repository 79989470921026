/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Outlet } from "react-router-dom";

const Settings = (props) => {

    return (
        <div>
            {props?.children}
            <Outlet />
        </div>
    );
};

export default Settings;
