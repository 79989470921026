/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Slider } from '@mui/material'
import useRequestStore from '../../app/stores/others/requestStore';
import { useTranslation } from 'react-i18next';

export default function FilterRange({ className, max = 1500, min = 0, defaultValue = 100, value, onChange }) {
  const { changeFilterRange, filter_range, changeRequestFilterForm } = useRequestStore.getState();
  const { t } = useTranslation();

  const handleMinMaxChange = (e, type) => {
    const x = parseInt(e.target.value.toString());
    let value = filter_range?.value;
    type === 'min' ? value[0] = x : value[1] = x;
    if (x < min || !x) type === 'min' ? value[0] = min : value[1] = max;
    if (x > max || !x) type === 'min' ? value[0] = min : value[1] = max;
    if (x < value[0] || x > value[1]) type === 'min' ? value[0] = min : value[1] = max;


    changeFilterRange('filter_range', value);
    changeRequestFilterForm('min_budget', value[0]);
    changeRequestFilterForm('max_budget', value[1]);
  }

  return (
    <div className={`w-full flex flex-col justify-start items-start ${className}`}>
      <div className='text-fs12 font-fw400 text-cGrey'>{t('Budget Range')}</div>

      <div className='w-full px-[9.5px]'>
        <Slider
          min={min}
          max={max}
          defaultValue={defaultValue}
          aria-label="Default"
          valueLabelDisplay="auto"

          sx={{
            color: '#285D43',
            boxShadow: 'none',

            '& .MuiSlider-thumb': {
              color: '#285D43',
              maxHeight: 20,
              minHeight: 20,
              maxWidth: 20,
              minWidth: 20,
              '&:hover': {
                color: "", // replace with your desired hover color
                boxShadow: 'none',
              },
              boxShadow: 'none',
              '&:hover, &:focus': {
                boxShadow: 'none',
              },
            },
            '& .MuiSlider-thumb.Mui-focusVisible': {
              boxShadow: 'none',
            },
            '& .MuiSlider-thumb:hover': {
              boxShadow: 'none',
            },
            '& .MuiSlider-thumb::after': {
              boxShadow: 'none',
            },
            '& .MuiSlider-rail': {
              opacity: 0.28,
              hover: "none",
              boxShadow: 'none',
            },
            '& .MuiSlider-track': {
              height: 4,

              hover: "none",
              boxShadow: 'none',
              '&:hover': {
                color: "", // replace with your desired hover color
              },
            },
            '& .MuiSlider-mark': {
              backgroundColor: '#bfbfbf',
            },
            '& .MuiSlider-markActive': {
              backgroundColor: '',
              hover: "none",
              boxShadow: 'none',
            },
          }}

          onChange={onChange}
          value={value}
        />
      </div>
      <div className='flex flex-row items-center justify-between w-full space-x-4 text-fs14 font-fw400 limadi-font-regular'>
        <div className='flex flex-row items-center justify-start space-x-1'>
          <div>DKK {filter_range?.value[0]}</div>
          {/* <input className='py-1 outline-none bg-cMainWhite w-s100 px-s2 border-cGrey' type="number" value={filter_range?.value[0]} onChange={(e) => handleMinMaxChange(e, 'min')} /> */}
        </div>

        <div className='flex flex-row-reverse items-center justify-end gap-1'>
          {/* <input className={`py-1 outline-none bg-cMainWhite border-cGrey float-right `} type="number" 
          style={{ maxWidth:  filter_range?.max?.toString()?.length + "ch", width: filter_range?.value[1]?.toString()?.length + "ch"}}
          value={filter_range?.value[1]} onChange={(e) => handleMinMaxChange(e, 'max')} /> */}
          <div>DKK {filter_range?.value[1]}</div>
        </div>

      </div>
    </div>
  )
}
