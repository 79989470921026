/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDebounce } from 'use-debounce';
import { car_manager_path, common_take, k_orderBy } from '../../../app/utility/const';
import CommonTable from '../../../components/table/CommonTable';
import CommonButton from '../../../components/button/CommonButton';
import { kuAllCarTableData } from '../../../app/urls/companyUrl';
import useCarStore, { getCarsTableData, getLicenseStatusText, handleOrder } from '../../../app/stores/company/carStore';
import Clamp from 'react-multiline-clamp';
import { useTranslation } from 'react-i18next';
import { changePageTitle } from '../../../app/utility/utilityFunctions';
import { useNavigate } from 'react-router-dom';
import useGeneralStore from '../../../app/stores/others/generalStore';

export default function CarManager() {

  const { car_table_data, search_key, setSearchKey, take, setTake, setApiUrl, setOrder, setIsAsc, is_asc, order, setAddCarForm, setCarDetails, setUpdateCarForm } = useCarStore();

  const [searchValue] = useDebounce(search_key, 500);

  const { t } = useTranslation();

  const navigateTo = useNavigate();

  const { path_record } = useGeneralStore();

  useEffect(() => {
    changePageTitle(t('Limadi | Car Manager'));
  }, [])

  const headers = [
    { name: "#", placement: "justify-center items-center" },

    { isActive: order === k_orderBy.name, sort: is_asc, name: t("Name"), onClickAction: () => handleOrder(k_orderBy.name, getCarsTableData), showSortImage: true },

    { isActive: order === k_orderBy.car_license_plate_number, sort: is_asc, name: t("License"), onClickAction: () => handleOrder(k_orderBy.car_license_plate_number, getCarsTableData), showSortImage: true},

    { isActive: order === k_orderBy.license_start_raw, sort: is_asc, name: t("Duration"), onClickAction: () => handleOrder(k_orderBy.license_start_raw, getCarsTableData), showSortImage: true},

    { isActive: order === k_orderBy.license_status, sort: is_asc, name: t("Status"), onClickAction: () => handleOrder(k_orderBy.license_status, getCarsTableData), showSortImage: true},
  ];


  const resetTable = async () => {
    if (searchValue?.length > 0) await setSearchKey('');
    await setApiUrl(kuAllCarTableData);
    if (take !== common_take) await await setTake(common_take);
    await setOrder(null);
    await setIsAsc(1);
    getCarsTableData();
  }

  const handleInitialSetup = async () => {
    if (!path_record?.old?.toLowerCase().includes(car_manager_path.edit) && !path_record?.old?.toLowerCase().includes(car_manager_path.details)) {
      await resetTable();
    } else {
      getCarsTableData();
    }

  }

  useEffect(() => { handleInitialSetup() }, []);

  useEffect(() => {
    if ((!path_record?.old?.toLowerCase().includes(car_manager_path.edit) && !path_record?.old?.toLowerCase().includes(car_manager_path.details))) {
      setApiUrl(kuAllCarTableData);
    }
    getCarsTableData()
  }, [searchValue]);

  // console.log("path_record", path_record)

  const contents = car_table_data?.data?.map((item, index) => {
    return {
      contents: [
        {value: (car_table_data?.current_page * common_take - common_take) + index + 1},
        {value: <Clamp lines={2}> {item?.name ?? ''}</Clamp>},
        {value: item?.car_license_plate_number},
        {value: ((item?.license_start ?? '') + ' - ' + (item?.license_end ?? ''))},
        {value: getLicenseStatusText(item?.license_status), className: "capitalize"},
      ],
      clickAction: () => {
        setCarDetails(item);
        setUpdateCarForm(item);
        navigateTo(car_manager_path.details + item?.id)
      }
    }
  })

  return (
    <>
      <CommonTable
        tableTitle={t('Car') + ` (${car_table_data?.total ?? 0})`}
        showSearchBox={true}
        showTakeOption={true}
        showPagination={true}
        showPageCountText={true}
        showTopRightFilter={false}
        headers={headers}
        outerPadding='p-s0'
        tableTitleClassName={'title'}
        paginationObject={car_table_data}

        tableHeaders={headers}
        tableContents={contents}
        hasClickFunction={true}
        widths="50px auto 12% 20% 10%"

        withClearSearch={true}
        onSearchClear={() => { setSearchKey("") }}
        searchValue={search_key}
        searchOnChange={(e) => {
          setApiUrl(kuAllCarTableData);
          setSearchKey(e.target.value);
        }}

        currentTakeAmount={take}
        withReloader={true}
        onReload={resetTable}
        takeOptionOnChange={async (e) => {
          await setTake(e.target.value);
          await setApiUrl(kuAllCarTableData);
          getCarsTableData();
        }}
        paginationOnClick={async (url) => {
          await setApiUrl(url);
          getCarsTableData();
        }}
        topRightMainComponent={
          <>
            <CommonButton
              btnLabel={t('Add Car')}
              width='w-[120px]'
              type='button'
              onClick={async () => {
                await setAddCarForm({
                  id: 0,
                  name: '',
                  license_id: '',
                  car_license_plate_number: '',
                  license_start: '',
                  image: '',
                  comment: '',
                })
                navigateTo(car_manager_path.add)
              }} />
          </>
        }

      />
    </>
  )
}
