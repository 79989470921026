import React, { } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useRequestStore, { declineRequestInvitation } from '../../../../../../../../app/stores/others/requestStore';
import CommonButton from '../../../../../../../../components/button/CommonButton';
import CommonInput from '../../../../../../../../components/input/CommonInput';
import CommonModal from '../../../../../../../../components/modal/CommonModal';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Toastr } from '../../../../../../../../app/utility/utilityFunctions';

const DeclineInvitationConfirmModal = ({ submitButtonText, title, request_id, showModal, setShowModal }) => {

  const navigateTo = useNavigate();
  const [comment, setComment] = React.useState('');
  const params = useParams();
  const { type } = params;
  const { t } = useTranslation();
  const { resetPlaceBidForm } = useRequestStore();

  const modalTitle = title ?? t("Decline Request")

  const modalSubmitButtonText = submitButtonText ?? t('Decline')

  const submitForm = async (e) => {
    e.preventDefault();
    if (!comment.trim()) {
      // Show a toast message and return from the function
      Toastr({ message: 'Please enter a Reason.', type: "warning" });
      return;
    }
    const success = await declineRequestInvitation(request_id, comment);
    if (success) {
      setShowModal(false);
      navigateTo(`/requests/${type}`);
      setComment('');
      resetPlaceBidForm();
    }

  }

  useEffect(() => {
    setComment("")
  }, [showModal])

  return (
    <div>
      <CommonModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle={modalTitle}
        widthClass="w-[50vw]"
        mainContent={
          <>
            {/* <div className='mt-s20 pb-s10'>{t('Do you want to decline this invitation?')}</div> */}

            <form onSubmit={submitForm}>

              <CommonInput
                className={'mb-2'}
                name={'comment'}
                value={comment}
                onChange={(e) => {
                  if (e.target.value !== " ") {
                    const trimmedValue = e.target.value.replace(/\s+/g, " ");
                    setComment(trimmedValue)
                  }
                }}
                labelText={t('Reason')} textarea={true}
                type={'text'}
                max_input={255}
                required={true}
                show_asterisk={false}
              />

              <div className='flex justify-end mt-s30'>
                <CommonButton
                  btnLabel={modalSubmitButtonText}
                  colorType='danger'
                  width='w-[100px]'
                  type='submit'
                />

              </div>
            </form>
          </>
        }
      />
    </div>
  );
};

export default DeclineInvitationConfirmModal;