/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useRequestDetailsPathChecks } from "../../../../../../../../app/hooks/useRequestDetailsPathChecks.js";
import useCreateRequestStore from "../../../../../../../../app/stores/others/createRequestStore.js";
import useGeneralStore from "../../../../../../../../app/stores/others/generalStore";
import useRequestStore, {
  checkIsShowDeliveryOverview
} from "../../../../../../../../app/stores/others/requestStore";
import {
  create_request_steps,
  request_details_types,
  user_role as role,
} from "../../../../../../../../app/utility/const";
import { iCrossOutlined } from "../../../../../../../../app/utility/imageImports.js";
import {
  addDaysToDate,
  addMinutesToTime,
  compareTime,
  extModifierDateTime,
  formatDate,
  formatTime,
  getStringFromDateObject,
  isEndTimeSmaller,
  isFutureDateTime,
  isStartDateValid,
  Toastr
} from "../../../../../../../../app/utility/utilityFunctions";
import RequestAccordion from "../../../../../../../../components/Accordion/RequestAccordion.jsx";
import ImageViewer from "../../../../../../../../components/image/ImageViewer";
import CommonDatePicker from "../../../../../../../../components/input/CommonDatePicker.jsx";
import CommonInput from "../../../../../../../../components/input/CommonInput.jsx";
import CommonTimePicker from "../../../../../../../../components/input/CommonTimePicker";
import CommonViewComponent from "../../../../../../../../components/viewer/CommonViewComponent";
import CommonEmptyView from "../../../../../../createRequest/components/content/selectShift/components/CommonEmptyView.js";
import DeliveryOverview from "./components/DeliveryOverview";

export default function Deliveries({ data, showTableViewButton = false }) { //data = request_details object!
  const params = useParams();
  const { type, request_id } = params;
  const {
    updateStopInfoWithId,
    not_planned_stops,
    request_details,
    setNotPlannedStops,
    setShowRequestTableViewModal,
    pickup_duration,
    fetchReqDetails
  } = useRequestStore();

  const location = useLocation();
  const { t } = useTranslation();
  const { user_role } = useGeneralStore();

  const [totalProducts, setTotalProducts] = useState(0);
  const [invalidStartTime, setInvalidStartTime] = useState(false);
  const [invalidEndTime, setInvalidEndTime] = useState(false);

  const addressValidation = (address) => {
    let x = address?.replace(/, 0000/g, "");
    let y = x?.replace(/, 0/g, "");
    return y?.length > 0 ? y : null;
  };

  const calculateDeliveryProducts = (stops) => {
    let totalProducts = 0;

    stops.forEach(stop => {
      totalProducts += stop.products.length;
    });

    return totalProducts;
  }

  const getDeliveryProducts = (stops) => {
    let totalProducts = [];

    stops.forEach(stop => {
      totalProducts = totalProducts.concat(stop.products);
    });

    return totalProducts;
  }

  const getDeliveryStatusText = (stopDataObj) => {
    let statusString = "";
    if (stopDataObj?.status === "not_delivered") statusString = "Completed";
    else statusString = "Delivered";
    return `${statusString}: ${formatDate(stopDataObj?.delivery_driver_complete_date)}, ${stopDataObj?.delivery_driver_complete_time}`;
  }

  useEffect(() => {
    if (request_details.id == request_id) {
      if (location?.state?.is_fetch_req_details === false  && fetchReqDetails === false && ((not_planned_stops?.length - 1) == request_details?.stops?.length)) return;

      if (data?.stops?.length > 0) {
        setTotalProducts(calculateDeliveryProducts(data?.stops));
      }
      else {
        setTotalProducts(0)
      }

      if (
        type === "not-planned" ||
        type === "in-bidding" ||
        type === "invitation" ||
        type === request_details_types.awarded ||
        type === request_details_types?.saved ||
        location?.pathname?.includes("/global-request/details")
      ) {
        let deliveryStops =
          request_details?.stops?.map((item) => ({
            date: item?.date,
            duration: !item?.duration ? "" : item?.duration,
            end_time: item?.end_time,
            id: item?.id,
            stop_id: item?.id,
            start_time: item?.start_time,
            type: "delivery",
            request_id: request_details?.request_id,
            title: item?.title,
            products: item?.products,
            address: item?.address,
            comment: item?.comment,
            floor_number: item?.floor_number,
          })) || [];
        let pickupStop = [
          {
            date: request_details?.pickup_date,
            duration: pickup_duration || request_details?.pickup_duration,
            end_time: request_details?.pickup_end_time,
            id: request_details?.pickup_id,
            stop_id: request_details?.pickup_id,
            start_time: request_details?.pickup_start_time,
            floor_number: request_details?.pickup_floor_number,
            type: "pickup",
            request_id: request_details?.request_id,
            title: request_details?.pickup_title,
            address: request_details.pickup_address,
            products: getDeliveryProducts(data?.stops),
            comment: request_details?.pickup_comment,
          },
        ];
        setNotPlannedStops(pickupStop.concat(deliveryStops));
        // generateStops({
        //   stops: pickupStop.concat(deliveryStops),
        //   date: request_details?.pickup_date,
        // });
      }
    }
  }, [request_details, request_id]);

  useEffect(() => {
    if (not_planned_stops?.length) {
      let updatedStops = not_planned_stops;
      updatedStops[0].duration = pickup_duration;

      setNotPlannedStops(updatedStops)
    }
  }, [pickup_duration])

  const { isEditPath } = useRequestDetailsPathChecks(type, request_id);
  const { expandedAccordion, setExpandedAccordion } = useCreateRequestStore();

  const [selectDelivery, setSelectDelivery] = useState(null);

  const handleBlurEvents = (type, event) => {
    switch (type) {
      case "date":
      // if (!item?.date) {
      //   if (errorFields.indexOf("date") === -1) {
      //     setErrorFields([...errorFields, "date"]);
      //   }
      // } else {
      //   let newFields = errorFields.filter((field) => field !== "date");
      //   setErrorFields(newFields);
      // }
      // return;
    }
  };

  const getFormattedDateString = (stop) => {
    if (stop?.start_date) {
      if (isEndTimeSmaller(formatTime(stop?.start_time), formatTime(stop?.end_time)) || (formatTime(stop?.start_time) === formatTime(stop?.end_time))) return `${formatDate(stop.start_date)} - ${formatDate(addDaysToDate(stop?.start_date, 1))}`;
      else return formatDate(stop.start_date);
    } else if (stop?.date) {
      if (isEndTimeSmaller(formatTime(stop?.start_time), formatTime(stop?.end_time)) || (formatTime(stop?.start_time) === formatTime(stop?.end_time))) return `${formatDate(stop.date)} - ${formatDate(addDaysToDate(stop?.date, 1))}`;
      else return formatDate(stop.date);
    } else {
      return "No date";
    }
  };


  return (
    <>
      {/* {data?.stops?.length > 0 ? ( */}
      <RequestAccordion
        showClearButton={false}
        title={t("Delivery") + `${expandedAccordion === create_request_steps.mass_import ? ` (${data?.stops?.length ? data?.stops?.length : 0})` : ""} `}
        subTitleOne={data?.stops?.length ? (data?.stops?.length) + " " + t(" Deliveries") : "No info added yet"}
        subTitleTwo={data?.stops?.length ? `${calculateDeliveryProducts(data?.stops)}` + " " + t(" Products") : null}
        titleRightComponent={data?.stops?.length ? t('Table view') : ""}
        titleRightComponentOnClick={() => { setShowRequestTableViewModal(true); }}
        isExpanded={expandedAccordion === create_request_steps.mass_import}
        onExpand={() => {
          setSelectDelivery(null);
          setExpandedAccordion(
            expandedAccordion === create_request_steps.mass_import
              ? null
              : create_request_steps.mass_import
          );
        }}
      >
        {data?.stops?.length > 0 ?
          <div className="space-y-5 -mt-s9">
            {data?.stops?.map((stop, index) => (
              <div
                key={index}
                className={` ${selectDelivery === index &&
                  "border-[1px] rounded border-cGray300 p-4 overflow-"
                  } `}
              >
                <RequestAccordion
                  showBorder={true}
                  showClearButton={false}
                  title={`${t("Delivery")} ${index + 1}`}
                  showShift={true}
                  isForm={false}
                  isBorder={false}
                  subTitleOne={stop?.products?.length + " " + t("Products")}
                  subTitleTwo={stop?.address}
                  isExpanded={selectDelivery === index}
                  onExpand={() => {
                    setSelectDelivery(selectDelivery === index ? null : index);

                    if (selectDelivery === index) {
                      setInvalidStartTime(false);
                      setInvalidEndTime(false);
                    }

                  }}
                >
                  <div
                    onClick={() => {
                      console.log("stop data details: ", stop);
                    }}
                    className="flex flex-col gap-3 -mt-4 relative" key={index}
                  >
                    <div className="relative">
                      {
                        (type === request_details_types.ongoing || type === request_details_types.history || type === request_details_types.completed) ?
                          <div className="absolute right-0 top-0 text-sm leading-6 text-cGray500">
                            {
                              extModifierDateTime(
                                "delivery",
                                stop?.status,
                                stop?.delivery_expected_date,
                                stop?.delivery_expected_time,
                                stop?.delivery_driver_complete_date,
                                stop?.delivery_driver_complete_time
                              )
                            }
                          </div>
                          : <></>
                      }
                    </div>

                    <CommonViewComponent
                      labelText={t("Delivery Address")}
                      value={stop?.address ? addressValidation(stop?.address) : "No address"}
                    />
                    <CommonViewComponent
                      labelText={t("Floor Number")}
                      value={stop?.floor_number ?? "No floor number"}
                    />

                    {/* only for company */}
                    {user_role === role.company ? (
                      <>
                        {(((type === request_details_types.not_planned &&
                          data?.awarded?.status === "init") ||
                          type === request_details_types.in_bidding ||
                          type === request_details_types.awarded) &&
                          isEditPath) ||
                          type === request_details_types.invitation || window.location.pathname.includes("/global-request/details") ? (
                          <>
                            {/* <CommonViewComponent labelText={t('Date')} value={defineNotPlannedDeliveryDate(data?.pickup_date, not_planned_stops[index]?.start_time, data?.pickup_start_time)} className='pb-[2px]' /> */}

                            <div className="grid grid-cols-4 gap-x-5 -mb-s12">
                              <div className="-mt-s6">
                                <CommonDatePicker
                                  startDate={
                                    data?.pickup_date
                                      ? data?.pickup_date
                                      : new Date()
                                  }
                                  required={false}
                                  allowPastDate={true}
                                  shouldUpdateValue={true}
                                  label={t("Date")}
                                  value={
                                    not_planned_stops[index + 1]?.date
                                      ? not_planned_stops[index + 1]?.date
                                      : null
                                  }

                                  onChange={(date) => {
                                    updateStopInfoWithId(
                                      stop?.id,
                                      "date",
                                      getStringFromDateObject(date)
                                    );

                                    updateStopInfoWithId(
                                      stop?.id,
                                      "start_time",
                                      ""
                                    );
                                    updateStopInfoWithId(
                                      stop?.id,
                                      "end_time",
                                      ""
                                    );
                                  }}
                                  onCloseEvent={() => handleBlurEvents("date")}
                                />
                              </div>
                              <CommonTimePicker
                                label={t("Time")}
                                name={"start_time"}
                                disabled={!not_planned_stops[index + 1]?.date}
                                init_time={not_planned_stops[index + 1]?.start_time ? not_planned_stops[index + 1]?.start_time?.slice(0, 5) : ""}
                                isInvalid={invalidStartTime}

                                init_date={not_planned_stops[0]?.date ?? new Date()}
                                isCustomMinTimeValidation={isStartDateValid(not_planned_stops[0]?.date, not_planned_stops[index + 1]?.date, true) ? true : false}
                                // isCustomMinTimeValidation={!isFutureDateTime(not_planned_stops[index + 1]?.date)}
                                customMinTimeValue={not_planned_stops[0]?.start_time ? addMinutesToTime(not_planned_stops[0]?.start_time?.slice(0, 5), 15) : ""}

                                // isInvalid={invalidStartTime}
                                onChange={(start_time, end_time) => {

                                  updateStopInfoWithId(
                                    stop?.id,
                                    "start_time",
                                    start_time
                                  );
                                  updateStopInfoWithId(
                                    stop?.id,
                                    "end_time",
                                    end_time
                                  );
                                }}
                                marginClass="mt-s6"
                                showExtendedTimeUi={false}
                                showClear={true}
                                handleClear={async () => {
                                  await updateStopInfoWithId(
                                    stop?.id,
                                    "start_time",
                                    ""
                                  );
                                  await updateStopInfoWithId(
                                    stop?.id,
                                    "end_time",
                                    ""
                                  );
                                }}
                                clearIconTooltipText={t("Clear delivery time")}
                              />

                              <CommonTimePicker
                                label={t("Range")}
                                name={"end_time"}
                                marginClass="mt-s6"
                                disabled={!not_planned_stops[index + 1]?.date}

                                init_date={not_planned_stops[index + 1]?.date ?? new Date()}
                                // isCustomMinTimeValidation={!isFutureDateTime(not_planned_stops[index + 1]?.date)}
                                // isCustomMinTimeValidation={true}
                                customMinTimeValue={not_planned_stops[index + 1]?.start_time ? addMinutesToTime(not_planned_stops[index + 1]?.start_time?.slice(0, 5), 15) : "00:00"}

                                init_time={not_planned_stops[index + 1]?.end_time?.slice(0, 5) ?? addMinutesToTime(not_planned_stops[index + 1]?.start_time?.slice(0, 5), 15)}
                                showExtendedTimeUi={false}
                                isInvalid={invalidEndTime}
                                onChange={(start_time, end_time) => {
                                  //TODO: if (not_planned_stops[index + 1]?.start_time === start_time) {
                                  //   console.log("BINGO!!");
                                  //   let formattedTime = addMinutesToTime(start_time, -15);
                                  //   updateStopInfoWithId(
                                  //     stop?.id,
                                  //     "end_time",
                                  //     formattedTime
                                  //   );
                                  // }
                                  // else 
                                  updateStopInfoWithId(
                                    stop?.id,
                                    "end_time",
                                    start_time
                                  );
                                }}
                                showClear={true}
                                handleClear={async () => {
                                  await updateStopInfoWithId(
                                    stop?.id,
                                    "end_time",
                                    ""
                                  );
                                }}
                                clearIconTooltipText={t("Clear delivery time")}
                              />

                              <div className="flex -mt-s18">
                                <div className="relative w-full">
                                  <CommonInput
                                    max_input={5}
                                    type="number"
                                    labelText={t("Duration (min)")}
                                    // parentClassName={"mt-s2"}
                                    name={"duration"}
                                    isInvalid={not_planned_stops[index + 1]?.duration && not_planned_stops[index + 1]?.duration < 3}
                                    value={
                                      not_planned_stops[index + 1]?.duration
                                    }
                                    onBlurEvent={(e) => {
                                      if (e.target.value && e.target.value < 3) {
                                        updateStopInfoWithId(
                                          stop?.id,
                                          "duration",
                                          3
                                        )
                                        return Toastr({ message: "Duration can not be less than 3 minutes!", type: "warning" })
                                      }
                                    }}
                                    onChange={(e) => {
                                      // if (!not_planned_stops[index + 1]?.duration && e.target.value === 0) {
                                      //   e.preventDefault();
                                      //   return;
                                      // }
                                      updateStopInfoWithId(
                                        stop?.id,
                                        "duration",
                                        e.target.value
                                      );
                                    }}
                                  />

                                  <div className="absolute right-0 flex space-x-2 bottom-[15px]">
                                    {not_planned_stops[index + 1]?.duration ? (
                                      <Tooltip
                                        title={t("Clear Duration")}
                                        color={"#242826"}
                                      >
                                        <img
                                          onClick={() => {
                                            updateStopInfoWithId(
                                              stop?.id,
                                              "duration",
                                              ""
                                            );
                                          }}
                                          className="w-5 h-5 cp"
                                          src={iCrossOutlined}
                                          alt=""
                                        />
                                      </Tooltip>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <CommonViewComponent
                              labelText={t("Date")}
                              value={getFormattedDateString(stop)}
                            />
                            <CommonViewComponent
                              labelText={t("Time")}
                              value={
                                stop?.start_time || stop?.end_time
                                  ? ` ${stop?.start_time
                                    ? formatTime(stop?.start_time)
                                    : "00:00"
                                  } ${stop?.end_time ? " - " : ""} ${stop?.end_time
                                    ? formatTime(stop?.end_time)
                                    : "- 00:00"
                                  }`
                                  : "No time"
                              }
                            />
                            <CommonViewComponent
                              labelText={t("Duration (min)")}
                              value={
                                stop?.duration ? `${stop?.duration} min` : "No duration"
                              }
                            />
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {stop?.products?.map((product, index) => (
                      <CommonViewComponent
                        key={index}
                        labelText={t(`Product ${index + 1}`)}
                        value={product?.text}
                      />
                    ))}

                    <CommonViewComponent
                      labelText={t("Comment")}
                      value={stop?.comment ?? "No comment"}
                    />

                    {stop?.attachment && (
                      <div className="">
                        <ImageViewer
                          src={stop?.attachment}
                          label={t("Attachment")}
                          withExtension={true}
                        />
                      </div>
                    )}

                    {checkIsShowDeliveryOverview(stop, type) && (
                      <DeliveryOverview index={index} stop={stop} />
                    )}
                  </div>
                </RequestAccordion>
              </div>
            ))}

            {/* todo customer profile */}
          </div> :
          <CommonEmptyView
            message="No delivery stops found!"
          />
        }
      </RequestAccordion >
      {/* ) : (
        ""
      )} */}
    </>
  );
}
