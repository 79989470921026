import React from 'react'
import CommonTable from '../../../components/table/CommonTable';
import { FormControlLabel, Radio } from "@mui/material";

const Test = () => {

  const data = [1, 2, 3, 4]

  const headers = [
    { index: 0, name: "#", onClickAction: () => { console.log('click event: #'); }, width: '2' },
    { isActive: true, sort: 1, index: 2, name: "Driver", width: '20' },
    { isActive: true, sort: 1, index: 3, name: "Vehicle", width: '20' },
    { isActive: true, sort: 1, index: 4, name: "Date & Time", width: '20' },
    { isActive: true, sort: 1, index: 5, name: "Details", width: '18' },
  ]

  return (
    <div className=''>
      <CommonTable
        tableTitle={"ShiftTable"}
        showSearchBox={false}
        showTopRightFilter={true}
        showTakeOption={true}
        showPagination={false}
        showPageCountText={true}
        headers={headers}
        outerPadding='p-s0'
        topRightFilterComponentOnClick={() => { }}
        paginationObject={{}}
        withClearSearch={false}
        onSearchClear={() => { }}
        searchValue={"fretgz"}
        searchOnChange={(e) => { }}
        currentTakeAmount={10}
        withReloader={true}
        onReload={() => { }}
        filtered={false}
        takeOptionOnChange={async (e) => { }}
        paginationOnClick={async (url) => { }}
        items={
          data?.map((item, index) => (
            <React.Fragment key={index}>
              <tr colSpan={5} className='border-b'>
                <td colSpan={2} className='flex items-center justify-center w-full h-[100%] border-r'>
                  <div className='flex items-center justify-center w-full '>
                    <FormControlLabel
                      checked={true}
                      value={true}
                      control={<Radio style={{ color: '#60B436' }} />}
                      onChange={(e) => { }}
                    />
                  </div>
                  <span className='w-full text-center'>01</span>
                </td>

                <td colSpan={3} className='w-full border-r h-[100%]'>
                  <div style={{ display: 'table', height: '100%', width: '100%' }}>
                    <div className='' style={{ display: 'table-row', height: '50%' }}>
                      <div className='border-b border-r ' style={{ display: 'table-cell' }}>Details</div>
                      <div className='border-b border-r ' style={{ display: 'table-cell' }}>Details</div>
                      <div className='border-b ' style={{ display: 'table-cell' }}>Details</div>
                    </div>

                    <td colSpan={3} className='w-full h-[100%]'>
                      <div className='flex justify-around'>
                        <div>1</div>
                        <div>1</div>
                        <div>1</div>
                        <div>1</div>
                        <div>1</div>
                      </div>
                    </td>

                  </div>
                </td>

                <td colSpan={1} className='text-center border-r table-info'>Details</td>
              </tr>
            </React.Fragment>
          ))
        }
      />
    </div>
  )
}

export default Test



