import React, { useState } from "react";
import { Autocomplete, TextField, ThemeProvider } from "@mui/material";
import { iCloseIcon, iDropdownArrowIcon } from "../../app/utility/imageImports";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  "@global": {
    '.MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: "#CBE9DA !important", // Apply custom focus background color
    },
  },
});

const CommonSingleSelect = ({
  className = "",
  label = "Select",
  onChange = () => {},
  disabled = false,
  dataArray = [
    { title: "hello", id: 1 },
    { title: "bye", id: 2 },
  ],
  isInvalid = false,
  value = 1,
  show_asterisk = false,
  noDataText = "No data found!",
  isCapitalize = false,
  showClear = true,
}) => {
  const [open, setOpen] = useState(false);
  const [isOnFocus, setIsOnFocus] = useState(false);

  let selectedId = dataArray?.find((item) => item.id == value);

  useStyles();

  const handleChange = (e, value) => {
    onChange(value?.id, value);
  };

  const handleOpen = () => {
    setOpen(true);
    setIsOnFocus(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsOnFocus(false);
  };

  const hasValue = !!selectedId

  return (
    <div>
      <Autocomplete
        disabled={disabled}
        id="single-select"
        options={dataArray}
        getOptionLabel={(option) => option?.title}
        onChange={handleChange}
        value={selectedId}
        noOptionsText={noDataText}
        popupIcon={<img src={iDropdownArrowIcon} />}
        disableCloseOnSelect={false}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        disableClearable={showClear === false ? true : !hasValue}
        autoHighlight={false}
        autoCapitalize={isCapitalize}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={label}
            className="w-full"
            focused={isOnFocus}
            autoFocus={false}
            style={{ width: "100%" }}
            sx={{
              "& label": {
                color: "#48504C",
                fontFamily: "fEuclidLimadiRegular",
                fontWeight: selectedId ? "normal" : "",
                userSelect: "none",
              },
              "& .MuiFormLabel-asterisk": {
                color: show_asterisk && disabled ? "#BCC2BF" : "#48504C",
              },
              "& .MuiInput-roor .MuiInput-input": {
                userSelect: "none",
              },
              "& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
                borderBottomStyle: "solid !important",
                borderBottom: disabled
                  ? "1px solid #D7DAD8"
                  : "2px solid #949494",
              },
              "& label.Mui-focused": {
                color: "#48504C",
                fontWeight: "normal",
                backgroundColor: "#FFFFFF",
              },
              "& .Mui-disabled": {
                cursor: "not-allowed",
                color: "#939D98",
              },
              "& .MuiInput-underline:after": {
                borderBottom: isInvalid
                  ? "1px solid #F0554B"
                  : disabled
                  ? "1px solid #D7DAD8"
                  : "1px solid #949494",
              },
              "& .MuiInput-underline:before": {
                borderBottomStyle: "solid",
                borderBottom: isInvalid
                  ? "1px solid #F0554B"
                  : disabled
                  ? "1px solid #D7DAD8"
                  : "1px solid #949494",
              },
              "& .MuiInputBase-input": {
                fontSize: 14,
                fontFamily: "fEuclidLimadiRegular",
                fontWeight: "normal",
                backgroundColor: "transparent !important",
              },
              "& .Mui-focused .MuiInputBase-input": {
                backgroundColor: "transparent !important",
                userSelect: "none",
              },
              "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 1000px white inset",
                backgroundColor: "#FFFFFF !important",
              },
            }}
          />
        )}
      />
    </div>
  );
};

export default CommonSingleSelect;
