
import ProtectedRoute from "../app/utility/ProtectedRoute";
import Layout from "../components/layout/Layout";
import TrackTrace from "../views/common/trackTrace/TrackTrace";
import CarManager from "../views/company/carManagement/CarManager";
import AddCar from "../views/company/carManagement/components/AddCar";
import AddLicense from "../views/company/carManagement/components/AddLicense";
import CarDetails from "../views/company/carManagement/components/CarDetails";
import EditCar from "../views/company/carManagement/components/EditCar";
import LicenseEdit from "../views/company/carManagement/components/LicenseEdit";
import AddAndEditDriver from "../views/company/driverManager/components/AddAndEditDriver";
import DriverManagerDetails from "../views/company/driverManager/components/DriverManagerDetails";
import DriverManager from "../views/company/driverManager/DriverManager";
import GlobalReqDetails from "../views/company/globalRequest/components/GlobalReqDetails";
import GlobalRequestHome from "../views/company/globalRequest/components/GlobalRequestHome";
import GlobalRequest from "../views/company/globalRequest/GlobalRequest";
import AddShift from "../views/company/shiftManager/components/AddShift";
import EditShift from "../views/company/shiftManager/components/EditShift";
import ShiftDetails from "../views/company/shiftManager/components/ShiftDetails";
import ShiftManager from "../views/company/shiftManager/ShiftManager";
import ShiftManagerTable from "../views/company/shiftManager/ShiftManagerTable";



export const companyRouteMapper = [
    {
        path: "/global-request",
        element: <ProtectedRoute />,
        children: [
            {
                path: "",
                element: <Layout><GlobalRequest /></Layout>,
                children: [
                    { path: "", element: <GlobalRequestHome /> },
                    { path: "details/:request_id", element: <GlobalReqDetails /> },
                    { path: "details/:request_id/:action", element: <GlobalReqDetails /> },
                ]
            },


        ],
    },
    {
        path: "/shift-manager",
        element: <ProtectedRoute />,
        children: [
            {
                path: "",
                element: <Layout><ShiftManager /></Layout>,
                children: [
                    { path: "", element: <ShiftManagerTable /> },
                    { path: "add", element: <AddShift /> },
                    { path: "details/:shift_id", element: <ShiftDetails /> },
                    { path: "edit/:shift_id", element: <EditShift /> },

                ]
            },

        ],
    },
    {
        path: "/car-manager",
        element: <ProtectedRoute />,
        children: [
            { path: "", element: <Layout><CarManager /></Layout> },
            { path: "details/:car_id", element: <Layout><CarDetails /></Layout> },
            { path: "add", element: <Layout><AddCar /></Layout> },
            { path: "add/license", element: <Layout><AddLicense /></Layout> },
            { path: "edit/license", element: <Layout><LicenseEdit /></Layout> },
            { path: "edit/:car_id", element: <Layout><EditCar /></Layout> },
        ],
    },

    {
        path: "/driver-manager",
        element: <ProtectedRoute />,
        children: [
            { path: "", element: <Layout><DriverManager /></Layout> },
            { path: "add", element: <Layout><AddAndEditDriver /></Layout> },
            { path: "edit/:driver_id", element: <Layout><AddAndEditDriver /></Layout> },
            // details
            { path: ":details/:driver_id", element: <Layout><DriverManagerDetails /></Layout> },
        ],
    },


    {
        path: "/track-trace",
        element: <ProtectedRoute />,
        children: [
            { path: "", element: <Layout><TrackTrace /></Layout> },

        ],
    },

]