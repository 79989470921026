import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonViewComponent from '../../../../components/viewer/CommonViewComponent';

const PurchaseLicenseInfo = ({ data, disabled = false }) => {

    const { t } = useTranslation();

    return (
        <>
            <div className='mb-3'>
                <div className={`font-fw700 text-fs24 ${disabled ? "text-cGray500" : "text-cGray800"} `}>{t("License Info")}</div>
            </div>
            <div className='space-y-3'>
                <CommonViewComponent labelText={t('Name')} disabled={disabled} value={data?.number ?? "NA"} />
                <CommonViewComponent labelText={t('Price')} disabled={disabled} value={`DKK  ${data?.price ? data?.price?.toLocaleString("da-DK") : 0}`} />
                <CommonViewComponent labelText={t('Duration')} disabled={disabled} value={data?.duration ? `${data?.duration} days` : "NA"} />
                <CommonViewComponent labelText={t('Description')} disabled={disabled} flexDirection={data?.description?.length > 20 ? "flex-col" : "flex-row"} value={data?.description ?? "NA"} />
            </div>
        </>
    )
}

export default PurchaseLicenseInfo