import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useGeneralStore from '../../../../../../app/stores/others/generalStore';
import useRequestStore from '../../../../../../app/stores/others/requestStore';
import { create_request_steps, request_details_types, user_role as role } from '../../../../../../app/utility/const';
import Agreement from '../../../../../customer/homePage/tables/inBiddings/components/Agreement';
import BiddingReceived from '../../../../../customer/homePage/tables/inBiddings/components/BiddingReceived';
import SelectCompany from '../../../../createRequest/components/content/selectCompany/SelectCompany';
import AcknowledgeDetails from '../summary/components/AcknowledgeDetails';
import AvailableShifts from '../summary/components/AvailableShifts';
import OnGoingDriver from '../summary/components/OnGoingDriver';
import ProposalInfo from '../summary/components/ProposalInfo';
import Review from '../summary/components/Review';
import Acknowledgement from './components/Acknowledgement';
import Pickup from './components/Pickup';
import Deliveries from './components/deliveries/Deliveries';
import BiddingDetails from './components/deliveries/components/BiddingDetails';
import useCreateRequestStore from '../../../../../../app/stores/others/createRequestStore';
import SelectShift from '../../../../createRequest/components/content/selectShift/SelectShift';

export default function MainContents() {

  const { request_details, setAcknowledgementComment } = useRequestStore();
  const { setExpandedAccordion } = useCreateRequestStore()
  const data = request_details;
  const params = useParams();
  const { type, request_id } = params;
  const { user_role } = useGeneralStore();
  const location = useLocation();

  const isNotPlannedEditMode = location.pathname.includes(`requests/not-planned/details/${request_id}/edit`) || location.pathname.includes(`requests/awarded/details/${request_id}/edit`);
  const isNotPlannedViewMode = location.pathname.includes(`requests/not-planned/details/${request_id}`);

  useEffect(() => {
    if (
      type === request_details_types?.completed &&
      user_role === role.company
    ) {
      setExpandedAccordion(create_request_steps.acknowledgement);
    }
    else {
      setExpandedAccordion(create_request_steps.pickup);
    }
  }, [type])

  useEffect(() => {
    return () => setAcknowledgementComment("")
  }, [])

  return (
    <div className='space-y-5'>

      {type === request_details_types.ongoing && <OnGoingDriver />}

      {/* {checkIsBiddingDetailsShow(type) && <BiddingDetails data={data} />} */}

      <Pickup data={data} />

      <Deliveries data={data} showTableViewButton={type === request_details_types.not_planned} />

      {/* {user_role === role.company && <UpdateSelectShift />} */}

      {user_role === role.company ? type === request_details_types.not_planned || type === request_details_types?.awarded ? isNotPlannedEditMode ? <SelectShift /> : <AvailableShifts /> : <AvailableShifts /> : ""}

      {user_role === role.company && <ProposalInfo />}

      {user_role === role.customer && type === request_details_types.in_bidding && <BiddingReceived />}
      {user_role === role.customer && (type !== request_details_types.in_bidding && type !== request_details_types.saved) && <BiddingDetails />}

      {user_role === role.customer && type === request_details_types.in_bidding && <Agreement />}
      {user_role === role.customer && type === request_details_types.saved && <SelectCompany />}

      {((type === request_details_types.completed || type === request_details_types.history) && user_role === role.company) && <Acknowledgement />}

      {((type === request_details_types.history) && user_role === role.customer) && <AcknowledgeDetails />}
      {((type === request_details_types.history) && user_role === role.customer) ? <Review /> : ""}
    </div>
  )
}
