import React, { useEffect } from "react";
import RequestAccordion from "../../../../../../../components/Accordion/RequestAccordion";
import {
  create_request_steps,
  request_details_types,
} from "../../../../../../../app/utility/const";
import useCreateRequestStore from "../../../../../../../app/stores/others/createRequestStore";
import CommonInput from "../../../../../../../components/input/CommonInput";
import useRequestStore from "../../../../../../../app/stores/others/requestStore";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CommonViewComponent from "../../../../../../../components/viewer/CommonViewComponent";
import { formatDateTime } from "../../../../../../../app/utility/utilityFunctions";

const Acknowledgement = () => {
  const { expandedAccordion, setExpandedAccordion } = useCreateRequestStore();
  const { acknowledgementComment, setAcknowledgementComment, request_details } =
    useRequestStore();
  const { t } = useTranslation();

  const params = useParams();
  const { type } = params;
  
  const getDateTime = (acknowledged_at) => {
    if(acknowledged_at) {
      const [date, time] = acknowledged_at?.split(" ");
      const formattedResult = formatDateTime(date, time, true)
      return formattedResult
    }

    return null
  }
  
  return (
    <RequestAccordion
      showClearButton={type === request_details_types.completed && acknowledgementComment}
      title={t("Acknowledgement")}
      clearHandler={() => {
        setAcknowledgementComment("");
      }}
      isTable={true}
      isExpanded={expandedAccordion === create_request_steps.acknowledgement}
      subTitleOne={request_details?.acknowledge ? "Acknowledged" : ""}
      subTitleTwo={getDateTime(request_details?.acknowledged_at)}
      onExpand={() => {
        setExpandedAccordion(
          expandedAccordion === create_request_steps.acknowledgement
            ? null
            : create_request_steps.acknowledgement
        );
      }}
    >
      {type === request_details_types.history ? (
        <div className="border-cGray200 rounded border-[1px] p-3">
          {request_details?.acknowledge ? (
            <div className="flex flex-col items-start justify-start space-y-1">
              <div className="text-fs10 leading-3 font-fw600 text-cGray600">Comment</div>
              <div className="item-title-normal text-cMainBlack">{request_details?.acknowledge ?? "NA"}</div>
            </div>
          ) : (
            <div className="font-normal text-cGrey500 text-fs14">
              {t("Company has not been acknowledged yet!")}
            </div>
          )}
        </div>
      ) : (
        <div className="-mt-s18">
        <CommonInput
          name={"comment"}
          value={acknowledgementComment}
          onChange={(e) => {
            if (e.target.value !== " ") {
              const trimmedValue = e.target.value.replace(/\s+/g, " ");
              setAcknowledgementComment(trimmedValue);
            }
          }}
          labelText={t("Comment")}
          textarea={true}
          type={"text"}
          max_input={255}
          required={true}
          show_asterisk={true}
        />
        </div>
      )}
    </RequestAccordion>
  );
};

export default Acknowledgement;
