import React from 'react'
import CommonModal from '../../../../../../../components/modal/CommonModal'
import CommonButton from '../../../../../../../components/button/CommonButton'
import useCreateRequestStore from '../../../../../../../app/stores/others/createRequestStore';
import { getStringFromDateObject } from '../../../../../../../app/utility/utilityFunctions';

export default function ConfirmChangeDateTimeModal({
    showModal = false,
    setShowModal = () => { },
    onConfirm = () => { },
    onCancel = () => { },
}) {
    const {
        cr_form,
        setCrFullForm,
        oldPickupDateTime,
        setOldPickupDateTime,
        updateCrField,
    } = useCreateRequestStore();


    function resetDeliveryDateTimes(formData) {
        let newFormData = {};
        let newTempDeliveries = [];

        formData.deliveries.forEach(delivery => {
            newTempDeliveries.push({
                ...delivery,
                date: '',
                start_time: '',
                end_time: '',
            })
        });

        newFormData = {
            ...formData,
            shift_id: null,
            shift_plan: null,
            deliveries: newTempDeliveries
        };

        return newFormData;
    }

    return (
        <div>
            <CommonModal
                preventClose
                showModal={showModal}
                setShowModal={setShowModal}
                modalTitle={"Warning"}
                widthClass='w-[700px]'
                mainContent={
                    <div
                        onClick={() => {
                            console.log("oldPickupDateTime: ", oldPickupDateTime);
                        }}
                    >
                        <div className="mt-s20">Updating the pickup time will reset delivery dates and times, and deselect the current shift.</div>

                        <div className="flex justify-end mt-s20">
                            <CommonButton
                                onClick={() => {
                                    updateCrField("pickup.date", getStringFromDateObject(oldPickupDateTime?.date));
                                    updateCrField("pickup.start_time", oldPickupDateTime?.start_time);
                                    updateCrField("pickup.end_time", oldPickupDateTime?.end_time);
                                    setOldPickupDateTime(null);
                                    onCancel();
                                }}
                                btnLabel={"Cancel"}
                                colorType="danger"
                                width="w-[100px]"
                            />
                            <CommonButton
                                onClick={() => {
                                    console.log("OLD cr_form: ", cr_form);
                                    let newCrForm = resetDeliveryDateTimes(cr_form);
                                    console.log("newCrForm: ", newCrForm);
                                    setCrFullForm(newCrForm);
                                    onConfirm();
                                }}
                                // onClick={onConfirm}
                                btnLabel={"Confirm"}
                                colorType="success"
                                width="w-[100px]"
                                className="ml-2"
                            />
                        </div>
                    </div>
                }
            />
        </div>
    )
}
