/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useParams, useNavigate, useLocation } from 'react-router';
import CommonDetailsInfo from '../../../../components/viewer/CommonDetailsInfo';
import { useTranslation } from 'react-i18next';
import CommonTitle from '../../../../components/title/CommonTitle';
import CommonButtonOutlined from '../../../../components/button/CommonButtonOutlined';
import CommonButton from '../../../../components/button/CommonButton';
import { useEffect } from 'react';
import useFavoriteAddressStore, { getFavoriteAddressDetails } from '../../../../app/stores/others/favoriteAddressStore';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import { k_fav_address_paths } from '../../../../app/utility/AllRoute';

const FavoriteAddressDetails = () => {

    const { favoriteAddressDetails, setShowFavoriteAddressDeleteModal, setAddFavoriteAddress_form } = useFavoriteAddressStore();

    const { t } = useTranslation();

    const { setPathRecord } = useGeneralStore();

    const { fav_address_id } = useParams();

    const location = useLocation();

    const navigateTo = useNavigate();

    useEffect(() => {
        getFavoriteAddressDetails(fav_address_id);
    }, [fav_address_id])

    useEffect(() => { setPathRecord(location.pathname); }, [location.pathname]);

    return (
        <div className='grid grid-cols-12 gap-6 md:gap-5 2xl:gap-5'>

            <div className='col-span-8'>
                <CommonTitle
                    title={t('Address Details')}
                    icon={true}
                    link={k_fav_address_paths.list}
                />

                <div className='mt-4 space-y-3'>
                    <CommonDetailsInfo title={t('Name')} description={favoriteAddressDetails?.title} />
                    <CommonDetailsInfo address={true} title={t('Address')} description={favoriteAddressDetails?.address} />
                    <CommonDetailsInfo title={t('Floor Number')} description={favoriteAddressDetails?.floor_number ?? "No floor number"} />
                    <CommonDetailsInfo title={t('Note')} description={favoriteAddressDetails?.note ?? "No note"} />
                </div>
            </div>

            <div className='flex justify-end col-span-4 space-x-5'>

                <CommonButtonOutlined
                    onClick={() => setShowFavoriteAddressDeleteModal(true)}
                    btnLabel={t("Delete")}
                    colorType='danger'
                />
                <CommonButton
                    onClick={async () => {
                        await setAddFavoriteAddress_form({
                            fav_id: favoriteAddressDetails?.id,
                            title: favoriteAddressDetails?.title,
                            address: favoriteAddressDetails?.address,
                            floor_number: favoriteAddressDetails?.floor_number,
                            note: favoriteAddressDetails?.note,
                            lng: favoriteAddressDetails?.lng,
                            lat: favoriteAddressDetails?.lat,
                        });
                        navigateTo(`/favorite-address/edit/${fav_address_id}`);
                    }}
                    type='submit'
                    btnLabel={t("Edit")}
                />
            </div>
        </div>
    )
}

export default FavoriteAddressDetails