import React, { useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { iCrossWhite } from "../../app/utility/imageImports";


const CommonModal = ({
    iAutoFocus = true,
    modalSpace = false,
    preventClose = false,
    showCrossIcon = true,
    modalOnHide = () => { },        // modalOnHide is a function that will be called when the modal is closed. [ This is an optional parameter. ]
    heightMax = "",
    showModal,
    setShowModal,
    onCloseModal = () => { },            //do something when the modal is closed
    mainContent,
    modalTitle,
    customClose,
    subTitle = "",
    singleButton,
    primaryActionButton,
    secondaryActionButton,
    useAutoClose = true,
    withPaddingY = false,
    withPaddingBody = true,
    widthClass = "w-full md:w-[60vw] lg:w-[55vw] xl:w-[50vw] 2xl:max-w-[1200px]",
    closeIconPosition = "top-3 right-3",
    isTransparentBackground = false,
}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (showModal && !iAutoFocus) {
            inputRef.current.focus();
        }
    }, [showModal, iAutoFocus]);

    return (

        <div>
            <input ref={inputRef} autoFocus value={""} onChange={() => { }} className='absolute h-0 outline-none caret-transparent' />
            <Transition appear show={showModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="overflow-y-auto fixed inset-0 z-[1004]"
                    onClose={() => {
                        if (preventClose) {
                            return;
                        }
                        setShowModal(false);
                        onCloseModal();
                    }}
                >
                    <div
                        className={`
                        px-4 min-h-screen text-center opacity-100 bg-cModalDropBg backdrop-blur-sm w-full !min-w-[1200px]
                        ${withPaddingY ? 'py-10' : 'py-0'}
                    `}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-100"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 w-full !min-w-[1200px]" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                            afterLeave={() => modalOnHide()}
                        >
                            <div
                                className={`${modalSpace ? 'my-s20' : ''} ${withPaddingBody && "p-5"} inline-block text-left align-middle ${isTransparentBackground ? "bg-transparent" : "bg-white"} rounded-lg shadow-xl opacity-100 transition-all transform gs-text-main-black ${widthClass} ${heightMax} `}
                            >
                                {showCrossIcon ? <div
                                    onClick={() => {
                                        setShowModal(false);
                                        onCloseModal();
                                    }}
                                    className={`absolute z-50 ${customClose ? "" : "p-2"} cursor-pointer ${closeIconPosition}`}
                                >
                                    {
                                        customClose ?
                                            <img src={customClose} alt="" className="" />
                                            : <img className="w-[12px] h-[12px]" src={iCrossWhite} alt="" />
                                    }

                                </div> : <></>}


                                <div className="relative flex flex-col justify-between">

                                    {modalTitle ? <div className="capitalize title text-cGray800">
                                        {modalTitle}
                                    </div> : ""}


                                    <div className="sub-title">{subTitle}</div>
                                </div>
                                {mainContent}
                                {singleButton ? (
                                    <div
                                        onClick={() => {
                                            if (useAutoClose) {
                                                setShowModal(false);
                                            }
                                        }}
                                        className="flex items-center justify-center mx-auto w-fit"
                                    >
                                        {singleButton}
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div className="flex items-center justify-between">
                                    <div onClick={() => setShowModal(false)}>
                                        {secondaryActionButton}
                                    </div>
                                    <div
                                        onClick={() => {
                                            if (useAutoClose) {
                                                setShowModal(false);
                                            }
                                        }}
                                    >
                                        {primaryActionButton}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default CommonModal;
