/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { RadioGroup } from "@mui/material";
import CommonRadioItemItem from '../../../components/Radio/CommonRadioItemItem';
import useSettingsStore, { getAdminAccessInfo, getToggleNotificationState, toggleAdminAccess } from '../../../app/stores/others/settingsStore';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { changePageTitle } from '../../../app/utility/utilityFunctions';
import CommonTitle from '../../../components/title/CommonTitle';

const AdminAccess = () => {

    const { adminAccess, adminAccessInfo } = useSettingsStore();

    const { t } = useTranslation()

    useEffect(() => {
        changePageTitle(t('Settings | Admin Access'));
        fetchData();
    }, [])

    const fetchData = async () => {
        await getAdminAccessInfo();
        await getToggleNotificationState();
        window.scrollTo(0, 0);
    }

    return (
        <div>
            <CommonTitle title={t("Admin Access")} />
            <div className='flex space-x-10'>
                <div>
                    <div className='mt-4 font-fw400 text-fs18 text-cGray800'>
                        {

                            !adminAccessInfo ? <span>{t('No Admin AccessInfo Available')}</span>
                                : <div dangerouslySetInnerHTML={{ __html: adminAccessInfo }}>
                                </div>
                        }
                    </div>

                    <div className='flex my-5'>

                        <RadioGroup
                            className="w-full"
                            required={true}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <div className="flex w-full space-x-5">

                                <CommonRadioItemItem
                                    isActive={adminAccess}
                                    checked={adminAccess}
                                    value="en"
                                    required={true}
                                    label={t("On")}
                                    onChange={(e) => { toggleAdminAccess() }}
                                />

                                <CommonRadioItemItem
                                    isActive={!adminAccess}
                                    checked={!adminAccess}
                                    value="da"
                                    required={true}
                                    label={t("Off")}
                                    onChange={(e) => {
                                        toggleAdminAccess()
                                    }}
                                />
                            </div>
                        </RadioGroup>
                    </div>
                </div>

                <div className='min-w-[400px] max-w-[400px]'></div>
            </div>
        </div>
    )
}

export default AdminAccess