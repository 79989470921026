import { useState, useEffect } from 'react';
import { k_shift_status } from '../../../../app/utility/const';
import { useParams } from 'react-router';

const useShiftStatus = (shiftDetailsData) => {

    const { shift_id } = useParams();

    const [shiftStatusTitle, setShiftStatusTitle] = useState('');

    useEffect(() => {
        switch (shiftDetailsData?.status) {
            case k_shift_status.complete:
                setShiftStatusTitle('Completed');
                break;
            case k_shift_status.break:
                setShiftStatusTitle('Break');
                break;
            case k_shift_status.ongoing:
                setShiftStatusTitle('Ongoing');
                break;
            case k_shift_status.init:
                if (shiftDetailsData?.is_maintenance === 1) {
                    setShiftStatusTitle('In Maintenance');
                }
                else if (shiftDetailsData?.is_maintenance === 0) {
                    setShiftStatusTitle('Not Started');
                }
                break;
            default:
                setShiftStatusTitle('');
                break;
        }
    }, [shiftDetailsData, shift_id]);

    return [shiftStatusTitle];
}

export default useShiftStatus;