/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import useCarStore, { getCarDetails } from '../../../../app/stores/company/carStore';
import { k_license_status } from '../../../../app/utility/const';
import AppliedForLicense from './AppliedForLicense';
import LicenseInfo from './LicenseInfo';
import CarDetailsTopBar from './CarDetailsTopBar';
import Details from './Details';
import CommonViewComponent from '../../../../components/viewer/CommonViewComponent';
import { formatDate } from '../../../../app/utility/utilityFunctions';
import { useNavigate } from 'react-router-dom';

const CarDetails = () => {

    const { setPathRecord } = useGeneralStore();

    const { carDetails } = useCarStore();

    const { t } = useTranslation();

    const { car_id } = useParams();

    const location = useLocation();
    
    const navigateTo = useNavigate();

    const handleGetCarDetails = async() => {
        const res = await getCarDetails(car_id);
        if(!res) {
            navigateTo("/car-manager")
        }
    }
    useEffect(() => {
        handleGetCarDetails()
    }, [car_id])

    useEffect(() => { setPathRecord(location.pathname); }, [location.pathname]);

    return (
        <div className='flex flex-col gap-4 w-full'>
            <CarDetailsTopBar />

                <div className='w-[50%]'>
                    <div>
                        {/* car details */}
                        <Details />

                        {
                            carDetails?.license_status === k_license_status?.no_license &&
                            <div className='mt-5'>

                                <div className='sub-title text-cGray800'>{t("License info")}</div>
                                <div className='mt-1 font-medium text-fs16 text-cGray500'>{t("Not applied yet!")}</div>
                            </div>
                        }

                        {
                            carDetails?.license_name ?
                                <div className='mt-5'>
                                    <LicenseInfo />
                                </div>
                                : ""
                        }


                        {(
                            carDetails?.license_status === k_license_status?.pending ||
                            carDetails?.license_status === k_license_status?.processing)
                            &&
                            <div className='mt-5'>
                                <AppliedForLicense />
                            </div>
                        }


                        {
                            (carDetails?.license_status === k_license_status?.expire_warning && carDetails?.renew_license_start) &&
                            <div className='mt-4 space-y-3'>
                                <div className='sub-title text-cGray800'>{t("Applied for renew")}</div>
                                <CommonViewComponent labelText={t("Status")} value='Pending' />
                                <CommonViewComponent labelText={t("License Active On")} value={formatDate(carDetails?.renew_license_start)} />
                                <CommonViewComponent labelText={t("Applied On")} value={formatDate(carDetails?.application_date)} />
                                <CommonViewComponent labelText={t("Application Note")} value={carDetails?.applied_note} />
                                {/* <div className='mt-1 font-medium text-fs16 text-cGray500'>{t("Waiting for the confirmation")}</div> */}
                            </div>
                        }

                        {/* license info */}

                    </div>
                </div>
        </div>
    )
}

export default CarDetails