/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import useCreateRequestStore, {
  getInitData,
  isFormComplete,
  loadCreateRequestData,
} from "../../../app/stores/others/createRequestStore";
import useRequestStore, { getNewRequestDetails, getRequestShiftPlannerList } from "../../../app/stores/others/requestStore";
import { create_request_steps, create_request_type } from "../../../app/utility/const";
import { changePageTitle } from "../../../app/utility/utilityFunctions";
import Content from "./components/content/Content";
import CreateRequestSummary from "./components/summary/CreateRequestSummary";
import TitleAndActions from "./components/titleAndActions/TitleAndActions";
import { useTranslation } from "react-i18next";
import useGeneralStore from "../../../app/stores/others/generalStore";
import { k_create_request_paths } from "../../../app/utility/AllRoute";
import AllShift from "./components/content/selectShift/components/AllShift";
import TableView from "./components/content/pickupAndDeliveryForm/components/TableView";
import { useDebounce } from "use-debounce";
import useShiftStore from "../../../app/stores/company/shiftStore";

export default function CreateRequest() {
  const {
    cr_form,
    setExpandedAccordion,
    available_shift_search_key,
    current_step,
    setCreateRequestType,
    resetCreateRequest,
    setFavSelected,
    setRate,
    setSearchCompanyKey,
    setCurrentSetup
  } = useCreateRequestStore();
  const { setAvailableShifts, setRedirectingToShiftDetails } = useRequestStore();
  const { request_history, fetchReqDetails } = useRequestStore.getState();
  const { path_record, user_role } = useGeneralStore();
  const role = user_role === 'private' ? 'customer' : 'company';
  const params = useParams();
  const { request_id } = params;
  const location = useLocation();
  const path = location.pathname;
  const [searchValue] = useDebounce(available_shift_search_key, 500);
  const { t } = useTranslation();
  const { resetFilterForm, resetFilterFormCopy } = useShiftStore();

  const showShiftList = isFormComplete(cr_form);

  const isAllShift = current_step === create_request_steps.allShifts;

  useEffect(() => {
    showShiftList && (role === 'company') &&
      getRequestShiftPlannerList(
        cr_form?.pickup?.start_time,
        cr_form?.pickup?.end_time,
        cr_form?.pickup?.date
      );
    if (!isAllShift) {
      resetFilterFormCopy();
      resetFilterForm();
    }
  }, [
    showShiftList,
    !isAllShift,
    cr_form?.pickup?.start_time,
    cr_form?.pickup?.end_time,
    cr_form?.pickup?.date,
    searchValue,
  ]);

  const updatePageTitle = () => {
    if (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") changePageTitle(t("Limadi | Edit Request"));
    else if (location?.state?.referrer && location?.state?.role === "private") changePageTitle(t("Limadi | Edit Request"));
    else changePageTitle(t("Limadi | Create Request"));
  }

  useEffect(() => {
    setCreateRequestType(create_request_type.mass_import);
    loadRequest();
    localStorage.removeItem("goBack");
    localStorage.removeItem("shiftDetailsGoBack")
    setRedirectingToShiftDetails(false);
    setCurrentSetup(null);

    return () => {
      setFavSelected(false);
      setRate(0);
      setSearchCompanyKey("");
    };
  }, []);

  useEffect(() => {
    updatePageTitle();
    if (
      !path_record?.old
        ?.toLowerCase()
        .includes(k_create_request_paths.shift_details)
    ) {
      if (path.includes(k_create_request_paths.create))
        setExpandedAccordion(null);
    }
    if (path.includes("/request/edit")) {
      loadRequest();
    }
  }, [path]);


  const loadRequest = async () => {
    resetFilterFormCopy();
    resetFilterForm();

    if (request_id) {
      if (location?.state?.is_fetch_req_details === false && fetchReqDetails === false && (request_history?.id == request_id) && cr_form?.id) return console.log("SKIPPED FETCHING REQUEST DETAILS DATA");

      console.log("loadRequest: calling getNewRequestDetails ");

      let latest_new_request_data = await getNewRequestDetails(request_id);
      await loadCreateRequestData(latest_new_request_data?.data ?? request_history);
    }
    getInitData();
  };

  return (
    <div
      // onClick={() => {
      //   console.log("cr_form:::", cr_form);
      //   console.log("request_history:::", request_history);
      // }}
      className="relative flex flex-col justify-start scroll-smooth">
      <TitleAndActions />
      {current_step === create_request_steps.tableView ? (
        <TableViewOrAllShift current_step={current_step} />
      ) : (
        <ContentAndSummary current_step={current_step} />
      )}
    </div>
  );
}



const TableViewOrAllShift = ({ current_step }) => (
  <div>
    {current_step === create_request_steps.tableView && <TableView />}
  </div>
);

const ContentAndSummary = ({ current_step }) => (
  <div
    className="flex flex-row justify-between items-start space-x-10">
    <Content />

    {current_step !== create_request_steps.tableView &&
      current_step !== create_request_steps.allShifts && (
        <CreateRequestSummary />
      )}
  </div>
);
