// hooks/usePathChecks.js
import { useLocation } from 'react-router-dom';
import { k_request_action } from '../utility/const';

export function useRequestDetailsPathChecks(type, request_id, shift_id) {

    const location = useLocation();

    const detailsPath = `/requests/${type}/details/${request_id}/`;
    const request_list = `/requests/${type}`;
    const request_details_path = `/requests/${type}/details/${request_id}`;
    const isDetailsPath =
        location.pathname === `/requests/${type}/details/${request_id}`;
    const editMode =
        location.pathname === `/requests/${type}/details/${request_id}`;
    const isUpDateProposalPath =
        location.pathname === detailsPath + k_request_action.UpDateProposal;
    const isProposalPath =
        location.pathname === detailsPath + k_request_action.proposal;
    const isAddAcknowledgement =
        location.pathname ===
        detailsPath + k_request_action.AddAcknowledgement;
    const isShiftDetailsPath =
        location.pathname === detailsPath + `shift/${shift_id}`;
    const isBudgetPath =
        location.pathname === detailsPath + k_request_action.budget;
    const isPlaceBidPath =
        location.pathname === detailsPath + k_request_action.placeBid;

    const isAssignNotPlannedRequestShiftPath =
        location.pathname === detailsPath + k_request_action.assignNotPlannedRequestShift;

    const isAcknowledgementPath = location.pathname === detailsPath + k_request_action.acknowledgement;

    const isEditPath =
        location.pathname.includes(`/requests/${type}/details/${request_id}/edit`) ;

    const isAvailableShifts =
        location.pathname === detailsPath + k_request_action.availableShifts;

    const isUpDateProposal =
        location.pathname === detailsPath + k_request_action.UpDateProposal;

    return {
        request_list,
        request_details_path,
        detailsPath,
        isDetailsPath,
        isEditPath,
        isUpDateProposalPath,
        isProposalPath,
        isAddAcknowledgement,
        isShiftDetailsPath,
        isBudgetPath,
        isPlaceBidPath,
        isAvailableShifts,
        isUpDateProposal,
        editMode,
        isAssignNotPlannedRequestShiftPath,
        isAcknowledgementPath
    };
}

