import React from "react";
import useCreateRequestStore, { handleUpdate } from "../../../../../../app/stores/others/createRequestStore";
import { create_request_steps } from "../../../../../../app/utility/const";
import { iLeftArrow, iQuestion, iSmallReloader } from "../../../../../../app/utility/imageImports";
import CommonButtonOutlined from "../../../../../../components/button/CommonButtonOutlined";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import TableViewInstructionsModal from "../../../modal/TableViewInstructionsModal";
import BackLink from "../../../../../../components/backLink/BackLink";
import { useLocation, useNavigate } from "react-router-dom";

const TableViewHeader = () => {
    const {
        setCurrentSetup,
        setRemoveAllStopConfirmationModal,
        setTableViewInstructionsModal,
    } = useCreateRequestStore();

    const [isClearButtonHover, setClearButtonHover] = useState(false);

    const navigateTo = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();

    const getReferrerName = () => {
        switch (location?.state?.referrer) {
            case "awarded" : return t("Upcoming");
            case "in-bidding": return "In Bidding";
            case "on-going": return "Ongoing";
            case "not-planned": return "Not Planned";
            default: return location?.state?.referrer
        }
    }

    const getLinksArray = () => {

        if (location?.state?.referrer) {

            return [
                {
                    label: getReferrerName(),
                    isActive: false,
                    linkFn: "",
                    linkTo: `/requests/${location?.state?.referrer}`,
                    cursorPointer: true,
                    onClick: () => {
                        setCurrentSetup("");
                    }
                },
                {
                    label: "Request details",
                    isActive: false,
                    linkFn: "",
                    linkTo: `/requests/${location?.state?.referrer}/details/${location?.state?.request_id}`,
                    state: location?.state,
                    cursorPointer: true,
                    onClick: () => {
                        setCurrentSetup("");
                    }
                },
                {
                    label: "Edit",
                    isActive: false,
                    linkFn: "",
                    linkTo: `/request/edit/${location?.state?.request_id}`,
                    state: location?.state,
                    cursorPointer: true,
                    onClick: () => {
                        setCurrentSetup("");
                    }
                },
                {
                    label: "Mass upload",
                    isActive: true,
                    linkFn: "",
                    linkTo: "",
                    cursorPointer: false,
                    onClick: () => {
                        setCurrentSetup("");
                    }
                },
            ]

        } else

            return [
                {
                    label: t("Create Request"),
                    isActive: false,
                    linkFn: "",
                    cursorPointer: true,
                    onClick: () => {
                        setCurrentSetup("")
                    }
                },
                {
                    label: t("Mass upload"),
                    linkTo: "",
                    isActive: true,
                },
            ];

    };

    return (
        <div className="mb-2">
            <div className="bg-white h-auto flex flex-row justify-between space-x-10  z-[11] mb-4">
                <div>
                    <div className="flex items-center justify-center">
                        <img
                            onClick={() => {
                                if (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") {
                                    setCurrentSetup("");
                                    return navigateTo(`/request/edit/${location?.state?.request_id}`, { state: location?.state })
                                }
                                setCurrentSetup(create_request_steps.mass_import);
                            }}
                            className="cursor-pointer h-s17"
                            src={iLeftArrow}
                            alt=""
                        />
                        <div className="ml-2 title">Back to request</div>

                        <div
                            onClick={() => {
                                setRemoveAllStopConfirmationModal(true);
                            }}
                            onMouseEnter={() => {
                                setClearButtonHover(true);
                            }}
                            onMouseLeave={() => {
                                setClearButtonHover(false);
                            }}
                            className={` flex rounded items-center py-2 px-4 ${isClearButtonHover ? "bg-cGray50" : ""
                                } justify-center ml-5 space-x-2 cursor-pointer`}
                        >
                            <img
                                className="w-s13 h-s15"
                                src={iSmallReloader}
                                alt=""
                            />
                            <div
                                className={`font-medium txt-fs16  `}
                            >
                                Clear all
                            </div>
                        </div>
                    </div>
                </div>

                <CommonButtonOutlined
                    onClick={handleUpdate}
                    width="w-[120px]"
                    height="h-11"
                    text="fs16"
                    btnLabel={t("Update")}
                />
            </div>
            <BackLink
                cursorPointer={false}
                linksArray={getLinksArray()}
            />

            <div
                className="flex justify-end space-x-2 ">
                <div onClick={() => setTableViewInstructionsModal(true)} className="font-medium leading-6 cursor-pointer text-fs14 text-cGray600">How can I fill up this form</div>
                <img onClick={() => setTableViewInstructionsModal(true)} src={iQuestion} alt="" className="cursor-pointer" />
            </div>


            <TableViewInstructionsModal />
        </div>
    );
};

export default TableViewHeader;
