
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import CommonChatBox from './CommonChatBox';
import useChatStore, { getChatCounter, getChatList, getMessageList } from '../../../../app/stores/others/chatStore';
import CommonSearchBox from '../../../../components/input/CommonSearchBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatChatTimestamp, initializeFirebase } from '../../../../app/utility/utilityFunctions';
import { useEffect } from 'react';
import { chat_role, user_role } from '../../../../app/utility/const';
import { useDebounce } from 'use-debounce';
import PageScrollLoader from './PageScrollLoader';
import firebase from "../../../../app/firebase/firebase";

const ChatLeftBar = () => {

    const { t } = useTranslation();

    const { firebase_new_message, setFirebaseNewMessage, current_role, setTextFieldCount, setMessage_scroll_bottom, setAddChatList, chat_list, selectChat, setSelectChat, setChatSearch, chat_search } = useChatStore();

    const [scroll, setScroll] = useState(false);

    const [searchValue] = useDebounce(chat_search, 500);

    let user = JSON.parse(localStorage.getItem('user'));

    const incrementData = async () => {
        setScroll(true);
        const success = await getChatList(chat_search, false, false, true)
        if (success?.success) {

            setAddChatList({
                current_page: success?.data?.current_page,
                last_page: success?.data?.last_page,
                data: [...chat_list?.data, ...success?.data?.data],
                total: success?.data?.total
            });
            setScroll(false);
        } else {
            setScroll(false);
        }
    }

    useEffect(() => {
        getChatList(chat_search, false);
        getChatCounter();
    }, [])

    // chat search
    useEffect(() => {
        getChatList(searchValue, false)
    }, [searchValue])

    useEffect(() => {
        notificationFetch();
    }, [firebase_new_message, localStorage.getItem('chat_message')])


    // get message notification then refresh chat list
    const notificationFetch = async () => {
        if (firebase_new_message) {
            const success = await getChatList(chat_search, false, false);
            if (success?.success) {
                setFirebaseNewMessage(false);
            }
        }
    }

    const handleChatBoxClick = async (item) => {
        setTextFieldCount(40);
        if (selectChat?.id !== item?.id) {
            const success = await getMessageList({ "chat_id": item?.id, take: 30 });
            if (success?.success) {
                await setSelectChat(item);
                await getChatList(searchValue, false, false, false, chat_list?.data?.length);
                initializeFirebase(true, firebase);
                await getChatCounter();
            }
        } else {
            setMessage_scroll_bottom(true);
        }
    };

    const SelectedChatIndicator = () => {
        return <div className='min-w-[4px] max-w-[4px] h-[57px] bg-cBrand'></div>;
    };



    return (
        <div className='rounded-br4'>
            <div className='mx-4'>
                <CommonSearchBox
                    value={chat_search}
                    onChange={(e) => { setChatSearch(e.target.value) }}
                    fullWidth={true}
                    onSearchClear={() => { setChatSearch("") }}
                    withClearSearch={true}
                    roundedFull={false}
                />
            </div>

            <div className='mt-s12'>
                {
                    chat_list?.data?.length ?
                        <div
                            className='overflow-y-auto' id="scrollableDiv">
                            <InfiniteScroll
                                height={window.innerHeight - 240}
                                dataLength={chat_list?.data?.length ?? 0} //This is important field to render the next data
                                next={() => {
                                    if (!scroll && (chat_list?.current_page < chat_list?.last_page)) {
                                        incrementData();
                                    }
                                }}
                                hasMore={!scroll && chat_list?.current_page < chat_list?.last_page}
                                scrollableTarget="scrollableDiv"
                                scrollThreshold={0.9}
                            >
                                <div className="space-y-2 ">
                                    {
                                        chat_list?.data?.map((item, index) => {

                                            const { id, image, user_type, last_message, name, created_at, email } = item;
                                            if (!id) return null;

                                            const isSeen = last_message?.from_type === current_role || last_message?.to_is_read;

                                            return (
                                                <div key={index} className='flex w-full h-full space-x-1'>
                                                    {selectChat?.id === id ? <SelectedChatIndicator /> : <div className='min-w-[4px] max-w-[4px]'></div>}
                                                    {(email !== user?.email) &&
                                                        <CommonChatBox
                                                            image={image}
                                                            user_type={user_type === user_role.customer ? chat_role.customer : user_type}
                                                            isSeen={isSeen}
                                                            name={name}
                                                            shortMessage={last_message?.message}
                                                            onClick={() => handleChatBoxClick(item)}
                                                            date_time={formatChatTimestamp(created_at)}
                                                        />}
                                                    <div className='min-w-[4px] max-w-[4px]'></div>
                                                </div>
                                            );
                                        })}
                                </div>

                                {
                                    scroll && <PageScrollLoader />
                                }
                            </InfiniteScroll>
                        </div>
                        :
                        <div className='h-[calc(100vh-240px)] space-y-2 mx-14'>
                            <div className='text-center text-fs20 font-fw600 text-cNero'>{t("No chat history")}</div>
                            <div className='text-center text-fs14 font-fw400 text-cLesson'>{t("To create a chat please click on the function icon at the top right corner")}</div>
                        </div>
                }
            </div>
        </div>
    );
};

export default ChatLeftBar;