import React from 'react'
import SummaryInfoItem from './components/SummaryInfoItem';
import { useTranslation } from 'react-i18next';

/**   
Props =>
content = [
      {
        title: 'Title',
        description: 'Home Shifting BD to DK',
      },
    ],
title = 'Summary',
*/
export default function Summary(pros) {

  const {
    content = [
      {
        title: 'Title',
        description: 'Home Shifting BD to DK',
        className: '',
        onClick: () => { },
      },
      {
        title: 'Transportation',
        description: 'Home Moving',
      },
      {
        title: 'Pickup Data',
        description: '10. Jan 2023',
      },
      {
        title: 'Pickup Time',
        description: '08.00 - 10:00',
      },
      {
        title: 'Delivery Overview',
        description: '7 Stops (35 Packages)',
      },
    ],
    content2 = null,
    content3 = null,
    content4 = null,
    title = ('Summary'),
    bg = 'bg-cSecondary50',
    width = "min-w-[380px] max-w-[380px]"
  } = pros;

  const { t } = useTranslation();

  return (
    <div
      className={`w-full ${width}`}>
      <div className='sub-title !leading-[31.2px]'>{t(title)}</div>
      <div className={`w-full ${bg} mt-4 p-4 rounded-br4`}>
        {
          <>
            {
              <div className='space-y-2'>
                {content.map((item, index) => (
                  <SummaryInfoItem key={item + index} title={item?.title} description={typeof item?.description === 'string' ? item.description.trim() : ""} className={item?.className} onClick={item?.onClick} titleClassName={item?.titleClassName} />
                ))}
              </div>
            }

            {content2 && <div className={`my-2  border-t-[1px] border-cGray200 h-s1 flex flex-row justify-center `} />}

            {
              content2 &&
              <div className='space-y-2'>
                {content2.map((item, index) => (
                  <SummaryInfoItem key={index} title={item.title} description={item.description} className={item?.className} onClick={item?.onClick} />
                ))}
              </div>
            }

            {content3 && <div className={`my-2  bg-cGray200 h-s1 flex flex-row justify-center `} />}

            {
              content3 &&
              <div className='space-y-2'>
                {content3.map((item, index) => (
                  <SummaryInfoItem key={index} title={item.title} description={item.description} className={item?.className} onClick={item?.onClick} />
                ))}
              </div>
            }

            {content4 && <div className={`my-2 bg-cGray200 h-s1 flex flex-row justify-center`} />}

            {
              content4 &&
              <div className='space-y-2'>
                {content4.map((item, index) => (
                  <SummaryInfoItem key={index} title={item.title} description={item.description} className={item?.className} onClick={item?.onClick} />
                ))}
              </div>
            }
          </>

        }
      </div>
    </div>
  )
}
