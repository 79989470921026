/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Clamp from "react-multiline-clamp";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRequestDetailsPathChecks } from "../../../../../../../app/hooks/useRequestDetailsPathChecks";
import useShiftStore, {
  defineScheduleInfo,
  getShiftDetails,
} from "../../../../../../../app/stores/company/shiftStore";
import useCreateRequestStore from "../../../../../../../app/stores/others/createRequestStore";
import useRequestStore, {
  getRequestShiftPlannerList
} from "../../../../../../../app/stores/others/requestStore";
import {
  all_shift_path,
  create_request_steps,
  request_details_types,
} from "../../../../../../../app/utility/const";
import RequestAccordion from "../../../../../../../components/Accordion/RequestAccordion";
import CommonEmptyData from "../../../../../../../components/emptyData/CommonEmptyData";
import CommonTable from "../../../../../../../components/table/CommonTable";
import ShiftDetails from "../../../../../../company/shiftManager/components/ShiftDetails";
import ShiftDetailsMainContents from "../../../../../../company/shiftManager/components/ShiftDetailsMainContents";
import CommonEmptyView from "../../../../../createRequest/components/content/selectShift/components/CommonEmptyView";
import ShiftDetailsModal from "../../../../../createRequest/components/content/selectShift/components/ShiftDetailsModal";
import useGeneralStore from "../../../../../../../app/stores/others/generalStore";
import useLayoutStore from "../../../../../../../app/stores/others/layoutStore";

const AvailableShifts = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { not_planned_stops, request_details, available_shifts, setRedirectingToShiftDetails } = useRequestStore();
  const { expandedAccordion, setExpandedAccordion, setCurrentSetup } = useCreateRequestStore();
  const { user_role } = useGeneralStore();
  const { shiftDetailsData, setShowAddShiftModal, setAllShiftCarList, setAllShiftDriverList, setShiftDetailsData } = useShiftStore();
  const { setActiveSection } = useLayoutStore();
  const navigateTo = useNavigate();

  const params = useParams();
  const { type, request_id } = params;

  const [showModal, setShowModal] = useState(false);
  const [selected_shift_index, setSelectedShiftIndex] = useState(false);
  const { isEditPath } = useRequestDetailsPathChecks(type, request_id);

  const headers = [
    { name: "#", placement: "justify-center items-center" },
    { name: t("Drivers") },
    { name: t("Schedule") },
    { name: t("Load") },
  ];

  const hasRequestStops = () => {
    return request_details?.pickup_address && request_details?.pickup_date && request_details?.pickup_start_time && request_details?.pickup_end_time &&
      request_details?.transport_type && request_details?.stops?.length;
  }

  useEffect(() => {
    if (request_details?.id == request_id) {
      if (!hasRequestStops()) return;
      let start_time = request_details?.pickup_start_time;
      let end_time = request_details?.pickup_end_time;

      if (
        not_planned_stops?.length > 0 &&
        (type === request_details_types.not_planned ||
          type === request_details_types.in_bidding ||
          type === request_details_types.invitation ||
          location.pathname.includes("global-request/details") ||
          type === request_details_types?.saved
        )
      ) {

        const start_date = request_details?.pickup_date;

        if (!request_details?.stops?.length) return
        getRequestShiftPlannerList(start_time, end_time, start_date);
      }
    }
  }, [request_details, not_planned_stops]);

  const expandController = async () => {
    setExpandedAccordion(
      expandedAccordion === create_request_steps.select_shift
        ? null
        : create_request_steps.select_shift
    );
    if (
      (type === request_details_types?.ongoing ||
        type === request_details_types?.completed ||
        type === request_details_types?.awarded ||
        type === request_details_types?.history) &&
      !shiftDetailsData?.id
    ) {
      // console.log("calling getShiftDetails with ID: ", request_details?.shift_plan?.id);

      await getShiftDetails(request_details?.shift_id);
    }
  };

  const getAssignedShiftDetails = async () => {
    const data = await getShiftDetails(request_details?.shift_id);
  }

  useEffect(() => {
    if (request_details?.shift_id) {
      console.log("calling getShiftDetails from Available shifts with ID: ", request_details?.shift_id);
      getAssignedShiftDetails()
    }
  }, [request_details?.shift_id]);

  const requestType = type ? type : location?.pathname?.includes("/global-request/details") ? "global-request" : "";

  const setLocalUrl = () => {
    let backUrl = location.pathname
    let allShiftBackUrl = localStorage?.getItem("goBack")
    if(location.pathname?.includes("all-shift")) {
        backUrl = allShiftBackUrl
    }
    localStorage.setItem("shiftDetailsGoBack", backUrl)
}

  function extractCounts(data) {
    let productCount = 0;
    let stopCount = 0;
    let requestCount = 0;

    // Count products and stops from req_with_stops
    if (data.req_with_stops && Array.isArray(data.req_with_stops)) {
      requestCount = data.req_with_stops.length; // Count of requests
      data.req_with_stops.forEach(request => {
        if (request.stops && Array.isArray(request.stops)) {
          stopCount += request.stops.length; // Count of stops
          request.stops.forEach(stop => {
            productCount += stop.products_count; // Sum of products
          });
        }
      });
    }

    // Count stops from custom_stops
    if (data.custom_stops && Array.isArray(data.custom_stops)) {
      stopCount += data.custom_stops.length; // Add custom stops count
    }

    return `${requestCount} | ${stopCount} | ${productCount}`;
  }

  const contents = available_shifts?.slice(0, 10)?.map((item, index) => {
    return {
      contents: [
        {
          value: (index + 1),
        },
        {
          value: (
            <Clamp lines={1}>
              {item?.shift_drivers?.length > 0
                ? item?.shift_drivers
                  ?.map((driver) =>
                    driver?.is_main_driver
                      ? driver?.driver?.name + " (M)"
                      : driver?.driver?.name,
                  )
                  .join(", ")
                : item?.driver_user?.name + " (M)" ?? ""}
            </Clamp>
          ),
        },
        {
          value: defineScheduleInfo({
            start_date: item?.shift_start_date,
            end_date: item?.shift_end_date,
            start_time: item?.shift_start_time,
            end_time: item?.shift_end_time,
          }),
        },
        {
          value: (
            <Tooltip title={"Request | Stops | Packages"}>
              {extractCounts(item)}
            </Tooltip>
          ),
        },
      ],
      clickAction: () => {
        if (location.pathname.includes(`/global-request/details/${request_id}`)) {
          setCurrentSetup(null);
          console.log("clickAction: setCurrentSetup to NULL");
        }
        setShiftDetailsData(null)
        setSelectedShiftIndex(index);
        setRedirectingToShiftDetails(true);
        setLocalUrl()
        navigateTo(
          `/requests/${requestType}/details/${request_id}/shift/${item?.id}`,
          {
            state: {
              referrer: requestType,
              role: user_role,
              request_id: request_id,
              isEditMode: location.pathname?.includes(`/requests/${type}/details/${request_id ?? location?.state?.request_id}/edit`)
            }
          }

        );
      },
    };
  });

  const getShiftPlanText = () => {
    switch(true) {
      case location?.state?.referrer === request_details_types.invitation : return "No available shift found!"
      case location?.state?.referrer === request_details_types.in_bidding : return "No available shift found!"
      case type === request_details_types.invitation : return "No available shift found!"
      case type === request_details_types.in_bidding : return "No available shift found!"
      case location?.pathname?.includes("global-request"): return "No available shift found!"
      default: return "No shift plan found!"
    }
  }

  const getSubtitleOne = () => {
    if (!request_details?.shift_id && (location.pathname.includes(`requests/${type}/details/${request_id}`) || location.pathname.includes("global-request/details"))) {
      if (hasRequestStops()) {
        return available_shifts?.length ? available_shifts?.length > 1 ? available_shifts?.length + " Shifts found" : available_shifts?.length + " Shift found" : getShiftPlanText();
      }
      else return "No info added yet";
    }
    else if (type && request_details?.shift_with_car_and_driver?.driver_user?.name) {
      return request_details?.shift_with_car_and_driver?.driver_user?.name;
    }
    else if (type === request_details_types.awarded && request_details?.shift_id && shiftDetailsData?.id) {
      return shiftDetailsData?.driver_user?.name;
    }
    else if (type === request_details_types.ongoing) {
      return request_details?.driver?.details?.name;
    }
    return "";
  }

  return (
    <div>
      {
        <RequestAccordion
          showClearButton={false}
          title={expandedAccordion === create_request_steps.select_shift && (location.pathname.includes(`requests/${type}/details/${request_id}`) && !request_details?.shift_id) ? t("Shift Planner") + ` (${available_shifts?.length})` : t("Shift Planner")}
          subTitleOne={getSubtitleOne()}
          // subTitleTwo={data?.pickup_address}
          titleRightComponent={
            request_details?.shift_id ? "" :
              available_shifts?.length > 10 ? t("See all shifts") :
                location.pathname.includes(`requests/not-planned/details/${request_id}/edit`) && !request_details?.shift_plan?.id ?
                  "Add Shift"
                  : <></>
          }
          titleRightComponentOnClick={() => {
            if (available_shifts?.length > 10) {
              setCurrentSetup(create_request_steps?.allShifts)
              localStorage.setItem("goBack", location.pathname)
              navigateTo(
                all_shift_path,
                {
                  state: {
                    referrer: requestType,
                    role: user_role,
                    request_id: request_id,
                    isEditMode: location.pathname?.includes(`/request/edit/${request_id}`) || location.pathname.includes(`/requests/${type}/details/${request_id}/edit`) ? true : false,
                    redirectFromRequest: !!location.pathname.includes(`/requests/${type}/details/${request_id}`) || !!location.pathname.includes(`/global-request/details/${request_id}`)
                  }
                }
              );
            }
            else {
              setShowAddShiftModal(true);
              setAllShiftDriverList([]);
              setAllShiftCarList([]);
            }
          }}

          isTable={true}
          isExpanded={expandedAccordion === create_request_steps.select_shift}
          onExpand={expandController}
        >
          {
            location.pathname.includes(`requests/saved/details/${request_id}`) && request_details?.shift_id ?
              <ShiftDetails outlinedBox={true} withTopBar={false} isFullWidth={true} shiftID={request_details?.shift_id} />
              :
              location.pathname.includes(`requests/saved/details/${request_id}`) && !request_details?.shift_id ?
                Object.values(available_shifts)?.length > 0 ?
                  <div className="mt-[-20px]">
                    <CommonTable
                      tableTitle={t("")}
                      tableTitleClassName={"sub-title"}
                      showSearchBox={false}
                      sort={false}
                      showTopRightFilter={false}
                      showTakeOption={false}
                      showPagination={false}
                      showPageCountText={true}
                      tableHeaders={headers}
                      tableContents={contents}
                      widths="50px auto 250px 90px"
                      hasClickFunction={true}
                      outerPadding="p-s0"
                      topRightFilterComponentOnClick={() => { }}
                      paginationObject={{}}
                      emptyDataMessage="No Shift Available!"
                      withClearSearch={false}
                      onSearchClear={() => { }}
                      searchValue={""}
                      searchOnChange={(e) => { }}
                      currentTakeAmount={10}
                      withReloader={true}
                      onReload={() => { }}
                      filtered={false}
                      headersFirstIndexFixApply={false}
                      takeOptionOnChange={async (e) => { }}
                      paginationOnClick={async (url) => { }}
                    />
                  </div>
                  :
                  <CommonEmptyView message={t("No Shift Available!")} />
                :
                request_details?.shift_id ?
                  <ShiftDetails outlinedBox={true} withTopBar={false} isFullWidth={true} shiftID={request_details?.shift_id} />
                  :
                  Object.values(available_shifts)?.length > 0 ? (
                    type === request_details_types?.ongoing ||
                      type === request_details_types?.completed ||
                      type === request_details_types?.history ? (
                      <ShiftDetailsMainContents />
                    ) : (
                      <div className="mt-[-20px]">
                        <CommonTable
                          tableTitle={t("")}
                          tableTitleClassName={"sub-title"}
                          showSearchBox={false}
                          sort={false}
                          showTopRightFilter={false}
                          showTakeOption={false}
                          showPagination={false}
                          showPageCountText={true}
                          tableHeaders={headers}
                          tableContents={contents}
                          widths="50px auto 250px 90px"
                          hasClickFunction={true}
                          outerPadding="p-s0"
                          topRightFilterComponentOnClick={() => { }}
                          paginationObject={{}}
                          withClearSearch={false}
                          onSearchClear={() => { }}
                          searchValue={""}
                          searchOnChange={(e) => { }}
                          currentTakeAmount={10}
                          withReloader={true}
                          onReload={() => { }}
                          filtered={false}
                          headersFirstIndexFixApply={false}
                          takeOptionOnChange={async (e) => { }}
                          paginationOnClick={async (url) => { }}
                          emptyDataMessage="No shift available"
                        />
                      </div>
                    )
                  ) : (
                    <CommonEmptyView message={isEditPath ? t("Fill out the required fields first.") : t("No Shift Available!")} />
                  )}
        </RequestAccordion>

        // : (
        //     <CommonEmptyData
        //         title={t("No Shift Available!")}
        //         details={t("No Shift Available to Plan!")}
        //     />
        // )
      }

      <ShiftDetailsModal
        showModal={showModal}
        setShowModal={setShowModal}
        selected_shift_index={selected_shift_index}
      />
    </div>
  );
};

export default AvailableShifts;
