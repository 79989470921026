import React from 'react'
import { useTranslation } from 'react-i18next';
import useCarStore from '../../../../app/stores/company/carStore';
import CommonViewComponent from '../../../../components/viewer/CommonViewComponent';

const AppliedForLicense = () => {
    const { carDetails } = useCarStore();

    const { t } = useTranslation();

    return (
        <div>
            <div className='sub-title'>{t("Applied for license")}</div>
            <div className='mt-1 space-y-3'>
                <CommonViewComponent labelText={t('License Active On')} value={carDetails?.license_start} />
                <CommonViewComponent labelText={t('Applied On')} value={carDetails?.license_applied_date} />
                <CommonViewComponent labelText={t('Application Note')} value={carDetails?.applied_note} />
            </div>
        </div>
    )
}

export default AppliedForLicense