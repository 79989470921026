/* eslint-disable react-hooks/exhaustive-deps */
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Image from '../image/Image';
import { ThemeProvider } from '@mui/material';
import TextField from '@mui/material/TextField';
import { theme, theme2 } from '../../app/utility/utilityFunctions';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { iCloseIcon, iDropdownArrowIcon, iDummyUserAvatar } from '../../app/utility/imageImports';

const CommonSelect = ({
    onOpen = () => { },
    isInvalid = false,
    label = "Select",
    isReadOnly = false,
    dataArray = [
        { title: "ONE", value: 1 },
        { title: "TWO", value: 2 },
        { title: "THREE", value: 3 },
        { title: "FOUR", value: 4 },
    ],
    value = '',   //example: 3
    onChange = () => { },
    required = false,
    disabled = false,
    show_asterisk = true,
    show_image = false,
    placeholder = `Select ${label}`,
    isRoundedFull = false,
    showClear = false,
    noDataText="No Data Found"
}) => {
    const [selectValue, setSelectValue] = useState(value);
    const [isOnFocus, setIsOnFocus] = useState(false);
    const { t } = useTranslation();

    const data_Array = dataArray?.length > 0
        ? [
            {
                title: placeholder,
                value: null
            },
            ...dataArray
        ]
        : dataArray;

    const handleChange = (event) => {
        if (event.target.value === null) {
            onChange(event, fetchTitle(""));
            setSelectValue("");
            handleClose();
            setIsOnFocus(false)
        } else {
            onChange(event, fetchTitle(event.target.value));
            setSelectValue(event.target.value);
        }
    };

    const fetchTitle = (selectionID) => {
        let res = data_Array.find(item => item.value === selectionID);
        return res?.title;
    }

    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOnFocus(false);
        setIsOpen(false)
    }

    useEffect(() => {
        setSelectValue(value);
        setIsOnFocus(!!value)
    }, [value])

    useEffect(() => {
        if (!selectValue) {
            handleClose();
        }
    }, [selectValue, handleChange])


    const handleOpen = () => {
        setIsOpen(true)
    }

    return (

        <ThemeProvider theme={show_asterisk ? theme2 : theme}>
            <select autoFocus value={value} className='absolute h-0 outline-none caret-transparent' />
            <TextField
                select
                id="standard-basic"
                variant="standard"
                focused={selectValue === null ? false : isOnFocus}
                autoFocus={false}
                label={label}
                onFocus={() => setIsOnFocus(true)}
                onBlur={() => setIsOnFocus(false)}
                SelectProps={{
                    onOpen: handleOpen, // Handle the open event
                    onClose: handleClose,
                    IconComponent: () => (
                        <div
                            className='pointer-events-none'
                            style={{ position: 'absolute', bottom: '3px', right: '0', padding: '0', margin: '0', display: 'flex', flexDirection: 'row-reverse', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={iDropdownArrowIcon} alt='down arrow icon' className='cursor-pointer' />
                            {showClear && value && <img src={iCloseIcon} alt='close icon' className="cursor-pointer" onClick={(e) => {
                                e.stopPropagation();
                                onChange(null)
                            }} />}
                        </div>
                    ),
                }}
                inputProps={{
                    readOnly: isReadOnly,
                }}
                disabled={disabled}
                required={required}

                value={value}
                onChange={(e) => handleChange(e)}
                open={isOpen}

                // sx={{
                //     // normal label color

                //     "& label": {
                //         color: '#48504C',
                //         fontFamily: 'fEuclidLimadiRegular',
                //         fontWeight: !value ? '' : 'normal',
                //     },
                //     '& .MuiFormLabel-asterisk': {
                //         color: show_asterisk && '#48504C',
                //     },

                //     //label color when input is focused
                //     "& label.Mui-focused": {
                //         color: '#48504C',
                //         fontWeight: 'normal',
                //         backgroundColor: '#FFFFFF',
                //     },

                //     // focused color for input with variant='standard'
                //     "& .MuiInput-underline:after": {
                //         borderBottomColor: isInvalid ? "#F0554B" : '#BCC2BF',
                //     },
                //     // focused color for input with variant='filled'
                //     "& .MuiFilledInput-underline:after": {
                //         borderBottomColor: isInvalid ? "#F0554B" : '#BCC2BF',
                //     },
                //     // focused color for input with variant='outlined'
                //     "& .MuiOutlinedInput-root": {
                //         "&.Mui-focused fieldset": {
                //             borderColor: '#BCC2BF',
                //             backgroundColor: '#FFFFFF',
                //         }
                //     },

                //     "& .MuiInputBase-input": {
                //         fontSize: 14,
                //         fontFamily: 'fEuclidLimadiRegular',
                //         fontWeight: 'normal',
                //         backgroundColor: 'transparent !important',
                //     },
                //     "& .Mui-focused .MuiInputBase-input": {
                //         backgroundColor: 'transparent !important',
                //     },


                //     '&:-webkit-autofill': {
                //         WebkitBoxShadow: '0 0 0 1000px white inset',
                //         backgroundColor: '#FFFFFF !important',
                //     },
                //     "& .MuiSelect-select[value='null']": {
                //         backgroundColor: 'transparent !important',
                //     },
                // }}

                sx={{
                    // normal label color
                    "& label": {
                        color: '#48504C',
                        fontFamily: 'fEuclidLimadiRegular',
                        fontWeight: !value ? '' : 'normal',
                    },
                    '& .MuiFormLabel-asterisk': {
                        color: show_asterisk && disabled ? '#BCC2BF' : '#48504C',
                        // color: show_asterisk && '#48504C',
                    },

                    "& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottomStyle: 'solid !important',
                        borderBottom: disabled ? '1px solid #D7DAD8' : '2px solid #949494',
                    },

                    "&:hover .MuiInput-root:before": {
                        borderBottom: disabled ? '1px solid #D7DAD8 !important' : '2px solid #949494 !important',
                    },

                    '& MuiSvgIcon-root:before': {
                        color: '#F0554B',
                    },

                    // label color when input is focused
                    "& label.Mui-focused": {
                        color: '#48504C',
                        fontWeight: 'normal',
                        backgroundColor: '#FFFFFF',
                    },

                    "& .Mui-disabled": {
                        cursor: "not-allowed",
                        color: "#939D98",
                    },

                    // underline color for input with variant='standard'
                    "& .MuiInput-underline:after": {
                        borderBottom: isInvalid ? "1px solid #F0554B" : disabled ? '1px solid #D7DAD8' : '1px solid #949494',
                    },
                    "& .MuiInput-underline:before": {
                        borderBottomStyle: 'solid',
                        borderBottom: isInvalid ? "1px solid #F0554B" : disabled ? '1px solid #D7DAD8' : '1px solid #949494',
                    },

                    // underline color for input with variant='filled'
                    "& .MuiFilledInput-underline:after": {
                        borderBottom: isInvalid ? "1px solid #F0554B" : disabled ? '1px solid #D7DAD8' : '1px solid #949494',
                    },
                    "& .MuiFilledInput-underline:before": {
                        borderBottomStyle: 'solid',
                        borderBottom: isInvalid ? "1px solid #F0554B" : disabled ? '1px solid #D7DAD8' : '1px solid #949494',
                    },

                    // underline color for input with variant='outlined'
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: isInvalid ? "#F0554B" : '#D7DAD8',
                        },
                        "&:hover fieldset": {
                            borderColor: isInvalid ? "#000000" : '#D7DAD8',
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: '#D7DAD8',
                            backgroundColor: '#FFFFFF',
                        }
                    },

                    "&:hover .MuiInput-underline:before": {
                        borderBottomStyle: 'solid',
                        borderBottom: isInvalid ? "2px solid #F0554B" : disabled ? "1px solid #949494" : '2px solid #949494',
                    },

                    "& .MuiInputBase-input": {
                        fontSize: 14,
                        fontFamily: 'fEuclidLimadiRegular',
                        fontWeight: 'normal',
                        backgroundColor: 'transparent !important',
                    },
                    "& .Mui-focused .MuiInputBase-input": {
                        backgroundColor: 'transparent !important',
                    },

                    '&:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                        backgroundColor: '#FFFFFF !important',
                    },
                    "& .MuiSelect-select[value='null']": {
                        backgroundColor: 'transparent !important',
                    },
                }}



                className="w-full pb-0 mb-0 bg-transparent"
            >

                {
                    data_Array?.length > 0 ?
                        data_Array?.map((item, index) =>
                            <MenuItem
                                style={{
                                    backgroundColor: (item.value === null) ? "transparent" : selectValue === item.value ? "#CBE9DA" : "",
                                    margin: '5px 10px',
                                    borderRadius: '4px',
                                }}

                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#CBE9DA',
                                    },
                                    '&:focus': {
                                        backgroundColor: item?.value ? '#CBE9DA' : '',
                                    },
                                }}


                                key={index} value={item?.value}
                            >
                                <div className="flex items-center space-x-1">
                                    {(show_image && index !== 0) && <Image src={item?.image} roundedFull={isRoundedFull} dummyImage={iDummyUserAvatar} alt={item?.title} className='w-5 h-5 rounded ' />}
                                    <div title={item?.title} className='truncate limadi-regular text-fs14'>
                                        {item?.title}
                                    </div>
                                </div>
                            </MenuItem>
                        ) :
                        <div className='font-normal text-center text-fs14 text-cGray800'>{noDataText}</div>
                }
            </TextField>
        </ThemeProvider>
    )
}

export default CommonSelect


