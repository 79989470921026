import React from "react";
import { useTranslation } from "react-i18next";
import useRequestStore from "../../../../../../../app/stores/others/requestStore";
import Image from "../../../../../../../components/image/Image";
import useGeneralStore, {
  getAllUserInfo,
} from "../../../../../../../app/stores/others/generalStore";
import { commonSelectedChat } from "../../../../../../../app/stores/others/chatStore";
import { useNavigate } from "react-router-dom";
import {
  base_url_src,
  chat_role,
  user_role as role,
} from "../../../../../../../app/utility/const";
import { iChatGreen, iDummyUserAvatar } from "../../../../../../../app/utility/imageImports";

const OnGoingDriver = () => {
  const { t } = useTranslation();

  const { request_details } = useRequestStore();

  const { user_role } = useGeneralStore();

  const navigateTo = useNavigate();

  const main_driver = request_details?.shift_derivers?.find(
    (i) => parseInt(i.is_main_driver) === 1
  );

  return (
    <div className="border-cGray200 rounded border-[1px] p-3">
      <div className="flex justify-between items-center">
        {request_details?.shift_derivers?.length > 0 ? (
          <DriverInfo
            driver={main_driver?.driver ?? "No driver"}
            vehicle={request_details?.car_license_number ?? "No license number"}
          />
        ) : (
          <DriverInfo
            driver={request_details?.driver?.details ?? "No driver"}
            vehicle={request_details?.car_license_number ?? "No license number"}
          />
        )}
        <div
          className="flex space-x-2 cursor-pointer"
          onClick={async () => {
            const res = await getAllUserInfo(
              request_details?.shift_derivers?.length > 0
                ? main_driver?.driver?.id
                : request_details?.driver?.details?.id
            );
            if (res?.success) {
              await commonSelectedChat(
                res?.data,
                user_role === role.customer
                  ? chat_role.customer
                  : chat_role.company
              );
              navigateTo("/chat");
            }
          }}
        >
          <img src={iChatGreen} className=" w-[14px] h-[14px] mt-s2" alt="" />
          <div className="text-fs16 font-medium text-cBrand leading-[21px]">
            Chat
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnGoingDriver;

const DriverInfo = ({ driver, vehicle }) => {
  const { t } = useTranslation();

  return (
    <div className="flex space-x-2">
      <img className="max-w-[50px] min-w-[50px] min-h-[50px] max-h-[50px] rounded-full object-cover border border-cGray300" src={driver?.image ? base_url_src + driver?.image : iDummyUserAvatar} />
      <div className="flex flex-col justify-center">
        <div className="font-fw500 leading-6 text-fs16 text-cGray800">
          {driver?.name ?? "Driver"}
        </div>
        <div className="flex gap-2 items-center">
          <div className="max-w-full whitespace-nowrap overflow-clip item-title-normal text-cGray500">
            Driver
          </div>
          {driver?.email && (
            <span className="h-s12 w-s1 bg-cGray200"></span>
          )}

          <div className="item-title-normal text-cGray500">
            {driver?.email}
          </div>
        </div>
      </div>
    </div>
  );
};
