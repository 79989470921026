import React from 'react'

export default function RequestID({ request_id = null }) {
    if (request_id)
        return (
            <div className="font-normal leading-[31.2px] text-fs24 mb-3 -mt-1.5 text-cGray800">
                Request ID: {request_id ?? ""}
            </div>
        )
    else return <></>
}
