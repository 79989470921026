import React from 'react';
import { iDummyUserAvatar } from '../../app/utility/imageImports';
import { base_url_src } from '../../app/utility/const';
import { useState } from 'react';


const DummyProfileImage = ({ src = "", className = "", dummyImage = "", isFullRoundedImage = true }) => {

    const [error, setError] = useState(false);

    return (
        <div className={`object-contain min-w-[200px] max-w-[200px] min-h-[200px] max-h-[200px] flex justify-center ${isFullRoundedImage ? "rounded-full" : "rounded-lg"} ${className}`}>
            <img className={`border border-cGray300 object-fill ${isFullRoundedImage ? "rounded-full" : "rounded-lg"} flex justify-center items-center min-w-[200px] max-w-[200px] min-h-[200px] max-h-[200px]`}
                src={base_url_src + src}
                alt=""
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = dummyImage ? dummyImage : iDummyUserAvatar;
                    setError(true)
                }}
            />
        </div>
    );
};

export default DummyProfileImage;