import React from 'react';

const CommonHomePageCard = ({ title = "NA", count = 0, selected = false,onClick }) => {
    return (
        <div onClick={onClick} className={`p-4 cursor-pointer ${selected ? 'border-2 border-cBrand bg-cMainWhite' : 'bg-cBgSideBar border-2 border-cBgSideBar'} rounded-br5`}>
            <div className='flex flex-col '>
                <span className='capitalize text-fs16 text-[#242826] font-fw700'>{title}</span>
                <span className='text-fs22 text-cBrand font-fw900 mt-s10'>{count}</span>
            </div>
        </div>
    );
};

export default CommonHomePageCard;