import React, { useState } from 'react'
import { iImageUploadIcon, iSignature } from "../../app/utility/imageImports";
import ImageViewerModal from './ImageViewerModal';
import { getImageExtension } from '../../app/utility/utilityFunctions';

export default function ImageViewer({ src = null, label = 'Attachment', className = '', is_signature = false, withExtension = false }) {

  const [showModal, setShowModal] = useState(false);

  return (
    <div className={`bg-cMainWhite w-[150px] h-[45px] flex rounded cp relative ${is_signature ? 'border-cMainBlue' : 'border-cMainBlue'} ${className}`}>
      <div onClick={() => setShowModal(true)} className="flex items-center justify-center space-x-2 text-center">
        <img
          src={is_signature ? iSignature : iImageUploadIcon}
          alt="img"
          className="w-[15px] h-[15px]"
        />
        <span className="text-fs16 font-fw500 text-cMainBlack overflow-clip max-w-[130px] whitespace-nowrap">{withExtension ? label + "." + getImageExtension(src) : label}</span>
      </div>

      <ImageViewerModal showModal={showModal} setShowModal={setShowModal} url={src} src={src} is_signature={is_signature} />
    </div>
  )
}
