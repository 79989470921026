/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import CommonMultiSelect from '../../../../components/select/CommonMultiSelect'
import useRequestStore, { getTableViewGlobalRequestsData, transportationTypeArray } from '../../../../app/stores/others/requestStore';
import CommonModal from '../../../../components/modal/CommonModal';
import { k_requests_status } from '../../../../app/utility/const';
import CommonSelect from '../../../../components/select/CommonSelect';
import CommonTimePicker from '../../../../components/input/CommonTimePicker';
import CommonButton from '../../../../components/button/CommonButton';
import CommonDatePicker from '../../../../components/input/CommonDatePicker';
import { getStringFromDateObject } from '../../../../app/utility/utilityFunctions';
import useGlobalReqStore from '../../../../app/stores/company/globlaReqStore';
import { useTranslation } from 'react-i18next';
import { kuGlobalRequestTableIndex } from '../../../../app/urls/companyUrl';
import MultipleSelectCombo from '../../../../components/select/MultipleSelectCombo';

export default function GlobalRequestFilter({ showModal, setShowModal }) {

  const { setGlobalRequestApiUrl, request_filter_form, changeRequestFilterForm, transport_type, resetRequestFilterForm, resetFilterRange, updateRequestFilterForm, request_filter_form_copy, updateRequestFilterFormCopy } = useRequestStore();

  const { globalReqCityList } = useGlobalReqStore();

  const divRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => { updateRequestFilterForm(request_filter_form_copy) }, [showModal]);

  useEffect(() => { if (divRef.current) divRef.current.focus() }, []);

  const cityIds = request_filter_form?.city?.map(item => item?.id)
  const cityList = globalReqCityList?.filter(item => !cityIds?.includes(item)).map(item => {return {title: item, id: item}});

  return (
    <CommonModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalSpace={true}
      modalTitle={
        <div className='flex items-baseline'>
          <div>{t('Filter')}</div>

          <div className='pl-4 text-base cursor-pointer select-none drop-shadow-sm text-cRed'
            onClick={async () => {
              await resetRequestFilterForm();
              resetFilterRange();
              setGlobalRequestApiUrl(kuGlobalRequestTableIndex);
              getTableViewGlobalRequestsData({
                data_set: k_requests_status.in_bidding,
                filter: {
                  transport_types: [],
                  pickup_date_from: "",
                  pickup_date_to: "",
                  start_time: "",
                  end_time: "",
                  city: [],
                },
                hasFilter: false
              });
              updateRequestFilterFormCopy("");
              setShowModal(false);
            }}>
            {t("Clear")}
          </div>

        </div>
      }
      mainContent={
        <>
          <form className='mt-6' onSubmit={async (e) => {
            e.preventDefault();
            setGlobalRequestApiUrl(kuGlobalRequestTableIndex);
            await getTableViewGlobalRequestsData({
              data_set: k_requests_status.in_bidding, filter: {
                transport_type: request_filter_form?.transport_types?.map(item => {return item?.id}),
                pickup_date_from: request_filter_form?.pickup_date_from,
                pickup_date_to: request_filter_form?.pickup_date_to,
                start_time: request_filter_form?.start_time,
                end_time: request_filter_form?.end_time,
                city: request_filter_form?.city?.map(item => {return item?.id}),
              }
            });
            updateRequestFilterFormCopy(request_filter_form);
            setShowModal(false);
          }}>

            <div tabIndex="0" ref={divRef} className='pt-0' ></div>

            <div className="mt-[28px]">
              <MultipleSelectCombo
                label={t('Type')}
                dataArray={transportationTypeArray()}
                onChange={(value) => {
                  changeRequestFilterForm('transport_types', value);
                }}
                selectedId={request_filter_form?.transport_types}
                />
            </div>

            <div className='mt-s28'>
              <MultipleSelectCombo
                label={t('Select City')}
                selectedId={request_filter_form?.city}
                dataArray={globalReqCityList?.length > 0 ? cityList : []}
                onChange={(value) => { changeRequestFilterForm('city', value) }}
              />
            </div>

            <div className="mt-[26px] grid grid-cols-2 items-center gap-7">
              <CommonDatePicker
                value={request_filter_form?.pickup_date_from}
                onChange={(date) => {
                  if(!date) {
                    changeRequestFilterForm('pickup_date_from', null)
                    return;
                  }
                  if (new Date(date) > new Date(request_filter_form?.pickup_date_to)) {
                    changeRequestFilterForm('pickup_date_from', getStringFromDateObject(date))
                    changeRequestFilterForm('pickup_date_to', "")
                  } else {
                    changeRequestFilterForm('pickup_date_from', getStringFromDateObject(date))
                  }
                }}
                label={t('Pickup date From')}
                allowPastDate={false}
                showClear={true}
              />

              <CommonDatePicker
                startDate={request_filter_form?.pickup_date_from}
                value={request_filter_form?.pickup_date_to}
                onChange={(date) => {
                  if(!date) {
                    changeRequestFilterForm('pickup_date_to', null)
                    return;
                  }
                  changeRequestFilterForm('pickup_date_to', getStringFromDateObject(date))}
                }
                label={t('Pickup date To')}
                allowPastDate={false}
                showClear={true}
              />
            </div>

            <div className="mt-[26px] grid grid-cols-2 items-center gap-7">
              <CommonTimePicker
                showExtendedTimeUi={false}
                label={t('Pickup time From')}
                init_time={request_filter_form?.start_time}
                onChange={(time) => changeRequestFilterForm('start_time', time)}
                showClear={true}
                handleClear={() => changeRequestFilterForm('start_time', null)}
                position='bottom-[10px]'
              />
              <CommonTimePicker
                showExtendedTimeUi={false}
                label={t('Pickup time To')}
                init_time={request_filter_form?.end_time}
                onChange={(time) => changeRequestFilterForm('end_time', time)}
                showClear={true}
                handleClear={() => changeRequestFilterForm('end_time', null)}
                position='bottom-[10px]'
              />
            </div>

            <div className='flex flex-row items-center justify-end w-full mt-5'>
              <CommonButton btnLabel={t('Apply Filter')} type='submit' />
            </div>

          </form>
        </>
      }
    />
  )
}
