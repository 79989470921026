/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useFavoriteCompaniesStore, {
    addFavoriteCompany,
    getCompanyDetails,
    getFavoriteCompany,
} from "../../app/stores/customer/favoriteCompaniesStore";
import { commonSelectedChat } from "../../app/stores/others/chatStore";
import useGeneralStore, {
    getAllUserInfo,
} from "../../app/stores/others/generalStore";
import useRequestStore from "../../app/stores/others/requestStore";
import useSettingsStore from "../../app/stores/others/settingsStore";
import { k_fav_company_paths } from "../../app/utility/AllRoute";
import { chat_role, k_request_paths, user_role as role } from "../../app/utility/const";
import {
    iAddressBlack,
    iChatGreen,
    iCvrBlack,
    iEmailBlack,
    iFavCompanyGray,
    iFavoriteIcon,
    iPhoneBlack,
    iWebsiteBlack
} from "../../app/utility/imageImports";
import Image from "../image/Image";
import RatingFiveStar from "../rating/RatingFiveStar";
import CommonSocialLinks from "../socialLinks/CommonSocialLinks";
import CommonModal from "./CommonModal";
import useCreateRequestStore from "../../app/stores/others/createRequestStore";

const CommonCompanyDetailsModal = ({
    showModal = false,
    setShowModal,
    company_id,
}) => {
    const { company_details } = useFavoriteCompaniesStore();

    const { request_details, setRequestDetails, isFavoriteCompanyBid, setIsFavoriteCompanyBid } = useRequestStore();

    const { setShowReviewModal, setSelectedCompanyId } = useSettingsStore();

    const [favoriteStatusChanged, setFavoriteStatusChanged] = useState(false);

    const navigateTo = useNavigate();

    const { t } = useTranslation();

    const { user_role } = useGeneralStore();
    const { selected_companies, setSelectedCompanies } = useCreateRequestStore();

    useEffect(() => {
        showModal && company_id && getCompanyDetails(company_id);
    }, [showModal, company_id]);

    const handleEmailClick = () => {
        if (company_details?.email)
            window.location = "mailto:" + company_details?.email;
    };

    const handlePhoneClick = () => {
        if (company_details?.phone)
            window.location = "tel:" + company_details?.phone;
    };

    const getWebsiteLink = () => {
        return company_details?.website?.includes("http")
            ? company_details?.website
            : `https://${company_details?.website}`;
    };

    const handleChat = async () => {
        const res = await getAllUserInfo(company_details?.id);
        if (res?.success) {
            await commonSelectedChat(
                res?.data,
                user_role === role.customer ? chat_role.customer : chat_role.company
            );
            setShowModal(false);
            navigateTo("/chat");
        }
    };

    const locallyUpdateSelectedCompanyFavStatus = (company_id = 0) => {
        let updated_selected_companies = [];
        selected_companies?.forEach((company) => {
            if (company?.id === company_id) {
                updated_selected_companies.push({ ...company, is_favorite: !!company?.is_favorite ? 0 : 1 });
            } else updated_selected_companies.push(company);
        });

        console.log("SHOW UPDATED SELECTED COMPANIES: ", updated_selected_companies);
        setSelectedCompanies(updated_selected_companies);
    }

    return (
        <div>
            <CommonModal
                showModal={showModal}
                setShowModal={setShowModal}
                modalTitle={t("Company Details")}
                modalSpace={true}
                mainContent={
                    <div className="mt-5">
                        <div className="flex space-x-[58px]">
                            <div className="flex flex-col justify-between">
                                <div>
                                    <div className="flex flex-col">
                                        <CompanyImage />

                                        <div className="mt-2 font-medium break-all min-w-[200px] text-fs16 text-cGray800">
                                            {company_details?.name}
                                        </div>
                                        <div
                                            className="flex mt-1 cursor-pointer"
                                            onClick={() => {
                                                setSelectedCompanyId(company_details?.id);
                                                setShowReviewModal(true);
                                            }}
                                        >
                                            <RatingFiveStar
                                                rating={
                                                    company_details?.rating
                                                        ? parseFloat(company_details?.rating?.toFixed(1))
                                                        : 0
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div
                                        onClick={handleChat}
                                        className="flex items-center mt-4 space-x-2 cursor-pointer justify-center-4"
                                    >
                                        <img
                                            src={iChatGreen}
                                            alt=""
                                            className="flex items-center justify-center w-4 h-4"
                                        />
                                        <div className="font-medium text-fs16 text-cBrand">
                                            {t("Chat")}
                                        </div>
                                    </div>

                                    <div
                                        onClick={async () => {
                                            setFavoriteStatusChanged(true);
                                            const success = await addFavoriteCompany(company_details?.id);
                                            locallyUpdateSelectedCompanyFavStatus(company_details?.id);
                                            if(success) setIsFavoriteCompanyBid(!isFavoriteCompanyBid)
                                            if (success && (window.location.pathname === k_fav_company_paths.list)) {
                                                await getFavoriteCompany();
                                            }

                                            if(window.location?.pathname?.includes("in-bidding") && success){
                                                const updatedBiddings = request_details?.biddings?.map(item => {
                                                    if (item?.company_user_id == company_details?.id) {
                                                        return { ...item, company_info: {...item?.company_info, is_favorite: !item?.company_info?.is_favorite} };
                                                    }
                                                    return item;
                                                })
                                                setRequestDetails({...request_details, biddings: updatedBiddings})
                                            }

                                            if (window.location.pathname?.includes(k_request_paths.saved) && success) {
                                                const invitation_data = request_details?.invitation_data.map((item) => {
                                                    if (item?.user_id === company_details?.id) {
                                                        return { ...item, is_favorite: !item?.is_favorite };
                                                    }
                                                    return item;
                                                })
                                                setRequestDetails({ ...request_details, invitation_data: invitation_data })
                                            }

                                        }}
                                        className="mt-5 font-medium cursor-pointer mb-s17 text-fs16"
                                    >
                                        {!company_details?.is_favorite ? (
                                            <div className="text-cBrand">{t("Add Favorite")}</div>
                                        ) : (
                                            <div btnLabel={t("Remove")} className="text-cRed500">
                                                {t("Remove Favorite")}
                                            </div>
                                        )}
                                    </div>

                                    <CommonSocialLinks item={company_details?.social_media} />
                                </div>
                            </div>

                            <div>
                                <div>
                                    <span className="sub-title text-cGray800">
                                        {t("Contact Information")}
                                    </span>
                                    <div className="break-all text-fs14 font-fw400 text-cGray700 mt-s12 overflow-clip">
                                        <ContactItem
                                            icon={iCvrBlack}
                                            content={company_details?.cvr ?? "No CVR"}
                                            className="cursor-default"
                                        />
                                        <ContactItem
                                            icon={iEmailBlack}
                                            content={company_details?.email ?? "No email"}
                                            onClick={handleEmailClick}
                                            className={company_details?.email ? "cursor-pointer text-cPrimary600" : "cursor-default"}
                                        />
                                        <ContactItem
                                            icon={iPhoneBlack}
                                            content={company_details?.phone ?? "No phone number"}
                                            onClick={handlePhoneClick}
                                            className={company_details?.phone ? "cursor-pointer text-cPrimary600" : "cursor-default"}
                                        />
                                        <ContactItem
                                            icon={iAddressBlack}
                                            content={company_details?.address ?? "No address"}
                                            link={company_details?.address ? `http://maps.google.com/?q=${company_details?.address}` : null}
                                            className={company_details?.address ? "cursor-pointer text-cPrimary600" : "cursor-default"}
                                        />
                                        <ContactItem
                                            icon={iWebsiteBlack}
                                            content={company_details?.website ?? "No website"}
                                            link={company_details?.website ? getWebsiteLink() : null}
                                            className={company_details?.website ? "cursor-pointer text-cPrimary600" : "cursor-default"}
                                        />
                                    </div>
                                </div>
                                <div className="my-5">
                                    <div className="mb-s5 sub-title text-cGray800">
                                        {t("About Company")}
                                    </div>
                                    <div className="custom-text text-fs14 text-cGray800">
                                        {company_details?.about ?? "No about"}{" "}
                                    </div>
                                </div>

                                <div>
                                    <div className="sub-title text-cGray800">
                                        {t("Company terms and conditions")}
                                    </div>
                                    {company_details?.terms_condition ? (
                                        <div
                                            className="custom-text text-fs14 text-cGray800 font-fw400 h-auto max-h-[300px] overflow-y-auto"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    company_details?.terms_condition?.terms_condition,
                                            }}
                                        />
                                    ) : (
                                        <div className="text-fs14 text-cMainBlack font-fw400">
                                            No company terms and conditions.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default CommonCompanyDetailsModal;

const ContactItem = ({ icon, content, onClick, link, className = "cursor-pointer" }) => {
    return (
        <div className="flex items-center my-s1">
            <img className="mr-s8 w-s16 h-s16" src={icon} alt="" />
            {link ? (
                <a
                    target="_blank"
                    href={link}
                    rel="noreferrer"
                    className={`break-all ${className}`}
                >
                    {content}
                </a>
            ) : (
                <div onClick={onClick} className={`w-full break-all ${className}`}>
                    {content}
                </div>
            )}
        </div>
    );
};

const CompanyImage = () => {

    const { company_details } = useFavoriteCompaniesStore();

    return (
        <div className="relative max-w-[160px] max-h-[160px] min-w-[160px] min-h-[160px]">
            <Image
                src={company_details?.image}
                dummyImage={iFavCompanyGray}
                className={`object-cover border border-cGray300 rounded-full  max-w-[160px] max-h-[160px] min-w-[160px] min-h-[160px]`}
                alt=""
            />

            {company_details?.is_favorite && (
                <div className="p-[4px] shadow-lg bg-white rounded-full absolute top-[8px] right-[8px]">
                    <img className="z-10" src={iFavoriteIcon} alt="" />
                </div>
            )}
        </div>
    );
};
