import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { commonSelectedChat } from "../../../../../../../../../app/stores/others/chatStore";
import { getAllUserInfo } from "../../../../../../../../../app/stores/others/generalStore";
import { chat_role } from "../../../../../../../../../app/utility/const";
import {
  iChatGreen,
  iDummyCompanyAvatar,
  iDummyUserAvatar,
  iFavoriteIcon,
} from "../../../../../../../../../app/utility/imageImports";
import Image from "../../../../../../../../../components/image/Image";

export default function CustomerProfile({ data, isGlobal = false, isCompany = false, isFavorite = false }) {
  const navigateTo = useNavigate();

  const { type } = useParams();
  return (
    <div className="border-cGray200 border-[1px] rounded p-3">
      <div className="relative flex flex-row justify-between space-x-4 text-fs14 font-fw400">
        <div className="relative flex flex-row items-center justify-start w-full space-x-3">
          {!isGlobal &&
            <div className='relative h-s44 w-s44'>
              <Image 
                cursorPointerClass="cursor-pointer" 
                dummyImage={isCompany ? iDummyCompanyAvatar : iDummyUserAvatar} 
                className="object-fill rounded-full h-[44px] w-[44px] cursor-pointers border border-[#D9D9D9]" 
                src={data?.image} />
              {(isFavorite) ?
                <div className="p-[2px] shadow-lg bg-white rounded-full absolute top-[-8px] right-[-8px]">
                  <img className="z-10" src={iFavoriteIcon} alt="" />
                </div>
                : <></>
              }
            </div>
            }

          <div className="flex flex-col items-start justify-start">
            <div className="max-w-full whitespace-nowrap overflow-clip text-fs16 font-fw500 leading-6">
              {isGlobal ? "Global Request" : data?.name ?? "NA"}
            </div>
            <div className="flex flex-row gap-2 items-center">
              <div className="max-w-full whitespace-nowrap overflow-clip text-fs12 font-fw400 text-cGray500">
                {isCompany ? "Company" : "Customer"}
              </div>
              {isGlobal ? (
                <></>
              ) : (
                <>
                  <span className="h-s12 w-s1 bg-cGray200"></span>
                  <div className="max-w-full whitespace-nowrap overflow-clip text-fs12 font-fw400 text-cGray500">
                    {data?.email ?? "No email"}
                  </div>
                  <span className="h-s12 w-s1 bg-cGray200"></span>

                  <div className="max-w-full whitespace-nowrap overflow-clip text-fs12 font-fw400 text-cGray500">
                    {data?.phone ?? "No phone number"}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {!isGlobal && (type !== "completed" && type !== "history") &&
          <img
            src={iChatGreen}
            alt=""
            className="w-5 h-5 cursor-pointer absolute top-3 right-3"
            onClick={async (e) => {
              e.stopPropagation();
              const res = await getAllUserInfo(data?.id);
              if (res?.success) {
                await commonSelectedChat(res?.data, chat_role.company);
                navigateTo("/chat");
              }
            }}
          />
        }
      </div>
    </div>
  );
}
