/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import useDriverStore, { addDriver, editDriver, getDriverDetails, resetForm, setDriverFormData } from '../../../../app/stores/company/driverStore';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/button/CommonButton';
import CommonInput from '../../../../components/input/CommonInput';
import CommonTitle from '../../../../components/title/CommonTitle';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import CommonButtonOutlined from '../../../../components/button/CommonButtonOutlined';
import { isValidEmail, Toastr } from '../../../../app/utility/utilityFunctions';

const AddAndEditDriver = () => {

    const { addDriver_form, changeAddDriverForm, setAddDriver_form, driverDetails } = useDriverStore();

    const { t } = useTranslation();

    const navigateTo = useNavigate();

    const { driver_id } = useParams();

    const location = useLocation();

    const editMode = location?.pathname === "/driver-manager/add" ? false : true;

    const handleSuccess = (success, navigateTo, driver_id, setAddDriver_form) => {
        if (success) {
            if (driver_id) {
                navigateTo(`/driver-manager/details/${driver_id}`);
            } else {
                resetForm(setAddDriver_form);
                navigateTo('/driver-manager');
            }
        }
    };

    const handleAddDriver = async (e) => {
        e.preventDefault();

        if (!addDriver_form?.name) {
            Toastr({ message: 'Name is required.', type: 'warning' });
            return;
        }

        if (!addDriver_form?.email) {
            Toastr({ message: 'Email is required.', type: 'warning' });
            return;
        }

        if (!addDriver_form?.phone) {
            Toastr({ message: 'Phone is required.', type: 'warning' });
            return;
        }

        try {
            let success;
            if (editMode) {
                success = await editDriver();
            } else {
                success = await addDriver();
            }
            handleSuccess(success, navigateTo, driver_id, setAddDriver_form);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (editMode) {
            if (!driverDetails?.id) {
                setUpdateData();
            } else {
                setDriverFormData(driverDetails);
            }
        }
    }, [driver_id]);


    const setUpdateData = async () => {
        const success = await getDriverDetails(driver_id);
        setDriverFormData(success);
    }


    return (
        <form className='grid grid-cols-12 gap-6 md:gap-5 2xl:gap-5' onSubmit={handleAddDriver}>

            <div className='col-span-8'>
                <CommonTitle
                    title={editMode ? t("Edit Driver") : t('Add Driver')}
                    icon={true}
                    link={"/driver-manager"}
                />

                <div className='mb-s5'>
                    <CommonInput
                        labelText={t('Name')}
                        value={addDriver_form?.name}
                        type='text'
                        name={'name'}
                        required={true}
                        max_input={55}
                        onChange={(e) => {
                            if (e.target.value !== " ") {
                                const trimmedValue = e.target.value.replace(/\s+/g, " ");
                                setAddDriver_form({ ...addDriver_form, name: trimmedValue })
                            }
                        }}
                    />
                </div>

                <CommonInput
                    labelText={t('Email')}
                    disabled={editMode}
                    value={addDriver_form?.email}
                    type='email'
                    name={'email'}
                    required={true}
                    onChange={(e) => {
                        changeAddDriverForm(e)
                    }}
                />

                <CommonInput
                    labelText={t('Phone')}
                    value={addDriver_form?.phone}
                    // name={'phone'}
                    type="tel"
                    name={'phone' ?? ''}
                    max_input={15}
                    min_input={0}
                    required={true}
                    onChange={(e) => {
                        const input = e.target.value;
                        const sanitizedInput = input.replace(/[^0-9+]/g, '');
                        const sanitizedWithSinglePlus = sanitizedInput.replace(/\++/g, '+');
                        const sanitizedWithMaxTwoPlus = sanitizedWithSinglePlus.replace(/\+/g, (match, index) => index === 0 ? match : '');
                        setAddDriver_form({ ...addDriver_form, phone: sanitizedWithMaxTwoPlus })
                    }}
                />

                <CommonInput
                    textarea={true}
                    max_input={255}
                    labelText={t('Note')}
                    value={addDriver_form?.comment}
                    type='text'
                    name={'comment'}
                    onChange={(e) => {
                        // Check if the input is only whitespace (including new lines)
                        const trimmedValue = e.target.value.trim();
                        if (trimmedValue === "") {
                            setAddDriver_form({ ...addDriver_form, comment: "" });
                        } else {
                            setAddDriver_form({ ...addDriver_form, comment: e.target.value });
                        }
                    }}
                />
            </div>

            <div className='flex justify-end col-span-4 space-x-5'>
                <CommonButtonOutlined
                    width='w-[120px]'
                    onClick={() => { editMode ? navigateTo(`/driver-manager/details/${driver_id}`) : navigateTo("/driver-manager") }}
                    colorType='danger' btnLabel={t('Cancel')}
                />
                <CommonButton
                    width='w-[120px]'
                    type='submit'
                    btnLabel={editMode ? t("Update") : t('Add')}
                    isDisabled={addDriver_form?.name && addDriver_form?.email && isValidEmail(addDriver_form?.email) && addDriver_form?.phone ? false : true}
                // colorType={(addDriver_form?.name && addDriver_form?.email && addDriver_form?.phone) ? "primary" : "disable"}
                />
            </div>
        </form>
    )
}

export default AddAndEditDriver
