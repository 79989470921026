import { Tooltip } from "antd";
import Clamp from "react-multiline-clamp";

export const TableViewElement = ({
    description = "",
    width = "",
    is_valid = true,
    tooltipText = "",
    showToolTrip = false,
}) => {
    return (
        <>
            {showToolTrip ? (
                <Tooltip title={tooltipText} arrow>
                    <div
                        className={`bg-white ${!is_valid
                            ? "border-cRed border-[1px]"
                            : "border-white border-[1px]"
                            } rounded cursor-pointer max-h-[50px] flex justify-start items-center min-h-[50px] text-fs12 font-normal leading-[20px] text-cGray800 p-2 ${width}`}
                    >
                        <Clamp lines={2}> {description}</Clamp>
                    </div>
                </Tooltip>
            ) : (
                <div
                    className={`cursor-pointer bg-white break-all ${!is_valid ? "border-cRed border-[1px]" : "border-white border-[1px]"
                        } rounded max-h-[50px] flex justify-start items-center min-h-[50px] text-fs12 font-normal leading-[20px] text-cGray800 px-2 py-1 ${width}`}
                >
                    <Clamp lines={2}> {description}</Clamp>
                </div>
            )}
        </>
    );
};
