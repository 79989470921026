/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import CommonTitle from '../../../../components/title/CommonTitle'
import useShiftStore, { addNewShift, getAllShiftCarsAndDriversList } from '../../../../app/stores/company/shiftStore';
import { useEffect } from 'react';
import { Toastr, checkForCurrentDate, checkPastTime, getStringFromDateObject } from '../../../../app/utility/utilityFunctions';
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import CommonButton from '../../../../components/button/CommonButton';
import CommonInput from '../../../../components/input/CommonInput';
import CommonSelect from '../../../../components/select/CommonSelect';
import CommonViewComponent from '../../../../components/viewer/CommonViewComponent';
import CommonTimePicker from '../../../../components/input/CommonTimePicker';
import CommonDatePicker from '../../../../components/input/CommonDatePicker';
import CommonCheckbox from '../../../../components/input/CommonCheckbox';
import CommonMultipleSelect from '../../../../components/input/CommonMultipleSelect';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';


const AddShift = () => {

    const {
        setShowAddShiftModal,
        addShiftForm,
        setAddShiftForm,
        setAllShiftCarList,
        setAllShiftDriverList,
        resetAddShiftForm,
        allShiftCarList,
        allShiftDriverList,
        resetFilterFormCopy
    } = useShiftStore();

    const [carDriverChooseMode, setCarDriverChooseMode] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [isDisableOptions, setIsDisableOptions] = useState(true)
    const [time, setTime] = useState("")
    const [startingTime, setStartingTime] = useState("");
    const { t } = useTranslation();
    const navigateTo = useNavigate()

    let [endTime] = useDebounce(time, 1000)
    let [startTime] = useDebounce(startingTime, 1000)

    const resetCarDriverData = () => { 
        setAllShiftCarList([]); 
        setAllShiftDriverList([]); 
        setAddShiftForm({...addShiftForm, car_id: "", driver_user_id: "", co_drivers: []});
        setIsDisableOptions(true) 
    }

    // b    time validation 
    const isTimeValid = (startTime, endTime, startDate = addShiftForm?.start_date, endDate = addShiftForm?.end_date) => {
        const startDateTime = new Date(startDate + 'T' + startTime);

        const endDateTime = new Date(endDate + 'T' + endTime);

        return startDateTime < endDateTime;

    }

    const fetchCarAndDrivers = async () => {
        let validatedTime = await isTimeValid(addShiftForm?.start_time, addShiftForm?.end_time);
        let x;
        if (addShiftForm?.is_maintenance) {
            if (validatedTime) {
                //the loading can be removed from here by sending false as 5th parameter to load the data silently
                x = await getAllShiftCarsAndDriversList(addShiftForm?.start_date, addShiftForm?.end_date, addShiftForm?.start_time, addShiftForm?.end_time, true);
            } else {
                let start_date_next = addShiftForm?.start_date ? new Date(addShiftForm?.start_date) : null;
                start_date_next?.setDate(start_date_next?.getDate() + 1);

                console.log('new gen end date:', getStringFromDateObject(start_date_next ?? new Date()));
                x = await getAllShiftCarsAndDriversList(addShiftForm?.start_date, getStringFromDateObject(start_date_next ?? new Date()), addShiftForm?.start_time, addShiftForm?.end_time, true);
            }
            setIsDisableOptions(false)
            return;

        } else {
            if (validatedTime) {
                //the loading can be removed from here by sending false as 5th parameter to load the data silently
                x = await getAllShiftCarsAndDriversList(addShiftForm?.start_date, addShiftForm?.end_date, addShiftForm?.start_time, addShiftForm?.end_time, true);
            } else {
                let start_date_next = addShiftForm?.start_date ? new Date(addShiftForm?.start_date) : null;
                start_date_next?.setDate(start_date_next?.getDate() + 1);

                console.log('new gen end date:', getStringFromDateObject(start_date_next ?? new Date()));
                x = await getAllShiftCarsAndDriversList(addShiftForm?.start_date, getStringFromDateObject(start_date_next ?? new Date()), addShiftForm?.start_time, addShiftForm?.end_time, true);
            }
            // console.log('x', x);
            setIsDisableOptions(false)
            // if (x?.cars?.length === 0 ) Toastr({ message: "No car found", type: "warning" });
            // if (x?.drivers?.length === 0) Toastr({ message: "No driver found", type: "warning" });
            return;
        }
    };

    const updateEndDate = async (addShiftForm, setAddShiftForm) => {
        const date = new Date(addShiftForm?.start_date);
        date.setDate(date.getDate() + 1);
        await setAddShiftForm({ ...addShiftForm, end_date: date.toISOString().slice(0, 10) });
        return { ...addShiftForm, end_date: date.toISOString().slice(0, 10) };
    };

    const handleAddSuccess = async (addSuccess, resetAddShiftForm, resetFilterFormCopy, setShowAddShiftModal) => {
        if (addSuccess) {
            resetAddShiftForm();
            await resetFilterFormCopy();
            navigateTo('/shift-manager')
        }
    };

    const handleAddShift = async () => {

        if (canSubmit) {

            //time validations.
            if (checkPastTime(addShiftForm?.start_time, addShiftForm?.start_date)) {
                return Toastr({ message: t("Start Time: Past Time not allowed."), type: "warning" });
            }

            let updatedForm = addShiftForm;
            if (addShiftForm?.start_time && (addShiftForm?.start_time > addShiftForm?.end_time)) {
                updatedForm = await updateEndDate(addShiftForm, setAddShiftForm);
            }

            if (addShiftForm?.start_date && addShiftForm?.end_date && addShiftForm?.start_time && addShiftForm?.end_time) {
                if (addShiftForm?.is_maintenance) {
                    let addSuccess = await addNewShift(updatedForm);
                    await handleAddSuccess(addSuccess, resetAddShiftForm, resetFilterFormCopy, setShowAddShiftModal);
                } else if (!addShiftForm?.is_maintenance && addShiftForm?.car_license_plate_number && addShiftForm?.driver_user_id && addShiftForm?.comment) {
                    let addSuccess = await addNewShift(updatedForm);
                    if (addSuccess) {
                        localStorage.setItem('add_shift_driver_user_id', 0);
                        localStorage.setItem('add_shift_comment', '');
                        await handleAddSuccess(addSuccess, resetAddShiftForm, resetFilterFormCopy, setShowAddShiftModal);
                    }
                } else Toastr({ message: t("Please fill up all required fields"), type: "warning" })
            }
        } else {
            Toastr({ message: t("Please fill up all required fields"), type: "warning" })
        }
    };

    useEffect(() => {
        resetCarDriverData();

        if (!isTimeValid(addShiftForm?.start_time, time)) {
            let start_date_next = addShiftForm?.start_date ? new Date(addShiftForm?.start_date) : null;
            start_date_next?.setDate(start_date_next?.getDate() + 1);
            setAddShiftForm({ ...addShiftForm, end_time: time, end_date: getStringFromDateObject(start_date_next ?? new Date()), car_id: null, car_license_plate_number: "", driver_user_id: "" });
        } else {
            if (isTimeValid(addShiftForm?.start_time, time, addShiftForm?.start_date, addShiftForm?.start_date))
                setAddShiftForm({ ...addShiftForm, end_time: time, end_date: addShiftForm?.start_date, car_id: null, car_license_plate_number: "", driver_user_id: "", co_drivers: [] });
            else
                setAddShiftForm({ ...addShiftForm, end_time: time, car_id: null, car_license_plate_number: "", driver_user_id: "", co_drivers: [] });
        }
    }, [endTime])

    useEffect(() => {
        localStorage.setItem('add_shift_driver_user_id', 0);
        localStorage.setItem('add_shift_comment', '');
        // fetchCarAndDrivers();
        return () => {
            resetAddShiftForm()
        }
    }, []);

    useEffect(() => {
        resetCarDriverData();

        if (!isTimeValid(addShiftForm?.start_time, endTime)) {
            let start_date_next = addShiftForm?.start_date ? new Date(addShiftForm?.start_date) : null;
            start_date_next?.setDate(start_date_next?.getDate() + 1);
            setAddShiftForm({ ...addShiftForm, end_time: endTime, end_date: getStringFromDateObject(start_date_next ?? new Date()), car_id: null, car_license_plate_number: "", driver_user_id: "", co_drivers: [] });
        } else {
            if (isTimeValid(addShiftForm?.start_time, endTime, addShiftForm?.start_date, addShiftForm?.start_date))
                setAddShiftForm({ ...addShiftForm, end_time: endTime, end_date: addShiftForm?.start_date, car_id: null, car_license_plate_number: "", driver_user_id: "", co_drivers: [] });
            else
                setAddShiftForm({ ...addShiftForm, end_time: endTime, car_id: null, car_license_plate_number: "", driver_user_id: "", co_drivers: [] });
        }
    }, [endTime])

    useEffect(() => {
        resetCarDriverData();
        setAddShiftForm({ ...addShiftForm, start_time: startTime, car_id: null, car_license_plate_number: "", driver_user_id: "", co_drivers: [] });
    }, [startTime])

    useEffect(() => {
        if (addShiftForm?.start_time && addShiftForm?.end_time) autoCheckAndFetchDriverCar();
        if (addShiftForm?.is_maintenance && addShiftForm?.start_date && addShiftForm?.end_date && addShiftForm?.start_time && addShiftForm?.end_time) {
            setCarDriverChooseMode(true);
            if (addShiftForm?.car_id && addShiftForm?.car_license_plate_number) setCanSubmit(true);
            else setCanSubmit(false);
        }
        else if (!addShiftForm?.is_maintenance && addShiftForm?.start_date && addShiftForm?.start_time && addShiftForm?.end_time) {
            setCarDriverChooseMode(true);
            if (addShiftForm?.car_id && addShiftForm?.car_license_plate_number && addShiftForm?.driver_user_id && addShiftForm?.comment) setCanSubmit(true);
            else setCanSubmit(false);
        }
        else setCarDriverChooseMode(false);

    }, [addShiftForm, isDisableOptions]);

    useEffect(() => {
        let local_comment = localStorage.getItem('add_shift_comment');
        let local_driver_user_id = localStorage.getItem('add_shift_driver_user_id');

        if (addShiftForm?.is_maintenance) {
            setAddShiftForm({ ...addShiftForm, comment: "", driver_user_id: null });
        } else {

            // check if new end date is required
            let validTimeValues = isTimeValid(addShiftForm?.end_time, addShiftForm?.start_time, addShiftForm?.start_date, addShiftForm?.start_date);

            if (!validTimeValues) {
                let end_date_next = addShiftForm?.start_date ? new Date(addShiftForm?.start_date) : null;
                // console.log('222 end_date_next : ', end_date_next);
                end_date_next?.setDate(end_date_next?.getDate() + 1);
                setAddShiftForm({ ...addShiftForm, comment: local_comment, driver_user_id: local_driver_user_id > 0 ? parseInt(local_driver_user_id) : null, end_date: end_date_next });
            } else
                setAddShiftForm({ ...addShiftForm, comment: local_comment, driver_user_id: local_driver_user_id > 0 ? parseInt(local_driver_user_id) : null, end_date: addShiftForm?.start_date });
        }
    }, [addShiftForm?.is_maintenance]);

    const autoCheckAndFetchDriverCar = async () => {
        if (isDisableOptions) {
            await fetchCarAndDrivers()
        }
    }

    return (
        <div>
            <div className='flex justify-between'>
                <CommonTitle title={t("Add Shift")} link='/shift-manager' icon={true} />

                {/* submit form */}
                <CommonButton
                    type="submit"
                    isDisabled={!canSubmit}
                    btnLabel={t("Add shift")}
                    onClick={handleAddShift}
                />
            </div>



            <div className='flex pt-5 space-x-s30'>
                <form className='w-full flex flex-col gap-5' onSubmit={(e) => e.preventDefault()}>
                    {/* maintenance state selection */}
                    <div className="flex items-center space-x-2.5 cursor-pointer select-none w-fit">
                        <CommonCheckbox
                            label={t("in maintenance")}
                            checked={addShiftForm?.is_maintenance}
                            onChange={() =>
                                setAddShiftForm({
                                    ...addShiftForm,
                                    co_drivers: [],
                                    driver_user_id: "",
                                    is_maintenance: !addShiftForm?.is_maintenance
                                })

                            }
                        />
                    </div>

                    {/*e       date pickers */}
                    <div className="grid items-center grid-cols-2 gap-7">
                        <CommonDatePicker
                            label={t("Start Date")}
                            required={true}
                            allowPastDate={false}
                            value={addShiftForm?.start_date}
                            onChange={(date) => {
                                resetCarDriverData();
                                if (!addShiftForm?.is_maintenance)
                                    setAddShiftForm({ ...addShiftForm, start_date: getStringFromDateObject(date), end_date: getStringFromDateObject(date), car_id: null, car_license_plate_number: "", driver_user_id: "", start_time: "", end_time: "" });
                                else
                                    setAddShiftForm({ ...addShiftForm, start_date: getStringFromDateObject(date), car_id: null, car_license_plate_number: "", driver_user_id: "" });
                            }}
                            show_asterisk={true}
                        />
                        {
                            addShiftForm?.is_maintenance &&
                            <CommonDatePicker
                                disabled={addShiftForm?.start_date ? false : true}
                                label={t("End date")}
                                required={addShiftForm?.is_maintenance}
                                startDate={getStringFromDateObject(addShiftForm?.start_date)}
                                value={addShiftForm?.end_date}
                                onChange={(date) => {
                                    resetCarDriverData();
                                    setAddShiftForm({ ...addShiftForm, end_date: getStringFromDateObject(date), car_id: null, car_license_plate_number: "", driver_user_id: "", start_time: "", end_time: "" });
                                }}
                                show_asterisk={true}
                            />
                        }
                    </div>

                    {/*p     time pickers */}
                    <div className="mt-s6 h-[38px] grid grid-cols-2 items-center gap-7">
                        <CommonTimePicker
                            heightClass="h-[38px]"
                            disabled={addShiftForm?.start_date ? false : true}
                            required={true}
                            showExtendedTimeUi={false}
                            label={t("start time")}
                            init_time={addShiftForm?.start_time}
                            onChange={(time) => {
                                setStartingTime(time)
                            }}
                            show_asterisk={true}
                            isCurrentTimeValidation={checkForCurrentDate(addShiftForm?.start_date)}
                        />

                        <CommonTimePicker
                            heightClass="h-[38px]"
                            required={true}
                            disabled={addShiftForm?.start_date ? false : true}
                            showExtendedTimeUi={false}
                            label={t("End Time")}
                            init_time={addShiftForm?.end_time}
                            onChange={(time) => {
                                setTime(time)
                            }}
                            show_asterisk={true}
                        />
                    </div>

                    {/*v           dropdowns          */}
                    <div
                        className={`items-center`}>
                            <div className="">
                                <CommonSelect
                                    disabled={isDisableOptions || !carDriverChooseMode}
                                    required={true}
                                    label={t("Vehicle")}
                                    placeholder={t("Select vehicle")}
                                    withCancel={true}
                                    onRemove={() => {
                                        setAddShiftForm({ ...addShiftForm, car_id: "" });
                                    }}
                                    dataArray={allShiftCarList}
                                    value={addShiftForm?.car_license_plate_number}
                                    onChange={(e) => {
                                        let car_id_found = allShiftCarList.find((car) => car.value === e.target.value);
                                        setAddShiftForm({ ...addShiftForm, car_id: parseInt(car_id_found?.id), car_license_plate_number: e.target.value });
                                    }}
                                    show_asterisk={true}
                                    noDataText={"No vehicles available"}
                                />
                            </div>
                    </div>

                    <div
                        className={`items-center`}>
                            <div className="pt-[0px]">
                                <CommonSelect
                                    disabled={isDisableOptions || (!addShiftForm?.is_maintenance && carDriverChooseMode ? false : true)}
                                    required={!addShiftForm?.is_maintenance}
                                    label={t("Main Driver")}
                                    placeholder={t("Select main driver")}
                                    withCancel={true}
                                    onRemove={() => {
                                        setAddShiftForm({ ...addShiftForm, driver_user_id: "", co_drivers: [] });
                                    }}

                                    dataArray={allShiftDriverList?.filter(item => !addShiftForm?.co_drivers?.includes(item.value))}
                                    show_image={true}
                                    isRoundedFull={true}
                                    value={addShiftForm?.is_maintenance ? null : addShiftForm?.driver_user_id}
                                    onChange={(e) => {
                                        localStorage.setItem('add_shift_driver_user_id', e.target.value);
                                        if(!e.target.value) {
                                            setAddShiftForm({ ...addShiftForm, driver_user_id: e.target.value, co_drivers: [] });
                                        }
                                        else {
                                            setAddShiftForm({ ...addShiftForm, driver_user_id: e.target.value });
                                        }
                                    }}
                                    show_asterisk={true}
                                    noDataText={"No drivers available"}
                                />
                            </div>
                    </div>

                    <div
                        className={`items-center mt-[11px]`}>
                            <div className="">
                                <CommonMultipleSelect
                                    disabled={!addShiftForm?.driver_user_id || (!addShiftForm?.is_maintenance && carDriverChooseMode ? false : true)}
                                    dataArray={allShiftDriverList?.filter(item => item?.value !== addShiftForm?.driver_user_id)}
                                    label={t("Co-Drivers")}
                                    selectItem={allShiftDriverList?.filter(item => addShiftForm?.co_drivers?.includes(item?.value))}
                                    onSelectItem={(e) => {
                                        if (!addShiftForm?.co_drivers?.includes(e?.value)) {
                                            if (addShiftForm?.co_drivers?.length < 2) {
                                                setAddShiftForm({ ...addShiftForm, co_drivers: [...addShiftForm?.co_drivers, e.value] });
                                            } else {
                                                Toastr({ message: t("Maximum 2 co-drivers allowed"), type: "warning" });
                                            }
                                        } else {
                                            setAddShiftForm({ ...addShiftForm, co_drivers: addShiftForm?.co_drivers?.filter((value) => value !== e.value) });
                                        }
                                    }}
                                    handleRemoveItem={(e) => {
                                        setAddShiftForm({ ...addShiftForm, co_drivers: addShiftForm?.co_drivers.filter((item) => item !== e.value) });
                                    }}
                                    isRoundedFull={true}
                                    noDataText={'No co-drivers available'}
                                />
                            </div>
                    </div>

                    {/*g    comments  */}
                    <div className="mt-[-27px]">
                        <CommonInput
                            disabled={addShiftForm?.is_maintenance}
                            required={true}
                            textarea={true}
                            max_input={255}
                            labelText={t("Shift Instruction")}
                            value={addShiftForm?.is_maintenance ? '' : addShiftForm?.comment}
                            onChange={(e) => {
                                if (e.target.value !== " ") {
                                    localStorage.setItem('add_shift_comment', e.target.value);
                                    setAddShiftForm({ ...addShiftForm, comment: e.target.value });
                                }
                            }}
                            show_asterisk={true}
                        />
                    </div>
                </form>

                <div className='space-y-2 w-[500px] '>

                </div>
            </div>
        </div>
    )
}

export default AddShift