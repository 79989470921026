/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import useTrackTraceStore, {
  getTrackAndTrace,
  handleTrackAndOrder,
} from "../../../../app/stores/others/trackTraceStore";
import CommonTable from "../../../../components/table/CommonTable";
import { useTranslation } from "react-i18next";
import useRequestStore, {
  isTableFiltered,
} from "../../../../app/stores/others/requestStore";
import {
  common_take,
  k_request_paths,
  k_request_types,
  k_requests_status,
  k_track_and_trace_order_by,
  user_role as role,
} from "../../../../app/utility/const";
import { formatDate } from "../../../../app/utility/utilityFunctions";
import { useNavigate } from "react-router-dom";
import useGeneralStore from "../../../../app/stores/others/generalStore";
import { useLocation } from "react-router-dom";
import Clamp from "react-multiline-clamp";

const TrackTraceTable = () => {
  const { t } = useTranslation();

  const navigateTo = useNavigate();

  const { user_role, setPathRecord, setRequestDetailsPath } = useGeneralStore();

  const location = useLocation();

  const {
    order,
    is_asc,
    filterForm,
    searchKey,
    copyFilterForm,
    trackTraceList,
    setApiUrl,
  } = useTrackTraceStore();

  const getData = () =>
    getTrackAndTrace({
      isLoading: true,
      ...filterForm,
    });

  const getMainDriverInfo = (names) => {
    const firstCommaIndex = names.indexOf(',');

    // If there is no co-driver, just return the original along with (M) added to the end  
    if (firstCommaIndex === -1) {
      return names + ' (M)';
    }

    // Add (M) after the first name and return the modified string  
    return names.slice(0, firstCommaIndex).trim() + ' (M)' + names.slice(firstCommaIndex);
  };

  const headers =
    user_role === role.company
      ? [
        { isActive: order === k_track_and_trace_order_by?.request_id, name: "Req. ID", sort: is_asc, onClickAction: () => { handleTrackAndOrder(k_track_and_trace_order_by.request_id, getData) }, showSortImage: true },
        { isActive: order === k_track_and_trace_order_by.customer, sort: is_asc, onClickAction: () => { handleTrackAndOrder(k_track_and_trace_order_by.customer, getData) }, name: t("Customer"), showSortImage: true },
        { isActive: order === k_track_and_trace_order_by.title, sort: is_asc, onClickAction: () => { handleTrackAndOrder(k_track_and_trace_order_by.title, getData) }, name: t("Title"), showSortImage: true },
        { isActive: order === k_track_and_trace_order_by.drivers, sort: is_asc, onClickAction: () => { handleTrackAndOrder(k_track_and_trace_order_by.drivers, getData) }, name: t("Driver"), showSortImage: true },
        { isActive: order === k_track_and_trace_order_by.type, sort: is_asc, onClickAction: () => { handleTrackAndOrder(k_track_and_trace_order_by.type, getData) }, name: t("Type"), showSortImage: true },
        { isActive: order === k_track_and_trace_order_by.pickup_date_time, sort: is_asc, onClickAction: () => { handleTrackAndOrder(k_track_and_trace_order_by.pickup_date_time, getData) }, name: t("Date & Time"), showSortImage: true },
        { isActive: order === k_track_and_trace_order_by.status, sort: is_asc, onClickAction: () => { handleTrackAndOrder(k_track_and_trace_order_by.status, getData) }, name: t("Status"), showSortImage: true },
      ]
      : [
        { isActive: order === k_track_and_trace_order_by?.request_id, name: "Req.ID", onClickAction: () => { }, sort: is_asc, onClickAction: () => { handleTrackAndOrder(k_track_and_trace_order_by.request_id, getData) }, showSortImage: true },
        { isActive: order === k_track_and_trace_order_by.company, sort: is_asc, onClickAction: () => { handleTrackAndOrder(k_track_and_trace_order_by.company, getData) }, name: t("Company"), showSortImage: true },
        { isActive: order === k_track_and_trace_order_by.title, sort: is_asc, onClickAction: () => { handleTrackAndOrder(k_track_and_trace_order_by.title, getData) }, name: t("Title"), showSortImage: true },
        { isActive: order === k_track_and_trace_order_by.type, sort: is_asc, onClickAction: () => { handleTrackAndOrder(k_track_and_trace_order_by.type, getData) }, name: t("Type"), showSortImage: true },
        { isActive: order === k_track_and_trace_order_by.pickup_date_time, sort: is_asc, onClickAction: () => { handleTrackAndOrder(k_track_and_trace_order_by.pickup_date_time, getData) }, name: t("Date & Time"), showSortImage: true },
        { isActive: order === k_track_and_trace_order_by.status, sort: is_asc, onClickAction: () => { handleTrackAndOrder(k_track_and_trace_order_by.status, getData) }, name: t("Status"), showSortImage: true },
      ];

  const isFilterActive = isTableFiltered(
    k_requests_status.trackTrace,
    copyFilterForm,
  );

  useEffect(() => {
    setPathRecord(location.pathname);
  }, [location.pathname]);

  const contents = trackTraceList?.data?.map((item, index) => {
    return user_role === role.company
      ? {
        contents: [
          { value: item?.request_id },
          {
            value: (
              <Clamp lines={1}>
                {(() => {
                  if (user_role === role.company) {
                    if (
                      item?.is_global &&
                      item?.status === k_requests_status.in_bidding &&
                      !item.is_invitation
                    ) {
                      return item?.bidding_customer
                        ? item?.bidding_customer || ""
                        : t("Global");
                    } else if (
                      item?.is_global &&
                      item?.status === k_requests_status.in_bidding &&
                      item.is_invitation
                    ) {
                      return item?.customer || "";
                    } else if (item?.role === role.company) {
                      if (item?.bidding_customer) {
                        return item?.bidding_customer || "";
                      } else {
                        return t("Created by company");
                      }
                    } else {
                      return item?.customer || "";
                    }
                  } else {
                    return item?.company || "";
                  }
                })()}
              </Clamp>
            ),
          },
          {
            value:  (
              <Clamp lines={1}>{item?.title ? item?.title : ""}</Clamp>
            ),
          },
          { value: item?.drivers },
          { value: item?.type },
          {
            value: `${formatDate(item?.pickup_date)}, ${item?.pickup_time
              .split(":")
              .slice(0, 2)
              .join(":")}`,
          },
          {
            value: getStatusText(item?.status,
              item?.is_planned,
              item?.is_invitation
            ),
          },
        ],
        clickAction: () => {
          setRequestDetailsPath("/track-trace");
          navigateTo(
            `${mapStatusToDisplayText(
              item?.status,
              item?.is_planned,
              item?.is_invitation,
            )}/details/` + item?.id,
          );
        },
      }
      : {
        contents: [
          { value: item.request_id },
          {
            value: (
              <Clamp lines={1}>
              <>
                {(() => {
                  if (user_role === role.company) {
                    if (
                      item?.is_global &&
                      item?.status === k_requests_status.in_bidding &&
                      !item.is_invitation
                    ) {
                      return item?.bidding_customer
                        ? item?.bidding_customer || ""
                        : t("Global");
                    } else if (
                      item?.is_global &&
                      item?.status === k_requests_status.in_bidding &&
                      item.is_invitation
                    ) {
                      return item?.customer || "";
                    } else if (item?.role === role.company) {
                      if (item?.bidding_customer) {
                        return item?.bidding_customer || "";
                      } else {
                        return t("Created by company");
                      }
                    } else {
                      return item?.customer || "";
                    }
                  } else {
                    return item?.company || "";
                  }
                })()}
              </>
              </Clamp>
            ),
          },
          {
            value:  (
              <Clamp lines={1}>{item?.title ? item?.title : ""}</Clamp>
            ),
          },
          { value: item?.type },
          {
            value: `${formatDate(item?.pickup_date)}, ${item?.pickup_time
              .split(":")
              .slice(0, 2)
              .join(":")}`,
          },
          {
            value: getStatusText(item?.status)
          }
        ],
        clickAction: () => {
          setRequestDetailsPath("/track-trace");
          navigateTo(
            `${mapStatusToDisplayText(
              item?.status,
              item?.is_planned,
              item?.is_invitation,
            )}/details/` + item?.id,
          );
        },
      };
  });

  console.log("trackTraceList", trackTraceList)

  return (
    <div>
      <CommonTable
        tableTitle={
          trackTraceList?.data?.length > 0 &&
          ((searchKey && isFilterActive && t("Filter & Search Result")) ||
            (searchKey && t("Search Result")) ||
            (isFilterActive && t("Filter Result")))
        }
        showSearchBox={false}
        showTopRightFilter={false}
        showTakeOption={true}
        showPagination={
          trackTraceList?.data?.length > 0 &&
          (searchKey?.length > 0 || isFilterActive)
        }
        showPageCountText={searchKey?.length > 0 || isFilterActive}
        tableHeaders={headers}
        tableContents={contents}
        hasClickFunction={true}
        widths={user_role === role.company ? "90px 23% auto 15% 15% 13% 10%" : "90px 20% auto 15% 12% 10%"}
        outerPadding="p-s0"
        topRightFilterComponentOnClick={() => { }}
        paginationObject={trackTraceList}
        withReloader={true}
        onReload={() => { }}
        filtered={false}
        takeOptionOnChange={async (e) => { }}
        paginationOnClick={async (url) => {
          setApiUrl(url);
          getData();
        }}
      />
    </div>
  );
};

export default TrackTraceTable;

const getStatusText = (status, is_planned = null, is_invitation = false) => {
  if (
    status === k_requests_status.awarded &&
    (is_planned === 1 || is_planned === null)
  ) {
    return k_request_types.upcoming;
  } else if (status === k_requests_status.awarded && is_planned === 0) {
    return k_request_types.not_planned;
  } else if (status === k_requests_status.completed) {
    return k_request_types.completed;
  } else if (status === k_requests_status.ongoing) {
    return k_request_types.ongoing;
  } else if (status === k_requests_status.in_bidding && !is_invitation) {
    return k_request_types.in_bidding;
  } else if (status === k_requests_status.in_bidding && is_invitation) {
    return k_request_types.invitation;
  } else if (status === "in_bidding_red") {
    return k_request_types.in_bidding;
  } else {
    return status;
  }
};

const mapStatusToDisplayText = (
  status,
  is_planned = null,
  is_invitation = false,
) => {
  const { resetPlaceBidForm } = useRequestStore.getState();

  if (
    (status === k_requests_status.in_bidding ||
      status === "in_bidding_red" ||
      status === "in_bidding_orange") &&
    !is_invitation
  ) {
    return k_request_paths.in_bidding;
  } else if (status === k_requests_status.in_bidding && is_invitation) {
    resetPlaceBidForm();
    return k_request_paths.invitation;
  } else if (status === k_requests_status.completed) {
    return k_request_paths.completed;
  } else if (status === k_requests_status.ongoing) {
    return k_request_paths.ongoing;
  }
  if (
    status === k_requests_status.awarded &&
    (is_planned === 1 || is_planned === null)
  ) {
    return k_request_paths.awarded;
  } else if (status === k_requests_status.awarded && is_planned === 0) {
    return k_request_paths.not_planned;
  } else {
    return `/requests/${status}`;
  }
};
