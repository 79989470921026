import Rating from '@mui/material/Rating';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useRequestStore, { writeReview } from '../../../../../../../../app/stores/others/requestStore';
import CommonButton from '../../../../../../../../components/button/CommonButton';
import CommonInput from '../../../../../../../../components/input/CommonInput';
import CommonModal from '../../../../../../../../components/modal/CommonModal';
import { useTranslation } from 'react-i18next';
import { Toastr } from '../../../../../../../../app/utility/utilityFunctions';

export default function RatingAndReviewModal({ showModal, setShowModal, request_id }) {

  const navigateTo = useNavigate();
  const [comment, setComment] = useState('');
  const { request_details } = useRequestStore();
  const [rating, setRating] = useState(5);
  const [componentValue, setComponentValue] = useState(0);

  // const buttonRef = useRef();

  // useEffect(() => {
  //   if (showModal && buttonRef.current) {
  //     buttonRef.current?.click();
  //   }
  // }, [showModal]);

  const { t } = useTranslation();

  const submitForm = async (e) => {
    e.preventDefault();

    // Validate input values
    if (!request_id || !request_details?.awarded?.company_user_id || !comment) {
      // Show a toast notification for invalid input
      Toastr({ message: t('Please fill in all fields'), type: "warning" });
      return;
    }
    if (rating < 1) {
      // Show a toast notification for invalid input
      Toastr({ message: t('Rating is required. Please provide a rating of 1 or more.'), type: "warning" });
      return;
    }

    try {
      const success = await writeReview(request_id, request_details?.awarded?.company_user_id, rating, comment);
      if (success) {
        setShowModal(false);
        navigateTo(-1);
        setComment('');
      } else {
        // Show a toast notification for unsuccessful review submission
        Toastr({ message: 'Failed to submit review', type: "warning" });
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (showModal) {
      setComment('');
      setRating(5);
    }
  }, [showModal])

  useEffect(() => {
    if (componentValue) setRating(componentValue);
  }, [componentValue]);


  return (
    <CommonModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle={t("Write Review")}
      widthClass="w-[50vw]"
      mainContent={
        <>
          <input autoFocus className='caret-transparent outline-none border-none h-0 w-0 text-[0px] -mt-5' />
          <div className='flex justify-center pb-2 mt- sub-title text-cGray800'>{t("Select Rating")}</div>
          <form onSubmit={submitForm}>

            <div className='flex justify-center mb-5'>
              <Rating
                name="size-large"
                size="large"
                defaultValue={5}
                value={rating}
                max={5}
                onChange={(event, newValue) => setComponentValue(newValue)}
              />
            </div>

            <CommonInput
              className="my-3"
              name={'comment'} value={comment}
              onChange={(e) => {
                if (e.target.value !== " ") {
                  const trimmedValue = e.target.value.replace(/\s+/g, " ");
                  setComment(trimmedValue)
                }
              }}

              labelText={t('Feedback')}
              textarea={true} type={'text'}
              max_input={255}
              required={true}
              rows={3} max_rows={5}
              show_asterisk={false} />

            <div className='flex justify-end mt-s30'>
              <CommonButton
                btnLabel={t('Submit')}
                width='w-[100px]'
                type='submit'
              />

            </div>

          </form>
        </>
      }
    />
  )
}
