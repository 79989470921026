import React from 'react';
import useRequestStore from '../../../../../../app/stores/others/requestStore';
import CommonModal from '../../../../../../components/modal/CommonModal';
import Image from '../../../../../../components/image/Image';
import { iFavCompanyGray, iFavoriteIcon, iFavoriteIconGray } from '../../../../../../app/utility/imageImports';
import RatingFiveStar from '../../../../../../components/rating/RatingFiveStar';

export default function SelectedCompaniesModal({ openOnClick }) {
    const { setSelectedCompaniesModal, selectedCompaniesModal, request_details } = useRequestStore();
    return (
        <div>
            <CommonModal
                widthClass='w-[720px]'
                showModal={selectedCompaniesModal}
                setShowModal={() => setSelectedCompaniesModal(false)}
                modalTitle={"Selected Companies (" + request_details?.invitation_data?.length + ")"}
                mainContent={
                    <div className='mt-5 space-y-2 max-h-[calc(100vh-200px)] overflow-y-auto'>
                        {request_details?.invitation_data?.length ? request_details?.invitation_data?.map((item, index) => 
                        <CompanyCard 
                            key={index} 
                            item={item} 
                            title={item?.name} 
                            starCount={item?.rate} 
                            image={item?.image} 
                            isFavorite={item?.is_favorite} 
                            openOnClick={openOnClick}
                        />) : <div className='w-full flex justify-center'>No company has been invited</div>}
                    </div>
                }
            />
        </div>
    )
}

const CompanyCard = ({
    title = "",
    image = "",
    starCount = 0,
    onRemove = () => { },
    isFavorite,
    item,
    openOnClick
}) => {

    return (
        <div className='flex items-center space-x-2 p-3 border border-cGray300 rounded'>
            <Image
                src={image}
                alt='company-image'
                dummyImage={iFavCompanyGray}
                className='w-[90px] h-[90px] object-cover rounded-full border border-cGray300 cursor-pointer'
                onClick={() => openOnClick(item?.user_id)}
            />

            <div className=''>
                <div className='leading-6 limadi-medium mb-1 cursor-pointer' onClick={() => openOnClick(item?.user_id)}>{title}</div>
                <RatingFiveStar rating={starCount} />
                {isFavorite ? 
                    <img className='mt-s9 max-w-[15px] min-w-[15px] h-[15px]' src={iFavoriteIcon} alt='' />
                    :  
                    <></>
                }
            </div>
        </div>
    )
}