import React from 'react';
import RequestConfirmModal from '../../views/common/createRequest/components/RequestConfirmModal';
import AddFavoriteAddressModal from '../../views/common/favoriteAddress/modal/AddFavoriteAddressModal';
import AddressDetailsModal from '../../views/common/favoriteAddress/modal/AddressDetailsModal';
import EditFavoriteAddressModal from '../../views/common/favoriteAddress/modal/EditFavoriteAddressModal';
import FavoriteAddressDeleteModal from '../../views/common/favoriteAddress/modal/FavoriteAddressDeleteModal';
import NotificationDetailsModal from '../../views/common/notification/modal/NotificationDetailsModal';
import DeleteAccountModal from '../../views/common/settings/profile/modal/DeleteAccountModal';
import EditAboutCompanyModal from '../../views/common/settings/profile/modal/EditAboutCompanyModal';
import EditCompanyPolicyModal from '../../views/common/settings/profile/modal/EditCompanyPolicyModal';
import EditCustomerProfileModal from '../../views/common/settings/profile/modal/EditCustomerProfileModal';
import CarDeleteModal from '../../views/company/carManagement/modal/CarDeleteModal';
import DriverDeleteModal from '../../views/company/driverManager/modal/DriverDeleteModal';
import FilterGlobalRequest from '../../views/company/globalRequest/components/modals/FilterGlobalRequest';
import AddShift from '../../views/company/shiftManager/components/Modal/AddShift';
import ConfirmDeleteShift from '../../views/company/shiftManager/components/Modal/ConfirmDeleteShift';
import CustomStopDetails from '../../views/company/shiftManager/components/Modal/CustomStopDetails';
import EditShift from '../../views/company/shiftManager/components/Modal/EditShift';
import FilterShift from '../../views/company/shiftManager/components/Modal/FilterShift';
import AddFavoriteCompaniesModal from '../../views/customer/favoriteCompanies/modal/AddFavoriteCompaniesModal';
import RemoveFavoriteCompanyModal from '../../views/customer/favoriteCompanies/modal/RemoveFavoriteCompanyModal';
import ImageUploadViewModal from '../imageUpload/ImageUploadViewModal';
import LogoutModal from '../modal/LogoutModal';
import ReviewModal from '../../views/common/settings/profile/modal/ReviewModal';
import CommonShareModal from '../../views/common/requests/modal/CommonShareModal';
import AddChatModal from '../../views/common/chat/modal/AddChatModal';
import ChatDeleteModal from '../../views/common/chat/modal/ChatDeleteModal';
import CustomerDetailsModal from '../modal/CustomerDetailsModal';
import PreviousBackConfirmModal from '../modal/PreviousBackConfirmModal';
import DriverResetPasswordModal from '../../views/company/driverManager/modal/DriverResetPasswordModal';
import RequestTableViewModal from '../../views/common/requests/modal/RequestTableViewModal';
import OnGoingChatModal from '../../views/common/requests/modal/OnGoingChatModal';

const CommonModalArea = () => {
    return (
        <div>
            {/* CommonModalArea */}

            <ImageUploadViewModal />
            <LogoutModal />
            <PreviousBackConfirmModal />

            {/* Notification Modal */}
            <NotificationDetailsModal />

            {/* FavoriteAddressModal */}
            <AddFavoriteAddressModal />
            <FavoriteAddressDeleteModal />
            <EditFavoriteAddressModal />
            <AddressDetailsModal />

            {/*  Favorite Companies Modal */}
            <AddFavoriteCompaniesModal />

            {/* settings Modal */}
            <EditCustomerProfileModal />
            <EditCompanyPolicyModal />
            <DeleteAccountModal />
            <EditAboutCompanyModal />
            <ReviewModal />

            <RequestConfirmModal />


            {/* Favorite CompanyModal */}
            <RemoveFavoriteCompanyModal />


            {/********************** Company Modal Start ***********************/}

            {/* global_request_modals */}
            <FilterGlobalRequest />

            {/* car modals */}

            <CarDeleteModal />

            {/* driver modals */}
            <DriverDeleteModal />
            <DriverResetPasswordModal />

            {/* shift modals */}
            <AddShift />
            <EditShift />
            <ConfirmDeleteShift />
            <FilterShift />
            <CustomStopDetails />


            <CommonShareModal />
            <RequestTableViewModal />
            <OnGoingChatModal />
            {/********************** Company Modal End ***********************/}


            {/* chat */}
            <AddChatModal />
            <ChatDeleteModal />
            <CustomerDetailsModal />

        </div>
    )
}

export default CommonModalArea